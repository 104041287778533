export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8146 3.449V6.59899C14.8146 7.59311 15.6541 8.399 16.6896 8.399H19.0333M8.24791 12.001H15.7479M8.24791 16.5H11.9979M13.9343 3C14.4908 3 15.0185 3.23728 15.3747 3.64767L19.0654 7.89936C19.3462 8.22285 19.5 8.6306 19.5 9.05169V17.4C19.5 19.3882 17.8211 21 15.75 21H8.25C6.17894 21 4.5 19.3882 4.5 17.4V6.6C4.5 4.61177 6.17893 3 8.25 3H13.9343Z"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
