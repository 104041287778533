import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { Options, CardWrapper, Alert, Button, InlineOptions } from '@components'

import s from './DomainContactsCard.module.scss'
import { useState } from 'react'
import cn from 'classnames'
import { domainsOperations } from '@redux'
import { useDispatch } from 'react-redux'
import DomainsContactsEditModal from '../DomainsContactsEditModal/DomainsContactsEditModal'

export default function DomainContactsCard({
  contact,

  name,
  profiletype,
  spusage,
  setIsLoading,
  availableRights,
  getContactsHandler,
}) {
  const { t } = useTranslation(['domain_contacts', 'payers'])

  const dispatch = useDispatch()

  const mobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const [showRemoveAlert, setShowRemoveAlert] = useState(false)
  const [settingsForm, setSettingForm] = useState(false)

  const [editData, setEditData] = useState(null)

  const checkIfHasArr = availableRights?.toolbar?.toolgrp

  const isDeleteUserAllowedToRender = Array.isArray(checkIfHasArr)
    ? availableRights?.toolbar?.toolgrp[0]?.toolbtn?.some(el => el?.$name === 'delete')
    : false

  const isEditUserAllowed = Array.isArray(checkIfHasArr)
    ? availableRights?.toolbar?.toolgrp[0]?.toolbtn?.some(el => el?.$name === 'edit')
    : false

  const handleRemoveAlert = () => setShowRemoveAlert(p => !p)

  const handleSettingsForm = () => setSettingForm(p => !p)

  const handleRemoveContact = () => {
    dispatch(
      domainsOperations.removeDomainContacts({
        elid: contact?.id?.$,
        setIsLoading,
        getContactsHandler,
      }),
    )

    handleRemoveAlert()
  }

  const handleEditDomainContact = () => {
    dispatch(
      domainsOperations.getServiceProfile(
        contact?.id?.$,
        setEditData,
        null,
        null,
        handleSettingsForm,
      ),
    )
  }

  const handleEditContactSubmit = values => {
    const data = {
      sok: 'ok',
      ...values,
    }

    const onSuccessFunc = () => {
      handleSettingsForm()
      getContactsHandler()
    }

    dispatch(
      domainsOperations.getServiceProfile(
        contact?.id?.$,
        setEditData,
        null,
        data,
        onSuccessFunc,
      ),
    )
  }

  const options = [
    {
      label: t('domain_contacts.contact_cards.drop_list.settings'),
      icon: 'NewIconSettings',
      disabled: !isEditUserAllowed,
      onClick: handleEditDomainContact,
    },
    {
      label: t('domain_contacts.contact_cards.drop_list.delete'),
      icon: 'Delete',
      disabled: !isDeleteUserAllowedToRender,
      onClick: handleRemoveAlert,
    },
  ]

  return (
    <>
      {!mobile ? (
        <tr>
          <td>{contact?.id?.$}</td>
          <td data-wrap>{name}</td>
          <td>{t(profiletype, { ns: 'payers' })}</td>
          <td>{spusage}</td>
          <td data-target="options">
            <InlineOptions options={options} />
          </td>
        </tr>
      ) : (
        <CardWrapper className={s.min_card_wrapper}>
          <div className={s.email_wrapper}>
            <p className={s.label}>{t('domain_contacts.table_header.id')}:</p>
            <p className={s.user_email}>{contact?.id?.$}</p>
          </div>
          <div className={s.name_wrapper}>
            <p className={s.label}>{t('domain_contacts.table_header.name')}:</p>
            <p className={s.user_name}>{name}</p>
          </div>
          <div className={s.email_wrapper}>
            <p className={s.label}>{t('domain_contacts.table_header.contact_type')}:</p>
            <p className={s.user_email}>{t(profiletype, { ns: 'payers' })}</p>
          </div>
          <div className={s.email_wrapper}>
            <p className={s.label}>{t('domain_contacts.table_header.usage')}:</p>
            <p className={s.user_email}>{spusage}</p>
          </div>
          <div className={s.options_mobile}>
            <Options options={options} />
          </div>
        </CardWrapper>
      )}

      {settingsForm && (
        <DomainsContactsEditModal
          editData={editData}
          closeModal={handleSettingsForm}
          handleSubmit={handleEditContactSubmit}
          isOpen
        />
      )}

      <Alert
        hasControlBtns={true}
        datatestid="domain_contacts_alert_remove"
        isOpened={showRemoveAlert}
        controlAlert={handleRemoveAlert}
        title={t('domain_contacts.alerts.remove.title')}
        text={`${t('domain_contacts.alerts.remove.text')} ${name}?`}
        mainBtn={
          <Button
            datatestid="alert_removecontacts_test_status"
            size="small"
            label={t('domain_contacts.alerts.remove.btn_text_ok').toUpperCase()}
            type="button"
            className={cn({ [s.remove_btn]: true, [s.btn]: true })}
            onClick={handleRemoveContact}
          />
        }
      />
    </>
  )
}
