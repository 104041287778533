export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.0598 17.1419L14.4133 17.4955L14.7669 17.1419L14.4133 16.7884L14.0598 17.1419ZM17.8061 13.2918C17.668 13.531 17.75 13.8367 17.9891 13.9748C18.2283 14.1129 18.534 14.031 18.6721 13.7918L17.8061 13.2918ZM13.1611 17.5537C13.4341 17.5123 13.6218 17.2573 13.5803 16.9843C13.5389 16.7113 13.2839 16.5236 13.0109 16.5651L13.1611 17.5537ZM9.59282 13.3821L13.7062 17.4955L14.4133 16.7884L10.2999 12.675L9.59282 13.3821ZM13.7062 16.7884L9.59282 20.9018L10.2999 21.6089L14.4133 17.4955L13.7062 16.7884ZM18.6721 13.7918C19.5443 12.2811 19.8776 10.519 19.6174 8.79412L18.6286 8.9433C18.855 10.4441 18.565 11.9773 17.8061 13.2918L18.6721 13.7918ZM19.6174 8.79412C19.3571 7.06924 18.5188 5.48388 17.2398 4.29775L16.5598 5.031C17.6727 6.06305 18.4021 7.44248 18.6286 8.9433L19.6174 8.79412ZM17.2398 4.29775C15.9607 3.11162 14.3167 2.39505 12.5771 2.26543L12.5028 3.26267C14.0164 3.37545 15.4469 3.99894 16.5598 5.031L17.2398 4.29775ZM12.5771 2.26543C10.8376 2.13581 9.10553 2.60082 7.6648 3.58429L8.22859 4.41021C9.48217 3.55449 10.9892 3.14988 12.5028 3.26267L12.5771 2.26543ZM7.6648 3.58429C6.22407 4.56776 5.16005 6.01137 4.64706 7.67863L5.60284 7.97271C6.0492 6.52202 6.975 5.26593 8.22859 4.41021L7.6648 3.58429ZM4.64706 7.67863C4.13407 9.34589 4.20252 11.1379 4.84119 12.7612L5.77176 12.3951C5.21604 10.9827 5.15648 9.4234 5.60284 7.97271L4.64706 7.67863ZM4.84119 12.7612C5.47987 14.3845 6.65091 15.7427 8.16246 16.6134L8.6616 15.7469C7.34639 14.9893 6.32747 13.8075 5.77176 12.3951L4.84119 12.7612ZM8.16246 16.6134C9.67401 17.4841 11.4365 17.8157 13.1611 17.5537L13.0109 16.5651C11.5103 16.793 9.97681 16.5045 8.6616 15.7469L8.16246 16.6134Z"
        fill="currentColor"
      />
    </svg>
  )
}
