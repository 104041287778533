const SvgComponent = props => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 16.6693C9.03553 16.6693 8.125 16.6693 8.125 16.6693C5.47335 16.6693 4.14752 16.6693 3.32376 15.8325C2.5 14.9956 2.5 13.6488 2.5 10.955V9.05023C2.5 6.35649 2.5 5.00962 3.32376 4.17278C4.14752 3.33594 5.47335 3.33594 8.125 3.33594H11.875C14.5267 3.33594 15.8525 3.33594 16.6762 4.17278C17.5 5.00962 17.5 6.35649 17.5 9.05023V13"
      strokeLinecap="round"
    />
    <path d="M5.83594 6.66406H8.33594" strokeLinecap="round" />
    <path d="M15.8333 12.3333H14.1667C13.381 12.3333 12.9882 12.3333 12.7441 12.0892C12.5 11.8451 12.5 11.4523 12.5 10.6666C12.5 9.8809 12.5 9.4881 12.7441 9.244C12.9882 9 13.381 9 14.1667 9H15.8333C16.619 9 17.0118 9 17.2559 9.244C17.5 9.4881 17.5 9.8809 17.5 10.6666C17.5 11.4523 17.5 11.8451 17.2559 12.0892C17.0118 12.3333 16.619 12.3333 15.8333 12.3333Z" />
    <path
      d="M13 16.5L15.5 14M15.5 14L18 16.5M15.5 14V19"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
