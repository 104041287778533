const SvgComponent = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.39844 10.6376C2.39844 9.41344 2.95902 8.25666 3.91986 7.4981L9.41986 3.15599C10.8731 2.0087 12.9238 2.0087 14.377 3.15599L19.877 7.4981C20.8379 8.25666 21.3984 9.41344 21.3984 10.6376V17.1992C21.3984 19.4084 19.6076 21.1992 17.3984 21.1992H15.8984C15.3462 21.1992 14.8984 20.7515 14.8984 20.1992V17.1992C14.8984 16.0946 14.003 15.1992 12.8984 15.1992H10.8984C9.79387 15.1992 8.89844 16.0946 8.89844 17.1992V20.1992C8.89844 20.7515 8.45072 21.1992 7.89844 21.1992H6.39844C4.1893 21.1992 2.39844 19.4084 2.39844 17.1992L2.39844 10.6376Z"
      stroke="currentColor"
    />
  </svg>
)
export default SvgComponent
