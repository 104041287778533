import { ErrorMessage } from 'formik'
import cn from 'classnames'
export default function ErrorMessageStyled({ name, component = 'span', className }) {
  return (
    <ErrorMessage
      className={cn('error_message', className)}
      name={name}
      component={component}
    />
  )
}
