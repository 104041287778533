import { Suspense } from 'react'
import Navigation from './navigation/Navigation'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { BlockingModal, Icon, Loader, MaintenancePage } from '@components'
import entireStore from '@redux/store'
import 'dayjs/locale/ru'
import 'dayjs/locale/uk'
import 'react-toastify/dist/ReactToastify.css'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { PRODUCTION_DOMAIN } from './utils/constants'

const TOAST_TYPES = {
  info: {
    icon: 'NewInfoIcon',
    className: 'color-info',
  },
  success: {
    icon: 'NewIconDone_ring_round',
    className: 'color-success',
  },
  warning: {
    icon: 'NewWarningIcon',
    className: 'color-warn',
  },
  error: {
    icon: 'NewIconRemove_fill',
    className: 'color-error',
  },
}

const tagManagerArgs = {
  gtmId: 'GTM-T5QQQVX',
}

if (PRODUCTION_DOMAIN === window.location.host) {
  Sentry.init({
    dsn: 'https://eb3aa2d250244c83868a97ae819cef41@o1326854.ingest.sentry.io/6587426',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.7,
  })
  TagManager.initialize(tagManagerArgs)
}

export default function App() {
  return (
    <Provider store={entireStore.store}>
      <PersistGate loading={null} persistor={entireStore.persistor}>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Navigation />
          </Suspense>

          <ToastContainer
            autoClose={6000}
            position="bottom-right"
            icon={({ type }) => (
              <Icon
                name={TOAST_TYPES[type]?.icon}
                className={TOAST_TYPES[type]?.className}
              />
            )}
          />

          <Loader />
          <MaintenancePage />
          <BlockingModal />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}
