/* eslint-disable no-unused-vars */
import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import s from './DedicIPItem.module.scss'
import { Icon } from '@components/index'

export default function DedicIPItem({ ip, activeIP, setActiveIP }) {
  const { t } = useTranslation(['vds', 'dedicated_servers', 'other'])

  return (
    <tr
      onClick={() => setActiveIP(ip)}
      className={cn({
        [s.active_ip]: activeIP?.id?.$ === ip?.id?.$,
      })}
    >
      <td className={s.value}>
        {ip?.name?.$}
        {ip?.is_main?.$ === 'on' && (
          <div className={s.main_ip}>
            <Icon name="NewIconPennant" />
          </div>
        )}
      </td>
      <td className={s.value}>{ip?.mask?.$}</td>
      <td className={s.value}>{ip?.gateway?.$}</td>
      <td className={s.value} data-wrap>
        <span className="line_clamp clamp_2">{ip?.domain?.$}</span>
      </td>
      <td className={cn(s.value, s[ip?.ip_status?.$.trim().toLowerCase()])}>
        {t(ip?.ip_status?.$?.trim(), { ns: 'other' })}
      </td>
    </tr>
  )
}

DedicIPItem.propTypes = {
  server: PropTypes.object,
  setActiveServer: PropTypes.func,
  activeServerID: PropTypes.string,
}
