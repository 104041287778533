const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={20}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="M20 0H0v20h20V0Z" />
      </mask>
      <g mask="url(#b)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.35 15.615h17.813l.137.1c-.038.55.05 3.188 0 3.35 0 .025-.1.088-.137.138H1.35l-.1-.1v-3.425l.1-.063Z"
        />
        <path
          fill="currentColor"
          d="M4.863 16.934a.63.63 0 0 0-.625.637c0 .35.275.625.625.625a.63.63 0 0 0 .625-.637.619.619 0 0 0-.625-.625ZM7.363 16.934a.63.63 0 0 0-.625.637c0 .35.275.625.625.625a.63.63 0 0 0 .625-.637.619.619 0 0 0-.625-.625ZM9.863 16.934a.63.63 0 0 0-.625.637c0 .35.275.625.625.625a.63.63 0 0 0 .625-.637.619.619 0 0 0-.625-.625Z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit={10}
          d="M12.977 17.559h3.75"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M10.625 12.5S7.5 10.625 7.5 6.875s3.125-6.25 3.125-6.25"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M10.5 12.5s3.125-1.875 3.125-5.625S10.5.625 10.5.625"
        />
        <path
          stroke="currentColor"
          d="M10.625 13.125a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5Z"
        />
        <path stroke="currentColor" strokeLinecap="round" d="M4.375 6.875h12.5" />
      </g>
      <path stroke="currentColor" d="M10.5 1v12" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
