import { useEffect, useLayoutEffect, useReducer, useState } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik'
import {
  Button,
  Select,
  InputField,
  Modal,
  CustomPhoneInput,
  PayersList,
  PolicyAgreement,
  PaymentMethodList,
  PaymentInfo,
} from '@components'
import {
  billingOperations,
  settingsOperations,
  billingSelectors,
  payersSelectors,
  authSelectors,
  settingsSelectors,
  cartOperations,
  userSelectors,
  billingActions,
} from '@redux'

import * as Yup from 'yup'
import { checkIfTokenAlive, sortPaymethodList, parsePaymentInfo } from '@utils'
import { QIWI_PHONE_COUNTRIES, SBER_PHONE_COUNTRIES, OFFER_FIELD } from '@utils/constants'

import s from './ModalCreatePayment.module.scss'

export default function ModalCreatePayment() {
  const dispatch = useDispatch()

  const { t } = useTranslation(['billing', 'other', 'payers', 'cart', 'user_settings'])

  const [state, setState] = useReducer((state, action) => {
    return { ...state, ...action }
  }, {})

  const paymentData = useSelector(billingSelectors.getPaymentData)
  const payersList = useSelector(payersSelectors.getPayersList)

  const payersSelectedFields = useSelector(payersSelectors.getPayersSelectedFields)
  const payersData = useSelector(payersSelectors.getPayersData)

  const geoData = useSelector(authSelectors.getGeoData)

  const paymentsMethodList = useSelector(billingSelectors.getPaymentsMethodList)
  const paymentsCurrency = useSelector(billingSelectors.getPaymentsCurrencyList)

  const userEdit = useSelector(settingsSelectors.getUserEdit)
  const userInfo = useSelector(userSelectors.getUserInfo)

  const filteredPayment_method = state.additionalPayMethodts?.find(
    e => e?.$key === state.selectedAddPaymentMethod,
  )

  const [paymentsList, setPaymentsList] = useState([])

  useEffect(() => {
    const sortedList = sortPaymethodList(paymentsMethodList || [])

    setPaymentsList(sortedList)
  }, [paymentsMethodList])

  useLayoutEffect(() => {
    dispatch(billingActions.setIsModalCreatePaymentOpened(true))

    return () => {
      dispatch(billingActions.setPaymentData(null))
      closeModalHandler()
    }
  }, [])

  useEffect(() => {
    dispatch(settingsOperations.getUserEdit(userInfo.$id))
    dispatch(billingOperations.getPaymentMethod())
  }, [])

  useEffect(() => {
    if (state.additionalPayMethodts && state.additionalPayMethodts?.length > 0) {
      setState({ selectedAddPaymentMethod: state.additionalPayMethodts[0]?.$key })
    }
  }, [state.additionalPayMethodts])

  useEffect(() => {
    if (userEdit) {
      const findCountry = userEdit?.phone_countries?.find(
        e => e?.$key === userEdit?.phone_country,
      )
      const code = findCountry?.$image?.slice(-6, -4)?.toLowerCase()
      setState({ userCountryCode: code })
    }
  }, [userEdit])

  const closeModalHandler = () =>
    dispatch(billingActions.setIsModalCreatePaymentOpened(false))

  const createPaymentMethodHandler = values => {
    const data = {
      postcode_physical: values?.postcode_physical,
      eu_vat: values?.eu_vat,
      cnp: values?.cnp,
      city_legal: values?.city_physical,
      city_physical: values?.city_physical,
      address_legal: values?.address_physical,
      address_physical: values?.address_physical,
      postcode: values?.postcode_physical,
      city: values?.city_physical,
      address: values?.address_physical,
      country_physical:
        payersData.selectedPayerFields?.country ||
        payersData.selectedPayerFields?.country_physical ||
        payersSelectedFields?.country ||
        payersSelectedFields?.country_physical ||
        '',
      country_legal:
        payersData.selectedPayerFields?.country ||
        payersData.selectedPayerFields?.country_physical ||
        payersSelectedFields?.country ||
        payersSelectedFields?.country_physical ||
        '',
      profile: values?.profile,
      amount: values?.amount,
      payment_currency: values?.payment_currency?.value,
      paymethod: values?.selectedPayMethod?.paymethod?.$,
      paymethod_name: values?.selectedPayMethod?.name?.$,
      country:
        payersSelectedFields?.country || payersSelectedFields?.country_physical || '',
      profiletype: values?.profiletype || '',
      person:
        (payersList && payersList.find(e => e?.id?.$ === values?.profile)?.name?.$) ||
        values?.person ||
        ' ',
      director:
        (payersList && payersList.find(e => e?.id?.$ === values?.profile)?.name?.$) ||
        values?.person ||
        ' ',
      name: '',
      [OFFER_FIELD]: values[OFFER_FIELD] ? 'on' : 'off',
    }

    if (values?.payment_method) {
      data['payment_method'] = values?.payment_method
    }

    if (values?.phone && values?.phone?.length > 0) {
      data['phone'] = values?.phone
    }

    if (values?.alfabank_login && values?.alfabank_login?.length > 0) {
      data['alfabank_login'] = values?.alfabank_login
    }

    if (values.profiletype && values.profiletype !== '1') {
      data.jobtitle = payersData.selectedPayerFields?.jobtitle || 'jobtitle '
      data.rdirector = payersData.selectedPayerFields?.rdirector || 'rdirector '
      data.rjobtitle = payersData.selectedPayerFields?.rjobtitle || 'rjobtitle '
      data.ddirector = payersData.selectedPayerFields?.ddirector || 'ddirector '
      data.djobtitle = payersData.selectedPayerFields?.djobtitle || 'djobtitle '
      data.baseaction = payersData.selectedPayerFields?.baseaction || 'baseaction '
      data.name = values?.name || ''
    }

    if (paymentData) {
      data.billorder = paymentData.billorder.$
      data.payment_id = paymentData.payment_id.$
    }

    /** ------- Analytics ------- */
    if (!values?.profile) {
      // Facebook pixel event
      window.fbq?.('track', 'AddPaymentInfo')
      // Quora pixel event
      if (window.qp) window.qp('track', 'AddPaymentInfo')
      // GTM
      window.dataLayer?.push({ event: 'AddPaymentInfo' })
    }

    if (window.qp) window.qp('track', 'InitiateCheckout')
    /** ------- /Analytics ------- */

    dispatch(billingOperations.createPaymentMethod(data, closeModalHandler))
  }

  const validationSchema = Yup.object().shape({
    profile: payersList?.length !== 0 ? Yup.string().required(t('Choose payer')) : null,
    amount: Yup.number().when('selectedPayMethod', {
      is: value => !!value,
      then: Yup.number()
        .positive(`${t('The amount must be greater than')} €${state.minAmount}`)
        .min(
          state.minAmount,
          `${t('The amount must be greater than')} €${state.minAmount}`,
        )
        .max(
          state.maxAmount > 0 ? state.maxAmount : null,
          state.maxAmount > 0
            ? `${t('The amount must be less than')} €${state.maxAmount}`
            : null,
        )
        .required(t('Enter amount')),
    }),

    selectedPayMethod: Yup.object().required(t('Select a Payment Method')),
    payment_method:
      state.additionalPayMethodts && state.additionalPayMethodts?.length > 0
        ? Yup.string().required(t('Is a required field', { ns: 'other' }))
        : null,
    phone:
      !filteredPayment_method?.hide?.includes('phone') &&
      filteredPayment_method?.hide?.includes('alfabank_login')
        ? Yup.string()
            .phone(
              state.countryCode,
              false,
              t('Must be a valid phone number', { ns: 'user_settings' }),
            )
            .required(t('Is a required field', { ns: 'other' }))
        : null,
    alfabank_login:
      filteredPayment_method?.hide?.includes('phone') &&
      !filteredPayment_method?.hide?.includes('alfabank_login')
        ? Yup.string().required(t('Is a required field', { ns: 'other' }))
        : null,
    [OFFER_FIELD]: Yup.bool().oneOf(
      [true],
      t('confirm license agreement', { ns: 'other' }),
    ),
  })

  const renderPayersListTitle = () => (
    <div className={cn(s.formBlockTitle, s.formBlockTitlePayers)}>
      {t('Payers choice')}
    </div>
  )

  return (
    <div className={s.modalBg}>
      <Modal
        closeModal={closeModalHandler}
        isOpen
        className={cn(s.modal, {
          [s.visible]: payersSelectedFields && !!payersData.selectedPayerFields,
        })}
      >
        <Modal.Header>
          {paymentData
            ? `${t('Payment', { ns: 'cart' })} #${paymentData.payment_id.$}`
            : t('Replenishment')}
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            validateOnBlur={false}
            validateOnMount={false}
            validateOnChange={false}
            validationSchema={validationSchema}
            initialValues={{
              profile:
                payersData.selectedPayerFields?.profile ||
                payersList?.[payersList?.length - 1]?.id?.$ ||
                '',
              amount: paymentData?.amount.$ || state.amount || '',
              selectedPayMethod: state.selectedPayMethod || paymentsList?.[0],
              name: payersData.state?.name || payersData.selectedPayerFields?.name || '',
              address_physical:
                payersData.state?.addressPhysical ??
                payersData.selectedPayerFields?.address_physical ??
                '',
              postcode_physical:
                payersData.state?.postcodePhysical ??
                payersData.selectedPayerFields?.postcode_physical ??
                '',
              city_physical:
                payersData.state?.cityPhysical ??
                (payersData.selectedPayerFields?.city_physical ||
                  geoData?.clients_city ||
                  ''),
              person:
                payersData.state?.person ?? payersData.selectedPayerFields?.person ?? '',
              country:
                payersSelectedFields?.country ||
                payersSelectedFields?.country_physical ||
                '',
              profiletype:
                payersData.state?.profiletype ||
                payersData.selectedPayerFields?.profiletype ||
                payersSelectedFields?.profiletype,
              eu_vat:
                payersData.state?.euVat || payersData.selectedPayerFields?.eu_vat || '',
              cnp: payersData.state?.cnp || payersData.selectedPayerFields?.cnp || '',
              [OFFER_FIELD]: state.isPolicyChecked || false,
              payment_currency: {
                title: paymentsCurrency?.payment_currency_list?.filter(
                  e => e?.$key === paymentsCurrency?.payment_currency,
                )[0]?.$,
                value: paymentsCurrency?.payment_currency,
              },
              phone: state.phone || '',
              payment_method: state.selectedAddPaymentMethod || undefined,
              alfabank_login: state.alfaLogin || '',
            }}
            onSubmit={createPaymentMethodHandler}
          >
            {({ values, setFieldValue, touched, errors, handleBlur }) => {
              const getFieldErrorNames = formikErrors => {
                const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
                  Object.keys(obj).forEach(key => {
                    const value = obj[key]
                    if (!value) return

                    const nextKey = prefix ? `${prefix}.${key}` : key
                    if (typeof value === 'object') {
                      transformObjectToDotNotation(value, nextKey, result)
                    } else {
                      result.push(nextKey)
                    }
                  })

                  return result
                }

                return transformObjectToDotNotation(formikErrors)
              }

              const ScrollToFieldError = ({
                scrollBehavior = { behavior: 'smooth', block: 'center' },
              }) => {
                const { submitCount, isValid, errors } = useFormikContext()

                useEffect(() => {
                  if (isValid) return

                  const fieldErrorNames = getFieldErrorNames(errors)
                  if (fieldErrorNames.length <= 0) return

                  const element = document.querySelector(`[name='${fieldErrorNames[0]}']`)
                  if (!element) return

                  // Scroll to first known error into view
                  try {
                    element.scrollIntoView(scrollBehavior)
                  } catch (e) {
                    checkIfTokenAlive(e?.message, dispatch)
                  }

                  // Formik doesn't (yet) provide a callback for a client-failed submission,
                  // thus why this is implemented through a hook that listens to changes on
                  // the submit count.
                }, [submitCount])

                return null
              }

              const renderPhoneList = paymethod => {
                if (paymethod === 'qiwi') {
                  return QIWI_PHONE_COUNTRIES
                } else if (paymethod === 'sberbank') {
                  return SBER_PHONE_COUNTRIES
                } else {
                  return []
                }
              }

              const setAdditionalPayMethodts = value =>
                setState({ additionalPayMethodts: value })

              useEffect(() => {
                dispatch(
                  cartOperations.getPayMethodItem(
                    {
                      paymethod: paymentsList[0]?.paymethod?.$,
                    },
                    setAdditionalPayMethodts,
                  ),
                )

                setState({
                  minAmount: Number(paymentsList?.[0]?.payment_minamount?.$),
                  maxAmount: Number(paymentsList?.[0]?.payment_maxamount?.$),
                })
              }, [paymentsList])

              return (
                <Form id="payment" className={s.formBlockList}>
                  <ScrollToFieldError />
                  <div className={s.formBlock}>
                    <div className={s.formBlockTitle}>{t('Payment method')}</div>

                    <PaymentMethodList
                      methods={paymentsMethodList}
                      selectedMethod={values.selectedPayMethod}
                      onMethodSelect={method => {
                        setFieldValue('selectedPayMethod', method)
                        setState({
                          selectedPayMethod: method,
                          selectedAddPaymentMethod: undefined,
                        })
                      }}
                      state={state}
                      setState={setState}
                      setFieldValue={setFieldValue}
                      setAdditionalPayMethodts={setAdditionalPayMethodts}
                      wrapperClassName={s.blockWrapper}
                    />

                    <div className={cn(s.additionalPayMethodBlock, s.blockWrapper)}>
                      {state.additionalPayMethodts &&
                        state.additionalPayMethodts?.length > 1 && (
                          <Select
                            placeholder={t('Not chosen', { ns: 'other' })}
                            label={`${t('Payment method')} Yookasa:`}
                            value={values.payment_method}
                            getElement={item => {
                              setFieldValue('payment_method', item)
                              setState({ selectedAddPaymentMethod: item })
                            }}
                            dropdownClass={s.selectDropdownClass}
                            itemsList={state.additionalPayMethodts?.map(
                              ({ $key, $ }) => ({
                                label: t(`${$.trim()}`, { ns: 'billing' }),
                                value: $key,
                              }),
                            )}
                            error={errors.payment_method}
                            isRequired
                            isColored
                          />
                        )}

                      {filteredPayment_method?.hide?.includes('phone') &&
                        !filteredPayment_method?.hide?.includes('alfabank_login') && (
                          <InputField
                            inputWrapperClass={s.inputHeight}
                            name="alfabank_login"
                            label={`${t('Имя пользователя в Альфа-Клик', {
                              ns: 'payers',
                            })}:`}
                            placeholder={t('Enter data', { ns: 'other' })}
                            className={cn(s.inputBig, s.additionalSelectPayMentMethod)}
                            error={!!errors.alfabank_login}
                            touched={!!touched.alfabank_login}
                            isRequired
                            onChange={e => setState({ alfaLogin: e.target.value })}
                          />
                        )}

                      {!filteredPayment_method?.hide?.includes('phone') &&
                        filteredPayment_method?.hide?.includes('alfabank_login') && (
                          <CustomPhoneInput
                            value={values.phone}
                            label={`${t('Phone', { ns: 'other' })}:`}
                            handleBlur={handleBlur}
                            setFieldValue={(name, value) => {
                              setFieldValue(name, value)
                              setState({ phone: value })
                            }}
                            name="phone"
                            onlyCountries={renderPhoneList(filteredPayment_method?.$key)}
                            isRequired
                            setCountryCode={value => setState({ countryCode: value })}
                            country={
                              state.countryCode ||
                              renderPhoneList(filteredPayment_method?.$key)?.[0]
                            }
                            isColored
                          />
                        )}
                    </div>
                    <ErrorMessage
                      className={s.error_message}
                      name={'selectedPayMethod'}
                      component="span"
                    />
                  </div>

                  <div className={cn(s.formBlock)}>
                    <PayersList renderTitle={renderPayersListTitle} />
                  </div>

                  <div className={cn(s.formBlock)}>
                    <div className={s.formBlockTitle}>{t('Top-up amount')}</div>
                    <div className={s.paymentBlock}>
                      {paymentData ? (
                        <div className={s.priceBlock}>
                          {t('Total', { ns: 'cart' })} : <b>€{paymentData.amount.$}</b>
                        </div>
                      ) : (
                        <InputField
                          inputWrapperClass={s.inputHeight}
                          name="amount"
                          placeholder={'0.00'}
                          value={values.amount}
                          onChange={e => {
                            const amount = e?.target?.value.replace(/[^0-9.]/g, '')
                            setFieldValue('amount', amount)
                            setState({
                              amount,
                            })
                          }}
                          className={s.input}
                          error={!!errors.amount}
                          touched={!!touched.amount}
                          isRequired
                          disabled={!!paymentData}
                          iconRight={values?.payment_currency?.title}
                        />
                      )}
                    </div>

                    <PolicyAgreement
                      fieldName={OFFER_FIELD}
                      fieldValue={values[OFFER_FIELD]}
                      error={!!errors[OFFER_FIELD]}
                      touched={!!touched[OFFER_FIELD]}
                      onCheck={() =>
                        setState({ isPolicyChecked: !state.isPolicyChecked })
                      }
                    />
                  </div>

                  <PaymentInfo
                    selectedPayMethod={values?.selectedPayMethod}
                    parsedText={parsePaymentInfo(values?.selectedPayMethod?.desc?.$)}
                    readMoreThresholds={{
                      minWidth170: 170,
                      minWidth106: 110,
                      default: 150,
                    }}
                  />
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={s.saveBtn}
            size="medium"
            label={t('Pay')}
            type="submit"
            form="payment"
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
