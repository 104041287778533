import cn from 'classnames'
import PropTypes from 'prop-types'
import s from './RadioButton.module.scss'

import { Icon } from '@components'

export default function RadioButton({
  disabled,
  value,
  name,
  label,
  labelClassName,
  className,
  selected,
  setFieldValue,
  id,
}) {
  const handleRadioChange = () => {
    setFieldValue(name, value)
  }

  return (
    <button
      className={cn(s.radio_container, className)}
      tabIndex={0}
      onKeyUp={() => null}
      type="button"
      onClick={handleRadioChange}
      disabled={disabled}
      id={id}
    >
      <div
        className={cn(s.radio_outer_circle, {
          [s.selected]: selected,
        })}
      >
        <Icon name="NewIconTick" className={s.icon} />
      </div>
      {label && (
        <p
          className={cn(s.label_text, {
            [labelClassName]: labelClassName,
          })}
        >
          {label}
        </p>
      )}
    </button>
  )
}

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  selected: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
}
