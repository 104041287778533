import cn from 'classnames'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { CardWrapper, Options } from '@components'
import { contractOperations } from '@redux'
import { getFormatedDate, useOutsideAlerter } from '@utils'

import s from './ContractItem.module.scss'

export default function ContractItem(props) {
  const {
    contractNumber,
    clientName,
    contractName,
    id,
    signDate,
    endDate,
    companyName,
    status,
    selectedContract,
    setSelectedContract,
    rights,
    item,
    signal,
    setIsLoading,
  } = props

  const [dropDown, setDropDown] = useState(false)

  const mobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const desktop = useMediaQuery({ query: '(max-width: 1549px)' })

  const dispatch = useDispatch()

  const handleDownloadBtn = () => {
    dispatch(contractOperations.getPdfFile(id, contractNumber, signal, setIsLoading))
  }

  const dropDownRef = useRef(null)

  const dropdownHandler = () => {
    setDropDown(!dropDown)
  }

  useOutsideAlerter(dropDownRef, dropDown, dropdownHandler)

  const { t } = useTranslation(['contracts', 'other', 'billing'])
  const typeOfContract = contractName.split('|')[0]
  const slicedContractName = contractName.split('|')[1]

  const { date: createdate } = getFormatedDate({
    date: signDate,
    time: item.signdate_locale_time?.$,
  })
  const { date: expiredate } = getFormatedDate({ date: endDate })

  const options = [
    {
      label: t('Download', { ns: 'billing' }),
      icon: 'DownloadWithFolder',
      onClick: () => handleDownloadBtn(),
      disabled: !rights.download || !rights.print,
    },
  ]
  return (
    <>
      {!mobile ? (
        <tr
          tabIndex={0}
          onClick={() => setSelectedContract(id)}
          className={cn(s.tr, { [s.selected]: selectedContract })}
        >
          <td>{contractNumber}</td>
          <td>{createdate}</td>
          <td>{expiredate ? expiredate : '-'}</td>
          <td data-wrap>{clientName}</td>
          <td data-wrap>{companyName}</td>
          <td data-wrap>
            {`${t(typeOfContract.trim())} ${
              slicedContractName?.length > 0 ? `| ${slicedContractName}` : ''
            }`}
          </td>
          <td className={s.status}>{t(`${status}`)}</td>
        </tr>
      ) : (
        <CardWrapper className={s.card}>
          <div className={s.options}>
            <Options options={options} />
          </div>

          <div className={cn({ [s.card_item_wrapper]: true, [s.first_cell]: true })}>
            {desktop && (
              <span className={s.item_title}>{t('Number', { ns: 'other' })}:</span>
            )}
            <span className={s.item_text}>{contractNumber}</span>
          </div>
          <div className={cn({ [s.card_item_wrapper]: true, [s.second_cell]: true })}>
            {desktop && <span className={s.item_title}>{t('signdate')}:</span>}
            <span className={s.item_text}>{signDate}</span>
          </div>
          <div className={cn({ [s.card_item_wrapper]: true, [s.third_cell]: true })}>
            {desktop && <span className={s.item_title}>{t('enddate')}:</span>}
            <span className={s.item_text}>{endDate ? endDate : '-'}</span>
          </div>
          <div className={cn({ [s.card_item_wrapper]: true, [s.fourth_cell]: true })}>
            {desktop && (
              <span className={s.item_title}>{t('Payer', { ns: 'other' })}:</span>
            )}
            <span className={s.item_text}>{clientName}</span>
          </div>
          <div className={cn({ [s.card_item_wrapper]: true, [s.fifth_cell]: true })}>
            {desktop && <span className={s.item_title}>{t('company')}:</span>}
            <span className={s.item_text}>{companyName}</span>
          </div>
          <div className={cn({ [s.card_item_wrapper]: true, [s.sixth_cell]: true })}>
            {desktop && <span className={s.item_title}>{t('contract_name')}:</span>}
            <span className={s.item_text}>{`${t(typeOfContract.trim())} ${
              slicedContractName?.length > 0 ? `| ${slicedContractName}` : ''
            }`}</span>
          </div>
          <div className={cn({ [s.card_item_wrapper]: true, [s.seventh_cell]: true })}>
            {desktop && (
              <span className={s.item_title}>{t('status', { ns: 'other' })}:</span>
            )}
            <span className={cn({ [s.status]: true, [s.text]: true })}>
              {t(`${status}`)}
            </span>
          </div>
        </CardWrapper>
      )}
    </>
  )
}
