import PropTypes from 'prop-types'

import { createElement, useMemo } from 'react'
import s from './Cta.module.scss'
import cn from 'classnames'
import { Link } from 'react-router-dom'

/**
 * @param {Object} params
 * @param {'reset'|'submit'} params.type
 * @param {'button'|'a'|'navlink'} params.element
 * @param {'button'|'text'|'round-btn'} params.viewType
 * @param {'primary'|'secondary'|'third'|'fourth'} params.view
 * @returns
 */
export default function Cta({
  element = 'button',
  buttonType = 'button',
  viewType = 'button',
  view = 'primary',
  children,
  className,
  ...other
}) {
  const component = useMemo(() => {
    if (element === 'navlink') return Link
    return element
  }, [])

  const props = useMemo(() => {
    const params = {
      className: cn(s.cta, s[viewType], s[`${viewType}_${view}`], className),
    }
    switch (element) {
      case 'button':
        params.type = buttonType
        break
      default:
        break
    }

    return params
  }, [])

  return createElement(
    component,
    {
      ...props,
      ...other,
    },
    children,
  )
}

Cta.propTypes = {
  element: PropTypes.oneOf(['button', 'a', 'navlink']),
  buttonType: PropTypes.oneOf(['button', 'reset', 'submit']),
  view: PropTypes.oneOf(['primary', 'secondary', 'third', 'fourth']),
  viewType: PropTypes.oneOf(['button', 'text', 'round-btn', 'link']),
}
