export default function Tick(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path
        d="M2 9.54546L4.63349 11.4553C5.05711 11.7625 5.64594 11.6912 5.98395 11.2917L13 3"
        strokeLinecap="round"
      />
    </svg>
  )
}
