import { useEffect, useReducer, useState } from 'react'
import AsideServicesMenu from './AsideServicesMenu/AsideServicesMenu'
import Header from './Header/Header'
import dayjs from 'dayjs'
import {
  authSelectors,
  userOperations,
  userSelectors,
  selectors,
  userActions,
  vdsOperations,
  billingSelectors,
  cartOperations,
  billingOperations,
} from '@redux'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Div100vh from 'react-div-100vh'
import cn from 'classnames'
import { toast } from 'react-toastify'
import { FIRST_MONTH_HOSTING_DISCOUNT_ID } from '@utils/constants'
import {
  PromotionBanner,
  EmailTrigger,
  PaginationUpdated,
  NotificationMessage,
} from '@components'
import s from './Container.module.scss'
import { useLocation } from 'react-router-dom'
import * as route from '@src/routes'
import { ContainerProvider } from '@src/context/ContainerContext'
import { RightsProvider } from '@src/context/RightsContext'
import { includesStringOrRegExp } from '@utils'

function getFaviconEl() {
  return document.getElementById('favicon')
}

function getFaviconMobEl() {
  return document.getElementById('favicon_mob')
}

export default function Component({ children, fromPromotionLink }) {
  const { i18n, t } = useTranslation(['other', 'container'])

  const dispatch = useDispatch()

  const location = useLocation()

  const [contextData, setContextData] = useReducer(
    (state, action) => {
      return { ...state, ...action }
    },
    { pageTitle: '' },
  )

  useEffect(() => {
    const hasPagination = includesStringOrRegExp(
      route.routesWithPagination,
      location.pathname,
    )

    if (!hasPagination && contextData.pagination) {
      setContextData({ pagination: null })
    }
  }, [location])

  const [isShowPromotion, setIsShowPromotion] = useState(false)
  const [promotionType, setPromotionType] = useState(false)
  const [isUserClosedBanner, setIsUserClosedBanner] = useState(false)
  const promotionsList = useSelector(selectors.getPromotionsList)
  const paymentsList = useSelector(billingSelectors.getPaymentsReadOnlyList)

  useEffect(() => {
    dispatch(cartOperations.getSalesList())
    dispatch(billingOperations.setPaymentsFilters({ status: '4' }, true))
    dispatch(billingOperations.checkIsStripeAvailable())
    const isBannerClosed = localStorage.getItem('isBannerClosed')
    setIsUserClosedBanner(!!isBannerClosed)
  }, [])

  /**
   * This useEffect manages hosting promo banner for promotion "1 month of hosting for free"
   */
  useEffect(() => {
    if (isUserClosedBanner) return

    let isPromotionActive

    /**
     * This is for a new version of API
     */
    if (promotionsList?.[0]?.products) {
      isPromotionActive = promotionsList?.some(
        el => el.id?.$ === FIRST_MONTH_HOSTING_DISCOUNT_ID,
      )

      /**
       * This is for an old version of API and should be deleted after API update
       */
    } else {
      isPromotionActive = promotionsList?.some(
        el => el?.promotion?.$ === '1month-hosting',
      )
    }

    if (
      !isPromotionActive &&
      paymentsList?.length &&
      promotionsList &&
      fromPromotionLink
    ) {
      setPromotionType('third')
      setIsShowPromotion(true)
      return
    }

    if (paymentsList?.length && isPromotionActive) {
      setPromotionType('second')
      setIsShowPromotion(true)
    }

    if (paymentsList && !paymentsList?.length && promotionsList) {
      setPromotionType('first')
      setIsShowPromotion(true)
    }
  }, [promotionsList, paymentsList])

  dayjs.locale(i18n.language)

  const [loading, setLoading] = useState(true)

  const sessionId = useSelector(authSelectors.getSessionId)
  const scrollForbidden = useSelector(selectors.isScrollForbidden)
  const online = useSelector(selectors.onlineStatus)

  const userTickets = useSelector(userSelectors.getUserTickets)
  const areNewTickets = userTickets.some(ticket => ticket.tstatus.$ === 'New replies')

  useEffect(() => {
    dispatch(userOperations.getUserInfo(sessionId, setLoading))

    /* Check if user has ordered  VDS Xl tariffs */
    dispatch(
      vdsOperations.setVdsFilters({
        isDedic: true,
        checkOrderedVdsTariffs: true,
      }),
    )

    /* END Check if user has ordered  VDS Xl tariffs */
    let intervalId

    if (sessionId && online) {
      intervalId = setInterval(() => {
        dispatch(userOperations.getNotify())
        dispatch(userOperations.getTickets())
      }, 60000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [sessionId, online])

  useEffect(() => {
    dispatch(userActions.setIsNewMessage(areNewTickets))

    if (areNewTickets) {
      if (location.pathname.match(/\/support\/requests\/(\d+)/)) {
        return
      }

      const ticketId = userTickets.find(ticket => ticket.tstatus.$ === 'New replies')?.id
        .$

      toast.info(t('new_ticket_message', { id: ticketId }), {
        position: 'bottom-right',
        autoClose: 8000,
      })
    }
  }, [areNewTickets])

  const favicon = getFaviconEl()
  const favicon_mob = getFaviconMobEl()

  if (areNewTickets) {
    favicon.href = require('@images/favIcons/favicon_active.png')
    favicon_mob.href = require('@images/favIcons/favicon_192_active.png')
  } else {
    favicon.href = require('@images/favIcons/favicon.ico')
    favicon_mob.href = require('@images/favIcons/logo192.png')
  }
  // }

  if (loading) {
    return <></>
  }

  const closePromotionBanner = () => setIsShowPromotion(false)

  const showMaintenanceNotif = dayjs() < dayjs.tz('2024-12-04 05:00:00', 'Europe/Kyiv')

  return (
    <ContainerProvider value={{ contextData, setContextData }}>
      <RightsProvider>
        <AsideServicesMenu />
        <Header />
        <Div100vh className={s.container_wrapper}>
          <div
            className={cn(s.container, {
              [s.scroll_forbidden]: scrollForbidden,
            })}
            id="container"
          >
            {isShowPromotion &&
              promotionType &&
              location.pathname === route.SHARED_HOSTING && (
                <PromotionBanner
                  type={promotionType}
                  closeBanner={closePromotionBanner}
                />
              )}

            <div className={s.messages_wrapper}>
              <EmailTrigger />
              {showMaintenanceNotif && (
                // <StaticNotification
                //   title="maintenance_notif_title"
                //   // text="cloud_maintenance_text"
                // />
                <NotificationMessage type="warning" showTitle hasCrossBtn>
                  {t('maintenance_notif_title')}
                </NotificationMessage>
              )}
            </div>

            {contextData.pageTitle && (
              <div className={s.title_wrapper}>
                <h2 className={'headings_h1'}>
                  {t(`burger_menu.services.services_list.${contextData.pageTitle}`, {
                    ns: 'container',
                  })}
                </h2>
              </div>
            )}

            {children}
            <div
              className={cn(s.pagination_wrapper, {
                [s.show]: contextData.pagination?.p_elems > 5,
              })}
            >
              {contextData.pagination && (
                <PaginationUpdated
                  disabled={contextData.isLoading}
                  pagination={contextData.pagination}
                  getItemsHandler={contextData.paginationSettings?.getItems}
                  pageList={contextData.paginationSettings?.pageList}
                />
              )}
            </div>
          </div>
        </Div100vh>
      </RightsProvider>
    </ContainerProvider>
  )
}
