import s from './SupportTable.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { CardWrapper, CheckBox, Icon, TooltipWrapper } from '@components'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import * as route from '@src/routes'
import { useRef } from 'react'
import TicketStatus from '../TicketStatus/TicketStatus'
import getFormatedDate from '@utils/getFormatedDate'
import { useOpenInNewWindow } from '@utils/index'
import { FIRST_BRACKETS_REGEXP } from '@utils/constants'

export default function SupportTableItem({
  id,
  theme,
  date,
  status,
  setSelectedTickets,
  selected,
  el,
}) {
  const { t } = useTranslation(['support', 'other'])
  const mobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const navigate = useOpenInNewWindow()

  const onClickItemHandler = e => {
    if (
      checkboxCell.current?.contains(e.target) ||
      checkboxMobileCell.current?.contains(e.target)
    )
      return
    navigate(e, `${route.SUPPORT}/requests/${id}`)
  }

  const checkboxCell = useRef()
  const checkboxMobileCell = useRef()

  const formatedDate = dateString => {
    return getFormatedDate({ fullDate: dateString })
  }

  const ticketTheme = theme.replace(FIRST_BRACKETS_REGEXP, '')

  return (
    <>
      {!mobile ? (
        <tr onClick={onClickItemHandler} tabIndex={0}>
          <td ref={checkboxCell} data-target="checkbox">
            <CheckBox onClick={setSelectedTickets} value={selected} />
          </td>
          <td data-wrap>
            {el.create_user?.$ && (
              <div data-td-value-top className="line_clamp clamp_2">
                {t(el.create_user.$)}
              </div>
            )}
            <span className={cn('text-dark-50', 'body_s', s.item_id)}>ID: {el.id.$}</span>
          </td>
          <td data-wrap>
            <div className="in_row">
              {el?.abuse && (
                <TooltipWrapper
                  content={`${t('abuse_tooltip')} ${el?.abuse.$}`}
                  className={s.abuse_tooltip}
                >
                  <Icon name="Warning_triangle" className={s.abuse} />
                </TooltipWrapper>
              )}
              <div className="line_clamp clamp_2">{ticketTheme}</div>
            </div>
          </td>
          <td>
            <div data-td-value-top>{formatedDate(el.date_start.$)?.date} </div>
            <span className={s.item_text_time}>
              {formatedDate(el.date_start.$)?.time}
            </span>
          </td>
          <td>
            <div data-td-value-top>{formatedDate(date)?.date} </div>
            <span className={s.item_text_time}>{formatedDate(date)?.time}</span>
          </td>
          <td className={s.w_limit}>
            <TicketStatus status={status} />
          </td>
          {/* <td data-target="options">
            <div className={s.icon_wrapper}>
              <Icon name="NewIconMessageChat" className={cn({ [s.unread]: unread })} />
            </div>
          </td> */}
        </tr>
      ) : (
        <CardWrapper
          data-testid="archive_item"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={onClickItemHandler}
        >
          <div className={s.checkbox_wrapper} ref={checkboxMobileCell}>
            <CheckBox onClick={setSelectedTickets} value={selected} />
            <div className="in_row body_s">
              <span className={cn('text-dark-50 in_row gap-0')}>
                <Icon name="NewIconHash" /> ID
              </span>
              <span>{id}</span>
            </div>
          </div>
          <div className="body_m_medium in_row">
            {el?.abuse && (
              <TooltipWrapper
                content={`${t('abuse_tooltip')} ${el?.abuse.$}`}
                className={s.abuse_tooltip}
              >
                <Icon name="Warning_triangle" className={s.abuse} />
              </TooltipWrapper>
            )}
            <p className={'line_clamp clamp_2'}>{ticketTheme}</p>
          </div>

          <CardWrapper.Grid className={s.body}>
            <div className="in_row">
              <Icon name="User" /> {t('initiator')}:
            </div>
            <div>{el.create_user?.$ ? t(el.create_user.$) : '-'}</div>

            <div className="in_row">
              <Icon name="Calendar" /> {t('created')}:
            </div>
            <div className={cn(s.item_text, s.third_item)}>
              {formatedDate(date)?.date}{' '}
              <span className={s.item_text_time}>{formatedDate(date)?.time}</span>
            </div>
            <div className="in_row">
              <Icon name="NewIconStatus" /> {t('status', { ns: 'other' })}:
            </div>
            <TicketStatus status={status} />
          </CardWrapper.Grid>
        </CardWrapper>
      )}
    </>
  )
}
SupportTableItem.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  setSelectedTickets: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
}

SupportTableItem.defaultProps = {
  id: '',
  theme: '',
  date: '',
  status: '',
  setSelectedTickets: () => null,
  selected: null,
}
