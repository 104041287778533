import { useRef, useState } from 'react'
import s from './PayersTable.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { CardWrapper, Modal, Cta, InlineOptions, Options } from '@components'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useOutsideAlerter } from '@utils'
import { payersOperations } from '@redux'
import { useDispatch } from 'react-redux'

export default function Component(props) {
  const { id, name, status, editHanler } = props

  const dispatch = useDispatch()

  const { t } = useTranslation(['payers', 'other'])

  const mobile = useMediaQuery({ query: '(max-width: 767px)' })

  const dropDownEl = useRef()
  const [isOpened, setIsOpened] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  const closeMenuHandler = () => {
    setIsOpened(!isOpened)
  }

  useOutsideAlerter(dropDownEl, isOpened, closeMenuHandler)

  const deletePayerHandler = () => {
    dispatch(payersOperations.deletePayer(id))
    setIsDeleteModal(!isDeleteModal)
  }

  const deleteModalHandler = () => setIsDeleteModal(true)

  const options = [
    {
      label: t('Edit'),
      icon: 'NewIconSettings',
      onClick: editHanler,
    },
    {
      label: t('Delete'),
      icon: 'Delete',
      onClick: deleteModalHandler,
      isDelete: true,
    },
  ]

  return (
    <>
      {!mobile ? (
        <tr>
          <td>{id}</td>
          <td>{name}</td>
          <td>{status}</td>
          <td data-target="options">
            <InlineOptions options={options} />
          </td>
        </tr>
      ) : (
        <CardWrapper className={s.item}>
          <CardWrapper.Grid>
            <div>{t('Id')}:</div>
            <div className={cn(s.item_text, s.first_item)}>{id}</div>

            <div>{t('Name')}:</div>
            <div className={cn(s.item_text, s.second_item)}>{name}</div>

            <div>{t('Payer status')}:</div>
            <div className={cn(s.item_text, s.fourth_item)}>{t(status)}</div>

            <div className={s.tableBlockFourth}>
              <Options mobileModalTitle={t('Payer')} options={options} useModalOnMobile />
            </div>
          </CardWrapper.Grid>
        </CardWrapper>
      )}

      <Modal
        simple
        isOpen={isDeleteModal}
        closeModal={() => setIsDeleteModal(false)}
        className={s.modal}
      >
        <Modal.Header />
        <Modal.Body>
          <div className={s.modalDeleteText}>
            {t('Are you sure you want to remove {{name}} from your list of payers?', {
              name: name,
            })}
          </div>
        </Modal.Body>
        <Modal.Footer column>
          <Cta
            buttonType="button"
            view="primary"
            onClick={deletePayerHandler}
            className={'body_m'}
          >
            {t('Delete')}
          </Cta>

          <Cta
            buttonType="button"
            view="secondary"
            onClick={() => setIsDeleteModal(false)}
            className={'body_m'}
          >
            {t('Cancel', { ns: 'other' })}
          </Cta>
        </Modal.Footer>
      </Modal>
    </>
  )
}
Component.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  unread: PropTypes.bool,
  setSelctedTicket: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
}

Component.defaultProps = {
  id: '',
  theme: '',
  date: '',
  status: '',
  unread: false,
  setSelctedTicket: () => null,
  selected: null,
}
