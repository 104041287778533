const SvgComponent = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M2.1563 14.5116C1.84372 14.8241 1.66806 15.248 1.66797 15.69V17.5C1.66797 17.721 1.75577 17.9329 1.91205 18.0892C2.06833 18.2455 2.28029 18.3333 2.5013 18.3333H5.0013C5.22232 18.3333 5.43428 18.2455 5.59056 18.0892C5.74684 17.9329 5.83464 17.721 5.83464 17.5V16.6666C5.83464 16.4456 5.92243 16.2337 6.07871 16.0774C6.23499 15.9211 6.44695 15.8333 6.66797 15.8333H7.5013C7.72232 15.8333 7.93428 15.7455 8.09056 15.5892C8.24684 15.4329 8.33464 15.221 8.33464 15V14.1666C8.33464 13.9456 8.42243 13.7337 8.57871 13.5774C8.73499 13.4211 8.94695 13.3333 9.16797 13.3333H9.3113C9.75329 13.3332 10.1771 13.1576 10.4896 12.845L11.168 12.1666C12.3262 12.5701 13.587 12.5685 14.7442 12.1623C15.9014 11.756 16.8865 10.969 17.5383 9.93007C18.1901 8.89115 18.47 7.66178 18.3322 6.44309C18.1945 5.22439 17.6472 4.08851 16.78 3.22128C15.9128 2.35404 14.7769 1.80679 13.5582 1.66905C12.3395 1.5313 11.1101 1.81122 10.0712 2.46301C9.03227 3.11479 8.2453 4.09986 7.83901 5.25707C7.43272 6.41428 7.43118 7.67511 7.83463 8.8333L2.1563 14.5116Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7526 6.66732C13.9827 6.66732 14.1693 6.48077 14.1693 6.25065C14.1693 6.02053 13.9827 5.83398 13.7526 5.83398C13.5225 5.83398 13.3359 6.02053 13.3359 6.25065C13.3359 6.48077 13.5225 6.66732 13.7526 6.66732Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
