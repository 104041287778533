import { useRef } from 'react'
import s from './SshList.module.scss'
import { Options, CardWrapper } from '@components'
import { cloudVpsActions } from '@redux'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import getFormatedDate from '@utils/getFormatedDate'

export default function SshItemMobile({ item }) {
  const { t } = useTranslation(['cloud_vps', 'vds'])

  const optionsBlock = useRef()
  const dispatch = useDispatch()

  const options = [
    {
      label: t('Rename'),
      icon: 'NewIconEdit',
      onClick: () => dispatch(cloudVpsActions.setItemForModals({ ssh_rename: item })),
    },
    {
      label: t('Delete'),
      icon: 'Delete',
      onClick: () => dispatch(cloudVpsActions.setItemForModals({ ssh_delete: item })),
      isDelete: true,
    },
  ]
  const [date, time] = item?.cdate?.$.split(' ') || []
  const { fullDateString } = getFormatedDate({ date, time })

  return (
    <CardWrapper className={s.mobile_item} tabIndex={0} onKeyUp={() => {}} role="button">
      <div className={s.mobile_item__header}>
        <div ref={optionsBlock} className={s.mobile_item_options_wrapper}>
          <Options options={options} useModalOnMobile />
        </div>
        <div className={s.mobile_item__header_name}>
          <p className={s.mobile_item__name}>{item?.comment?.$}</p>
        </div>
      </div>
      <CardWrapper.Grid>
        <p className={s.mobile_item__param}>{t('Created at')}</p>
        <p className={s.mobile_item__value}>{fullDateString}</p>

        <p className={s.mobile_item__param}>{t('Fingerprint')}</p>
        <p className={s.mobile_item__value}>{item?.fingerprint?.$}</p>
      </CardWrapper.Grid>
    </CardWrapper>
  )
}
