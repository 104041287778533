import { useTranslation } from 'react-i18next'
import { Modal } from '@components'

export default function Component(props) {
  const { t } = useTranslation(['domains', 'other'])

  const { closeModal, whoisData, isOpen } = props

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} isClickOutside>
      <Modal.Header>{t('Domain Information')}</Modal.Header>
      <Modal.Body>{whoisData}</Modal.Body>
    </Modal>
  )
}
