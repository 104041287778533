export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.66714 10.6537C2.44428 10.2462 2.44429 9.75371 2.66715 9.3462C4.08334 6.75669 6.83613 5 9.99997 5C13.1639 5 15.9167 6.75674 17.3329 9.34632C17.5557 9.75383 17.5557 10.2463 17.3328 10.6538C15.9167 13.2433 13.1639 15 10 15C6.83614 15 4.08331 13.2433 2.66714 10.6537Z"
        stroke="#2A1C3C"
      />
      <circle cx="10" cy="10" r="2.5" stroke="#2A1C3C" />
    </svg>
  )
}
