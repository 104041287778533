import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import * as Yup from 'yup'
import { authOperations } from '@redux'
import * as routes from '@src/routes'
import { Cta, InputField } from '@components'
import { Form, Formik } from 'formik'
import {
  EMAIL_SPECIAL_CHARACTERS_REGEX,
  CYRILLIC_ALPHABET_PROHIBITED,
  SUPPORT_MAIL,
} from '@utils/constants'

import s from '../AuthStyles.module.scss'

export default function PasswordReset() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('auth')

  const [sentToEmail, setSentToEmail] = useState('')
  // const [isValid, setIsValid] = useState('')

  const [errorType, setErrorType] = useState('')
  const [errorTime, setErrorTime] = useState('')

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EMAIL_SPECIAL_CHARACTERS_REGEX, t('warnings.special_characters'))
      .matches(CYRILLIC_ALPHABET_PROHIBITED, t('warnings.cyrillic_prohibited'))
      .email(t('warnings.invalid_email'))
      .required(t('warnings.email_required')),
  })

  // const handleInputChange = event => {
  //   setEmail(event.target.value)
  //   setErrorType('')
  //   setErrorTime('')
  // }

  // const validateEmail = async () => {
  //   const valid = await validationSchema.isValid(email)
  //   setIsValid(valid ? 'valid' : 'invalid')
  //   return valid
  // }

  const handleSubmit = ({ email }) => {
    const successCallback = () => setSentToEmail(email)
    dispatch(authOperations.reset({ email, successCallback, setErrorType, setErrorTime }))
  }

  return !sentToEmail ? (
    <div className={cn('box_styles', s.form_wrapper)}>
      <h3 className={s.title}>{t('reset.form_title')}</h3>
      <div className={s.form} onSubmit={handleSubmit}>
        {errorType && (
          <div className={s.credentials_error}>
            {t(`warnings.${errorType}`, { time: errorTime })}
          </div>
        )}

        <p className={cn('body_m_light', s.description)}>
          {t('reset.description_1')}
          <a className={s.mail_link} href={`mailto:${SUPPORT_MAIL}`}>
            {SUPPORT_MAIL}
          </a>
          {t('reset.description_2')}
        </p>

        <Formik
          initialValues={{ email: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, errors, touched }) => {
            return (
              <Form id="reset_form">
                <InputField
                  // className={s.field_wrapper}
                  label={t('email_label')}
                  placeholder={t('email_placeholder')}
                  name="email"
                  error={!!errors.email}
                  touched={!!touched.email}
                  autoComplete="on"
                  onBlur={e => {
                    const trimmedValue = e.target.value.trim()
                    setFieldValue('email', trimmedValue)
                  }}
                />
              </Form>
            )
          }}
        </Formik>

        <Cta buttonType="submit" form="reset_form">
          {t('reset.send_btn')}
        </Cta>
      </div>

      <Link className={cn(s.reset_pass_link, s.pink_link)} to={routes.LOGIN}>
        {t('reset.cancel_link')}
      </Link>
    </div>
  ) : (
    <div className={cn('box_styles', s.form_wrapper)}>
      <h3 className={s.title}>{t('reset.confirmation_title')}</h3>
      <form className={s.form}>
        <p className={cn('body_m_light', s.description)}>
          {t('reset.confirm_descr_1', { email: sentToEmail })}
        </p>

        <p className={cn('body_m_light', s.description)}>
          {t('reset.confirm_descr_2')}
          <a className={s.mail_link} href={`mailto:${SUPPORT_MAIL}`}>
            {SUPPORT_MAIL}
          </a>
          {t('reset.confirm_descr_3')}
        </p>

        <Cta onClick={() => navigate(routes.LOGIN, { replace: true })}>OK</Cta>
      </form>
    </div>
  )
}
