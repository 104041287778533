export default function Coins(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.6683 4.33189C12.6683 5.25236 10.579 5.99856 8.00163 5.99856C5.4243 5.99856 3.33496 5.25236 3.33496 4.33189M12.6683 4.33189C12.6683 3.41141 10.579 2.66522 8.00163 2.66522C5.4243 2.66522 3.33496 3.41141 3.33496 4.33189M12.6683 4.33189V12.3319C12.6683 13.2524 10.579 13.9986 8.00163 13.9986C5.4243 13.9986 3.33496 13.2524 3.33496 12.3319V4.33189M12.6683 6.99856C12.6683 7.91903 10.579 8.66522 8.00163 8.66522C5.4243 8.66522 3.33496 7.91903 3.33496 6.99856M12.6683 9.66522C12.6683 10.5857 10.579 11.3319 8.00163 11.3319C5.4243 11.3319 3.33496 10.5857 3.33496 9.66522" />
    </svg>
  )
}
