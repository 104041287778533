const SvgComponent = props => (
  <svg
    width={19}
    height={9}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.406 0c-2.916 0-4.37 2.169-5.653 4.082C7.522 5.917 6.459 7.5 4.344 7.5 2.776 7.5 1.5 6.154 1.5 4.5c0-1.655 1.275-3 2.843-3 .96 0 1.755.317 2.506 1 .171.153.336.323.488.5a.751.751 0 0 0 1.136-.983 7.118 7.118 0 0 0-.618-.63C6.829.453 5.68 0 4.344 0 1.949 0 0 2.018 0 4.5S1.95 9 4.344 9c2.916 0 4.37-2.169 5.653-4.082C11.228 3.083 12.291 1.5 14.406 1.5c1.568 0 2.844 1.345 2.844 3 0 1.654-1.275 3-2.842 3-.96 0-1.756-.317-2.507-1a5.59 5.59 0 0 1-.488-.5.751.751 0 0 0-1.135.982c.195.226.404.44.617.63C11.921 8.547 13.07 9 14.406 9c2.395 0 4.344-2.018 4.344-4.5S16.8 0 14.406 0Z" />
    {/* <defs>
      <linearGradient
        id="a"
        x1={9.375}
        y1={0}
        x2={9.375}
        y2={9}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#924EE8" />
        <stop offset={1} stopColor="#C630BB" />
      </linearGradient>
    </defs> */}
  </svg>
)

export default SvgComponent
