import cn from 'classnames'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import s from './DedicItem.module.scss'
import {
  CheckBox,
  EditCell,
  TooltipWrapper,
  ServerState,
  Options,
  CopyText,
  AutoprolongIcon,
} from '@components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as route from '@src/routes'
import { dedicOperations } from '@redux'
import {
  formatCountryName,
  getFlagFromCountryName,
  getFormatedDate,
  getImageIconName,
  getItemCostValue,
  isDisabledDedicTariff,
  isUnpaidOrder,
  useCreateTicketOption,
  useOpenInNewWindow,
} from '@utils'
import { DEDIC_STATUSES } from '@src/utils/constants'

export default function DedicItem({
  server,
  setElidForEditModal,
  setElForProlongModal,
  setElidForHistoryModal,
  setElidForInstructionModal,
  setElidForRebootModal,
  activeServices,
  setActiveServices,
  rights,
  handleEditSubmit,
  setIdForDeleteModal,
  unpaidItems,
}) {
  const { t } = useTranslation([
    'vds',
    'other',
    'dedicated_servers',
    'cloud_vps',
    'countries',
  ])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleNewWindowNavigate = useOpenInNewWindow()

  const [originName, setOriginName] = useState('')

  const optionsCell = useRef()
  const servernameCell = useRef()
  const checkboxCell = useRef()

  const unpaidOption = isUnpaidOrder(server, unpaidItems)
  const createTicketOption = useCreateTicketOption(server.id.$)

  useEffect(() => {
    if (server?.server_name?.$) {
      setOriginName(server?.server_name?.$)
    }
  }, [server])

  const isToolsBtnVisible =
    Object.keys(rights)?.filter(key => key !== 'ask' && key !== 'filter' && key !== 'new')
      .length > 0

  const isActive = activeServices?.some(service => service?.id?.$ === server?.id?.$)

  const toggleIsActiveHandler = e => {
    e?.stopPropagation()
    isActive
      ? setActiveServices(activeServices?.filter(item => item?.id?.$ !== server?.id?.$))
      : setActiveServices([...activeServices, server])
  }

  const handleToolBtnClick = fn => {
    fn()
  }

  const editNameHandler = value => {
    handleEditSubmit(server?.id?.$, value)
    setOriginName(value)
  }

  const options = [
    unpaidOption,
    {
      label: t('instruction'),
      icon: 'Instruction',
      disabled: server?.status?.$ === DEDIC_STATUSES.ordered || !rights?.instruction,
      onClick: () => handleToolBtnClick(setElidForInstructionModal),
    },
    {
      label: t('go_to_panel'),
      icon: 'ToPanel',
      disabled:
        server.transition?.$ !== 'on' ||
        server?.status?.$ !== DEDIC_STATUSES.active ||
        !rights?.gotoserver,
      onClick: () => dispatch(dedicOperations.goToPanel(server.id.$)),
    },
    {
      label: t('prolong'),
      icon: 'Renew',
      disabled:
        server?.status?.$ === DEDIC_STATUSES.ordered ||
        server?.status?.$ === DEDIC_STATUSES.procesing ||
        !rights?.prolong ||
        isDisabledDedicTariff(server?.name?.$),
      onClick: () => handleToolBtnClick(setElForProlongModal),
    },
    {
      label: t('edit', { ns: 'other' }),
      icon: 'NewIconEdit',
      disabled:
        (server?.status?.$ !== DEDIC_STATUSES.stopped &&
          server?.status?.$ !== DEDIC_STATUSES.active) ||
        !rights?.edit,
      onClick: () => handleToolBtnClick(setElidForEditModal),
    },
    {
      label: t('reload'),
      icon: 'Reboot',
      disabled: server.show_reboot?.$ !== 'on' || !rights?.reboot,
      onClick: () => handleToolBtnClick(setElidForRebootModal),
    },
    {
      label: t('ip_addresses'),
      icon: 'IP',
      disabled:
        server.has_ip_pricelist?.$ !== 'on' ||
        server?.status?.$ === DEDIC_STATUSES.procesing ||
        !rights?.ip,
      onClick: () =>
        navigate(route.DEDICATED_SERVERS_IP, {
          state: { plid: server?.id?.$, isIpAllowedRender: rights?.ip },
          replace: true,
        }),
    },
    {
      label: t('history'),
      icon: 'HistoryTimer',
      disabled: server?.status?.$ === DEDIC_STATUSES.ordered || !rights?.history,
      onClick: () => handleToolBtnClick(setElidForHistoryModal),
    },
    createTicketOption,
    {
      label: t('delete', { ns: 'other' }),
      icon: 'Delete',
      disabled:
        server?.status?.$ === DEDIC_STATUSES.procesing ||
        server?.scheduledclose?.$ === 'on' ||
        !rights?.delete,
      onClick: () => setIdForDeleteModal([server.id.$]),
      isDelete: true,
    },
  ]

  const { date: createdate } = getFormatedDate({
    date: server.createdate?.$,
    time: server.i_opentime?.$,
  })
  const { date: expiredate } = getFormatedDate({
    date: server.expiredate?.$,
    time: server.i_expiretime?.$,
  })

  const itemCountry = formatCountryName(server, t)

  return (
    <tr
      onClick={e => {
        if (
          optionsCell.current.contains(e.target) ||
          checkboxCell.current.contains(e.target) ||
          servernameCell.current.contains(e.target)
        ) {
          return
        }
        handleNewWindowNavigate(e, `${route.DEDICATED_SERVERS}/${server.id.$}`, {
          state: server,
        })
      }}
      data-stop-propagation
      tabIndex={0}
    >
      <td ref={checkboxCell} data-target="checkbox">
        <CheckBox
          className={s.check_box}
          value={isActive}
          onClick={toggleIsActiveHandler}
        />
      </td>
      <td ref={servernameCell} data-target="name">
        <EditCell
          originName={originName}
          onSubmit={editNameHandler}
          placeholder={t(originName || t('server_placeholder', { ns: 'vds' }), {
            ns: 'vds',
          })}
        />
        <span className={cn('text-dark-50', 'body_s', s.item_id)}>
          ID: {server?.id?.$}
        </span>
      </td>
      <td data-wrap>
        <div data-td-value-top> {server?.pricelist?.$.split(' / ')[0]}</div>
        <span>{getItemCostValue(server?.cost?.$, t)}</span>
      </td>
      <td>
        <ServerState server={server} />
      </td>
      <td>
        {server?.datacentername && (
          <TooltipWrapper className={s.popup} content={itemCountry?.DCName}>
            <img
              src={require(`@images/countryFlags/${getFlagFromCountryName(
                itemCountry?.countryName,
              )}.png`)}
              width={20}
              height={20}
              alt={itemCountry?.countryName}
            />
          </TooltipWrapper>
        )}
      </td>
      <td>{createdate}</td>
      <td>
        <div className="in_row align_start" data-td-value-top>
          {expiredate} <AutoprolongIcon item={server} />
        </div>
        <ServerState server={server} onlyAutoDelete />
      </td>
      <td>
        {server?.ostempl?.$ && (
          <TooltipWrapper
            className={s.popup}
            wrapperClassName={s.popup__wrapper}
            content={server?.ostempl?.$}
          >
            <img
              src={require(`@images/soft_os_icons/${getImageIconName(
                server?.ostempl?.$,
              )}.png`)}
              alt={server?.ostempl?.$}
            />
          </TooltipWrapper>
        )}
      </td>
      <td>
        {server?.ip?.$ && (
          <CopyText
            text={server?.ip?.$}
            promptTextOnHover={server?.ip?.$}
            promptText={t('ip_address_copied', { ns: 'cloud_vps' })}
            label="IP"
          />
        )}
      </td>
      <td ref={optionsCell} data-stop-propagation data-target="options">
        {isToolsBtnVisible && (
          <Options options={options} columns={2} dropdownClassName={s.options_dopdown} />
        )}
      </td>
    </tr>
  )
}

DedicItem.propTypes = {
  server: PropTypes.object,
  setElidForEditModal: PropTypes.func,
  setElForProlongModal: PropTypes.func,
  setElidForHistoryModal: PropTypes.func,
  setElidForInstructionModal: PropTypes.func,
  setElidForRebootModal: PropTypes.func,
  setActiveServices: PropTypes.func,
  activeServices: PropTypes.arrayOf(PropTypes.object),
  rights: PropTypes.object,
  handleEditSubmit: PropTypes.func,
}
