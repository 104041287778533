import cn from 'classnames'
import { parseLocations } from '@utils'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import s from './BreadCrumbs.module.scss'

export default function Component() {
  const { t } = useTranslation('crumbs')

  const pathnames = parseLocations()

  return (
    <div className={s.crumbs}>
      {pathnames?.map((e, index) => {
        const disabled = pathnames?.length === index + 1
        const hidden = e === 'services' || e === 'support'

        const renderPath = () => {
          const pathes = pathnames.filter((_, i) => i <= index)
          return `/${pathes?.join('/')}`
        }
        return (
          <div className={s.linksBlock} key={e}>
            <Link
              className={cn(s.links, 'body_light_s_m', {
                [s.disabled]: disabled,
                [s.hidden]: hidden,
              })}
              to={renderPath()}
            >
              {t(e)}
            </Link>
            <span className={s.slash}>{pathnames?.length !== index + 1 && '/'}</span>
          </div>
        )
      })}
    </div>
  )
}
