import s from './Table.module.scss'
import cn from 'classnames'
export default function Table({
  children,
  stickyHeader,
  isItemsClickable,
  className,
  vAlignTop,
  color = 'primary',
}) {
  return (
    <table
      className={cn(s.table, s[color], className, {
        [s.sticky_header]: stickyHeader,
        [s.item_clickable]: isItemsClickable,
        [s.v_align_top]: vAlignTop,
      })}
    >
      {children}
    </table>
  )
}
