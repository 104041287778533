const SvgComponent = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="2.00391" y="6.00195" width="20" height="16" rx="5" />
    <path d="M19.0039 6.5V6.5C19.0039 4.17692 16.8717 2.43898 14.5964 2.90744L5.99564 4.67817C3.67159 5.15665 2.00391 7.20267 2.00391 9.57546L2.00391 13" />
    <path d="M6.00391 17.5H12.0039" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.0039 13.998C15.0039 12.6173 16.1232 11.498 17.5039 11.498H22.0039V16.498H17.5039C16.1232 16.498 15.0039 15.3788 15.0039 13.998V13.998Z" />
    <path d="M17.5039 14H17.7039" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default SvgComponent
