import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@components'
import cn from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { cutDcSuffix, translatePeriodToMonths } from '@utils'
import s from './VdsItem.module.scss'
import { CLOUD_DAY_PERIOD } from '@src/utils/constants'

export default function VdsItem({ el, deleteItemHandler }) {
  const { t } = useTranslation(['vds', 'virtual_hosting', 'cloud_vps'])
  const tabletOrHigher = useMediaQuery({ query: '(min-width: 768px)' })
  const dropdownEl = useRef()
  const infoEl = useRef()

  const [dropOpened, setDropOpened] = useState(false)
  const controlPanel = el?.desc?.$?.includes('Control panel')
  const IPaddresses = el?.desc?.$?.includes('IP-addresses')
  const hasBasePrice = el?.desc?.$?.includes('base price')

  const tariffName = cutDcSuffix(el?.pricelist_name?.$)

  const onShevronClick = () => {
    if (!dropOpened) {
      dropdownEl.current.style.height = dropdownEl.current.scrollHeight + 'px'
      if (!tabletOrHigher) {
        //
      } else {
        infoEl.current.style.marginBottom = '5px'
      }
    } else {
      dropdownEl.current.style.height = '0'
      infoEl.current.style.marginBottom = '0'
    }
    setDropOpened(!dropOpened)
  }

  const getTranslatedText = regex => {
    let text = el?.desc?.$?.match(regex)?.[1]
    if (text) {
      if (text?.includes('EUR')) {
        text = text.replace(text.split('EUR ')[1], t(text.split('EUR ')[1].trim()))
      } else {
        text = t(text)
      }
    }

    return text
  }

  const getTranslatedCP = string => {
    let partText = ''
    if (typeof string === 'string') {
      string?.match(/^(.+?)(?= - \d+?\.)/g)?.[0]
    }

    return typeof partText === 'string' && partText
      ? string.replace(partText, t(partText))
      : string
  }

  const IPaddressesCountText = el?.desc?.$?.match(/IP-addresses count(.+?)(?=<br\/>)/)
  const portSpeedCountText = el?.desc?.$?.match(
    /(Port speed|Outgoing traffic)(.+?)(?=<br\/>|$)/,
  )

  const parameters = [
    {
      label: t('processors'),
      value: getTranslatedText(/CPU count(.+?)(?=<br\/>)/),
      condition: hasBasePrice,
    },
    {
      label: t('memory'),
      value: getTranslatedText(/Memory(.+?)(?=<br\/>)/),
      condition: hasBasePrice,
    },
    {
      label: t('disk_space'),
      value: getTranslatedText(/Disk space(.+?)(?=<br\/>)/),
      condition: hasBasePrice,
    },
    {
      label: t('IPcount'),
      value:
        IPaddressesCountText?.length > 1 && IPaddressesCountText[1]
          ? IPaddressesCountText[1].replace('Unit', t('Unit'))
          : '',
      condition: IPaddresses && IPaddressesCountText?.length > 0,
    },
    {
      label: t('port_speed'),
      value:
        portSpeedCountText?.length > 1 && portSpeedCountText[2]
          ? portSpeedCountText[2]
          : '',
      condition: hasBasePrice && portSpeedCountText?.length > 0,
    },
    {
      label: t('license_to_panel'),
      value: getTranslatedCP(getTranslatedText(/Control panel (.+?)(?=$|<br\/>)/)),
      condition: controlPanel,
    },
    {
      label: t('Service limits'),
      value: t('port_speed_limits'),
      condition: el?.desc?.$.includes('Service limits'),
    },
    {
      label: 'IPv4',
      value: '',
      condition: el?.desc?.$.includes('public-v4'),
    },
  ]

  return (
    <div className={s.items_wrapper}>
      <div className={s.server_item}>
        <div className={s.main_info_wrapper} ref={infoEl}>
          <div className={s.tariff_name}>
            {tariffName}
            {!(el.count > 1) && (
              <button
                className={cn(s.shevron_btn, { [s.opened]: dropOpened })}
                type="button"
                onClick={onShevronClick}
              >
                <Icon
                  name="Shevron"
                  width={11}
                  className={cn(s.shevron, { [s.opened]: dropOpened })}
                />
              </button>
            )}
          </div>

          <div className={s.periodInfo}>
            <span className={'body_xs'}>
              {t('Period', { ns: 'other' })}:
              {el['item.period']?.$ === CLOUD_DAY_PERIOD
                ? ' ' + t('day', { ns: 'other' })
                : ` ${el['item.period']?.$} ${translatePeriodToMonths(
                    el['item.period']?.$,
                  )}`}
            </span>
            <span className={'body_xs'}>
              {t('amount', { ns: 'vds' })}: {el?.count} {t('pcs.', { ns: 'vds' })}
            </span>
          </div>

          {typeof deleteItemHandler === 'function' && (
            <div className={s.control_bts_wrapper}>
              <button className={s.btn_delete} type="button" onClick={deleteItemHandler}>
                <Icon name="Delete" />
              </button>
            </div>
          )}
        </div>
        {!(el.count > 1) && (
          <table
            className={cn('body_s', s.dropdown, { [s.opened]: dropOpened })}
            ref={dropdownEl}
          >
            <tbody>
              {parameters.map(
                (param, index) =>
                  param.condition && (
                    <tr key={index}>
                      <td className={s.label}>{param.label}:</td>
                      <td>{param.value}</td>
                    </tr>
                  ),
              )}
            </tbody>
          </table>
        )}
      </div>

      {typeof deleteItemHandler === 'function' && (
        <button className={s.btn_delete} type="button" onClick={deleteItemHandler}>
          <Icon name="Delete" />
        </button>
      )}
    </div>
  )
}
