import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { FilesBanner, Loader, InputField, Select } from '@components'

import { affiliateSelectors, affiliateOperations } from '@redux'

import s from './AboutAffiliateProgram.module.scss'

export default function AboutAffiliateProgram({ signal, setIsLoading, isLoading }) {
  const { t } = useTranslation(['affiliate_program', 'other'])
  const dispatch = useDispatch()
  const higherThanMobile = useMediaQuery({ query: '(min-width: 768px)' })
  const higherThan1550px = useMediaQuery({ query: '(min-width: 1550px)' })
  const descrWrapper = useRef(null)
  const referralLink = useSelector(affiliateSelectors.getRefLink)
  const promocode = useSelector(affiliateSelectors.getPromocode)

  const [isDescrOpened, setIsDescrOpened] = useState(false)

  const [link, setLink] = useState('')

  useEffect(() => {
    if (referralLink) {
      return
    }
    dispatch(affiliateOperations.getReferralLink(signal, setIsLoading))
  }, [])

  const toggleDescrHeight = () => {
    if (!isDescrOpened) {
      descrWrapper.current.style.height = descrWrapper.current.scrollHeight + 25 + 'px'
    } else {
      descrWrapper.current.removeAttribute('style')
    }
    setIsDescrOpened(!isDescrOpened)
  }

  const handleRefLinkCreating = value => {
    const arr = referralLink.split('')
    arr.splice(18, 0, value)
    const newLink = arr.join('')
    setLink(newLink)
  }

  const servicesList = [
    { label: t('cloud_vps_option', { keyPrefix: 'about_section' }), value: 'vps' },
    {
      label: t('cloud_vps_big_option', { keyPrefix: 'about_section' }),
      value: 'big_vds',
    },
    { label: t('dedicated_servers', { keyPrefix: 'about_section' }), value: 'dedicated' },
    { label: t('shared_hosting', { keyPrefix: 'about_section' }), value: 'virtual' },
    {
      label: t('servers_for_forex', { keyPrefix: 'about_section' }),
      value: 'server-for-forex',
    },
  ]

  return (
    <>
      <p className={s.description_title}> {t('about_section_title')} </p>

      <div className={s.notebook_wrapper}>
        <div className={s.tablet_wrapper}>
          <div className={s.description_wrapper} ref={descrWrapper}>
            <p className={s.paragraph}>
              {t('description_1', { keyPrefix: 'about_section' })}
            </p>
            <p className={s.paragraph}>
              {t('description_2', { keyPrefix: 'about_section' })}
            </p>
          </div>

          {!higherThanMobile && (
            <button
              className={s.btn_more}
              type="button"
              onClick={toggleDescrHeight}
              data-testid="btn_more"
            >
              {t(isDescrOpened ? 'collapse' : 'read_more', { ns: 'other' })}
            </button>
          )}

          <ul className={s.percents_list}>
            <li className={s.percents_item}>
              <span className={s.percent}>
                {t('Up to', {
                  keyPrefix: 'about_section',
                  value: t('15%'),
                }).toUpperCase()}
              </span>
              <span className={s.percents_categories}>
                {t('cloud_vps', { keyPrefix: 'about_section' })}
              </span>
            </li>
            <li className={s.percents_item}>
              <span className={s.percent}>
                {t('Up to', {
                  value: t('40%'),
                  ns: 'affiliate_program',
                  keyPrefix: 'about_section',
                }).toUpperCase()}
              </span>
              <span className={s.percents_categories}>
                {t('shared_hosting', { keyPrefix: 'about_section' })}
              </span>
            </li>
            <li className={s.percents_item}>
              <span className={s.percent}>
                {t('Up to', {
                  value: t('15%'),
                  keyPrefix: 'about_section',
                }).toUpperCase()}
              </span>
              <span className={s.percents_categories}>
                {t('dedicated_servers', { keyPrefix: 'about_section' })}
              </span>
            </li>
          </ul>
        </div>
        {higherThan1550px && <FilesBanner datatestid="descktop_banner" />}
      </div>

      <p className={s.link_title}>{t('referral_link', { keyPrefix: 'about_section' })}</p>

      <div className={s.fields_list}>
        <Select
          label={t('service', { keyPrefix: 'about_section' })}
          getElement={item => handleRefLinkCreating(item)}
          itemsList={servicesList}
          placeholder={t('service_placeholder', { keyPrefix: 'about_section' })}
        />

        <InputField
          name="ref_link_field"
          label={t('referral_link', { keyPrefix: 'about_section' })}
          labelTooltipPlace="bottom"
          value={link}
          placeholder={link}
          autoComplete="off"
          iconRight="NewIconCopy"
          inputIconTooltip={t('link_copied', { keyPrefix: 'about_section' })}
          onIconClick={() => navigator.clipboard.writeText(link)}
          useFormik={false}
          disabled
        />

        <InputField
          name="ref_link_field"
          label={t('promocode')}
          labelTooltipPlace="bottom"
          value={promocode}
          placeholder={promocode}
          autoComplete="off"
          iconRight="NewIconCopy"
          inputIconTooltip={t('promocode_copied', { keyPrefix: 'about_section' })}
          onIconClick={() => navigator.clipboard.writeText(promocode)}
          useFormik={false}
          disabled
        />
      </div>

      {isLoading && <Loader local shown={isLoading} halfScreen />}

      {!higherThan1550px && <FilesBanner datatestid="mobile_banner" />}
    </>
  )
}
