export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.18827 5.23852C7.69983 5.63734 6.38459 6.51616 5.44653 7.73867C4.50846 8.96118 4 10.4591 4 12C4 13.5409 4.50846 15.0388 5.44653 16.2613C6.38459 17.4838 7.69983 18.3627 9.18827 18.7615"
        stroke="currentColor"
      />
      <path
        d="M20 12L20.3904 11.6877L20.6403 12L20.3904 12.3123L20 12ZM11 12.5C10.7239 12.5 10.5 12.2761 10.5 12C10.5 11.7239 10.7239 11.5 11 11.5L11 12.5ZM16.3904 6.68765L20.3904 11.6877L19.6096 12.3123L15.6096 7.31235L16.3904 6.68765ZM20.3904 12.3123L16.3904 17.3123L15.6096 16.6877L19.6096 11.6877L20.3904 12.3123ZM20 12.5L11 12.5L11 11.5L20 11.5L20 12.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
