import { useTranslation } from 'react-i18next'
import { CardsContainer, CardWrapper, PaginationUpdated, Table } from '@components'
import { userOperations, userSelectors } from '@redux'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import s from './DashboardItemsBlock.module.scss'
import { getFormatedDate, getItemCostValue } from '@utils'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

export const HEAD_CELLS = [
  { label: 'name', value: 'name' },
  { label: 'expiredate', value: 'expiredate' },
  { label: 'autorenewal', value: 'autoprolong' },
  { label: 'renew', value: 'prolongcost' },
]

export default function DashboardItemsBlock({ className }) {
  const { t } = useTranslation(['other', 'cloud_vps', 'autoprolong'])

  const widerThan768 = useMediaQuery({ query: '(min-width: 768px)' })

  const [pagination, setPagination] = useState({})

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const servicesItems = useSelector(userSelectors.getDashboardItems)

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })

  const formatDatesAndTranslate = str => {
    const regex = /\d{4}-\d{2}-\d{2}/g

    const formattedString = str.replace(
      regex,
      match => getFormatedDate({ date: match }).date,
    )

    return formattedString
      .replace('Stopped', t('stopped'))
      .replace('Will end', t('will_end'))
      .replace('Daily charges', t('daily_charges'))
  }

  const getItems = ({ p_cnt, p_num }) => {
    dispatch(
      userOperations.getDashboardItems({
        p_cnt,
        p_num,
        setPagination,
      }),
    )
  }

  const showItem = elid => {
    dispatch(
      userOperations.getDashboardShowItem({
        elid,
        navigate,
      }),
    )
  }

  useEffect(() => {
    getItems({ p_num: 1, p_cnt: 5 })
  }, [])

  if (!servicesItems?.length) {
    return null
  }

  return (
    <section className={cn('box_styles', s.section, className)}>
      <h4 className="other_btn_uppercase">{t('active_services')}</h4>
      {widerThan768 ? (
        <Table isItemsClickable vAlignTop>
          <thead>
            <tr>{renderHeadCells()}</tr>
          </thead>
          <tbody className={s.tbody}>
            {servicesItems?.map(service => {
              const { name, expiredate, id, autoprolong, prolongcost } = service
              const renderName = name?.$.split('#')[0].split(' /')[0]
              return (
                <tr
                  onClick={() => showItem(id?.$)}
                  data-stop-propagation
                  tabIndex={0}
                  key={id?.$}
                >
                  <td data-wrap>
                    <span className="line_clamp clamp_2">{`${renderName} #${id.$}`}</span>
                  </td>

                  <td className={s?.[`${expiredate?.$color}`]}>
                    {formatDatesAndTranslate(expiredate?.$)}
                  </td>
                  <td>{t(autoprolong?.$orig, { ns: 'autoprolong' })}</td>

                  <td>{getItemCostValue(prolongcost?.$, t, true)}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <CardsContainer>
          {servicesItems?.map(service => {
            const { name, expiredate, id, autoprolong, prolongcost } = service
            const renderName = name?.$.split('#')[0].split(' /')[0]

            return (
              <CardWrapper onClick={() => showItem(id?.$)} key={id?.$} className={s.item}>
                <span className={s.label}>{t('name')}:</span>

                <span className={cn(s.value, s.value_name)}>
                  {`${renderName} #${id.$}`}
                </span>
                <span className={s.label}>{t('expiredate')}:</span>
                <span className={cn(s.value, s?.[`${expiredate?.$color}`])}>
                  {formatDatesAndTranslate(expiredate?.$)}
                </span>
                <span className={s.label}>{t('autorenewal')}:</span>
                <span className={s.value}>
                  {t(autoprolong?.$orig, { ns: 'autoprolong' })}
                </span>
                <span className={s.label}>{t('renew')}:</span>
                <span className={s.value}>
                  {getItemCostValue(prolongcost?.$, t, true)}
                </span>
              </CardWrapper>
            )
          })}
        </CardsContainer>
      )}

      <PaginationUpdated
        className={s.pagination}
        getItemsHandler={getItems}
        pagination={pagination}
      />
    </section>
  )
}
