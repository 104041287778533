export default function Time(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <circle cx="7.79932" cy="7.79923" r="6.5" />
      <path
        d="M7.79932 5.19922V7.62528C7.79932 7.73395 7.85362 7.83542 7.94404 7.8957L9.74932 9.09922"
        strokeLinecap="round"
      />
    </svg>
  )
}
