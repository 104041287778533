export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.002 10.6667C20.002 7.52397 20.002 5.95262 19.0256 4.97631C18.0493 4 16.478 4 13.3353 4H10.6686C7.52592 4 5.95457 4 4.97826 4.97631C4.00195 5.95262 4.00195 7.52397 4.00195 10.6667V18.3333C4.00195 19.119 4.00195 19.5118 4.24603 19.7559C4.49011 20 4.88295 20 5.66862 20H13.3353C16.478 20 18.0493 20 19.0256 19.0237C20.002 18.0474 20.002 16.476 20.002 13.3333V10.6667Z"
        stroke="currentColor"
        strokeWidth="0.833333"
      />
      <path
        d="M9 12L15 12"
        stroke="currentColor"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.001 9L12.001 15"
        stroke="currentColor"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
