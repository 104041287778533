export default function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 10 6"
      width={10}
      height={6}
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.5 1L4.5 5L8.5 1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
