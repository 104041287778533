export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.25 17.25H12.75V6.75H17.25C18.0773 6.75 18.75 7.42275 18.75 8.25V12C18.75 12.8273 18.0773 13.5 17.25 13.5H14.25V17.25ZM14.25 12H17.25V8.24925H14.25V12ZM5.25 8.25H7.5V15.75H5.25V17.25H11.25V15.75H9V8.25H11.25V6.75H5.25V8.25Z"
        fill="currentColor"
      />
    </svg>
  )
}
