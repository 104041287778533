import s from './SupportArchiveTable.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import * as route from '@src/routes'
import { CardWrapper } from '@components'
import getFormatedDate from '@utils/getFormatedDate'
import { useOpenInNewWindow } from '@utils/index'
import { FIRST_BRACKETS_REGEXP } from '@utils/constants'

export default function SupportArchiveTableItem(props) {
  const { id, theme, date } = props
  const { t } = useTranslation(['support', 'other'])
  const navigate = useOpenInNewWindow()
  const mobile = useMediaQuery({ query: '(max-width: 767px)' })

  const { date: createdate, time } = getFormatedDate({ fullDate: date })

  const onClickItemHandler = e =>
    navigate(e, `${route.SUPPORT}/requests_archive/${id}`, {
      replace: true,
    })

  const ticketTheme = theme.replace(FIRST_BRACKETS_REGEXP, '')

  return (
    <>
      {!mobile ? (
        <tr onClick={onClickItemHandler} tabIndex={0}>
          <td>{id}</td>
          <td data-wrap>
            <div className="line_clamp clamp_2">{ticketTheme}</div>
          </td>
          <td>
            {createdate} <span className={s.item_text_time}>{time}</span>
          </td>
        </tr>
      ) : (
        <CardWrapper
          data-testid="archive_item"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={onClickItemHandler}
        >
          <CardWrapper.Grid>
            <p className={s.item_title}>{t('request_id')}:</p>
            <p className={cn(s.item_text, s.first_item)}>{id}</p>
            <p className={s.item_title}>{t('subject', { ns: 'support' })}:</p>
            <p className={cn(s.item_text, s.second_item)}>{ticketTheme}</p>
            <p className={s.item_title}>{t('date', { ns: 'other' })}:</p>
            <p>
              {createdate} <span className={s.item_text_time}>{time}</span>
            </p>
          </CardWrapper.Grid>
        </CardWrapper>
      )}
    </>
  )
}
SupportArchiveTableItem.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  setSelctedTicket: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
}

SupportArchiveTableItem.defaultProps = {
  id: '',
  theme: '',
  date: '',
  setSelctedTicket: () => null,
  selected: null,
}
