const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.295 10.79c3.473 0 6.288-1.17 6.288-2.612 0-1.443-2.815-2.613-6.288-2.613-3.472 0-6.287 1.17-6.287 2.613s2.815 2.612 6.287 2.612Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M16.575 10.995c0 1.45-2.813 2.613-6.287 2.613C6.811 13.608 4 12.433 4 10.995M16.575 13.808c0 1.45-2.813 2.612-6.287 2.612C6.811 16.42 4 15.245 4 13.808"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.008 8.153v8.425c0 1.438 2.812 2.588 6.287 2.588s6.288-1.163 6.288-2.588V8.153"
    />
    <path
      fill="currentColor"
      d="M4.55 6.5V1.037h3.083v.938H5.691V3.13h1.815v.93H5.69V6.5H4.55Zm4.93-.407V1.997H8.117v-.96H12v.96h-1.38v4.096H9.48Zm3.127 0V1.104c.353-.06.848-.105 1.545-.105.705 0 1.208.135 1.545.405.33.255.54.675.54 1.17 0 .495-.157.922-.465 1.2-.39.36-.967.533-1.642.533-.15 0-.285-.008-.39-.03v1.815h-1.133Zm1.133-4.186V3.4c.097.022.21.03.382.03.608 0 .983-.308.983-.818 0-.465-.323-.742-.9-.742a2.04 2.04 0 0 0-.465.037Z"
    />
  </svg>
)
export default SvgComponent
