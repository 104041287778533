import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { CSSTransition } from 'react-transition-group'
import animations from './animations.module.scss'
import s from './BurgerListItem.module.scss'

import { useTranslation } from 'react-i18next'

export default function BurgerListItem({
  svg,
  name,
  isProfile,
  email,
  subList,
  isListOpened,
  arrow,
  controlMenu,
}) {
  const { t } = useTranslation('container')

  return (
    <div
      className={cn(s.item_wrapper, {
        [s.profile]: isProfile,
      })}
    >
      <div
        className={cn(s.list_item_header, {
          [s.opened]: isListOpened,
          [s.profile]: isProfile,
        })}
      >
        {svg}
        {isProfile ? (
          <div className={s.profile_wrapper}>
            <p className={s.user_name}>{name}</p>
            <p className={s.email}>{email}</p>
          </div>
        ) : (
          <p
            className={cn('body_m', s.list_name, {
              [s.opened]: isListOpened,
            })}
          >
            {name}
          </p>
        )}
        {subList?.length ? arrow : <></>}
      </div>

      {subList?.length && (
        <CSSTransition
          in={isListOpened}
          classNames={animations}
          timeout={150}
          unmountOnExit
        >
          <ul
            className={cn(s.list, {
              [s.opened]: isListOpened,
              [s.profile]: isProfile,
            })}
          >
            {subList?.map((item, index) => {
              if (item.allowedToRender) {
                return (
                  <li key={index} className={s.list_item}>
                    {item.routeName ? (
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={controlMenu}
                      >
                        <NavLink
                          className={cn('body_s_m', s.list_item_link)}
                          to={item.routeName}
                        >
                          {t(item.name)}
                        </NavLink>
                      </div>
                    ) : (
                      <button onClick={item?.onClick}>
                        <p
                          className={cn('body_s_m', s.list_item_link, {
                            [s.opened]: isListOpened,
                          })}
                        >
                          {t(item.name)}
                        </p>
                      </button>
                    )}
                  </li>
                )
              } else {
                return null
              }
            })}
          </ul>
        </CSSTransition>
      )}
    </div>
  )
}

BurgerListItem.propTypes = {
  name: PropTypes.string,
  subList: PropTypes.array,
  svg: PropTypes.object,
  email: PropTypes.string,
  isProfile: PropTypes.bool,
  isListOpened: PropTypes.bool,
  arrow: PropTypes.object,
}
