import { CardWrapper } from '@components/index'
import s from '../AccessLogsComponents.module.scss'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { getFormatedDate } from '@utils'

export default function Component(props) {
  const { time, user, ip } = props
  const { t } = useTranslation(['access_log', 'other'])
  const mobile = useMediaQuery({ query: '(max-width: 1023px)' })

  const { date, time: logTime } = getFormatedDate({ fullDate: time })

  return (
    <>
      {!mobile ? (
        <tr>
          <td>
            {date} <span className={s.item_text_time}>{logTime}</span>
          </td>
          <td data-wrap>{user}</td>
          <td>{ip}</td>
        </tr>
      ) : (
        <CardWrapper>
          <CardWrapper.Grid>
            <span className={s.item_title}>{t('time', { ns: 'other' })}:</span>
            <span className={s.item_text}>
              {date} <span className={s.item_text_time}>{logTime}</span>
            </span>
            <span className={s.item_title}>{t('user', { ns: 'other' })}:</span>
            <span className={s.item_text}>{user}</span>
            <span className={s.item_title}>{t('remote_ip_address')}:</span>
            <span className={s.item_text}>{ip}</span>
          </CardWrapper.Grid>
        </CardWrapper>
      )}
    </>
  )
}

Component.propTypes = {
  time: PropTypes.string,
  user: PropTypes.string,
  ip: PropTypes.string,
}

Component.defaultProps = {
  time: '',
  user: '',
  ip: '',
}
