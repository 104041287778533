import { useTranslation } from 'react-i18next'
import { forwardRef, useState } from 'react'
import { Icon } from '@components'
import cn from 'classnames'
import s from './NotificationMessage.module.scss'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'

const messageTypes = {
  info: {
    containerClass: s.info,
    title: 'Info',
    iconName: 'NewWarningInfoIcon',
  },
  warning: {
    containerClass: s.warning,
    title: 'Warning',
    iconName: 'NewWarningIcon',
  },
  error: {
    containerClass: s.error,
    iconName: 'NewWarningIcon',
    title: 'Error',
  },
  success: {
    containerClass: s.success,
    title: 'Success',
    iconName: 'NewSuccessIcon',
  },
  note: {
    containerClass: s.notes,
    title: 'Note',
    iconName: '',
  },
}

function NotificationMessage(
  {
    children,
    className,
    iconClassName,
    type,
    showTitle,
    customTitle,
    onClick,
    hasCrossBtn,
  },
  ref,
) {
  const wider1024 = useMediaQuery({ query: '(min-width: 1024px)' })
  const [notifIsHidden, setNotifIsHidden] = useState(false)
  const { t } = useTranslation(['other'])

  const { containerClass, title, iconName } = messageTypes[type] || messageTypes.info

  const WrapWithButton = ({ children, onClick }) => {
    return onClick && !hasCrossBtn ? (
      <button type="button" onClick={onClick} className={s.wrapBtn}>
        {children}
      </button>
    ) : (
      children
    )
  }

  const WrapWithIconButton = ({ children, onClick }) => {
    return onClick && hasCrossBtn ? (
      <button type="button" onClick={onClick} className={cn(s.iconButtonWrap, {})}>
        {children}
      </button>
    ) : (
      children
    )
  }

  const handleCloseNotif = () => {
    setNotifIsHidden(true)
  }

  return (
    !notifIsHidden && (
      <WrapWithButton onClick={onClick}>
        <div
          className={cn(s.container, containerClass, {
            [className]: className,
          })}
          ref={ref}
        >
          <WrapWithIconButton onClick={onClick}>
            {iconName && (
              <Icon name={iconName} className={cn({ [iconClassName]: iconClassName })} />
            )}

            <div className={s.textWrapper}>
              {wider1024 && showTitle && (
                <p className="body_m">{customTitle || t(title)}</p>
              )}
              <p className="body_s">{children}</p>
            </div>
          </WrapWithIconButton>

          {/* Close Btn */}
          {hasCrossBtn && (
            <button className={s.closeButton} onClick={handleCloseNotif} type="button">
              <Icon name="Close" />
            </button>
          )}
        </div>
      </WrapWithButton>
    )
  )
}

export default forwardRef(NotificationMessage)

NotificationMessage.propTypes = {
  time: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  type: PropTypes.oneOf(['info', 'warning', 'error', 'success', 'note']),
  showTitle: PropTypes.bool,
  onClick: PropTypes.func,
}

NotificationMessage.defaultProps = {
  type: 'info',
  showTitle: false,
}
