import { IconButton, Select } from '@components'
import s from './Sort.module.scss'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

const orderButtons = [{ name: 'asc' }, { name: 'desc', className: 'rotate_180' }]

export default function Sort({ sortBy, setSortBy, list, getItems, className }) {
  const { t } = useTranslation(['other'])

  const changeOrderHandler = e => {
    const order = e.currentTarget.name
    getItems({ ...sortBy, p_order: order })
    setSortBy(prev => ({ ...prev, p_order: order }))
  }

  return (
    <div className={cn(s.sort, className)}>
      <Select
        className={s.sort_select}
        placeholder={t('sort')}
        label={`${t('sort')}:`}
        itemsList={list.map(el => {
          return {
            ...el,
            label: t(el.label),
          }
        })}
        itemIcon
        getElement={value => {
          getItems({ ...sortBy, p_sort: value })
          setSortBy(prev => ({ ...prev, p_sort: value }))
        }}
        value={sortBy.p_sort}
      />
      <div>
        <label className={s.label}>{t('sort_order')}:</label>
        <div className={s.sort_order_btns}>
          {orderButtons.map(({ name, className }) => {
            return (
              <IconButton
                key={name}
                name={name}
                onClick={changeOrderHandler}
                icon="NewIconArrowUp"
                color="secondary"
                size="medium"
                className={cn(className, {
                  [s.btn_active]: sortBy.p_order === name,
                })}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
