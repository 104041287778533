export default function Receipt(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path d="M3.33447 2.33282H12.6678V12.9766C12.6678 13.5301 12.0322 13.8425 11.5939 13.5044L10.7067 12.82C10.4835 12.6478 10.1761 12.6346 9.93901 12.7871L8.72215 13.5693C8.28294 13.8517 7.71934 13.8517 7.28013 13.5693L6.06327 12.7871C5.82614 12.6346 5.51877 12.6478 5.29556 12.82L4.40834 13.5044C3.97006 13.8425 3.33447 13.5301 3.33447 12.9766V2.33282Z" />
      <path d="M6 6.33333H8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 2.33333H14" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 9.00051H8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 6.33333H10.3333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 9.00051H10.3333" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
