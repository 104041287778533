import cn from 'classnames'
import s from './BalanceBtn.module.scss'

import { userSelectors, billingActions } from '@redux'
import { roundToDecimal } from '@utils'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from '@components'

export default function BalanceBtn(props) {
  const dispatch = useDispatch()
  const { realbalance } = useSelector(userSelectors.getUserInfo)

  const { isHeader, isBurger } = props

  return (
    <div
      className={cn(s.balance_item, {
        [s.header_btn]: isHeader,
        [s.burger_btn]: isBurger,
      })}
    >
      <button
        onClick={() => dispatch(billingActions.setIsModalCreatePaymentOpened(true))}
        className={s.balance_wrapper}
      >
        <Icon name="WalletBalance" className={s.wallet_icon} />
        <span className={'body_s_m'}>€{roundToDecimal(realbalance, 'floor')}</span>
      </button>
    </div>
  )
}
