import { Icon } from '@components'
import s from './FtpItem.module.scss'
import { translatePeriodToMonths } from '@utils'
import { useTranslation } from 'react-i18next'

export default function FtpItem(props) {
  const { pricelist_name, deleteItemHandler, count, period } = props
  const { t } = useTranslation(['other', 'vds'])

  return (
    <div className={s.server_item}>
      <div className={s.server_info}>
        <span className={'body_l_regular'}>{pricelist_name}</span>
        <div className={s.periodInfo}>
          <span className={'body_xs'}>
            {t('Period', { ns: 'other' })}: {period} {translatePeriodToMonths(period)}
          </span>
          <span className={'body_xs'}>
            {t('amount', { ns: 'vds' })}: {count} {t('pcs.', { ns: 'vds' })}
          </span>
        </div>
      </div>

      {typeof deleteItemHandler === 'function' && (
        <button className={s.btn_delete} type="button" onClick={deleteItemHandler}>
          <Icon name="Delete" />
        </button>
      )}
    </div>
  )
}
