import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import cn from 'classnames'
import DNSItem from '../DNSItem/DNSItem'
import DNSMobileItem from '../DNSMobileItem/DNSMobileItem'
import { CheckBox, CardsContainer, Table } from '@components'

import s from './DNSList.module.scss'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { billingOperations } from '@src/Redux'

export const HEAD_CELLS = [
  { label: 'Id', value: 'Id' },
  { label: 'pricing_plan', value: 'tariff' },
  { label: 'status', value: 'status' },
  { label: 'datacenter', value: 'datacenter' },
  { label: 'created', value: 'created' },
  { label: 'valid_until', value: 'valid_until' },
]

export default function DNSList({
  emptyFilter,
  dnsList,
  setElidForEditModal,
  setElForProlongModal,
  setElidForHistoryModal,
  setElidForInstructionModal,
  pageRights,
  setActiveServices,
  activeServices,
  signal,
}) {
  const dispatch = useDispatch()

  const { t } = useTranslation([
    'vds',
    'other',
    'dedicated_servers',
    'domains',
    'access_log',
    'dns',
  ])
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  const [unpaidItems, setUnpaidItems] = useState([])

  useEffect(() => {
    dispatch(billingOperations.getUnpaidOrders(setUnpaidItems, signal))
  }, [])

  if (dnsList) {
    if (dnsList.length === 0 && emptyFilter) {
      return (
        <div className={s.no_results_wrapper}>
          <p className={s.no_results_text}>{t('nothing_found', { ns: 'access_log' })}</p>
        </div>
      )
    }

    if (dnsList.length === 0 && dnsList) {
      return (
        <div className={s.no_service_wrapper}>
          <img
            src={require('@images/services/no_dns.png')}
            alt="dns"
            className={s.dns_img}
          />
          <p className={s.no_service_title}>
            {t('YOU DO NOT HAVE DNS HOSTING YET', { ns: 'dns' })}
          </p>
          <p className={s.no_service_description}>
            {t('no services description', { ns: 'dns' })}
          </p>
        </div>
      )
    }
  }

  const isAllActive = activeServices?.length === dnsList?.length
  const toggleIsActiveHandler = () => {
    isAllActive ? setActiveServices([]) : setActiveServices(dnsList)
  }

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })

  return (
    <>
      {isDesktop ? (
        <Table stickyHeader vAlignTop>
          <thead>
            <tr>
              <th>
                <CheckBox value={isAllActive} onClick={toggleIsActiveHandler} />
              </th>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody className={s.tbody}>
            {dnsList?.map(el => {
              return (
                <DNSItem
                  key={el.id.$}
                  storage={el}
                  activeServices={activeServices}
                  setActiveServices={setActiveServices}
                  setElidForEditModal={() => setElidForEditModal(el.id.$)}
                  setElForProlongModal={() => setElForProlongModal([el])}
                  setElidForHistoryModal={() => setElidForHistoryModal(el.id.$)}
                  setElidForInstructionModal={() => setElidForInstructionModal(el.id.$)}
                  pageRights={pageRights}
                  unpaidItems={unpaidItems}
                />
              )
            })}
          </tbody>
        </Table>
      ) : (
        <>
          <label className={cn('in_row', s.check_all)}>
            <CheckBox value={isAllActive} onClick={toggleIsActiveHandler} />
            {t('Choose all', { ns: 'other' })}
          </label>
          <CardsContainer>
            {dnsList?.map(el => {
              return (
                <DNSMobileItem
                  key={el.id.$}
                  storage={el}
                  setElidForEditModal={() => setElidForEditModal(el.id.$)}
                  setElForProlongModal={() => setElForProlongModal([el])}
                  setElidForHistoryModal={() => setElidForHistoryModal(el.id.$)}
                  setElidForInstructionModal={() => setElidForInstructionModal(el.id.$)}
                  activeServices={activeServices}
                  setActiveServices={setActiveServices}
                  pageRights={pageRights}
                  unpaidItems={unpaidItems}
                />
              )
            })}
          </CardsContainer>
        </>
      )}
    </>
  )
}

DNSList.propTypes = {
  dnsList: PropTypes.arrayOf(PropTypes.object),
  emptyFilter: PropTypes.bool,
  setElidForEditModal: PropTypes.func,
  setElForProlongModal: PropTypes.func,
  setElidForHistoryModal: PropTypes.func,
  setElidForInstructionModal: PropTypes.func,
  setActiveServices: PropTypes.func,
  activeServices: PropTypes.arrayOf(PropTypes.object),
  pageRights: PropTypes.object,
}
