export default function getFlagFromCountryName(country) {
  switch (country?.toLowerCase()) {
    case 'netherlands':
      return 'nl'
    case 'poland':
      return 'pl'
    case 'europe':
      return 'eu_circle'
    case 'usa':
      return 'us'
    case 'singapore':
      return 'sg'
    default:
      return 'undefined'
  }
}
