import { useTranslation } from 'react-i18next'

import s from './FilesBanner.module.scss'
import cn from 'classnames'

export default function Banner({ datatestid }) {
  const { t } = useTranslation('affiliate_program')

  return (
    <div className={s.banner} data-testid={datatestid}>
      <p className={s.banner_text}>{t('about_section.banners_link_caption')}</p>

      <a className={cn('btn_base_styles', s.download_link)} href="/banners.zip" download>
        <span className={s.btn_text}>{t('about_section.banners_download_link')}</span>
      </a>
    </div>
  )
}
