export default function SvgComponent({ ...props }) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.0006 32.0002L29.334 37.3335L41.334 25.3335M19.5574 10.1833C21.7009 10.0123 23.7359 9.16938 25.3726 7.77461C29.1919 4.51979 34.8094 4.51979 38.6287 7.77461C40.2654 9.16938 42.3004 10.0123 44.4439 10.1833C49.4461 10.5825 53.4183 14.5547 53.8175 19.5569C53.9885 21.7004 54.8314 23.7354 56.2262 25.3721C59.481 29.1914 59.481 34.8089 56.2262 38.6282C54.8314 40.2649 53.9885 42.2999 53.8175 44.4434C53.4183 49.4456 49.4461 53.4178 44.4439 53.817C42.3004 53.988 40.2654 54.8309 38.6287 56.2257C34.8094 59.4805 29.1919 59.4805 25.3726 56.2257C23.7359 54.8309 21.7009 53.988 19.5574 53.817C14.5552 53.4178 10.583 49.4456 10.1838 44.4434C10.0128 42.2999 9.16987 40.2649 7.7751 38.6282C4.52028 34.8089 4.52028 29.1914 7.7751 25.3721C9.16987 23.7354 10.0128 21.7004 10.1838 19.5569C10.583 14.5547 14.5552 10.5825 19.5574 10.1833Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
