import TicketStatus from '@components/Support/TicketStatus/TicketStatus'
import { useTranslation } from 'react-i18next'
import { Cta, Table } from '@components'
import { userSelectors } from '@redux'
import { useSelector } from 'react-redux'
import * as routes from '@src/routes'
import cn from 'classnames'
import s from './DashboardTicketsBlock.module.scss'
import { useOpenInNewWindow } from '@utils'
import { FIRST_BRACKETS_REGEXP } from '@utils/constants'

export const HEAD_CELLS = [
  { label: 'subject', value: 'name' },
  { label: 'status', value: 'tstatus' },
]

export default function DashboardQuickAccessBlock() {
  const { t } = useTranslation(['other'])

  const navigate = useOpenInNewWindow()

  const tickets = useSelector(userSelectors.getUserTickets)

  if (!tickets?.length) {
    return null
  }

  return (
    <section className={cn('box_styles', s.section)}>
      <h4 className="other_btn_uppercase">{t('tickets')}</h4>

      <Table isItemsClickable vAlignTop>
        <tbody className={s.tbody}>
          {tickets?.slice(0, 3)?.map(t => {
            return (
              <tr
                onClick={e => navigate(e, `${routes.SUPPORT}/requests/${t?.id?.$}`)}
                data-stop-propagation
                tabIndex={0}
                key={t?.id?.$}
              >
                <td data-wrap>
                  <span className="line_clamp clamp_2">
                    {t?.name?.$.replace(FIRST_BRACKETS_REGEXP, '')}
                  </span>
                </td>
                <td className={s.status}>
                  <TicketStatus status={t?.tstatus?.$} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      <div className={s.buttonBlock}>
        <Cta viewType="link" element="navlink" to={routes.SUPPORT}>
          {t('all_tickets')}
        </Cta>
      </div>
    </section>
  )
}
