export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.33924 6.3451C5.22042 7.46392 4.45849 8.88938 4.14981 10.4412C3.84113 11.9931 3.99956 13.6016 4.60506 15.0634C5.21056 16.5252 6.23594 17.7747 7.55153 18.6537C8.86713 19.5328 10.4138 20.002 11.9961 20.002C13.5783 20.002 15.1251 19.5328 16.4407 18.6537C17.7562 17.7747 18.7816 16.5252 19.3871 15.0634C19.9926 13.6016 20.1511 11.9931 19.8424 10.4412C19.5337 8.88938 18.7718 7.46392 17.6529 6.3451"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path d="M12 8.00195L12 4.00195" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
}
