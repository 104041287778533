import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import {
  TrustedUserCard,
  ManageUserForm,
  Cta,
  Table,
  CardsContainer,
  Loader,
} from '@components'
import { userSelectors, usersOperations } from '@redux'

import * as routes from '@src/routes'
import cn from 'classnames'

import s from './TrustedUsers.module.scss'
import { useCancelRequest, useContainerContext, usePageRender } from '@utils'

export const HEAD_CELLS = [
  { label: 'email', value: 'email' },
  { label: 'name', value: 'name' },
  { label: 'full_access', value: 'full_access' },
  { label: 'status', value: 'status' },
]

export default function TrustedUsers() {
  const isComponentAllowedToRender = usePageRender('customer', 'user')

  const { t } = useTranslation('trusted_users')
  const { signal, isLoading, setIsLoading: setLoading } = useCancelRequest()

  const setIsLoading = value => {
    setLoading(value)
    setContextData({ isLoading: value })
  }
  const dispatch = useDispatch()
  const { setContextData } = useContainerContext()
  const [readMore, setReadMore] = useState(false)
  const [createdNewUser, setCreatedNewUser] = useState(false)
  const [data, setData] = useState([])

  const [isUserFormActive, setIsUserFormActive] = useState(false)
  const handleUserForm = () => {
    setIsUserFormActive(!isUserFormActive)
  }

  const setPagination = value => setContextData({ pagination: value })

  const checkIfHasArr = data.metadata?.toolbar?.toolgrp
  const isCreatingNewUserAllowed = Array.isArray(checkIfHasArr)
    ? data.metadata?.toolbar?.toolgrp[0]?.toolbtn?.some(el => el?.$name === 'new')
    : false

  const laptopOrHigher = useMediaQuery({ query: '(min-width: 1024px)' })

  const subtitleText = t('trusted_users.subtitle')

  const getUsersHandler = useCallback(
    (() => {
      let num
      return ({ p_col, p_num, p_cnt } = {}) => {
        num = p_num ?? num
        dispatch(
          usersOperations.getUsers({
            setData,
            p_col,
            p_cnt,
            p_num: num,
            setPagination,
            signal,
            setIsLoading,
          }),
        )
      }
    })(),
    [],
  )

  useEffect(() => {
    setContextData({
      paginationSettings: { getItems: getUsersHandler, pageList: 'trusted_users' },
    })
    const p_num = localStorage.getItem('p_num.trusted_users') || 1
    getUsersHandler({ p_num })
  }, [])

  const handleUserRolesData = () => {
    getUsersHandler()
  }
  const checkIfCreatedUser = () => {
    setCreatedNewUser(!createdNewUser)
    getUsersHandler()
  }

  const hadndleReadMoreBtn = () => {
    setReadMore(!readMore)
  }

  const { $id: pageOwnerId } = useSelector(userSelectors.getUserInfo)

  const hasPageOwnerFullAccess = data.elem?.some(user => {
    return user.id.$ === pageOwnerId && user.default_access_allow
  })

  const handleSubmit = values => {
    const { email, name, phone, password } = values

    dispatch(
      usersOperations.createNewUser(password, email, phone, name, checkIfCreatedUser),
    )
  }

  useEffect(() => {
    if (createdNewUser) handleUserForm()
  }, [createdNewUser])

  if (!isComponentAllowedToRender) {
    return <Navigate replace to={routes.HOME} />
  }

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(`trusted_users.table_header.${cell.label}`)}</th>
    })

  const renderItems = () => {
    return data.elem?.map(user => {
      return (
        <TrustedUserCard
          user={user}
          key={user.id.$}
          name={user.realname.$}
          email={user.email.$}
          hasAccess={user?.default_access_allow?.$}
          status={user?.enabled?.$}
          userId={user.id.$}
          handleUserRolesData={handleUserRolesData}
          isOwner={user.self.$ === 'on'}
          hasPageOwnerFullAccess={hasPageOwnerFullAccess}
          signal={signal}
          setIsLoading={setIsLoading}
          availableRights={data.metadata}
        />
      )
    })
  }

  return (
    <>
      <div className={s.description_wrapper}>
        <p className={cn(s.subtitle, { [s.showMore]: readMore })}>{subtitleText}</p>
        <div className={s.subtitle_wrapper}></div>
        {!laptopOrHigher && (
          <Cta viewType="text" view="primary" onClick={hadndleReadMoreBtn}>
            {readMore ? t('trusted_users.read_less') : t('trusted_users.read_more')}
          </Cta>
        )}
      </div>
      {isCreatingNewUserAllowed && (
        <div>
          <Cta onClick={handleUserForm} isShadow className={s.addNewUserBtn}>
            {`${t('trusted_users.button')}`.toUpperCase()}
          </Cta>
        </div>
      )}

      {laptopOrHigher ? (
        <Table stickyHeader>
          <thead>
            <tr>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody>{renderItems()}</tbody>
        </Table>
      ) : (
        <CardsContainer>{renderItems()}</CardsContainer>
      )}

      {isUserFormActive && (
        <ManageUserForm
          closeModal={handleUserForm}
          handleSubmit={handleSubmit}
          title={t('trusted_users.form.title')}
          datatestid="trusted_form"
          isOpen
        />
      )}
      {isLoading && <Loader local shown={isLoading} />}
    </>
  )
}
