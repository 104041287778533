const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="#913EF5"
      d="M2.672 8.667c0-1.257 0-1.886.39-2.276C3.452 6 4.082 6 5.34 6h5.333c1.257 0 1.885 0 2.276.39.39.391.39 1.02.39 2.277V10c0 1.886 0 2.828-.585 3.414-.586.586-1.529.586-3.414.586H6.672c-1.886 0-2.829 0-3.414-.586-.586-.586-.586-1.528-.586-3.414V8.667Z"
    />
    <path
      stroke="#913EF5"
      strokeLinecap="round"
      d="M10.67 5.333v-.666A2.667 2.667 0 0 0 8.002 2v0a2.667 2.667 0 0 0-2.667 2.667v.666"
    />
    <circle cx={8.005} cy={10.001} r={1.333} fill="#913EF5" />
  </svg>
)
export default SvgComponent
