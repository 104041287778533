const SvgComponent = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.00391 6.16471V17.1647C2.00391 17.5684 2.24664 17.9325 2.61929 18.0878L12.0039 21.998M2.00391 6.16471L11.2347 2.31856C11.727 2.11343 12.2808 2.11343 12.7731 2.31856L17.0039 4.08138M2.00391 6.16471L7.00391 8.24805M12.0039 10.3314V21.998M12.0039 10.3314L22.0039 6.16471M12.0039 10.3314L7.00391 8.24805M12.0039 21.998L21.3885 18.0878C21.7612 17.9325 22.0039 17.5684 22.0039 17.1647V6.16471M22.0039 6.16471L17.0039 4.08138M7.00391 8.24805L17.0039 4.08138"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
