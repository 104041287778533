import { TooltipWrapper, Icon } from '@components'
import cn from 'classnames'
import s from './PaymentMethodComponents.module.scss'
import { useTranslation } from 'react-i18next'

export default function PaymentMethodButton({ method, isSelected, onSelect, withHint }) {
  const { image, name, paymethod } = method
  let paymentName = name?.$
  let balance = ''

  const isAccount = name?.$.includes('Account balance')

  const { t } = useTranslation(['cart', 'other'])

  if (paymentName?.includes('Account balance')) {
    balance = paymentName?.match(/[-\d|.|\\+]+/g)
    paymentName = t('Account balance')
  }

  return (
    <button
      onClick={onSelect}
      type="button"
      className={cn(s.paymentMethodBtn, {
        [s.selected]: isSelected,
        [s.withHint]: withHint,
        [s.account]: isAccount,
      })}
      key={paymentName}
    >
      <div className={s.descrWrapper}>
        {isAccount ? (
          <div className={s.wallet_icon_wrapper}>
            <Icon name="WalletBalance" className={s.wallet_icon} />
          </div>
        ) : (
          <img src={`${process.env.REACT_APP_BASE_URL}${image?.$}`} alt="icon" />
        )}
        <span className={cn({ [s.methodDescr]: paymethod?.$ === '71' })}>
          {paymentName}
          {balance?.length > 0 && (
            <>
              <br />
              <span className={s.balance}>€ {Number(balance).toFixed(2)}</span>
            </>
          )}
        </span>
      </div>
      {paymethod?.$ === '71' && (
        <TooltipWrapper
          content={t(method?.name.$, { ns: 'other' })}
          wrapperClassName={cn(s.infoBtnCard)}
          place="bottom"
        >
          <Icon name="NewInfoIcon" />
        </TooltipWrapper>
      )}
    </button>
  )
}
