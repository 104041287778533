export default function ArrowUp(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path
        d="M6.66406 6.66784L9.40814 3.92376C9.73358 3.59833 10.2612 3.59833 10.5867 3.92377L13.3307 6.66784M9.9974 4.16785L9.9974 15.8345"
        strokeLinecap="round"
      />
    </svg>
  )
}
