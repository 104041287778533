const SvgComponent = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.2498 8.28667V36C34.2498 37.7949 32.7947 39.25 30.9998 39.25H9C7.20507 39.25 5.75 37.7949 5.75 36V4C5.75 2.20508 7.20508 0.75 9 0.75H26.0445L34.2498 8.28667Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <mask id="path-2-inside-1_3068_47160" fill="currentColor">
      <path d="M25 1H33.5V9.5H26C25.4477 9.5 25 9.05229 25 8.5V1Z" />
    </mask>
    <path
      d="M25 1H33.5H25ZM33.5 11H26C24.6193 11 23.5 9.88071 23.5 8.5L26.5 8H33.5V11ZM26 11C24.6193 11 23.5 9.88071 23.5 8.5V1H26.5V8L26 11ZM33.5 1V9.5V1Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_3068_47160)"
    />
    <rect x="1" y="20" width="21" height="13" rx="2" fill="#FF9314" />
    <path
      d="M8.76242 25.2C8.63576 24.9667 8.45242 24.79 8.21242 24.67C7.97909 24.5433 7.70242 24.48 7.38242 24.48C6.82909 24.48 6.38576 24.6633 6.05242 25.03C5.71909 25.39 5.55242 25.8733 5.55242 26.48C5.55242 27.1267 5.72576 27.6333 6.07242 28C6.42576 28.36 6.90909 28.54 7.52242 28.54C7.94242 28.54 8.29576 28.4333 8.58242 28.22C8.87576 28.0067 9.08909 27.7 9.22242 27.3H7.05242V26.04H10.7724V27.63C10.6458 28.0567 10.4291 28.4533 10.1224 28.82C9.82242 29.1867 9.43909 29.4833 8.97242 29.71C8.50576 29.9367 7.97909 30.05 7.39242 30.05C6.69909 30.05 6.07909 29.9 5.53242 29.6C4.99242 29.2933 4.56909 28.87 4.26242 28.33C3.96242 27.79 3.81242 27.1733 3.81242 26.48C3.81242 25.7867 3.96242 25.17 4.26242 24.63C4.56909 24.0833 4.99242 23.66 5.53242 23.36C6.07242 23.0533 6.68909 22.9 7.38242 22.9C8.22242 22.9 8.92909 23.1033 9.50242 23.51C10.0824 23.9167 10.4658 24.48 10.6524 25.2H8.76242ZM13.4296 22.98V30H11.7196V22.98H13.4296ZM19.2388 22.98V24.35H16.3788V25.83H18.5188V27.16H16.3788V30H14.6688V22.98H19.2388Z"
      fill="#fff"
    />
  </svg>
)
export default SvgComponent
