import MessageItem from '../MessageItem/MessageItem'
import PropTypes from 'prop-types'
import s from './OpenedTicketMessages.module.scss'
import { systemNotificationsTranslate } from '@utils'
import { useTranslation } from 'react-i18next'
import { forwardRef, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { IconButton } from '@components'

const OpenedTicketMessages = forwardRef(function OpenedTicketMessages(
  { messages, isScrollSmooth, scrollToNewMessage },
  ref,
) {
  const { t } = useTranslation('support')
  const [isGoDownShown, setIsGoDownShown] = useState(false)
  const [hasNewMessage, setHasNewMessage] = useState(false)

  const prevMessagesCount = useRef(messages?.length)

  useEffect(() => {
    if (
      messages[messages.length - 1]?.$type === 'incoming' &&
      prevMessagesCount.current !== messages.length
    ) {
      setHasNewMessage(true)
    }
    prevMessagesCount.current = messages.length
  }, [messages.length])

  const onScrollTicketsHandler = e => {
    const { scrollHeight, scrollTop, offsetHeight } = e.target

    const isShowBtn = scrollHeight - scrollTop > offsetHeight * 1.5
    setIsGoDownShown(isShowBtn)

    if (scrollHeight - scrollTop - 70 < offsetHeight) {
      setHasNewMessage(false)
    }
  }

  return (
    <div
      className={cn(s.messagesBlock, { [s.smooth]: isScrollSmooth })}
      ref={ref}
      onScroll={onScrollTicketsHandler}
    >
      {isGoDownShown && (
        <IconButton
          icon="ArrowSign"
          onClick={scrollToNewMessage}
          className={cn(s.go_down, { [s.has_new]: hasNewMessage })}
          color="third"
          size="small"
        />
      )}
      {messages?.map(el => {
        if (el.$type !== 'info') {
          if (el.$type === 'system') {
            return (
              <div key={el?.$id} className={s.archiveTicket}>
                {systemNotificationsTranslate(el?.body?.$, t)}
              </div>
            )
          }
          return <MessageItem key={el?.$id} message={el} />
        }
      })}
    </div>
  )
})
export default OpenedTicketMessages

OpenedTicketMessages.propTypes = {
  messages: PropTypes.array,
}

OpenedTicketMessages.defaultProps = {
  messages: [],
}
