import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { BASIC_TYPE, PREMIUM_TYPE } from '@src/utils/constants'

import s from '../../../Pages/ServicesPage/CloudVPSPage/InstancesPage/CreateInstancePage/CreateInstancePage.module.scss'
import { BtnLikeRadio, Icon } from '@components/index'

export default function CloudTypeSection({
  value,
  switchCloudType,
  // isLaunchMode,
  premiumTariffs,
  basicTariffs,
}) {
  const CATEGORIES = [
    { type: PREMIUM_TYPE, label: 'Premium VPS', hasTariffs: premiumTariffs },
    { type: BASIC_TYPE, label: 'Basic VPS', hasTariffs: basicTariffs },
  ]

  const { t } = useTranslation(['cloud_vps'])

  const tabsToRender = CATEGORIES.filter(el => el.hasTariffs)

  return (
    <section className={cn('box_styles', s.section)}>
      <h3 className={cn('other_btn_uppercase', s.section_title)}>{t('server_type')}</h3>

      <ul className={s.grid}>
        {tabsToRender.map(({ type, label }) => {
          return (
            <li key={type}>
              <BtnLikeRadio
                isActive={type === value}
                onClick={() => switchCloudType(type)}
              >
                {type === PREMIUM_TYPE && <Icon name="NewIconCrown" />}
                {label}
              </BtnLikeRadio>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
