import { Icon } from '@components'
import s from './VhostItem.module.scss'
import { translatePeriodToMonths } from '@utils'
import { useTranslation } from 'react-i18next'

export default function Component(props) {
  const { pricelist_name, itemId, deleteItemHandler, count, period } = props
  const { t } = useTranslation(['other', 'vds'])

  return (
    <div className={s.server_item}>
      <div className={s.priceList}>
        <div className={s.domainInfo}>
          <p className={'body_l_regular'}>
            {pricelist_name} #{itemId}
          </p>
          <div className={s.periodInfo}>
            <span className={'body_xs'}>
              {t('Period', { ns: 'other' })}: {period > 0 ? period : ''}{' '}
              {translatePeriodToMonths(period)}
            </span>
            <span className={'body_xs'}>
              {t('amount', { ns: 'vds' })}: {count} {t('pcs.', { ns: 'vds' })}
            </span>
          </div>
        </div>

        {typeof deleteItemHandler === 'function' && (
          <button className={s.btn_delete} type="button" onClick={deleteItemHandler}>
            <Icon name="Delete" />
          </button>
        )}
      </div>
    </div>
  )
}
