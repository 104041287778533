import { SERVICES_ID } from '@utils/constants'
import { usePageRender } from '@utils/index'
import { createContext, useContext, useState } from 'react'
import * as route from '@src/routes'
import { useSelector } from 'react-redux'
import { userSelectors } from '@redux/index'

export const RightsContext = createContext({})

export const RightsProvider = ({ children }) => {
  /* STATE */
  const [isUseCertificate, setIsUseCertificate] = useState(false)

  const toggleCertificateModal = () => setIsUseCertificate(p => !p)

  /* GENERAL CATEGORIES PERMISSIONS */
  const areServicesAllowed = usePageRender('mainmenuservice', null, false)
  const isFinanceAllowed = usePageRender('finance', null, false)
  const isAffiliateProgramAllowed = usePageRender('customer', 'affiliate.client', false)
  const isSupportAllowed = usePageRender('support', null, false)

  /* SERVICES ROUTES PERMISSIONS */
  const isDomainsAllowed = usePageRender(
    'mainmenuservice',
    'domain',
    false,
    SERVICES_ID.domain,
  )
  const isVdsAllowed = usePageRender('mainmenuservice', 'vds', false, SERVICES_ID.vds)
  const isDedicactedAllowed = usePageRender(
    'mainmenuservice',
    'dedic',
    false,
    SERVICES_ID.dedic,
  )
  const isVirtualHostAllowed = usePageRender(
    'mainmenuservice',
    'vhost',
    false,
    SERVICES_ID.sharedHosting,
  )
  const isDnsAllowed = usePageRender('mainmenuservice', 'dnshost', false, SERVICES_ID.dns)
  const isFtpAllowed = usePageRender('mainmenuservice', 'storage', false, SERVICES_ID.ftp)
  /* zabota-o-servere !!! this func is not provided in mainmenuservice, needs to be checked */
  // const isWebsitecareAllowed = usePageRender(
  //   'mainmenuservice',
  //   'zabota-o-servere',
  //   false,
  //   SERVICES_ID.siteCare,
  // )
  const isForexServerAllowed = usePageRender(
    'mainmenuservice',
    'forexbox',
    false,
    SERVICES_ID.forex,
  )
  // const isVPNAllowed = usePageRender('mainmenuservice', 'vpn', false, SERVICES_ID.vpn)
  const isInstancesAllowed = usePageRender(
    'mainmenuservice',
    'instances',
    false,
    SERVICES_ID.instances,
  )

  const servicesMenuList = [
    {
      name: 'burger_menu.services.services_list.cloud_vps',
      routeName: route.CLOUD_VPS,
      allowedToRender: isInstancesAllowed,
      service_id: SERVICES_ID.instances,
      iconName: 'NewIconCloudServers',
    },
    {
      name: 'burger_menu.services.services_list.dedicated_servers',
      routeName: route.DEDICATED_SERVERS,
      allowedToRender: isDedicactedAllowed,
      service_id: SERVICES_ID.dedic,
      iconName: 'NewIconDedicatedServers',
    },
    {
      name: 'burger_menu.services.services_list.virtual_hosting',
      routeName: route.SHARED_HOSTING,
      allowedToRender: isVirtualHostAllowed,
      service_id: SERVICES_ID.sharedHosting,
      iconName: 'NewIconVirtualHosting',
    },

    {
      name: 'burger_menu.services.services_list.forex_server',
      routeName: route.FOREX,
      allowedToRender: isForexServerAllowed,
      service_id: SERVICES_ID.forex,
      iconName: 'NewIconForexServers',
    },
    // {
    //   name: 'burger_menu.services.services_list.wordpress_hosting',
    //   routeName: route.WORDPRESS,
    //   allowedToRender: isVirtualHostAllowed,
    //   service_id: SERVICES_ID.sharedHosting,
    // },
    {
      name: 'burger_menu.services.services_list.virtual_servers',
      routeName: route.VPS,
      allowedToRender: isVdsAllowed,
      service_id: SERVICES_ID.vds,
      iconName: 'NewIconVirtualServers',
    },
    {
      name: 'burger_menu.services.services_list.domains',
      routeName: route.DOMAINS,
      allowedToRender: isDomainsAllowed,
      service_id: SERVICES_ID.domain,
      iconName: 'NewIconDomains',
    },
    {
      name: 'burger_menu.services.services_list.external_ftp',
      routeName: route.FTP,
      allowedToRender: isFtpAllowed,
      service_id: SERVICES_ID.ftp,
      iconName: 'NewIconFTP',
    },
    {
      name: 'burger_menu.services.services_list.dns_hosting',
      routeName: route.DNS,
      allowedToRender: isDnsAllowed,
      service_id: SERVICES_ID.dns,
      iconName: 'NewIconDNS_Hosting',
    },
    // {
    //   name: 'burger_menu.services.services_list.wetsite_care',
    //   routeName: route.SITE_CARE,
    //   allowedToRender: isWebsitecareAllowed,
    //   service_id: SERVICES_ID.siteCare,
    // },
    // {
    //   name: 'VPN',
    //   routeName: route.VPN,
    //   allowedToRender: isVPNAllowed,
    //   service_id: SERVICES_ID.vpn,
    // },
  ]

  /* SUPPORT ROUTES PERMISSIONS */
  const isArchiveAllowed = usePageRender('support', 'clientticket_archive', false)
  const isRequestsAllowed = usePageRender('support', 'clientticket', false)

  const supportMenuList = [
    {
      name: 'burger_menu.support.support_list.requests',
      routeName: `${route.SUPPORT}/requests`,
      allowedToRender: isRequestsAllowed,
      iconName: 'SupportIcon',
    },

    {
      name: 'burger_menu.support.support_list.requests_archieve',
      routeName: `${route.SUPPORT}/requests_archive`,
      allowedToRender: isArchiveAllowed,
      iconName: 'NewIconArchive',
    },
  ]

  /* AFFILIATE PROGRAM ROUTES PERMISSIONS */
  //   const isAffiliateProgramIncomeAllowed = usePageRender(
  //     'customer',
  //     'affiliate.client.reward',
  //     false,
  //   )
  //   const isAffiliateProgramStatisticAllowed = usePageRender(
  //     'customer',
  //     'affiliate.client.click',
  //     false,
  //   )

  const refProgramMenuList = [
    {
      name: 'burger_menu.ref_program.ref_program_list.about_program',
      routeName: `${route.AFFILIATE_PROGRAM}/${route.AFFILIATE_PROGRAM_ABOUT}`,
      allowedToRender: true,
      iconName: 'NewIconHelpCircle',
    },
    {
      name: 'burger_menu.ref_program.ref_program_list.incomes',
      routeName: `${route.AFFILIATE_PROGRAM}/${route.AFFILIATE_PROGRAM_INCOME}`,
      allowedToRender: true,
      iconName: 'NewIconCurrencyEuro',
    },
    {
      name: 'burger_menu.ref_program.ref_program_list.statistic',
      routeName: `${route.AFFILIATE_PROGRAM}/${route.AFFILIATE_PROGRAM_STATISTICS}`,
      allowedToRender: true,
      iconName: 'NewIconLineChartUp',
    },
  ]

  /* FINANCE ROUTES PERMISSIONS */
  const isExpensesAllowed = usePageRender('finance', 'expense')
  const isPaymentsAllowed = usePageRender('finance', 'payment')
  const isAutoPaymentAllowed = usePageRender('finance', 'payment.recurring.settings')
  const isPaymentMethodAllowed = usePageRender(
    'finance',
    'payment.recurring.stored_methods',
  )
  const arePayersAllowed = usePageRender('customer', 'profile', false)

  const isAutoRenewalAllowed = usePageRender('finance', 'services.autoprolong')

  const financeMenuList = [
    {
      name: 'burger_menu.finance.finance_list.payments',
      routeName: `${route.BILLING}/payments`,
      allowedToRender: isPaymentsAllowed,
      iconName: 'NewIconWalletTopUp',
    },
    {
      name: 'burger_menu.finance.finance_list.expenses',
      routeName: `${route.BILLING}/expenses`,
      allowedToRender: isExpensesAllowed,
      iconName: 'NewIconCreditCardDown',
    },
    {
      name: 'burger_menu.finance.finance_list.automatic_payment',
      routeName: `${route.BILLING}/auto_payment`,
      allowedToRender: isAutoPaymentAllowed,
      iconName: 'NewIconWalletAutoTopUp',
    },
    {
      name: 'burger_menu.finance.finance_list.auto_renewal',
      routeName: `${route.BILLING}/auto_renewal`,
      allowedToRender: isAutoRenewalAllowed,
      iconName: 'Renew',
    },
    {
      name: 'profile.payers',
      routeName: `${route.BILLING}/payers`,
      allowedToRender: arePayersAllowed,
      iconName: 'NewIconUsers',
    },
    {
      name: 'burger_menu.finance.finance_list.payment_method',
      routeName: `${route.BILLING}/payment_method`,
      allowedToRender: isPaymentMethodAllowed,
      iconName: 'NewIconCreditCard',
    },
  ]

  /** FOR HEADER SECTION */
  const isEnvelopeAllowed = usePageRender('support', 'clientticket', false)

  const isTrustedUsersAllowed = usePageRender('customer', 'user', false)
  const isDomainContactsAllowed = usePageRender('customer', 'service_profile')
  const areUserSettingsAllowed = usePageRender('customer', 'usrparam', false)
  const isAuthLogAllowed = usePageRender('stat', 'authlog', false)
  const areContractsAllowed = usePageRender('customer', 'contract', false)

  const profileMenuList = [
    {
      name: 'profile.user_settings',
      routeName: route.USER_SETTINGS,
      allowedToRender: areUserSettingsAllowed,
    },
    {
      name: 'profile.trusted_users',
      routeName: route.USER_SETTINGS + route.TRUSTED_USERS,
      allowedToRender: isTrustedUsersAllowed,
    },
    {
      name: 'profile.domain_contacts',
      routeName: route.USER_SETTINGS + route.DOMAIN_CONTACTS,
      allowedToRender: isDomainContactsAllowed,
    },
    {
      name: 'profile.visiting_log',
      routeName: route.USER_SETTINGS + route.ACCESS_LOG,
      allowedToRender: isAuthLogAllowed,
    },
    // {
    //   name: 'profile.payers',
    //   routeName: route.PAYERS,
    //   allowedToRender: arePayersAllowed,
    // },
    {
      name: 'profile.contracts',
      routeName: route.USER_SETTINGS + route.CONTRACTS,
      allowedToRender: areContractsAllowed,
    },
    {
      name: 'profile.use_certificate',
      allowedToRender: areContractsAllowed,
      onClick: toggleCertificateModal,
    },
  ]

  const currentActiveServices = useSelector(userSelectors.getActiveServices)

  const checkServiceExistence = service_id => {
    return currentActiveServices.some(
      service => Number(service.id_itemtype.$) === service_id,
    )
  }

  const myServicesMenuList = []
  const otherServicesMenuList = []

  servicesMenuList.forEach(item => {
    const activeServiceExist = checkServiceExistence(item.service_id)

    if (item.allowedToRender) {
      if (activeServiceExist) {
        myServicesMenuList.push(item)
      } else {
        otherServicesMenuList.push(item)
      }
    }
    return
  })

  return (
    <RightsContext.Provider
      value={{
        isUseCertificate,
        toggleCertificateModal,

        categories_rights: {
          areServicesAllowed,
          isFinanceAllowed,
          isAffiliateProgramAllowed,
          isSupportAllowed,
          isEnvelopeAllowed,
        },
        menuLists: {
          myServicesMenuList,
          otherServicesMenuList,
          refProgramMenuList,
          supportMenuList,
          financeMenuList,
          profileMenuList,
          allServicesList: servicesMenuList,
        },
      }}
    >
      {children}
    </RightsContext.Provider>
  )
}

export const useRightsContext = () => useContext(RightsContext)
