import { useTranslation } from 'react-i18next'
import { translatePeriodToMonths } from '@utils'

import s from './DnsItem.module.scss'
import { Icon } from '@components'

export default function DnsItem(props) {
  const { t } = useTranslation([
    'other',
    'dns',
    'crumbs',
    'vds',
  ])

  const { pricelist_name, deleteItemHandler, count, period } = props

  return (
    <div className={s.server_item}>
      <div className={s.server_info}>
        <span className={'body_l_regular'}>
          {pricelist_name
            ?.replace('for', t('for', { ns: 'dns' }))
            ?.replace('domains', t('domains', { ns: 'dns' }))
            ?.replace('DNS-hosting', t('dns', { ns: 'crumbs' }))}{' '}
        </span>
        <div className={s.periodInfo}>
          <span className={'body_xs'}>
            {t('Period', { ns: 'other' })}: {period} {translatePeriodToMonths(period)}
          </span>
          <span className={'body_xs'}>
            {t('amount', { ns: 'vds' })}: {count} {t('pcs.', { ns: 'vds' })}
          </span>
        </div>
      </div>

      {typeof deleteItemHandler === 'function' && (
        <button className={s.btn_delete} type="button" onClick={deleteItemHandler}>
          <Icon name="Delete" />
        </button>
      )}
    </div>
  )
}
