export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.4329 9.33163C6.67564 9.57283 7.00395 9.70821 7.34614 9.70821C7.68834 9.70821 8.01664 9.57283 8.25939 9.33163L9.33187 8.2591C9.57328 8.01638 9.7088 7.68796 9.7088 7.34561C9.7088 7.00327 9.57328 6.67485 9.33187 6.43213L6.90435 4.00329C6.89955 3.99867 6.89597 3.99293 6.89392 3.98659C6.89188 3.98024 6.89144 3.97349 6.89263 3.96694C6.89383 3.96038 6.89664 3.95422 6.9008 3.94902C6.90495 3.94381 6.91034 3.93971 6.91646 3.93709C8.48341 3.26539 10.515 3.6077 11.805 4.88852C13.0393 6.11364 13.1096 7.95636 12.6575 9.56455C12.5933 9.79314 12.5934 10.035 12.658 10.2635C12.7225 10.492 12.8489 10.6983 13.0232 10.8595L19.7479 17.0012C19.9463 17.1771 20.1066 17.3918 20.219 17.632C20.3314 17.8721 20.3935 18.1327 20.4015 18.3978C20.4094 18.6629 20.3631 18.9267 20.2653 19.1732C20.1675 19.4197 20.0204 19.6436 19.8329 19.8311C19.6454 20.0186 19.4215 20.1657 19.175 20.2635C18.9286 20.3613 18.6647 20.4077 18.3996 20.3997C18.1346 20.3918 17.874 20.3297 17.6338 20.2173C17.3937 20.1049 17.179 19.9445 17.0031 19.7461L10.7927 13.005C10.6335 12.8336 10.4307 12.7087 10.206 12.6438C9.98131 12.5789 9.74318 12.5763 9.51714 12.6364C7.92113 13.0639 6.10474 12.9836 4.88654 11.7811C3.5751 10.4893 3.29214 8.20622 3.92788 6.89957C3.93074 6.89386 3.93492 6.88891 3.94007 6.88513C3.94522 6.88134 3.95119 6.87884 3.95749 6.87781C3.9638 6.87679 3.97025 6.87728 3.97633 6.87923C3.98241 6.88119 3.98794 6.88456 3.99247 6.88907L6.4329 9.33163Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}
