const SvgComponent = props => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 1.00002C12 0.734799 12 0 12 0C12 0 11.2652 1.61307e-05 11 1.61191e-05L-1.43051e-05 1.56382e-05C-1.43051e-05 1.56382e-05 -1.43103e-05 0.119023 -1.43137e-05 0.195278C-1.43217e-05 0.379372 -1.43265e-05 0.489871 -1.43343e-05 0.666682C-1.4342e-05 0.843492 -1.43549e-05 1.13809 -1.43549e-05 1.13809L-1.43634e-05 1.33335L-1.49754e-05 15.3333C-1.49808e-05 15.4571 0.0344613 15.5785 0.0995505 15.6838C0.164641 15.7891 0.257772 15.8742 0.368509 15.9296C0.479245 15.985 0.603214 16.0084 0.726521 15.9973C0.849829 15.9862 0.967604 15.9409 1.06665 15.8667L5.99998 12.1667L10.9333 15.8667C11.0324 15.9409 11.1501 15.9862 11.2734 15.9973C11.3967 16.0084 11.5207 15.985 11.6314 15.9296C11.7422 15.8742 11.8353 15.7891 11.9004 15.6838C11.9655 15.5785 12 15.4571 12 15.3333L12 1.00002Z"
      fill="#FF4AB7"
    />
  </svg>
)

export default SvgComponent
