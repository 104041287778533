import s from './PolicyAgreement.module.scss'
import cn from 'classnames'
import { t } from 'i18next'
import { OFERTA_URL, PRIVACY_URL } from '@config/config'
import { CheckBox, Cta } from '@components'
import { ErrorMessage } from 'formik'

export default function PolicyAgreement({
  fieldName,
  fieldValue,
  setFieldValue,
  checkboxClassName,
  wrapperClassName,
  error,
  touched,
  onCheck,
}) {
  const handleCheckboxClick = () => {
    if (onCheck) {
      onCheck()
    } else {
      setFieldValue(fieldName, !fieldValue)
    }
  }

  return (
    <>
      <div className={cn(s.offerBlock, { [wrapperClassName]: wrapperClassName })}>
        <CheckBox
          name={fieldName}
          value={fieldValue}
          onClick={handleCheckboxClick}
          className={cn(s.checkbox, { [checkboxClassName]: checkboxClassName })}
          error={error}
          touched={touched}
        />

        <div className={cn('body_s', s.offerBlockText)}>
          {t('I agree with', { ns: 'payers' })}
          <Cta
            element="a"
            viewType="link"
            target="_blank"
            href={OFERTA_URL}
            rel="noreferrer"
          >
            {t('Terms of Service', { ns: 'domains' })}
          </Cta>
          {t('and', { ns: 'domains' })}
          <Cta
            element="a"
            viewType="link"
            target="_blank"
            href={PRIVACY_URL}
            rel="noreferrer"
          >
            {t('Terms of the offer', { ns: 'domains' })}
          </Cta>
        </div>
      </div>
      <ErrorMessage className={'error_message'} name={fieldName} component="span" />
    </>
  )
}
