export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99997 7.49994V10.6249M2.24747 13.4383C1.52581 14.6883 2.42831 16.2499 3.87081 16.2499H16.1291C17.5708 16.2499 18.4733 14.6883 17.7525 13.4383L11.6241 2.81494C10.9025 1.56494 9.09747 1.56494 8.37581 2.81494L2.24747 13.4383V13.4383ZM9.99997 13.1249H10.0058V13.1316H9.99997V13.1249Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
