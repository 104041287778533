const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    style={{ flexShrink: 0 }}
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M10 4L10 16" stroke="currentColor" strokeLinecap="round" />
    <path d="M16 10L4 10" stroke="currentColor" strokeLinecap="round" />
  </svg>
)

export default SvgComponent
