import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import s from './TarifCard.module.scss'
import { Icon } from '@components'
import { roundToDecimal } from '@utils'

export default function Component(props) {
  const { t } = useTranslation(['virtual_hosting', 'other'])

  const [data, setData] = useState(null)

  const { tariff, selected, setPriceHandler, period, isColored } = props

  useEffect(() => {
    const data = {}

    const newArr = tariff?.desc?.$?.split(' |')?.map(el => {
      el = el?.replace('<p>', '')
      el = el?.replace('</p>', '')
      el = el?.replace('\n', '')

      return el
    })

    newArr?.forEach((el, index) => {
      if (index === 0) {
        return (data.name = el)
      } else if (el?.includes('SSD')) {
        el = el?.replace('SSD ', '')
        return (data.ssd = el)
      } else if (el?.includes('Sites')) {
        el = el?.replace('Sites ', '')
        return (data.sites = el)
      } else if (el?.includes('Subdomains')) {
        el = el?.replace('Subdomains ', '')
        return (data.subdomains = el)
      } else if (el?.includes('Database')) {
        el = el?.replace('Database ', '')
        return (data.database = el)
      } else if (el?.includes('ОЗУ') || el?.includes('RAM')) {
        el = el?.replace('ОЗУ ', '')
        el = el?.replace('RAM ', '')
        return (data.ram = el)
      }
    })

    setData(data)
  }, [tariff])

  const parsePrice = price => {
    if (period === 'Trial period') {
      return
    }

    const words = price?.match(/[\d|.|\\+]+/g)
    const amounts = []

    if (words && words.length > 0) {
      words.forEach(w => {
        if (!isNaN(w)) {
          amounts.push(w)
        }
      })
    } else {
      return
    }

    if (amounts?.length === 1) {
      return {
        amount: amounts[0],
      }
    }

    return {
      percent: amounts[0],
      old_amount: roundToDecimal(amounts[1]),
      amount: roundToDecimal(amounts[2]),
    }
  }

  return (
    <button
      className={cn('box_styles', s.card, {
        [s.selected]: selected,
        [s.disabled]: period === 'Trial period' && data?.name !== 'AFFORDABLE',
        [s.cardColor]: isColored,
      })}
      onClick={
        period === 'Trial period' && data?.name !== 'AFFORDABLE' ? null : setPriceHandler
      }
    >
      <div className={s.tariff_title}>{data?.name.toLowerCase()}</div>

      <div className={cn('body_s', s.tariff_parameters)}>
        <span className={s.parameter_label}>SSD</span>
        <div>{data?.ssd === 'unlimited' ? <Icon name="Infinity" /> : data?.ssd}</div>

        <span className={s.parameter_label}>{t('Sites')}</span>
        <div>{data?.sites === 'unlimited' ? <Icon name="Infinity" /> : data?.sites}</div>

        <span className={s.parameter_label}>{t('Subdomains')}</span>
        <div>
          {data?.subdomains === 'unlimited' ? <Icon name="Infinity" /> : data?.subdomains}
        </div>

        <span className={s.parameter_label}>{t('Database')}</span>
        <div>
          {data?.database === 'unlimited' ? <Icon name="Infinity" /> : data?.database}
        </div>

        <span className={s.parameter_label}>{t('RAM')}</span>
        <div>{data?.ram === 'unlimited' ? <Icon name="Infinity" /> : data?.ram}</div>
      </div>

      <div className={s.card_footer}>
        <div
          className={cn(s.tariff_price, {
            [s.freeTrial]: period === 'Trial period' && data?.name === 'AFFORDABLE',
          })}
        >
          {period === 'Trial period' && data?.name !== 'AFFORDABLE'
            ? t(tariff?.price?.$?.trim())
            : period === 'Trial period' && data?.name === 'AFFORDABLE'
            ? `0.00 EUR ${t('for 10 days')}`
            : '€' + parsePrice(tariff?.price?.$)?.amount}
        </div>

        {parsePrice(tariff?.price?.$)?.percent && (
          <span className={cn('body_xs', s.old_price)}>
            €{parsePrice(tariff?.price?.$)?.old_amount}
          </span>
        )}
      </div>
    </button>
  )
}
