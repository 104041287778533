import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { InputField, Button, Modal, Cta } from '@components'
import {
  settingsActions,
  settingsOperations,
  settingsSelectors,
  userSelectors,
} from '@redux'
import * as Yup from 'yup'
import s from './ModalTwoStepVerification.module.scss'
import Clock from './Clock'

export default function Component(props) {
  const dispatch = useDispatch()

  const [showMore, setShowMore] = useState(false)

  const twoStepVerif = useSelector(settingsSelectors.getTwoStepVerif)
  const userInfo = useSelector(userSelectors.getUserInfo)

  const { t } = useTranslation(['user_settings', 'other', 'support'])

  const { closeModal, isOpen } = props

  const validationSchema = Yup.object().shape({
    qrcode: Yup.string()
      .matches(
        /^\d{6}$/,
        t('Password must contain at least 6 characters', { ns: 'other' }),
      )
      .required(t('Is a required field', { ns: 'support' })),
  })

  useEffect(() => {
    dispatch(settingsOperations.getQR(twoStepVerif?.qrimage))
  }, [])

  const saveSecretKeyHandler = () => {
    dispatch(settingsOperations.getSecretKeyFile())
  }

  const closeModalHandler = () => {
    dispatch(settingsActions.clearTwoStepVerif())
    closeModal(false)
  }

  const downloadQrHandler = () => {
    const link = document.createElement('a')
    link.href = twoStepVerif?.qrimage
    link.setAttribute('download', 'QRcode.png')
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const sendPasswrodHandler = values => {
    dispatch(settingsOperations.setTotpPassword(userInfo?.$id, values, closeModal))
  }

  return (
    <Modal closeModal={closeModalHandler} isOpen={isOpen} className={s.modal}>
      <Modal.Header>{t('2-Step Verification')}</Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            qrcode: '',
            secret: twoStepVerif?.secret || '',
            email: userInfo?.$email || '',
          }}
          onSubmit={sendPasswrodHandler}
        >
          {({ errors, touched, values, setFieldValue }) => {
            const onInputItemsChange = text => {
              let value = text.replace(/\D/g, '')
              if (value.length > 6) {
                value = value.slice(0, 6)
              }
              setFieldValue('qrcode', value)
            }

            return (
              <Form id="two-step-modal">
                <div className={s.qrInstruction}>
                  <div
                    className={cn('body_m', s.instruction, { [s.showMore]: showMore })}
                  >
                    <p>{t('Two Step Instruction')}</p>
                    <p>
                      {t(
                        'Make sure that time difference between the server and the phone is less than 1 minute.',
                      )}
                    </p>
                    <p className={s.important}>{t('Important!')}</p>
                    <p>{t('Two step warning')}</p>
                  </div>

                  <Cta
                    view="primary"
                    viewType="text"
                    onClick={() => setShowMore(!showMore)}
                    className={cn('body_m', s.readMore)}
                  >
                    {t(showMore ? 'Collapse' : 'Read more')}
                  </Cta>

                  <div className={s.qrBlock}>
                    <img className={s.qrImage} src={twoStepVerif?.qrimage} alt="qrCode" />
                    <Cta
                      view="secondary"
                      viewType="button"
                      onClick={saveSecretKeyHandler}
                      className={'body_m'}
                    >
                      {t('Save key')}
                    </Cta>

                    <Cta
                      view="primary"
                      viewType="text"
                      onClick={downloadQrHandler}
                      className={cn('body_m', s.qrCodeBtn)}
                    >
                      {t('Save QR code')}
                    </Cta>
                  </div>
                </div>

                <div className={s.fieldsRow}>
                  <InputField
                    className={s.passInput}
                    background
                    type="text"
                    label={`${t('Authentication code')}:`}
                    placeholder={t('Enter code')}
                    value={values?.qrcode}
                    onChange={e => onInputItemsChange(e?.target?.value)}
                    name="qrcode"
                    error={!!errors.qrcode}
                    touched={!!touched.qrcode}
                  />

                  <InputField
                    name="ref_link_field"
                    label={`${t('Key')}:`}
                    labelTooltipPlace="bottom"
                    value={twoStepVerif?.secret}
                    placeholder={twoStepVerif?.secret}
                    autoComplete="off"
                    iconRight="NewIconCopy"
                    inputIconTooltip={t('Key copied')}
                    onIconClick={() =>
                      navigator.clipboard.writeText(twoStepVerif?.secret)
                    }
                    useFormik={false}
                    disabled
                  />
                </div>

                <div className={s.timeRow}>
                  <div className={'body_m'}>
                    <p>{t('Server time')}:</p>

                    <Clock date={twoStepVerif?.servertime} />
                  </div>

                  <div className={'body_m'}>
                    <p>{t('Current time')}:</p>
                    <Clock date={twoStepVerif?.actualtime} />
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={s.saveBtn}
          size="medium"
          label={t('Save', { ns: 'other' })}
          type="submit"
          form="two-step-modal"
        />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModalHandler}
          className={cn('body_m')}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
