import { Formik, Form } from 'formik'
import {
  InputField,
  Icon,
  CheckBox,
  DoubleInputField,
  Select,
  Cta,
  Modal,
} from '@components'
import s from './Filter.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { exists as isTranslationExists } from 'i18next'
import { onlyDigitsValue } from '@utils'
import { useEffect, useRef } from 'react'
export default function FilterModal({
  filters,
  filterState,
  closeFilterModal,
  setFiltersHandler,
  clearFilterValues,
  fields,
  isFiltered,
}) {
  const { t } = useTranslation(['filter'])

  const isDirty = useRef()

  return (
    <Modal isOpen closeModal={closeFilterModal} className={s.modal}>
      <Modal.Header>
        <p>{t('filter')}</p>
      </Modal.Header>
      <Modal.Body className={s.modal__body}>
        <Formik
          initialValues={filterState}
          onSubmit={values => {
            if (!isDirty.current) {
              closeFilterModal()
              return
            }
            closeFilterModal()
            setFiltersHandler(values)
          }}
        >
          {({ setFieldValue, values, errors, touched, dirty }) => {
            const setOnlyDigitsHandler = (field, value) => {
              const transValue = onlyDigitsValue(value)
              setFieldValue(field, transValue)
            }

            useEffect(() => {
              isDirty.current = dirty
            }, [dirty])

            return (
              <Form id="filter" className={cn(s.form)}>
                {fields
                  .filter(field => !field.hidden)
                  .map(field => {
                    if (field.type === 'checkbox') {
                      return (
                        <span className={s.checkbox_field} key={field.label}>
                          <CheckBox
                            value={values[field.value] === field.checkboxValues.checked}
                            onClick={() => {
                              setFieldValue(
                                field.value,
                                values[field.value] === field.checkboxValues.checked
                                  ? ''
                                  : field.checkboxValues.checked,
                              )
                            }}
                          />
                          <span>{t(field.label)}</span>
                        </span>
                      )
                    } else if (field.type === 'select') {
                      const options =
                        field.options ??
                        filters.slist
                          .find(list => list.$name === field.value)
                          ?.val.filter(el => {
                            if (field.pickOptions) {
                              return field.pickOptions.includes(el.$key)
                            }
                            return !field.excludeOptions?.includes(el.$key)
                          })
                          .map(el => ({
                            label: field.transformLable?.(el.$.trim()) ?? el?.$?.trim(),
                            value: el?.$key,
                          }))

                      const itemList = options?.map(el => ({
                        ...el,
                        label: isTranslationExists(`select.${el.label}`, {
                          ns: 'filter',
                        })
                          ? t(`select.${el.label}`)
                          : el.label,
                      }))

                      return (
                        <Select
                          label={`${t(field.label)}:`}
                          placeholder={t('not_selected')}
                          value={values[field.value]}
                          getElement={item => setFieldValue(field.value, item)}
                          itemsList={itemList}
                          className={s.select}
                          hasNotSelectedOption={!field.hideEmptyOption}
                          key={field.label}
                          isColored
                        />
                      )
                    } else if (field.type === 'date_range') {
                      const group = field.groupLabel

                      const leftField = field
                      const rightField = fields.find(
                        el => el.groupLabel === group && el.rangeSide === 'right',
                      )

                      let datesOrdered = null
                      if (values[leftField.value] && values[rightField.value]) {
                        datesOrdered = [
                          new Date(values[leftField.value]),
                          new Date(values[rightField.value]),
                        ]
                      } else if (values[leftField.value]) {
                        datesOrdered = new Date(values[leftField.value])
                      }

                      return (
                        <DoubleInputField
                          className={s.input}
                          nameLeft={leftField.value}
                          nameRight={rightField.value}
                          valueLeft={values[leftField.value]}
                          onChangeLeft={() => null}
                          valueRight={values?.[rightField.value]}
                          onChangeRight={() => null}
                          label={`${t(field.groupLabel)}:`}
                          isCalendar
                          dates={datesOrdered}
                          range={values[leftField.value]?.length !== 0}
                          setFieldValue={setFieldValue}
                          key={field.label}
                        />
                      )
                    } else if (field.type === 'digits_range') {
                      const group = field.groupLabel

                      const leftField = field
                      const rightField = fields.find(
                        el => el.groupLabel === group && el.rangeSide === 'right',
                      )

                      return (
                        <DoubleInputField
                          className={s.input}
                          nameLeft={leftField.label}
                          nameRight={rightField.label}
                          valueLeft={values[leftField.value]}
                          onChangeLeft={e =>
                            setOnlyDigitsHandler(leftField.value, e.target.value)
                          }
                          valueRight={values?.[rightField.value]}
                          onChangeRight={e =>
                            setOnlyDigitsHandler(rightField.value, e.target.value)
                          }
                          label={`${t(field.groupLabel)}:`}
                          placeholderLeft={t(leftField.label)}
                          placeholderRight={t(rightField.label)}
                          setFieldValue={setFieldValue}
                          key={field.label}
                        />
                      )
                    } else if (field.type === 'cost_range') {
                      const group = field.groupLabel

                      const leftField = field
                      const rightField = fields.find(
                        el => el.groupLabel === group && el.rangeSide === 'right',
                      )

                      return (
                        <DoubleInputField
                          className={s.input}
                          nameLeft={leftField.label}
                          nameRight={rightField.label}
                          valueLeft={values[leftField.value]}
                          onChangeLeft={e =>
                            setOnlyDigitsHandler(leftField.value, e.target.value)
                          }
                          valueRight={values?.[rightField.value]}
                          onChangeRight={e =>
                            setOnlyDigitsHandler(rightField.value, e.target.value)
                          }
                          label={`${t(field.groupLabel)}:`}
                          placeholderLeft="0.00"
                          placeholderRight="0.00"
                          textLeft="EUR"
                          textRight="EUR"
                          setFieldValue={setFieldValue}
                          key={field.label}
                        />
                      )
                    } else {
                      return (
                        <InputField
                          key={field.label}
                          name={field.value}
                          label={`${t(field.label)}:`}
                          placeholder={`${t(field.label)}`}
                          className={s.input}
                          error={!!errors[field.value]}
                          touched={!!touched[field.value]}
                          onChange={
                            field.type === 'number'
                              ? e => setOnlyDigitsHandler(field.value, e.target.value)
                              : e => setFieldValue(field.value, e.target.value)
                          }
                        />
                      )
                    }
                  })}
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Cta view="secondary" buttonType="submit" form={'filter'}>
          <Icon name="NewIconSearch_light" />
          {t('search')}
        </Cta>
        <Cta
          viewType="text"
          onClick={() => {
            closeFilterModal()
            isFiltered && setFiltersHandler(clearFilterValues)
          }}
        >
          <Icon name="NewIconClose_square_light" />
          {t('clear_filter')}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
