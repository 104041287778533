import { useState, useEffect } from 'react'
import s from './NotificationBtn.module.scss'
import cn from 'classnames'

import { useSelector } from 'react-redux'
import { userSelectors } from '@redux'

import { usePageRender } from '@utils'
import { Icon, NotificationsBar } from '@components'

export default function NotificationBtn(props) {
  const { isHeader, isBurger, isMobile, isScrolled } = props
  const isBellAllowedToRender = usePageRender('support', 'notification', false)

  const userItems = useSelector(userSelectors.getUserItems)
  const notifications = userItems?.messages_count ? userItems?.messages_count : 0

  const [isNotificationBarOpened, setIsNotificationBarOpened] = useState(false)

  const handleBellClick = () => {
    setIsNotificationBarOpened(!isNotificationBarOpened)
  }

  // Closing the notifications when switching to size 768 / 767
  useEffect(() => {
    setIsNotificationBarOpened(false)
  }, [isMobile])

  return (
    <>
      {isBellAllowedToRender && notifications > 0 && (
        <div
          className={cn(s.item_bell, {
            [s.header_btn]: isHeader,
            [s.burger_btn]: isBurger,
            [s.notification_messages]: notifications > 0,
          })}
        >
          <button
            onClick={handleBellClick}
            className={cn(s.btn, {
              [s.opened]: isNotificationBarOpened,
            })}
          >
            <Icon name="Bell" svgheight="20" svgwidth="20" className={s.bell} />
            {notifications > 0 && (
              <div className={s.notification_counter_wrapper}>
                <p className={s.notification_messages_counter}>{notifications}</p>
              </div>
            )}
          </button>
        </div>
      )}

      <NotificationsBar
        // countNotification={notifications}
        // removedNotification={handleRemoveNotif}
        isBarOpened={isNotificationBarOpened}
        handler={handleBellClick}
        isScrolled={isScrolled}
      />
    </>
  )
}
