import s from './CardsContainer.module.scss'
import cn from 'classnames'
import Grid from './Grid'

function CardWrapper({ className, children, ...props }) {
  return (
    <li className={cn(s.item, className)} {...props}>
      {children}
    </li>
  )
}

CardWrapper.Grid = Grid

export default CardWrapper
