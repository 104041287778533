import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { CardsContainer, CardWrapper, Icon, Table } from '@components'
import { affiliateOperations, usersOperations } from '@redux'

import s from './AffiliateProgramStatistics.module.scss'
import { getFormatedDate, useContainerContext, useFilter } from '@utils'

const FILTER_FIELDS = [
  {
    label: 'dateStart',
    value: 'dateStart',
    type: 'date_range',
    groupLabel: 'transition_date',
    rangeSide: 'left',
  },
  {
    label: 'dateEnd',
    value: 'dateEnd',
    type: 'date_range',
    groupLabel: 'transition_date',
    rangeSide: 'right',
    hidden: true,
  },
  { label: 'from_site', value: 'site' },

  {
    label: 'client_registered',
    value: 'referal',
    type: 'select',
    hideEmptyOption: true,
  },
  {
    label: 'client_payed',
    value: 'payed',
    type: 'select',
    hideEmptyOption: true,
  },
]

const HEAD_CELLS = [
  { label: 'date', value: 'date' },
  { label: 'statistics_section.from_site', value: 'from_site' },
  { label: 'statistics_section.client', value: 'client' },
  { label: 'statistics_section.payment', value: 'payment' },
]

export default function AffiliateProgramStatistics({ signal, setIsLoading, isLoading }) {
  const dispatch = useDispatch()
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })

  const [availableRights, setAvailabelRights] = useState({})

  const [sortBy, setSortBy] = useState('+createdate')
  const [filters, setFilters] = useState({})
  const { setContextData } = useContainerContext()

  const setPagination = value => setContextData({ pagination: value })
  const [items, setItems] = useState([])

  const getItems = useCallback(
    (() => {
      let num, cnt
      return ({ p_col, p_num, p_cnt } = {}) => {
        num = p_num ?? num
        cnt = p_cnt ?? cnt
        dispatch(
          affiliateOperations.getStatistics({
            p_col,
            p_cnt,
            p_num: num,
            setPagination,
            setSortBy,
            setFilters,
            setItems,
            setIsLoading,
            signal,
          }),
        )
      }
    })(),
    [],
  )

  const setFiltersHandler = values => {
    if (!values.referal) {
      values.referal = 'null'
    }
    if (!values.payed) {
      values.payed = 'null'
    }
    dispatch(
      affiliateOperations.setStatisticFilter({
        values,
        signal,
        setIsLoading,
        successCallback: () => getItems({ p_num: 1 }),
      }),
    )
  }

  // eslint-disable-next-line no-unused-vars
  const { FilterButton, FilterClouds, isFiltered } = useFilter({
    fields: FILTER_FIELDS,
    filters: filters,
    setFiltersHandler,
    itemsCount: items?.length,
  })

  useEffect(() => {
    dispatch(
      usersOperations.getAvailableRights(
        'affiliate.client.click',
        setAvailabelRights,
        signal,
        setIsLoading,
      ),
    )

    setContextData({
      paginationSettings: { getItems, pageList: 'affiliate' },
    })
    const p_num = localStorage.getItem('p_num.affiliate')
    const p_col = p_num ? undefined : sortBy
    getItems({ p_col, p_num: p_num ?? 1 })
  }, [])

  const checkIfHasArr = availableRights?.toolbar?.toolgrp
  const isFilterAllowedToRender = Array.isArray(checkIfHasArr)
    ? availableRights?.toolbar?.toolgrp[0]?.toolbtn?.some(el => el?.$name === 'filter')
    : false

  const { t } = useTranslation(['affiliate_program', 'other'])

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label, { ns: 'affiliate_program' })}</th>
    })

  return (
    <>
      {isFilterAllowedToRender && (
        <>
          <div className={s.tools_wrapper}>
            <FilterButton />
          </div>
          <FilterClouds />
        </>
      )}
      {items.length === 0 && !isLoading && (
        <p className={s.no_results}>{t('statistics_section.no_result')}</p>
      )}
      {widerThan1024 ? (
        <Table stickyHeader className={s.table}>
          <thead>
            <tr>{renderHeadCells()}</tr>
          </thead>
          <tbody className={s.tbody}>
            {items.map(({ cdate, payed, site, referal }, index) => {
              const { date, time } = getFormatedDate({ fullDate: cdate?.$ })

              return (
                <tr key={index}>
                  <td className={s.row_value}>
                    {date}
                    <span className={s.time}>{time}</span>
                  </td>

                  <td>
                    {site?.$ ? (
                      <span data-wrap>{site?.$}</span>
                    ) : (
                      <span className={s.stub}>
                        {t('statistics_section.unknown_source')}
                      </span>
                    )}
                    {site?.$ && <div className={s.full_text}>{site?.$}</div>}
                  </td>

                  <td className={s.row_value}>
                    {referal?.$ ? (
                      <span data-wrap>{referal?.$}</span>
                    ) : (
                      <span className={s.stub}>
                        {t('statistics_section.not_registered')}
                      </span>
                    )}
                  </td>

                  <td className={s.payment_cell}>
                    {payed?.$ === 'on' ? (
                      <Icon name="Check" className={s.icon_check} />
                    ) : (
                      <span className={s.stub}>{t('statistics_section.not_paid')}</span>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <CardsContainer>
          {items.map(({ cdate, payed, site, referal }, index) => {
            const { date } = getFormatedDate({ fullDate: cdate?.$ })

            return (
              <CardWrapper key={index}>
                <CardWrapper.Grid>
                  <span>{t('date', { ns: 'other' })}:</span>
                  <span>{date}</span>

                  <span>{t('statistics_section.from_site')}:</span>
                  <span className={s.website}>
                    {site?.$ ? (
                      <span className={s.website_url}>{site?.$}</span>
                    ) : (
                      <span>{t('statistics_section.unknown_source')}</span>
                    )}
                    {site?.$ && <div className={s.full_text}>{site?.$}</div>}
                  </span>

                  <span>{t('statistics_section.client')}:</span>
                  <span>
                    {referal?.$ || <span>{t('statistics_section.not_registered')}</span>}
                  </span>

                  <span>{t('statistics_section.payment')}:</span>
                  <span>
                    {payed?.$ === 'on' ? (
                      <Icon name="Check" className={s.icon_check} />
                    ) : (
                      <span>{t('statistics_section.not_paid')}</span>
                    )}
                  </span>
                </CardWrapper.Grid>
              </CardWrapper>
            )
          })}
        </CardsContainer>
      )}
    </>
  )
}
