const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.489 6.308c.556-3.775 4.86-5.006 7.548-2.424 2.093-.768 3.642.199 4.08 2.384 3.668.37 3.403 4.913.357 5.297H3.35C0 11.141.105 5.91 4.489 6.308Z"
    />
    <path d="M5.322 7.977a.616.616 0 0 0-.611.623c0 .343.269.612.611.612a.616.616 0 0 0 .612-.624.606.606 0 0 0-.612-.611ZM7.772 7.977a.616.616 0 0 0-.612.623c0 .343.27.612.612.612a.616.616 0 0 0 .611-.624.606.606 0 0 0-.611-.611Z" />
    <path strokeLinecap="round" strokeMiterlimit={10} d="M10.824 8.597h3.669" />
    <path d="M8.53 17.513c-.6 0-1.089.483-1.089 1.075s.477 1.076 1.064 1.076c.599 0 1.075-.484 1.088-1.076 0-.592-.477-1.075-1.064-1.075ZM11.62 17.513c-.6 0-1.089.483-1.089 1.075s.477 1.076 1.064 1.076c.598 0 1.075-.484 1.087-1.076 0-.592-.476-1.075-1.063-1.075ZM14.799 14.173c-.6 0-1.088.483-1.088 1.075 0 .593.477 1.076 1.063 1.076.6 0 1.076-.483 1.088-1.076 0-.592-.476-1.075-1.063-1.075ZM5.264 14.173c-.6 0-1.088.483-1.088 1.075 0 .593.476 1.076 1.063 1.076.6 0 1.076-.483 1.088-1.076 0-.592-.477-1.075-1.063-1.075Z" />
    <path
      strokeMiterlimit={10}
      d="M8.532 18.674v-6.62M11.622 18.41v-6.62M14.8 14.703V11.79M5.266 14.703V11.79"
    />
  </svg>
)
export default SvgComponent
