import s from './Button.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { LoaderDots } from '@components'

export default function Component(props) {
  const { label, type, onClick, disabled, size, className, datatestid, loading, form } =
    props

  return (
    <button
      data-testid={datatestid}
      disabled={disabled}
      className={cn('btn_base_styles', s.btn, {
        [s.disabled]: disabled,
        [s.block]: size === 'block',
        [s.small]: size === 'small',
        [s.medium]: size === 'medium',
        [s.large]: size === 'large',
        [className]: className,
      })}
      type={type}
      onClick={onClick}
      form={form}
    >
      {loading ? <LoaderDots classname={s.loader} /> : label}
    </button>
  )
}

Component.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  datatestid: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['block', 'small', 'medium', 'large', '']),
  loading: PropTypes.bool,
  form: PropTypes.string,
}

Component.defaultProps = {
  label: 'Button',
  type: 'button',
  onClick: () => null,
  disabled: false,
  size: '',
  datatestid: null,
  loading: false,
}
