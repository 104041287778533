const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={103} height={103} fill="none" {...props}>
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.844 64.356A51.447 51.447 0 0 1 6.64 29.264C12.002 18.396 21.03 9.817 32.096 5.069a50.043 50.043 0 0 1 34.917-1.703C78.482 7.014 88.285 14.674 94.66 24.969m0 0V10.603m0 14.366H79.34"
    />
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M100.156 38.372a51.446 51.446 0 0 1-3.797 35.092C90.997 84.332 81.971 92.91 70.904 97.659a50.043 50.043 0 0 1-34.917 1.703C24.517 95.714 14.715 88.054 8.34 77.758m0 0v14.367m0-14.367h15.319"
    />
    <path
      fill={'currentColor'}
      d="M51.478 49.84c-5.323-.63-7.803-3.056-7.803-7.633 0-4.206 3.439-7.627 7.664-7.627 4.227 0 7.664 3.421 7.664 7.627 0 .641.522 1.161 1.168 1.161.646 0 1.168-.52 1.168-1.161 0-5.093-3.867-9.299-8.832-9.876v-2.895c0-.641-.523-1.162-1.168-1.162-.644 0-1.167.52-1.167 1.162v2.895c-4.965.579-8.832 4.784-8.832 9.876 0 4.002 1.71 8.975 9.861 9.942 5.325.63 7.804 3.056 7.804 7.633 0 4.205-3.438 7.626-7.665 7.626-4.226 0-7.664-3.42-7.664-7.626 0-.642-.523-1.162-1.167-1.162-.645 0-1.168.52-1.168 1.162 0 5.093 3.867 9.297 8.832 9.876v2.896c0 .642.523 1.162 1.167 1.162.645 0 1.168-.52 1.168-1.162v-2.896c4.965-.578 8.832-4.783 8.832-9.876 0-4.003-1.71-8.976-9.862-9.942Z"
    />
  </svg>
)
export default SvgComponent
