import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardWrapper, Icon, IconButton } from '@components'
import { useOutsideAlerter } from '@utils'
import PropTypes from 'prop-types'
import cn from 'classnames'

import s from './DedicIPMobileItem.module.scss'

export default function DedicIPMobileItem({
  ip,
  setElidForEditModal,
  rights,
  setElidForDeleteModal,
}) {
  const { t } = useTranslation(['vds', 'dedicated_servers', 'other'])
  const dropdownEl = useRef()

  const [toolsOpened, setToolsOpened] = useState(false)

  useOutsideAlerter(dropdownEl, toolsOpened, () => setToolsOpened(false))

  const handleToolBtnClick = (fn, id) => {
    fn(id)
    setToolsOpened(false)
  }

  return (
    <CardWrapper className={s.item}>
      <div className={s.btns}>
        {rights?.edit && (
          <IconButton
            onClick={() => handleToolBtnClick(setElidForEditModal, ip?.id.$)}
            icon="NewIconEdit"
            color="secondary"
            size="small"
            className={s.icon_btn}
          />
        )}

        {rights?.delete && ip?.is_main?.$ !== 'on' && (
          <IconButton
            onClick={() => handleToolBtnClick(setElidForDeleteModal, ip?.id.$)}
            icon="Delete"
            color="secondary"
            size="small"
            className={s.icon_btn}
          />
        )}
      </div>

      {ip?.is_main?.$ === 'on' && (
        <div className={s.main_ip}>
          <Icon name="NewIconPennant" />
        </div>
      )}

      <span className={cn('body_s', s.label)}>{t('ip_address')}:</span>
      <span className={s.value}>{ip?.name?.$}</span>

      <span className={cn('body_s', s.label)}>
        {t('mask', { ns: 'dedicated_servers' })}:
      </span>
      <span className={s.value}>{ip?.mask?.$}</span>

      <span className={cn('body_s', s.label)}>
        {t('gateway', { ns: 'dedicated_servers' })}:
      </span>
      <span className={s.value}>{ip?.gateway?.$}</span>

      <span className={cn('body_s', s.label)}>
        {t('domain', { ns: 'dedicated_servers' })}:
      </span>
      <span className={s.value}>{ip?.domain?.$}</span>

      <span className={cn('body_s', s.label)}>{t('status', { ns: 'other' })}:</span>
      <span className={cn(s.value, s[ip?.ip_status?.$.trim().toLowerCase()])}>
        {t(ip?.ip_status?.$?.trim(), { ns: 'other' })}
      </span>
    </CardWrapper>
  )
}

DedicIPMobileItem.propTypes = {
  server: PropTypes.object,
  setElidForEditModal: PropTypes.func,
  rights: PropTypes.object,
}
