import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { TooltipWrapper, Icon } from '@components'
import PropTypes from 'prop-types'
import s from './ServerState.module.scss'

export default function ServerState({ className, server, onlyAutoDelete }) {
  const { t } = useTranslation('vds')

  if (onlyAutoDelete) {
    return (
      <>
        {server?.scheduledclose?.$ === 'on' && (
          <TooltipWrapper
            content={`${t('scheduled_deletion')}${server?.scheduledclose_prop?.$}`}
          >
            <span className={cn(s.status, s.error)}>Auto delete</span>
          </TooltipWrapper>
        )}
      </>
    )
  }

  const statusMapping = {
    '5_open': {
      id: 'open_5',
      content: 'in_progress',
      displayStatus: 'in progress',
    },
    '5_transfer': {
      id: 'transfer_5',
      content: 'in_progress_transfer',
      displayStatus: 'transfer',
    },
    '5_close': {
      id: 'close_5',
      content: 'deletion_in_progress',
      className: s.error,
      displayStatus: 'deletion',
    },
    '3_employeesuspend': {
      id: 'employeesuspend_3',
      content: 'stopped_by_admin',
      className: s.error,
      displayStatus: 'stopped',
      attentIcon: true,
    },
    '3_autosuspend': {
      id: 'autosuspend_3',
      content: 'stopped',
      className: s.error,
      displayStatus: 'stopped',
    },
    '3_abusesuspend': {
      id: 'abusesuspend_3',
      content: 'Suspended due to abuse',
      className: s.error,
      displayStatus: 'suspended',
      attentIcon: true,
    },
  }

  const renderStatus = statusKey => {
    const status = statusMapping[statusKey]

    return (
      status && (
        <TooltipWrapper content={t(status?.content)}>
          <span
            className={cn(s.status, [status?.className], {
              [s.with_icon]: status?.attentIcon,
            })}
          >
            {status?.displayStatus}
            {status?.attentIcon && (
              <Icon name={'Attention'} className={status?.className} />
            )}
          </span>
        </TooltipWrapper>
      )
    )
  }

  return (
    <div className={cn(s.wrapper, className)}>
      {server?.item_status?.$orig === '1' && (
        <TooltipWrapper content={t('ordered')}>
          <span className={cn(s.status, s.warn)}>ordered</span>
        </TooltipWrapper>
      )}
      {server?.item_status?.$orig === '2' && (
        <TooltipWrapper content={t('active')}>
          <span className={cn(s.status, s.success)}>active</span>
        </TooltipWrapper>
      )}

      {renderStatus(server?.item_status?.$orig)}
    </div>
  )
}

ServerState.propTypes = {
  className: PropTypes.string,
  server: PropTypes.object,
}
