import PropTypes from 'prop-types'
import AutoRenewalTable from './AutoRenewalTableItem'
import { useTranslation } from 'react-i18next'
import s from './AutoRenewalTable.module.scss'
import { useMediaQuery } from 'react-responsive'
import { CardsContainer, Table } from '@components'

const HEAD_CELLS = [
  { label: 'service', value: 'service' },
  { label: 'itemtype_name', value: 'itemtype_name' },
  { label: 'cost', value: 'cost' },
  { label: 'next_payment_date', value: 'next_payment_date' },
  { label: 'Payment method', value: 'prolong_period' },
  { label: 'status', value: 'item_status' },
]

export default function PaymentsTable(props) {
  const { t } = useTranslation(['billing', 'other'])
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label, { ns: 'other' })}</th>
    })

  const { list, setElForProlongModal, setItemService } = props

  const renderItems = () => {
    return list?.map(el => {
      const {
        id,
        name,
        itemtype,
        cost,
        itemtype_name,
        next_payment_date,
        full_paymethod,
        item_status,
      } = el

      const openAutoRenewModal = () => {
        setItemService(itemtype?.$)
        setElForProlongModal([el])
      }

      return (
        <AutoRenewalTable
          key={id?.$}
          id={id?.$}
          name={name?.$}
          itemtype={itemtype?.$}
          itemtype_name={itemtype_name?.$}
          cost={cost?.$}
          next_payment_date={next_payment_date?.$}
          full_paymethod={full_paymethod?.$}
          status={item_status?.$}
          openAutoRenewModal={openAutoRenewModal}
          el={el}
        />
      )
    })
  }

  return (
    <>
      {widerThan1024 ? (
        <Table stickyHeader className={s.table}>
          <thead>
            <tr>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody className={s.tbody}>{renderItems()}</tbody>
        </Table>
      ) : (
        <CardsContainer>{renderItems()}</CardsContainer>
      )}
    </>
  )
}

PaymentsTable.propTypes = {
  list: PropTypes.array,
  setSelctedPayment: PropTypes.func,
  selctedPayment: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object]),
}

PaymentsTable.defaultProps = {
  list: [],
}
