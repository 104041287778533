export default function SvgComponent(props) {
  const { svgwidth, svgheight } = props

  return (
    <svg
      viewBox="0 0 15 18"
      width={svgwidth}
      height={svgheight}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.60946 14.0689L3.54078 14.5641L3.60946 14.0689ZM12.0981 14.0689L12.0294 13.5736H12.0294L12.0981 14.0689ZM3.28263 5.73848C3.28263 3.21318 5.32979 1.16602 7.85509 1.16602V0.166016C4.77751 0.166016 2.28263 2.66089 2.28263 5.73848H3.28263ZM3.28263 7.48062V5.73848H2.28263V7.48062H3.28263ZM1.83203 11.4036C1.83203 10.9791 1.9483 10.5832 2.15043 10.2444L1.29167 9.73202C0.999667 10.2214 0.832031 10.7937 0.832031 11.4036H1.83203ZM3.67813 13.5736C2.61376 13.426 1.83203 12.5015 1.83203 11.4036H0.832031C0.832031 12.9772 1.95897 14.3448 3.54078 14.5641L3.67813 13.5736ZM7.85377 13.9343C6.60139 13.9343 4.98798 13.7552 3.67813 13.5736L3.54078 14.5641C4.85875 14.7469 6.5289 14.9343 7.85377 14.9343V13.9343ZM12.0294 13.5736C10.7196 13.7552 9.10615 13.9343 7.85377 13.9343V14.9343C9.17864 14.9343 10.8488 14.7469 12.1668 14.5641L12.0294 13.5736ZM13.8755 11.4036C13.8755 12.5015 13.0938 13.426 12.0294 13.5736L12.1668 14.5641C13.7486 14.3448 14.8755 12.9772 14.8755 11.4036H13.8755ZM13.5576 10.2452C13.7594 10.5838 13.8755 10.9794 13.8755 11.4036H14.8755C14.8755 10.7942 14.7081 10.2224 14.4166 9.7332L13.5576 10.2452ZM12.4276 5.73848V7.4839H13.4276V5.73848H12.4276ZM7.85509 1.16602C10.3804 1.16602 12.4276 3.21318 12.4276 5.73848H13.4276C13.4276 2.66089 10.9327 0.166016 7.85509 0.166016V1.16602ZM14.4166 9.7332C14.1639 9.30933 13.9241 8.9533 13.7288 8.56181C13.54 8.18344 13.4276 7.83373 13.4276 7.4839H12.4276C12.4276 8.05053 12.6103 8.55987 12.8339 9.00823C13.0511 9.44348 13.341 9.88188 13.5576 10.2452L14.4166 9.7332ZM2.28263 7.48062C2.28263 7.83083 2.16996 8.18087 1.9808 8.5596C1.78509 8.95148 1.5448 9.30777 1.29167 9.73202L2.15043 10.2444C2.36738 9.88079 2.65788 9.44201 2.87543 9.00642C3.09955 8.55767 3.28263 8.04785 3.28263 7.48062H2.28263Z"
      />
      <path
        d="M9.66602 16.3613C9.27655 16.9471 8.61057 17.3331 7.85442 17.3331C7.09828 17.3331 6.4323 16.9471 6.04283 16.3613"
        strokeLinecap="round"
      />
    </svg>
  )
}
