export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.2581 14.0586V7.39193C15.2581 5.09193 13.3915 3.22526 11.0915 3.22526C8.79147 3.22526 6.9248 5.09193 6.9248 7.39193V16.5586"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00788 14.6421L6.87454 16.7754L4.74121 14.6421"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
