import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'
import cn from 'classnames'
import 'react-phone-input-2/lib/style.css'
import { ErrorMessage } from 'formik'
import i18n from 'i18next'

import { returnLanguage } from './langimport'
import { selectors } from '@redux'
import { TooltipWrapper, Icon } from '@components'

import s from './CustomPhoneInput.module.scss'

export default function CustomPhoneInput(props) {
  const {
    handleBlur,
    setFieldValue,
    label,
    name,
    labelClass,
    containerClass,
    wrapperClass,
    inputClass,
    userId,
    isRequired,
    buttonClass,
    setCountryCode,
    country = 'us',
    isColored,
    iconRight,
    onIconClick,
    inputIconTooltip,
    infoText,
    ...restProps
  } = props

  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  const lang = returnLanguage(i18n.language === 'en' ? 'es' : i18n.language)

  const inputRef = useRef(null)

  const onValueChange = (value, data) => {
    setCountryCode && setCountryCode(data?.countryCode)
    setFieldValue(userId ? 'phone' + userId : name, value)
  }

  const [dynamicDropdownStyle, setDynamicDropdownStyle] = useState({})
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    if (inputRef.current) {
      const updateDropdownWidth = () => {
        const inputElement = inputRef.current
        if (inputElement) {
          const inputWidth = inputElement.numberInputRef.clientWidth
          setDynamicDropdownStyle(prevStyle => ({
            ...prevStyle,
            width: `${inputWidth}px`,
            zIndex: -1,
          }))
        }
      }

      updateDropdownWidth()

      /* The function is needed to dynamically change the dropdown width */
      window.addEventListener('resize', updateDropdownWidth)

      return () => {
        window.removeEventListener('resize', updateDropdownWidth)
      }
    }
  }, [inputRef])

  useEffect(() => {
    if (inputRef.current) {
      const dropdownContainer = inputRef.current.dropdownContainerRef

      const updateIsOpened = () => {
        const isOpen = dropdownContainer.classList.contains('open')
        setIsOpened(isOpen)
      }

      // Set the initial value for `isOpened`
      updateIsOpened()

      // Using MutationObserver to monitor class changes
      const observer = new MutationObserver(updateIsOpened)
      observer.observe(dropdownContainer, {
        attributes: true,
        attributeFilter: ['class'],
      })

      return () => observer.disconnect()
    }
  }, [inputRef, isOpened])

  const WrapWithButton = ({ children, onIconClick }) => {
    return onIconClick ? (
      <button
        type="button"
        onClick={onIconClick}
        className={cn({
          [s.field_icon_right]: !inputIconTooltip,
          [s.interactive_icon]: !inputIconTooltip,
        })}
      >
        {children}
      </button>
    ) : (
      children
    )
  }

  const WrapWithTooltip = ({ children }) => {
    return inputIconTooltip ? (
      <TooltipWrapper
        content={inputIconTooltip}
        events={{ click: true }}
        wrapperClassName={cn(s.field_icon_right, s.interactive_icon)}
        className={s.hint}
        delayShow={0}
      >
        {children}
      </TooltipWrapper>
    ) : (
      children
    )
  }

  const renderIcon = () =>
    iconRight && (
      <WrapWithTooltip>
        <WrapWithButton onIconClick={onIconClick}>
          <Icon
            name={iconRight}
            className={cn({ [s.field_icon_right]: !onIconClick && !inputIconTooltip })}
          />
        </WrapWithButton>
      </WrapWithTooltip>
    )

  return (
    <div
      className={cn(s.container, { [wrapperClass]: wrapperClass, [s.opened]: isOpened })}
    >
      <p className={cn(s.phone_label, labelClass)}>
        {isRequired ? requiredLabel(label) : label}
      </p>

      <PhoneInput
        ref={inputRef}
        country={country}
        localization={lang}
        onChange={onValueChange}
        onBlur={handleBlur}
        name={name}
        // className={s.field_input}
        dropdownStyle={dynamicDropdownStyle}
        containerClass={cn({ [containerClass]: containerClass })}
        onMount={(_, data) => {
          setCountryCode && setCountryCode(data?.countryCode)
        }}
        inputProps={{
          name,
          getInputRef: el => {
            inputRef.current = el
          },
        }}
        inputClass={cn(s.react_phone_input, s.lang, s.field_input, {
          [inputClass]: inputClass,
        })}
        buttonClass={cn(s.lang_btn, s.extra_style, s.opened, {
          [s.lightTheme]: !darkTheme,
          [buttonClass]: buttonClass,
        })}
        dropdownClass={cn(s.drop_down, s.list, s.list_hover, {
          [s.lightThemeDrop]: !darkTheme,
          [s.background]: isColored,
        })}
        searchClass={cn(s.drop_search, s.list)}
        searchStyle={{ backgroundColor: 'red' }}
        {...restProps}
      />

      {renderIcon()}

      {infoText && (
        <>
          <TooltipWrapper
            content={infoText}
            className={s.hint}
            wrapperClassName={s.info_btn}
            place="top-end"
          >
            <Icon name="NewInfoIcon" />
          </TooltipWrapper>
        </>
      )}

      <ErrorMessage
        name={userId ? 'phone' + userId : name}
        component="span"
        className={s.error_message}
      />
    </div>
  )
}

function requiredLabel(labelName) {
  return (
    <>
      {labelName} {<span className={s.required_star}>*</span>}
    </>
  )
}

CustomPhoneInput.defaultProps = {
  type: 'text',
  placeholder: '',
  name: '',
  className: '',
  userId: '',
  isRequired: false,
}

CustomPhoneInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  wrapperClass: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  isRequired: PropTypes.bool,
}
