const SvgComponent = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.2498 8.28667V36C34.2498 37.7949 32.7947 39.25 30.9998 39.25H9C7.20507 39.25 5.75 37.7949 5.75 36V4C5.75 2.20508 7.20508 0.75 9 0.75H26.0445L34.2498 8.28667Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <mask id="path-2-inside-1_3068_47206" fill="currentColor">
      <path d="M25 1H33.5V9.5H26C25.4477 9.5 25 9.05229 25 8.5V1Z" />
    </mask>
    <path
      d="M25 1H33.5H25ZM33.5 11H26C24.6193 11 23.5 9.88071 23.5 8.5L26.5 8H33.5V11ZM26 11C24.6193 11 23.5 9.88071 23.5 8.5V1H26.5V8L26 11ZM33.5 1V9.5V1Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_3068_47206)"
    />
    <rect x="1" y="20" width="27" height="13" rx="2" fill="#FDB022" />
    <path
      d="M11.9763 22.98V30H10.2663V25.79L8.69625 30H7.31625L5.73625 25.78V30H4.02625V22.98H6.04625L8.01625 27.84L9.96625 22.98H11.9763ZM18.5559 25.24C18.5559 25.6467 18.4626 26.02 18.2759 26.36C18.0893 26.6933 17.8026 26.9633 17.4159 27.17C17.0293 27.3767 16.5493 27.48 15.9759 27.48H14.9159V30H13.2059V22.98H15.9759C16.5359 22.98 17.0093 23.0767 17.3959 23.27C17.7826 23.4633 18.0726 23.73 18.2659 24.07C18.4593 24.41 18.5559 24.8 18.5559 25.24ZM15.8459 26.12C16.1726 26.12 16.4159 26.0433 16.5759 25.89C16.7359 25.7367 16.8159 25.52 16.8159 25.24C16.8159 24.96 16.7359 24.7433 16.5759 24.59C16.4159 24.4367 16.1726 24.36 15.8459 24.36H14.9159V26.12H15.8459ZM19.3162 28.7V27.32L22.4762 22.8H24.3862V27.22H25.2062V28.7H24.3862V30H22.6762V28.7H19.3162ZM22.7962 24.68L21.1162 27.22H22.7962V24.68Z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
