import dayjs from 'dayjs'
import parseDateByServerTime from './parseDateByServerTime'

export default function getFormatedDate({ date, time = '00:00:00', fullDate }) {
  if (!date && !fullDate) return {}

  const convertedDate = dayjs.tz(parseDateByServerTime(fullDate || `${date} ${time}`))

  const formatedDate = convertedDate.format('ll')
  const formatedTime = convertedDate.format('LT')

  return {
    date: formatedDate,
    time: formatedTime,
    fullDateString: `${formatedDate} ${formatedTime}`,
  }
}
