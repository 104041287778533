import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Trans, useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import { authActions, authOperations, authSelectors } from '@redux'
import {
  VerificationModal,
  InputField,
  Icon,
  LoaderDots,
  Cta,
  ErrorMessageStyled,
} from '@components'
import * as routes from '@src/routes'
import { RECAPTCHA_KEY } from '@config/config'
import {
  EMAIL_SPECIAL_CHARACTERS_REGEX,
  GOOGLE_LOGIN_LINK,
  VK_LOGIN_LINK,
} from '@utils/constants'
import s from '../AuthStyles.module.scss'

import cn from 'classnames'

export default function LoginForm({ geoCountryId }) {
  const { t } = useTranslation('auth')
  const dispatch = useDispatch()
  const location = useLocation()
  const formVisibility = useSelector(authSelectors.getTotpFormVisibility)
  const recaptchaEl = useRef()

  const globalErrMsg = useSelector(authSelectors.getAuthErrorMsg)

  const [errMsg, _setErrMsg] = useState(location?.state?.errMsg || '')

  const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(authActions.clearAuthErrorMsg())
    }
  }, [])

  const navigate = useNavigate()

  const handleSubmit = ({ email, password, reCaptcha }, { setFieldValue }) => {
    const resetRecaptcha = () => {
      recaptchaEl && recaptchaEl?.current?.reset()
      setFieldValue('reCaptcha', '')
    }

    const navigateAfterLogin = () => {
      navigate(routes.HOME, {
        replace: true,
      })
    }

    dispatch(
      authOperations.login(
        email,
        password,
        reCaptcha,
        resetRecaptcha,
        navigateAfterLogin,
      ),
    )
  }

  const handleUserKeyPress = e => {
    if (e.keyCode === 9) {
      e.preventDefault()
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EMAIL_SPECIAL_CHARACTERS_REGEX, t('warnings.special_characters'))
      .required(t('warnings.email_required')),
    password: Yup.string().required(t('warnings.password_required')),
    reCaptcha: Yup.string().nullable().required(t('warnings.recaptcha')),
  })

  useEffect(() => {
    if (formVisibility === 'shown') {
      window.addEventListener('keydown', handleUserKeyPress)
    }

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [formVisibility])

  const isVKAllowed =
    geoCountryId === '182' || geoCountryId === '80' || geoCountryId === '113'

  return (
    <div className={cn('box_styles', s.form_wrapper)}>
      <p className={cn('headings_h1', s.title)}>{t('logIn')}</p>

      <Formik
        initialValues={{ email: '', password: '', reCaptcha: '' }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, errors, touched }) => {
          return (
            <>
              {formVisibility && <VerificationModal />}
              <Form className={s.form}>
                {(errMsg || globalErrMsg) && (
                  <div
                    className={s.credentials_error}
                    dangerouslySetInnerHTML={{
                      __html: t(errMsg || globalErrMsg, {
                        value: location?.state?.value || '',
                      }),
                    }}
                  ></div>
                )}

                {location.state?.from === routes.CHANGE_PASSWORD && !errMsg && (
                  <div className={s.success_notif}>{t('changed_pass')}</div>
                )}
                {location.state?.from === routes.REGISTRATION && !errMsg && (
                  <div className={s.success_notif}>{t('registration_success')}</div>
                )}

                <InputField
                  className={s.input_field_wrapper}
                  datatestid="input_email"
                  label={t('email_or_login_label')}
                  placeholder={t('email_placeholder')}
                  name="email"
                  error={!!errors.email}
                  touched={!!touched.email}
                  autoComplete="on"
                  onBlur={e => {
                    const trimmedValue = e.target.value.trim()
                    setFieldValue('email', trimmedValue)
                  }}
                />

                <InputField
                  className={s.input_field_wrapper}
                  datatestid="input_password"
                  label={t('password_label')}
                  placeholder={t('password_placeholder')}
                  name="password"
                  type="password"
                  error={!!errors.password}
                  touched={!!touched.password}
                />

                <div className={s.recaptcha_wrapper}>
                  {!isCaptchaLoaded && (
                    <div className={s.loaderBlock}>
                      <LoaderDots classname={s.loader} />
                    </div>
                  )}

                  <ReCAPTCHA
                    className={s.recaptcha}
                    ref={recaptchaEl}
                    sitekey={RECAPTCHA_KEY}
                    asyncScriptOnLoad={() => setIsCaptchaLoaded(true)}
                    onChange={value => {
                      setFieldValue('reCaptcha', value)
                    }}
                  />

                  <ErrorMessageStyled name="reCaptcha" />
                </div>

                <Cta className={s.submit_btn} buttonType="submit">
                  {t('logIn')}
                </Cta>
              </Form>
            </>
          )
        }}
      </Formik>

      <Link
        className={cn('body_s', s.reset_pass_link, s.pink_link)}
        to={routes.RESET_PASSWORD}
      >
        {t('forgot_password')}
      </Link>

      <p className={cn('other_uppercase_small', s.social_title)}>{t('login_with')}</p>

      <ul className={cn(s.social_list)}>
        {/* <li>
          <a className={s.soc_link} href={}>
            <Icon name="Facebook" width="24px" height="24px" />
          </a>
        </li> */}
        <li>
          <a className={s.soc_link} href={GOOGLE_LOGIN_LINK}>
            <Icon name="Google" width="24px" height="24px" />
          </a>
        </li>
        {isVKAllowed && (
          <li>
            <a className={s.soc_link} href={VK_LOGIN_LINK}>
              <Icon name="Vk" width="24px" height="24px" />
            </a>
          </li>
        )}
      </ul>

      <p>
        <Trans
          t={t}
          i18nKey="dont_have_account"
          components={{
            Link: <Link className={s.pink_link} to={routes.REGISTRATION} />,
          }}
        />
      </p>
    </div>
  )
}
