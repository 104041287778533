import PropTypes from 'prop-types'
import SharedHostingTableItem from './SharedHostingTableItem'
import { useTranslation } from 'react-i18next'
import s from './SharedHostingTable.module.scss'
import { CheckBox, CardsContainer, Table } from '@components'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'

const HEAD_CELLS = [
  { label: 'Domain name', value: 'Domain name' },
  { label: 'IP address', value: 'IP address' },
  { label: 'pricing_plan', value: 'Tariff' },
  { label: 'status', value: 'status' },
  { label: 'data_center', value: 'Data center' },
  { label: 'created_at', value: 'createdate' },
  { label: 'valid_until', value: 'Valid until' },
]

export default function SharedHostingTable(props) {
  const { t } = useTranslation(['domains', 'other'])
  const {
    list,
    selctedItem,
    setSelctedItem,
    historyVhostHandler,
    instructionVhostHandler,
    platformVhostHandler,
    prolongVhostHandler,
    editVhostHandler,
    changeTariffVhostHandler,
    rights,
    activeServices,
    setActiveServices,
    setElForProlongModal,
    setIdForDeleteModal,
    unpaidItems,
  } = props

  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  const isAllActive = activeServices?.length === list?.length
  const toggleIsAllActiveHandler = () => {
    isAllActive ? setActiveServices([]) : setActiveServices(list)
  }

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })
  const renderItems = () => {
    return list.map(el => {
      const {
        id,
        domain,
        pricelist,
        real_expiredate,
        item_status,
        cost,
        datacentername,
        ip,
      } = el

      const onItemClick = () => setSelctedItem(el)

      return (
        <SharedHostingTableItem
          key={id?.$}
          id={id?.$}
          domain={domain?.$}
          tariff={pricelist?.$}
          expiredate={real_expiredate?.$}
          status={item_status?.$}
          cost={cost?.$}
          setSelctedItem={onItemClick}
          selected={selctedItem?.id?.$ === id?.$}
          datacentername={datacentername?.$}
          ip={ip?.$}
          el={el}
          historyVhostHandler={historyVhostHandler}
          instructionVhostHandler={instructionVhostHandler}
          platformVhostHandler={platformVhostHandler}
          prolongVhostHandler={prolongVhostHandler}
          setElForProlongModal={setElForProlongModal}
          editVhostHandler={editVhostHandler}
          changeTariffVhostHandler={changeTariffVhostHandler}
          rights={rights}
          activeServices={activeServices}
          setActiveServices={setActiveServices}
          setIdForDeleteModal={setIdForDeleteModal}
          unpaidItems={unpaidItems}
        />
      )
    })
  }
  return (
    <>
      {isDesktop ? (
        <Table stickyHeader className={s.table} vAlignTop>
          <thead>
            <tr>
              <th>
                <CheckBox
                  // className={s.check_box}
                  value={isAllActive}
                  onClick={toggleIsAllActiveHandler}
                />
              </th>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody className={s.tbody}>{renderItems()}</tbody>
        </Table>
      ) : (
        <>
          <label className={cn('in_row', s.check_all)}>
            <CheckBox value={isAllActive} onClick={toggleIsAllActiveHandler} />
            {t('Choose all', { ns: 'other' })}
          </label>
          <CardsContainer>{renderItems()}</CardsContainer>
        </>
      )}
    </>
  )
}

SharedHostingTable.propTypes = {
  list: PropTypes.array,
  setSelctedPayment: PropTypes.func,
  selctedPayment: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object]),
  rights: PropTypes.object,
  activeServices: PropTypes.array,
  setActiveServices: PropTypes.func,
  elForProlongModal: PropTypes.array,
  setElForProlongModal: PropTypes.func,
}

SharedHostingTable.defaultProps = {
  list: [],
}
