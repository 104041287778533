const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={22}
    fill="none"
    {...props}
    stroke="currentColor"
  >
    <path
      d="m15.719 8.53 2.125 1.149.445.25c.948.532.948 1.905 0 2.438l-6.037 3.392a4.596 4.596 0 0 1-4.504 0L1.71 12.367c-.948-.533-.948-1.906 0-2.438l.445-.25 2.007-1.15m12.018 5.119 1.932.973c.996.501 1.028 1.92.056 2.467l-5.917 3.324a4.595 4.595 0 0 1-4.504 0l-5.83-3.275c-.99-.556-.935-2.007.093-2.486l2.152-1.003m8.09-2.835 6.036-3.391c.948-.533.948-1.906 0-2.439l-6.037-3.391a4.595 4.595 0 0 0-4.504 0L1.71 4.983c-.948.533-.948 1.906 0 2.439l6.037 3.391c1.4.787 3.104.787 4.504 0Z"
      stroke="currentColor"
    />
  </svg>
)
export default SvgComponent
