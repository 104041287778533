import { FixedFooter } from '@components/index'
import { roundToDecimal } from '@utils'
import s from './SelectedItemsFooter.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
export default function SelectedItemsFooter({
  items,
  renderButtons,
  className,
  hasPriceDescription = true,
}) {
  const { t } = useTranslation(['other'])
  const getTotalPrice = () => {
    const list = items.length >= 1 ? items : []

    return list
      ?.reduce((totalPrice, server) => {
        return totalPrice + +server?.cost?.$?.trim()?.split(' ')?.[0]
      }, 0)
      ?.toFixed(2)
  }
  return (
    <FixedFooter
      isShown={items.length >= 1}
      className={cn(s.footer, className)}
      onlyFadeEffect
    >
      {hasPriceDescription && (
        <>
          <p className="pink">
            {t('total', { ns: 'other' })}:{' '}
            <span className={s.tools_footer_value}>
              € {roundToDecimal(getTotalPrice())}/
              <span className="text-dark-50 body_m_light">
                {t('short_month', { ns: 'other' })}
              </span>
            </span>
          </p>
          <p className={s.services_selected}>
            {t('services_selected', { ns: 'other' })}{' '}
            <span className={s.tools_footer_value}>{items.length}</span>
          </p>
        </>
      )}

      <div className={s.buttons_wrapper}>{renderButtons()}</div>
    </FixedFooter>
  )
}
