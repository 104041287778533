import qs from 'qs'
import { actions, authSelectors, cartActions, cloudVpsActions } from '@redux'
import { toast } from 'react-toastify'
import { axiosInstance } from '@config/axiosInstance'
import {
  checkIfTokenAlive,
  handleLoadersClosing,
  renameAddonFields,
  cookies,
  sortCloudsByType,
  handleLongRequest,
  handleLoadersOpen,
} from '@utils'
import { t } from 'i18next'
import * as routes from '@src/routes'

const getInstances =
  ({
    p_cnt,
    p_num,
    p_sort,
    p_order,
    signal,
    setIsLoading,
    isLoader = true,
    setPagination,
    setSortBy,
    setFilters,
    setData,
  }) =>
  (dispatch, getState) => {
    isLoader && handleLoadersOpen(setIsLoading, dispatch)

    const sessionId = authSelectors.getSessionId(getState())

    return axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances',
          out: 'json',
          auth: sessionId,
          p_cnt,
          p_num,
          p_sort,
          p_order,
          lang: 'en',
        }),
        { signal },
      )
      .then(async ({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        try {
          const elemsList = data.doc.elem || []
          elemsList.forEach(el => {
            if (!el.createdate?.$ && el.createdate?.[0]?.$) {
              const createdate = el.createdate?.[0]?.$
              el.createdate = { $: createdate }
            }

            if (el.i_expiretime.$ === '00:00:00') {
              el.i_expiretime.$ = '08:00'
            }
          })

          const p_cnt = +data.doc.p_cnt.$
          const p_elems = +data.doc.p_elems.$
          const p_num = +data.doc.p_num.$

          const p_sort = data.doc.p_sort.$
          const p_order = data.doc.p_order.$

          const { data: filterData } = await axiosInstance.post(
            '/',
            qs.stringify({
              func: 'instances.filter',
              out: 'json',
              auth: sessionId,
              lang: 'en',
            }),
            { signal },
          )

          setPagination?.({ p_cnt, p_elems, p_num })
          setSortBy({ p_sort, p_order })
          setData(elemsList)

          setFilters?.(filterData.doc)

          handleLoadersClosing('closeLoader', dispatch, setIsLoading)
        } catch (error) {
          console.log(error)
        }
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

const setInstancesFilter =
  ({ values, signal, setIsLoading, successCallback, isLoader = true }) =>
  (dispatch, getState) => {
    isLoader && handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances.filter',
          out: 'json',
          auth: sessionId,
          sok: 'ok',
          lang: 'en',
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

const editInstance =
  ({
    values,
    elid,
    errorCallback = () => {},
    closeModal = () => {},
    successCallback,
    successToast,
    setIsLoading,
    signal,
  }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances.edit',
          out: 'json',
          sok: 'ok',
          auth: sessionId,
          elid,
          lang: 'en',
          clicked_button: 'ok',
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
        closeModal()
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
        successToast
          ? toast.success(successToast)
          : toast.success(t('request_sent', { ns: 'cloud_vps' }))
      })
      .catch(error => {
        errorCallback()
        closeModal()
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

const deleteInstance =
  ({ elid, closeModal, successCallback }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances.delete',
          auth: sessionId,
          elid,
          out: 'json',
          lang: 'en',
        }),
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
        closeModal()
        toast.success(t('server_deleted_success', { ns: 'other', id: `#${elid}` }))
        dispatch(actions.hideLoader())
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        closeModal()
        dispatch(actions.hideLoader())
      })
  }

const instancesActionRequest = ({ elid, action, sessionId, ...params }) => {
  return axiosInstance.post(
    '/',
    qs.stringify({
      func: `instances.${action}`,
      auth: sessionId,
      elid,
      out: 'json',
      lang: 'en',
      ...params,
    }),
  )
}

const serviceActionRequest = ({ elid, action, sessionId, ...params }) => {
  return axiosInstance.post(
    '/',
    qs.stringify({
      func: `service.${action}`,
      auth: sessionId,
      elid,
      out: 'json',
      lang: 'en',
      ...params,
    }),
  )
}

const changeInstanceState =
  ({ action, elid, closeModal, successCallback, newFnName = false }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())

    const requestFn = newFnName ? instancesActionRequest : serviceActionRequest

    requestFn({ elid, action, sessionId })
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)

        successCallback()
        closeModal()
        toast.success(t('request_sent', { ns: 'cloud_vps' }))
        dispatch(actions.hideLoader())
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        closeModal()
        dispatch(actions.hideLoader())
      })
  }

const getTariffsListToChange =
  ({ elid, setTariffsForResize, closeModal, showLoader = false }) =>
  (dispatch, getState) => {
    showLoader && dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())

    serviceActionRequest({ elid, action: 'changepricelist', sessionId })
      .then(({ data }) => {
        if (data.doc?.error) {
          if (data.doc.error.$type === 'no_pricelist') {
            toast.error(t('warning.no_pricelist', { ns: 'cloud_vps' }))
            return
          } else {
            throw new Error(data.doc.error.msg.$)
          }
        }

        const tariffs = data.doc.slist.find(item => item.$name === 'pricelist')?.val
        setTariffsForResize(tariffs)
        showLoader && dispatch(actions.hideLoader())
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        closeModal()
        dispatch(actions.hideLoader())
      })
  }

const getTariffPriceForResize =
  ({ elid, pricelist, setPrice }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())

    serviceActionRequest({
      elid,
      action: 'changepricelist.pricelist',
      sessionId,
      pricelist,
      snext: 'ok',
      sok: 'ok',
    })
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        const regex = /<b>(\d+\.\d{2})\sEUR<\/b>/
        const sentence = data.doc.money_info.$
        const match = sentence.match(regex)
        const price = match?.[1] || 0

        setPrice?.(price)
        dispatch(actions.hideLoader())
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        dispatch(actions.hideLoader())
      })
  }

const changeTariff =
  ({ elid, pricelist, successCallback }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())
    serviceActionRequest({
      elid,
      action: 'changepricelist.getmoney',
      sessionId,
      pricelist,
      clicked_button: 'finish',
      snext: 'ok',
      sok: 'ok',
    })
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        dispatch(actions.hideLoader())
      })
  }

const changeTariffConfirm =
  ({ action, elid, closeModal, successCallback }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances.confirm.resize',
          select_resize: action,
          auth: sessionId,
          elid,
          out: 'json',
          lang: 'en',
          sok: 'ok',
          clicked_button: 'ok',
        }),
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
        closeModal()
        toast.success(t('request_sent', { ns: 'cloud_vps' }))
        dispatch(actions.hideLoader())
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        closeModal()
        dispatch(actions.hideLoader())
      })
  }

const changeInstancePassword =
  ({ password, elid, closeModal, setIsLoading, signal }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances.fleio.changepassword',
          out: 'json',
          sok: 'ok',
          auth: sessionId,
          elid,
          lang: 'en',
          clicked_button: 'ok',
          password,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        toast.success(t('request_sent', { ns: 'cloud_vps' }))
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
      .finally(() => {
        closeModal()
      })
  }

const rebuildInstance =
  ({ action, elid, successCallback, errorCallback, ...params }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: `instances.fleio.${action}`,
          out: 'json',
          auth: sessionId,
          elid,
          lang: 'en',
          ...params,
        }),
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback(data.doc)
        dispatch(actions.hideLoader())
      })
      .catch(err => {
        errorCallback && errorCallback()
        checkIfTokenAlive(err.message, dispatch)
        dispatch(actions.hideLoader())
      })
  }

const openConsole =
  ({ elid }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances.fleio.vnc',
          out: 'json',
          auth: sessionId,
          elid,
          lang: 'en',
        }),
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)

        const { $name, $: value } = data.doc.cookies.cookie
        cookies.setCookie($name, value)

        const url = data.doc.ok.$
        const link = document.createElement('a')
        link.setAttribute('target', '__blank')
        link.href = url
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)

        dispatch(actions.hideLoader())
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        dispatch(actions.hideLoader())
      })
  }

/* EDIT SERVERS OPERATION TO GET FULL DATA */
const getInstanceInfo =
  ({ elid, setInstanceInfo, setFullData, signal, setIsLoading, isLoader = true }) =>
  (dispatch, getState) => {
    isLoader && handleLoadersOpen(setIsLoading, dispatch)
    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances.edit',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const renamedSlistData = renameAddonFields(data?.doc, { isEditFunc: true })

        /** If the server is ordered for more than one day,
         *  we get expire time 00:00:00 from the backend,
         *  but the server will expire at 8:00 UTC+3
         */
        if (renamedSlistData.expiretime.$ === '00:00:00') {
          renamedSlistData.expiretime.$ = '08:00'
        }

        const d = {
          createdate: renamedSlistData?.createdate?.$,
          opentime: renamedSlistData?.opentime?.$,
          expiredate: renamedSlistData?.real_expiredate?.$,
          expiretime: renamedSlistData?.expiretime?.$,
          instances_uuid: renamedSlistData?.instances_uuid.$,
          ip: renamedSlistData?.ip?.$,
          ip_v6: renamedSlistData?.ip_v6?.$,
          rdns_record: renamedSlistData?.rdns_record?.$,
          backup_rotation: renamedSlistData?.backup_rotation?.$,
          image_bill_id: data.doc?.image_bill_id?.$ || '',
          image_bill_name: data.doc?.image_bill_name?.$ || '',
          autoprolong_item: renamedSlistData?.slist
            ?.find(e => e?.$name === 'autoprolong')
            ?.val?.find(v => v?.$key === renamedSlistData?.autoprolong?.$),
        }

        const clearStr = /\s*\(.*?\)\s*\.?/g

        renamedSlistData?.slist?.forEach(list => {
          if (list?.$name === 'stored_method') {
            d[`${list?.$name}_list`] = list?.val?.filter(
              v => v?.$key && v?.$key?.length > 0,
            )
          } else {
            d[`${list?.$name}`] =
              list?.val?.length > 1
                ? list?.val?.map(v => {
                    const { $ } = v
                    return $?.replace(clearStr, '')
                  })
                : list?.val?.[0]?.$.replace(clearStr, '')
          }
        })

        setInstanceInfo?.(d)
        setFullData?.({ ...renamedSlistData, details_info: d })
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
  }

const getInstanceNetworkTrafficInfo =
  (elid, setNetworkTrafficInfo, signal, setIsLoading) => (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances.fleio.trafficdata',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const { cycle_traffic, month_traffic } = data.doc

        setNetworkTrafficInfo({ cycle_traffic, month_traffic })
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
  }

const getOsList =
  ({ signal, setIsLoading, lastTariffID, closeLoader, datacenter, setSshList }) =>
  dispatch => {
    setIsLoading && setIsLoading(true)

    return dispatch(getTariffParamsRequest({ signal, id: lastTariffID, datacenter }))
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)

        try {
          const osList = data.doc.slist.find(el => el.$name === 'instances_os').val

          const OsByZones = {}
          osList.forEach(el => {
            if (!OsByZones[el.$depend]) {
              OsByZones[el.$depend] = [el]
            } else {
              OsByZones[el.$depend].push(el)
            }
          })

          const sshList = data.doc.slist.find(el => el.$name === 'instances_ssh_keys').val

          let formatedSshList
          if (sshList[0].$key === 'none') {
            formatedSshList = []
          } else {
            formatedSshList = sshList.map(el => ({
              label: el.$,
              value: el.$key,
            }))
          }

          const operationSystems = { [data.doc.datacenter.$]: OsByZones }

          dispatch(cloudVpsActions.setOperationSystems(operationSystems))

          setSshList && setSshList(formatedSshList)

          closeLoader && closeLoader()
        } catch (error) {
          console.error(error)
          toast.error(t('warnings.unknown_error', { ns: 'auth' }), {
            toastId: 'unknown_error',
            updateId: 'unknown_error',
          })
        }
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        handleLoadersClosing(err?.message, dispatch, setIsLoading)
      })
  }

const getAllTariffsInfo =
  ({
    signal,
    setIsLoading,
    needOsList = false,
    needDcList = false,
    datacenter,
    setSshList,
    isBasic,
    setPeriodsList,
    setLimitIsReached,
  }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    return axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'v2.instances.order.pricelist',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          datacenter,
        }),
        { signal },
      )
      .then(async ({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        try {
          const tariffs = data.doc.list.find(el => el.$name === 'pricelist').elem
          const limitIsReached = tariffs[0]?.restriction_struct?.itemmax_reached?.$
          if (limitIsReached) {
            setLimitIsReached?.(true)
          } else {
            const dcID = data.doc.datacenter.$
            const DClist = data.doc.slist.find(el => el.$name === 'datacenter').val

            const periodsList = data.doc.flist?.find(el => el.$name === 'fperiod')?.val
            const formatedPeriodsList = periodsList
              .filter(el => el.$key !== 'null')
              .map(el => ({
                label: el.$,
                value: el.$key,
              }))

            const labels = data.doc.flist?.find(el => el.$name === 'flabel')?.val

            const cloudBasicTag = labels.find(el =>
              el?.$.toLowerCase().includes('type:basic'),
            )?.$key
            const windowsTag = labels.find(el =>
              el.$.toLowerCase().includes('windows'),
            ).$key
            const soldOutTag = labels.find(el =>
              el.$.toLowerCase().includes('soldout:true'),
            )?.$key
            // const amdTag = labels.find(el => el.$.toLowerCase().includes('cpu:AMD'))?.$key

            const { basicTariffs, premiumTariffs } = sortCloudsByType(data, cloudBasicTag)

            /**
             * it is important to get ID of the last Tariff for Premium type
             * because it must have full list of OS,
             * and the Tariff must be from selected DC,
             * because OS IDs differs between DC
             */
            if (needOsList) {
              let lastTariffID

              if (isBasic) {
                /** we pick last tariff in the list because first one doesn`t have Windows OS */
                lastTariffID = basicTariffs[dcID][basicTariffs[dcID].length - 1].id.$
              } else {
                /** we pick last tariff in the list because first one doesn`t have Windows OS */
                lastTariffID = premiumTariffs[dcID][premiumTariffs[dcID].length - 1].id.$
              }
              await dispatch(
                getOsList({ signal, lastTariffID, datacenter: dcID, setSshList }),
              )
            }

            basicTariffs && dispatch(cloudVpsActions.setBasicTariffs(basicTariffs))
            dispatch(cloudVpsActions.setPremiumTariffs(premiumTariffs))
            needDcList && dispatch(cloudVpsActions.setInstancesDCList(DClist))
            dispatch(cloudVpsActions.setWindowsTag(windowsTag))
            soldOutTag && dispatch(cloudVpsActions.setSoldOutTag(soldOutTag))
            // amdTag && dispatch(cloudVpsActions.setAmdTag(amdTag))
            setPeriodsList && setPeriodsList(formatedPeriodsList)
          }
        } catch (error) {
          console.log(error)
        }
      })
      .then(() => {
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
        return 'success'
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        handleLoadersClosing(err?.message, dispatch, setIsLoading)
      })
  }

const getTariffParams =
  ({ signal, id, datacenter, setIsLoading }) =>
  dispatch => {
    setIsLoading(true)

    return dispatch(getTariffParamsRequest({ signal, id, datacenter }))
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        renameAddonFields(data.doc, { isNewFunc: true })

        const networkOptions = data.doc.slist.find(el => el.$name === 'network').val
        const ipv6_id = networkOptions.find(el => el.$.includes('v6')).$key
        const addon_name = data.doc.register.network
        const ipv6_parametr = { [addon_name]: ipv6_id }

        return ipv6_parametr
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        handleLoadersClosing(err?.message, dispatch, setIsLoading)
      })
  }

const getTariffParamsRequest =
  ({ signal, id, datacenter }) =>
  (_, getState) => {
    const sessionId = authSelectors.getSessionId(getState())
    const specialTariffFieldName = `period_${id}`

    return axiosInstance.post(
      '/',
      qs.stringify({
        func: 'v2.instances.order.param',
        out: 'json',
        auth: sessionId,
        lang: 'en',
        pricelist: id,
        datacenter,
        [specialTariffFieldName]: '-50',
      }),
      { signal },
    )
  }

const setOrderData =
  ({ signal, setIsLoading, orderData }) =>
  (dispatch, getState) => {
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'v2.instances.order.param',
          auth: sessionId,
          out: 'json',
          sok: 'ok',
          lang: 'en',
          licence_agreement: 'on',
          ...orderData,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        dispatch(
          cartActions.setCartIsOpenedState({
            isOpened: true,
            redirectPath: routes.CLOUD_VPS,
          }),
        )
      })
      .then(() => {
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        handleLoadersClosing(err?.message, dispatch, setIsLoading)
      })
  }

const getSshKeys =
  ({
    p_cnt,
    p_num,
    setAllSshItems,
    signal,
    setIsLoading,
    setPagination,
    setP_cntToStorage,
  }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)

    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'sshkeys',
          out: 'json',
          auth: sessionId,
          p_num,
          p_cnt,
          lang: 'en',
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)

        const sshList = data.doc.elem || []

        dispatch(cloudVpsActions.setSshCount(Number(data?.doc?.p_elems?.$)))

        const p_cnt = +data.doc.p_cnt.$
        const p_elems = +data.doc.p_elems.$
        const p_num = +data.doc.p_num.$

        if (setP_cntToStorage) {
          localStorage.setItem('p_cnt.sshkeys', p_cnt)
        }

        setPagination?.({ p_cnt, p_elems, p_num })

        setAllSshItems
          ? setAllSshItems(sshList)
          : dispatch(cloudVpsActions.setSshList(sshList))

        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

/* Below being request for changing data of ssh keys */
const editSsh =
  ({
    values,
    elid,
    errorCallback = () => {},
    closeModal = () => {},
    successCallback = () => {},
    setIsLoading,
    setAllSshItems,
    signal,
    p_col,
    p_num,
    p_cnt,
  }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    closeModal()
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'sshkeys.edit',
          out: 'json',
          sok: 'ok',
          auth: sessionId,
          elid,
          lang: 'en',
          clicked_button: 'ok',
          processingmodulegroup: '1',
          ...values,
        }),
        { signal },
      )
      .then(async ({ data }) => {
        function errorHandler(data) {
          if (data.doc?.error) {
            /* Get and parse errors */
            let errorMessage = data.doc?.error?.msg?.$
            const errorObject = JSON.parse(data.doc?.error?.$object)
            /* Get the first key */
            const errorKey = Object.keys(errorObject)?.[0]

            if (errorKey) {
              const specifiedErrorDescr = errorObject[errorKey]?.[0]
              toast.error(t(specifiedErrorDescr, { ns: 'other' }))
              errorMessage = `${errorMessage}: ${specifiedErrorDescr}`
              console.error(errorMessage)
            } else if (data.doc.error.$type) {
              console.error(`${errorMessage}: ${data.doc.error?.$type}`)
            } else {
              console.error(errorMessage)
            }
            handleLoadersClosing('closeLoader', dispatch, setIsLoading)
            throw new Error(errorMessage)
          } else if (typeof data === 'string' && !data.match(/long.+billmgr/)) {
            handleLoadersClosing('closeLoader', dispatch, setIsLoading)
            throw new Error(data)
          }
        }

        const successCallbackHandler = () => {
          successCallback
            ? successCallback()
            : dispatch(
                getSshKeys({
                  p_col,
                  p_num,
                  p_cnt,
                  setAllSshItems,
                  signal,
                  setIsLoading,
                }),
              )
          toast.success(t('Changes saved successfully', { ns: 'other' }))
        }

        await handleLongRequest(data, errorHandler, successCallbackHandler)
      })
      .catch(error => {
        closeModal()
        errorCallback()
        checkIfTokenAlive(error?.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

const deleteSsh =
  ({ elid, item, closeModal, successCallback }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'sshkeys.delete',
          auth: sessionId,
          elid,
          out: 'json',
          lang: 'en',
        }),
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback && successCallback()
        closeModal()
        toast.success(
          t('server_deleted_success', { ns: 'other', id: `${item?.comment?.$}` }),
        )
        dispatch(actions.hideLoader())
      })
      .catch(err => {
        closeModal()
        checkIfTokenAlive(err.message, dispatch)
        dispatch(actions.hideLoader())
      })
  }

const generateSsh =
  ({ setSSHKey }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'sshkeys.generator',
          out: 'json',
          auth: sessionId,
          lang: 'en',
        }),
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        const publicKey = data.doc.public_key.$
        const privateKey = data.doc.private_key.$
        setSSHKey({ publicKey, privateKey })
        dispatch(actions.hideLoader())
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch)
      })
  }

const getMetrics =
  ({ elid, metric, hours, setData, signal, setIsLoading }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'instances.fleio.measures',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
          hours,
          metric,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)

        // data.doc.measures.forEach(item => {
        //   const date = parseDateByServerTime(item.$date)
        //   const convertedDate = dayjs.tz(date).format()
        //   console.log(convertedDate)
        //   item.$date = convertedDate
        // })

        setData(data.doc?.measures || [])
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch)
      })
  }

const setImageFilter =
  ({ func, values, signal, setIsLoading, successCallback }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: `${func}.filter`,
          out: 'json',
          auth: sessionId,
          sok: 'ok',
          lang: 'en',
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

const getImages =
  ({
    func,
    elid,
    p_cnt,
    p_num,
    p_sort,
    p_order,
    setData,
    setPagination,
    setSortBy,
    setDailyCosts,
    signal,
    setIsLoading,
    setFilters,
    setBackupRotation,
  }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func,
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
          p_cnt,
          p_num,
          p_sort,
          p_order,
        }),
        { signal },
      )
      .then(async ({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        const elemsList = data.doc.elem || []
        /** unifies the data structure */
        elemsList.forEach(el => {
          if (!el.createdate?.$ && el.createdate?.[0]?.$) {
            el.createdate.$ = el.createdate?.[0]?.$
          }
        })

        const created_today_value = data.doc?.manual_created_today?.$ || 0
        const created_total = data.doc?.manual_created_total?.$ || 0

        const p_cnt = +data.doc.p_cnt.$
        const p_elems = +data.doc.p_elems.$
        const p_num = +data.doc.p_num.$
        const p_sort = data.doc.p_sort.$
        const p_order = data.doc.p_order.$
        const priceObj = data.doc.cost || {}

        const costSummaryObj = {
          ...priceObj,
          created_today: { $: Number(created_today_value) },
          created_total: { $: Number(created_total) },
        }

        setBackupRotation && setBackupRotation(data.doc?.backup_rotation?.$)

        if (setFilters) {
          const { data: filterData } = await axiosInstance.post(
            '/',
            qs.stringify({
              func: `${func}.filter`,
              out: 'json',
              auth: sessionId,
              lang: 'en',
            }),
            { signal },
          )

          setFilters?.(filterData.doc)
        }

        setDailyCosts?.(costSummaryObj)

        setPagination({ p_cnt, p_elems, p_num })
        setSortBy?.({ p_sort, p_order })
        setData(elemsList)
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })

      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch)
      })
  }

const editImage =
  ({ func, values, successCallback = () => {}, elid, signal, setIsLoading }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: `${func}.edit`,
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error && !data.doc?.error?.$object === 'runningoperation')
          throw new Error(data.doc.error.msg.$)

        successCallback(data.doc)

        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch)
      })
  }

const getImageParams =
  ({ setData, signal, setIsLoading } = {}) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'v2.image.order.pricelist',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          plid: '',
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)

        const pricelistElem = data.doc.list.find(el => el.$name === 'pricelist')
          ?.elem?.[0]

        const pricelist = pricelistElem.id.$
        const pricePeriod = pricelistElem.prices.price.period.$

        return axiosInstance.post(
          '/',
          qs.stringify({
            func: 'v2.image.order.param',
            out: 'json',
            auth: sessionId,
            lang: 'en',
            [`period_${pricelist}`]: pricePeriod,
            pricelist,
          }),
          { signal },
        )
      })
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        setData(data.doc)
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch)
      })
  }

const createImage =
  ({ values, successCallback, closeModal, signal, setIsLoading }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'v2.image.order.param',
          out: 'xjson',
          auth: sessionId,
          lang: 'en',
          sok: 'ok',
          image_type: 'image',
          skipbasket: 'on',
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
        closeModal()

        handleLoadersClosing('closeLoader', dispatch)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        closeModal()
        handleLoadersClosing(error?.message, dispatch)
      })
  }

const deleteImage =
  ({ func, elid, successCallback, signal, setIsLoading, values }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: `${func}.delete`,
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

const copyModal =
  ({ elid, values, successCallback = () => {}, setItemData, signal, setIsLoading }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'image.copy',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        setItemData && setItemData(data.doc)
        successCallback()
        values?.sok === 'ok' && toast.success(t('request_sent', { ns: 'cloud_vps' }))
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

export default {
  getInstances,
  setInstancesFilter,
  editInstance,
  deleteInstance,
  changeInstanceState,
  changeInstancePassword,
  getTariffsListToChange,
  changeTariff,
  changeTariffConfirm,
  rebuildInstance,
  getSshKeys,
  editSsh,
  deleteSsh,
  getInstanceInfo,
  getInstanceNetworkTrafficInfo,
  openConsole,
  getAllTariffsInfo,
  getTariffParams,
  getOsList,
  setOrderData,
  getTariffParamsRequest,
  generateSsh,
  getMetrics,
  getImages,
  editImage,
  getImageParams,
  createImage,
  deleteImage,
  copyModal,
  setImageFilter,
  getTariffPriceForResize,
}
