import cn from 'classnames'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LangBtn, Icon, NotificationBtn, BalanceBtn } from '@components'
import ListItems from './ListItems/ListItems'
import { userSelectors, authOperations } from '@redux'
import { useOutsideAlerter } from '@utils'
import * as routes from '@src/routes'
import { NavLink } from 'react-router-dom'
import { useRightsContext } from '@src/context/RightsContext'

import s from './BurgerMenu.module.scss'

export default function BurgerMenu({
  classes,
  isOpened,
  controlMenu,
  isScrolled,
  isMobile,
}) {
  const { t } = useTranslation('container')

  const { categories_rights, menuLists } = useRightsContext()

  // const darkTheme = useSelector(selectors.getTheme) === 'dark'
  const { $realname, $email, $avatar_src, $avatar } = useSelector(
    userSelectors.getUserInfo,
  )

  const dispatch = useDispatch()
  const getBurgerEl = useRef()

  useOutsideAlerter(getBurgerEl, isOpened, controlMenu)

  const logOut = () => {
    controlMenu()
    dispatch(authOperations.logout())
  }

  const [activeList, setActiveList] = useState([
    { active: false, listId: 1, listName: 'profile' },
    { active: false, listId: 2, listName: 'my_services' },
    { active: false, listId: 3, listName: 'services' },
    { active: false, listId: 4, listName: 'finance' },
    { active: false, listId: 5, listName: 'affiliate_program' },
    { active: false, listId: 6, listName: 'support' },
  ])

  return (
    <div className={isOpened ? s.burger : ''}>
      <div ref={getBurgerEl} className={classes}>
        <div className={s.burger_nav}>
          {/* <div className={s.theme_btn_wrapper}>
              <ThemeBtn burgerType />
            </div> */}
          <div className={s.left_wrapper}>
            <BalanceBtn isBurger />
            <LangBtn burgerType />

            <NotificationBtn isBurger isScrolled={isScrolled} isMobile={isMobile} />
          </div>

          <button
            className={cn(s.header_menuburger, s.hamburger_spin, {
              [s.opened]: isOpened,
            })}
            type="button"
            onClick={controlMenu}
          >
            <span className={s.hamburger_inner}></span>
          </button>
        </div>

        <div className={s.burger_body}>
          <div className={s.list_wrapper}>
            <div>
              <ListItems
                controlMenu={controlMenu}
                name={$realname}
                avatar_src={$avatar ? $avatar_src : ''}
                subList={menuLists.profileMenuList}
                isProfile
                email={$email}
                id={1}
                activeList={activeList}
                setActiveList={setActiveList}
              />
            </div>

            <ul className={s.list}>
              <li>
                <div
                  role="button"
                  tabIndex={0}
                  className={s.list_item}
                  onClick={() => controlMenu()}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      controlMenu()
                    }
                  }}
                >
                  <NavLink to={routes.HOME} className={s.list_item_header}>
                    <Icon name="NewIconHome" className={s.icon} />
                    <p className={cn('body_m', s.text)}>{t('burger_menu.dashboard')}</p>
                  </NavLink>
                </div>
              </li>

              {categories_rights.areServicesAllowed && (
                <>
                  {menuLists.myServicesMenuList.length > 0 && (
                    <li className={s.list_item}>
                      <ListItems
                        controlMenu={controlMenu}
                        name={'my_services'}
                        subList={menuLists.myServicesMenuList}
                        id={2}
                        activeList={activeList}
                        setActiveList={setActiveList}
                      />
                    </li>
                  )}

                  {menuLists.otherServicesMenuList.length > 0 && (
                    <li className={s.list_item}>
                      <ListItems
                        controlMenu={controlMenu}
                        name={'services'}
                        subList={menuLists.otherServicesMenuList}
                        id={3}
                        activeList={activeList}
                        setActiveList={setActiveList}
                      />
                    </li>
                  )}
                </>
              )}
              {categories_rights.isFinanceAllowed && (
                <li className={s.list_item}>
                  <ListItems
                    controlMenu={controlMenu}
                    name={'finance'}
                    subList={menuLists.financeMenuList}
                    id={4}
                    activeList={activeList}
                    setActiveList={setActiveList}
                  />
                </li>
              )}

              {categories_rights.isAffiliateProgramAllowed && (
                <li className={s.list_item}>
                  <ListItems
                    controlMenu={controlMenu}
                    name={'ref_program'}
                    subList={menuLists.refProgramMenuList}
                    id={5}
                    activeList={activeList}
                    setActiveList={setActiveList}
                  />
                </li>
              )}
              {categories_rights.isSupportAllowed && (
                <li className={s.list_item}>
                  <ListItems
                    controlMenu={controlMenu}
                    name={'support'}
                    subList={menuLists.supportMenuList}
                    id={6}
                    activeList={activeList}
                    setActiveList={setActiveList}
                  />
                </li>
              )}
            </ul>
          </div>

          <div
            className={s.exit_wrapper}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={logOut}
          >
            <Icon name="NewIconExit" className={s.icon} />
            <p className={'body_m'}>{t('profile.log_out')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

BurgerMenu.propTypes = {
  classes: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  controlMenu: PropTypes.func.isRequired,
}
