import { useTranslation } from 'react-i18next'
import { IconButton, SelectedItemsFooter, TooltipWrapper } from '@components'

import s from './DomainBottomBar.module.scss'

export default function VDS(props) {
  const { t } = useTranslation(['vds', 'other', 'access_log'])

  const { selctedItem, NSDomainHandler, renewDomainHandler, editDomainHandler, rights } =
    props

  const checkProlongSelctedItemStatus = () => {
    let blocked = false
    selctedItem?.forEach(el => {
      if (
        (el?.status?.$ !== '3' && el?.status?.$ !== '2') ||
        el?.item_status?.$.trim() === 'Suspended by Administrator'
      ) {
        blocked = true
      }
    })
    return blocked
  }

  const checkNSSelctedItemStatus = () => {
    let blocked = false
    selctedItem?.forEach(el => {
      if (
        el?.item_status?.$orig === '5_open' ||
        el?.item_status?.$orig === '3_autosuspend'
      ) {
        blocked = true
      }
    })
    return blocked
  }

  return (
    <SelectedItemsFooter
      className={s.footer}
      items={selctedItem}
      renderButtons={() => (
        <>
          <TooltipWrapper content={t('edit', { ns: 'other' })}>
            <IconButton
              size="xsmall"
              disabled={selctedItem?.length === 0 || !rights?.edit}
              onClick={() => editDomainHandler()}
              icon="NewIconEdit"
            />
          </TooltipWrapper>

          <TooltipWrapper content={t('prolong', { ns: 'vds' })}>
            <IconButton
              size="xsmall"
              disabled={
                selctedItem?.length === 0 ||
                checkProlongSelctedItemStatus() ||
                !rights?.prolong
              }
              onClick={() => renewDomainHandler()}
              icon="Renew"
            />
          </TooltipWrapper>

          <TooltipWrapper content={t('ns_settings', { ns: 'domains' })}>
            <IconButton
              size="xsmall"
              disabled={
                selctedItem?.length === 0 || !rights?.ns || checkNSSelctedItemStatus()
              }
              onClick={() => NSDomainHandler()}
              icon="DomainsListName"
            />
          </TooltipWrapper>
        </>
      )}
    />
  )
}
