export default function includesStringOrRegExp(arr, value) {
  return arr?.some(el => {
    const isRegExp = el instanceof RegExp
    if (isRegExp) {
      return el.test(value)
    } else {
      return value === el
    }
  })
}
