import { useRightsContext } from '@src/context/RightsContext'
import { useLocation } from 'react-router-dom'

export default function useIsPageAllowed() {
  const location = useLocation()
  const { menuLists } = useRightsContext()

  return menuLists.allServicesList.find(service =>
    location.pathname?.includes(service.routeName),
  ).allowedToRender
}
