export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8957 13.8185L16.4107 8.05254L14.1967 9.46254C13.7552 9.74402 13.2209 9.84115 12.7086 9.73308C12.1963 9.62501 11.7468 9.32036 11.4567 8.88454L9.99966 6.69554L8.54166 8.88554C8.25134 9.32119 7.80177 9.62565 7.28948 9.73353C6.77718 9.84141 6.24301 9.74411 5.80166 9.46254L3.58966 8.05254L5.10466 13.8185H14.8957ZM4.12566 7.20854C3.35866 6.71954 2.38966 7.42654 2.62066 8.30654L4.13666 14.0725C4.19278 14.2862 4.3181 14.4753 4.49304 14.6103C4.66799 14.7453 4.8827 14.8185 5.10366 14.8185H14.8957C15.1166 14.8185 15.3313 14.7453 15.5063 14.6103C15.6812 14.4753 15.8065 14.2862 15.8627 14.0725L17.3787 8.30654C17.6087 7.42654 16.6407 6.72054 15.8737 7.20854L13.6597 8.61854C13.439 8.75937 13.172 8.8081 12.9159 8.75425C12.6597 8.70041 12.4349 8.54828 12.2897 8.33054L10.8317 6.14054C10.7403 6.00358 10.6166 5.89128 10.4715 5.81362C10.3263 5.73595 10.1643 5.69531 9.99966 5.69531C9.83504 5.69531 9.67298 5.73595 9.52784 5.81362C9.3827 5.89128 9.25898 6.00358 9.16766 6.14054L7.70966 8.33054C7.56458 8.54845 7.33983 8.70078 7.08367 8.75481C6.82752 8.80885 6.5604 8.76028 6.33966 8.61954L4.12566 7.20854Z"
        fill="currentColor"
      />
      <path
        d="M10.945 3.94595C10.9453 4.19658 10.846 4.43705 10.6689 4.61446C10.4919 4.79187 10.2516 4.89169 10.001 4.89195C9.75037 4.89222 9.5099 4.79291 9.33249 4.61588C9.15508 4.43884 9.05526 4.19858 9.055 3.94795C9.05473 3.69732 9.15404 3.45685 9.33108 3.27944C9.50811 3.10204 9.74837 3.00222 9.999 3.00195C10.2496 3.00169 10.4901 3.101 10.6675 3.27803C10.8449 3.45507 10.9447 3.69532 10.945 3.94595ZM18.501 5.83695C18.5011 6.08758 18.4017 6.328 18.2246 6.50532C18.0474 6.68263 17.8071 6.78232 17.5565 6.78245C17.3059 6.78259 17.0655 6.68315 16.8881 6.50602C16.7108 6.32889 16.6111 6.08858 16.611 5.83795C16.611 5.58732 16.7106 5.34696 16.8878 5.16974C17.065 4.99252 17.3054 4.89295 17.556 4.89295C17.8066 4.89295 18.047 4.99252 18.2242 5.16974C18.4014 5.34696 18.501 5.58632 18.501 5.83695ZM3.39 5.83695C3.39007 5.96105 3.36569 6.08395 3.31826 6.19863C3.27083 6.31331 3.20127 6.41752 3.11357 6.50532C3.02586 6.59311 2.92173 6.66278 2.8071 6.71033C2.69247 6.75788 2.5696 6.78239 2.4455 6.78245C2.3214 6.78252 2.1985 6.75814 2.08383 6.71071C1.96915 6.66328 1.86494 6.59373 1.77714 6.50602C1.68934 6.41832 1.61968 6.31418 1.57213 6.19955C1.52457 6.08492 1.50007 5.96205 1.5 5.83795C1.5 5.58732 1.59956 5.34696 1.77678 5.16974C1.95401 4.99252 2.19437 4.89295 2.445 4.89295C2.69563 4.89295 2.93599 4.99252 3.11322 5.16974C3.29044 5.34696 3.39 5.58632 3.39 5.83695Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 16C5.25 15.8674 5.30268 15.7402 5.39645 15.6464C5.49021 15.5527 5.61739 15.5 5.75 15.5H14.487C14.6196 15.5 14.7468 15.5527 14.8406 15.6464C14.9343 15.7402 14.987 15.8674 14.987 16C14.987 16.1326 14.9343 16.2598 14.8406 16.3536C14.7468 16.4473 14.6196 16.5 14.487 16.5H5.75C5.61739 16.5 5.49021 16.4473 5.39645 16.3536C5.30268 16.2598 5.25 16.1326 5.25 16Z"
        fill="currentColor"
      />
    </svg>
  )
}
