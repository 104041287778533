const Status = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <g clipPath="url(#clip0_2126_10577)">
      <path
        d="M7.99967 1.33337V4.00004M7.99967 12V14.6667M3.99967 8.00004H1.33301M14.6663 8.00004H11.9997M12.7186 12.719L10.833 10.8334M12.7186 3.33334L10.833 5.21895M3.28072 12.719L5.16634 10.8334M3.28072 3.33334L5.16634 5.21895"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2126_10577">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Status
