import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckBox,
  TooltipWrapper,
  ServerState,
  EditCell,
  Options,
  CopyText,
  AutoprolongIcon,
} from '@components'
import PropTypes from 'prop-types'
import * as route from '@src/routes'
import { useNavigate } from 'react-router-dom'
import s from './VDSItem.module.scss'
import {
  useCreateTicketOption,
  isUnpaidOrder,
  getFormatedDate,
  getImageIconName,
  getItemCostValue,
  isDateString,
} from '@utils'

export default function VDSItem({
  server,
  rights,
  activeServices,
  setActiveServices,
  setIdForEditModal,
  setIdForDeleteModal,
  setIdForPassChange,
  setIdForReboot,
  setElForProlong,
  setIdForHistory,
  setIdForInstruction,
  goToPanelFn,
  handleEditSubmit,
  unpaidItems,
}) {
  const { t } = useTranslation(['vds', 'other', 'cloud_vps'])
  const navigate = useNavigate()

  const [originName, setOriginName] = useState('')

  const unpaidOption = isUnpaidOrder(server, unpaidItems)
  const createTicketOption = useCreateTicketOption(server.id.$)

  useEffect(() => {
    if (server?.server_name?.$) {
      setOriginName(server?.server_name?.$)
    }
  }, [server])

  const handleToolBtnClick = fn => {
    fn()
  }

  const isToolsBtnVisible =
    Object.keys(rights)?.filter(key => key !== 'ask' && key !== 'filter' && key !== 'new')
      .length > 0

  const isActive = activeServices?.some(service => service?.id?.$ === server?.id?.$)
  const toggleIsActiveHandler = () => {
    isActive
      ? setActiveServices(activeServices?.filter(item => item?.id?.$ !== server?.id?.$))
      : setActiveServices([...activeServices, server])
  }

  const editNameHandler = value => {
    handleEditSubmit(server?.id?.$, { server_name: value }, setOriginName)
    setOriginName(value)
  }

  const options = [
    unpaidOption,
    {
      label: t('instruction'),
      icon: 'Instruction',
      disabled:
        (server?.status?.$ !== '3' && server?.status?.$ !== '2') || !rights?.instruction,
      onClick: () => handleToolBtnClick(setIdForInstruction),
    },
    {
      label: t('go_to_panel'),
      icon: 'ToPanel',
      disabled:
        server?.transition?.$ !== 'on' ||
        server?.status?.$ !== '2' ||
        !rights?.gotoserver,
      onClick: () => handleToolBtnClick(goToPanelFn),
    },
    {
      label: t('prolong'),
      icon: 'Renew',
      disabled:
        (server?.status?.$ !== '3' && server?.status?.$ !== '2') ||
        server?.status?.$ === '5' ||
        server?.item_status?.$?.trim() === 'Suspended by Administrator' ||
        !rights?.prolong ||
        server?.pricelist?.$?.toLowerCase()?.includes('ddos'),
      onClick: () => handleToolBtnClick(setElForProlong),
    },
    {
      label: t('edit', { ns: 'other' }),
      icon: 'NewIconEdit',
      disabled: (server?.status?.$ !== '3' && server?.status?.$ !== '2') || !rights?.edit,
      onClick: () => handleToolBtnClick(setIdForEditModal),
    },
    {
      label: t('password_change'),
      icon: 'ChangePassword',
      disabled:
        server?.allow_changepassword?.$ !== 'on' ||
        !rights?.changepassword ||
        server?.ostempl?.$?.includes('Windows'),
      onClick: () => handleToolBtnClick(setIdForPassChange),
    },
    {
      label: t('reload'),
      icon: 'Reboot',
      disabled: server?.show_reboot?.$ !== 'on' || !rights?.reboot,
      onClick: () => handleToolBtnClick(setIdForReboot),
    },
    {
      label: t('ip_addresses'),
      icon: 'IP',
      disabled:
        server?.status?.$ === '5' || server?.has_ip_pricelist?.$ !== 'on' || !rights?.ip,
      onClick: () =>
        navigate(route.VPS_IP, {
          state: { id: server?.id?.$ },
          replace: true,
        }),
    },
    {
      label: t('history'),
      icon: 'HistoryTimer',
      disabled:
        (server?.status?.$ !== '3' && server?.status?.$ !== '2') || !rights?.history,
      onClick: () => handleToolBtnClick(setIdForHistory),
    },
    createTicketOption,
    {
      label: t('delete', { ns: 'other' }),
      icon: 'Delete',
      disabled:
        server?.status?.$ === '5' ||
        server?.scheduledclose?.$ === 'on' ||
        !rights?.delete,
      onClick: () => handleToolBtnClick(setIdForDeleteModal),
      isDelete: true,
    },
  ]

  const { date: expiredate } = isDateString(server?.expiredate?.$)
    ? getFormatedDate({ date: server?.expiredate?.$, time: server?.i_expiretime?.$ })
    : { date: t(server?.expiredate?.$?.toLowerCase(), { ns: 'vds' }) }

  const { date: createdate } = getFormatedDate({
    date: server?.createdate?.$,
    time: server?.i_opentime?.$,
  })

  return (
    <>
      <tr>
        <td data-target="checkbox">
          <CheckBox
            className={s.check_box}
            value={isActive}
            onClick={toggleIsActiveHandler}
          />
        </td>
        <td data-target="name">
          <EditCell
            originName={originName}
            onSubmit={editNameHandler}
            placeholder={t(originName || t('server_placeholder', { ns: 'vds' }), {
              ns: 'vds',
            })}
          />
          <span className={cn('text-dark-50', 'body_s', s.item_id)}>
            ID: {server?.id?.$}
          </span>
        </td>

        <td>
          <div data-td-value-top data-wrap>
            {' '}
            {server?.pricelist?.$.split(' / ')[0]}
          </div>
          <span>{getItemCostValue(server?.cost?.$, t)}</span>
        </td>
        <td>
          <ServerState server={server} />
        </td>
        <td>{createdate}</td>
        <td>
          <div className="in_row align_start" data-td-value-top>
            {expiredate} <AutoprolongIcon item={server} />
          </div>
          <ServerState server={server} onlyAutoDelete />
        </td>
        <td>
          {server?.ostempl?.$ && (
            <TooltipWrapper
              className={s.popup}
              wrapperClassName={s.popup__wrapper}
              content={server?.ostempl?.$}
            >
              <img
                src={require(`@images/soft_os_icons/${getImageIconName(
                  server?.ostempl?.$,
                )}.png`)}
                alt={server?.ostempl?.$}
              />
            </TooltipWrapper>
          )}
        </td>
        <td>
          {server?.ip?.$ && (
            <CopyText
              text={server?.ip?.$}
              promptTextOnHover={server?.ip?.$}
              promptText={t('ip_address_copied', { ns: 'cloud_vps' })}
              label="IP"
            />
          )}
        </td>
        <td data-target="options">
          {isToolsBtnVisible && (
            <Options
              options={options}
              columns={2}
              dropdownClassName={s.options_dopdown}
            />
          )}
        </td>
      </tr>
    </>
  )
}

VDSItem.propTypes = {
  server: PropTypes.object.isRequired,
  rights: PropTypes.object.isRequired,
  setIdForEditModal: PropTypes.func.isRequired,
  setIdForDeleteModal: PropTypes.func.isRequired,
  setIdForPassChange: PropTypes.func.isRequired,
  setIdForReboot: PropTypes.func.isRequired,
  setElForProlong: PropTypes.func.isRequired,
  setIdForInstruction: PropTypes.func.isRequired,
  setIdForHistory: PropTypes.func.isRequired,
  goToPanelFn: PropTypes.func.isRequired,
  activeServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  setActiveServices: PropTypes.func.isRequired,
}
