import { Icon, TooltipWrapper } from '@components'
import s from './InlineOptions.module.scss'

const InlineOptions = ({ options }) => {
  return (
    <div className={s.options} data-option-wrapper>
      {options
        .filter(option => !option.hidden)
        .map(option => {
          return (
            <TooltipWrapper key={option.label} content={option.label}>
              <button
                onClick={option.onClick}
                disabled={option.disabled}
                className={s.btn}
              >
                <Icon name={option.icon} />
              </button>
            </TooltipWrapper>
          )
        })}
    </div>
  )
}

export default InlineOptions
