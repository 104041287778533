const ThumbsUp = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentcolor"
    {...props}
  >
    <path
      d="M4.66683 14.6666V7.33331M1.3335 8.66665V13.3333C1.3335 14.0697 1.93045 14.6666 2.66683 14.6666H11.6177C12.6048 14.6666 13.4443 13.9464 13.5944 12.9708L14.3124 8.30409C14.4987 7.09258 13.5614 5.99998 12.3356 5.99998H10.0002C9.63197 5.99998 9.3335 5.7015 9.3335 5.33331V2.97721C9.3335 2.06931 8.5975 1.33331 7.6896 1.33331C7.47305 1.33331 7.27682 1.46084 7.18887 1.65873L4.84279 6.93741C4.73579 7.17816 4.49704 7.33331 4.23358 7.33331H2.66683C1.93045 7.33331 1.3335 7.93027 1.3335 8.66665Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ThumbsUp
