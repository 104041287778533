import { Button, InputField, Modal, NotificationMessage, Cta } from '@components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import s from './Modals.module.scss'
import { getInstanceMainInfo } from '@utils'

export const DeleteModal = ({ item, closeModal, onSubmit }) => {
  const { t } = useTranslation(['cloud_vps', 'other'])
  const { displayName } = getInstanceMainInfo(item)

  const validationSchema = Yup.object().shape({
    comfirm: Yup.string()
      .matches(/^Permanently delete$/, t('type_delete'))
      .required(t('Is a required field', { ns: 'other' })),
  })
  return (
    <Modal isOpen={!!item} closeModal={closeModal}>
      <Modal.Header>
        {t('delete', { ns: 'other' })} {displayName}
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ comfirm: '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => {
            return (
              <Form id={'delete'} className={s.body}>
                <p className={s.body__text}>{t('delete_text')}</p>
                <NotificationMessage type="warning">
                  {t('delete_warning')}
                </NotificationMessage>

                <InputField
                  inputClassName={s.input}
                  name="comfirm"
                  placeholder="Permanently delete"
                  error={!!errors.comfirm}
                  touched={!!touched.comfirm}
                  isRequired
                  autoComplete="off"
                />
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button label={t('Confirm')} size="small" type="submit" form={'delete'} />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
