import cn from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import BurgerListItem from '../BurgerListItem/BurgerListItem'
import { Icon } from '@components'

import s from './ListItems.module.scss'

export default function ListItems(props) {
  const {
    name,
    email,
    isProfile,
    subList,
    controlMenu,
    id,
    activeList,
    setActiveList,
    avatar_src,
  } = props

  const currentItem = activeList.filter(item => {
    return item.listId === id
  })

  const { t } = useTranslation('container')

  const iconsMap = {
    dashboard: 'NewIconHome',
    my_services: 'NewIconServices',
    services: 'IconLayers',
    finance: 'NewIconFinance',
    ref_program: 'NewIconReferralProgram',
    support: 'NewIconSupport',
  }

  const renderIcon = name => {
    const iconName = iconsMap[name]

    if (iconName) {
      return (
        <Icon
          name={iconName}
          className={cn(s.icon, {
            [s.active]: currentItem[0].active,
            [s.support]: name === 'support',
          })}
        />
      )
    }

    return (
      <div className={s.avatar_img}>
        {avatar_src ? (
          <img
            src={`${process.env.REACT_APP_BASE_URL}${avatar_src}`}
            alt="Avatar"
            className={s.avatar_img}
          />
        ) : (
          <Icon name="User" className={s.profile_icon} />
        )}
      </div>
    )
  }

  const handleClick = () => {
    if (!subList?.length) {
      controlMenu()
      return
    }

    const mutatedList = activeList?.map(item => {
      if (item.listId === id) {
        return item
      } else {
        return { ...item, active: false }
      }
    })

    setActiveList(
      mutatedList?.map(item => {
        if (item.listId === id) {
          return { ...item, active: !item.active }
        } else {
          return item
        }
      }),
    )
  }

  return (
    <div
      className={s.list_items_wrapper}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={handleClick}
    >
      <BurgerListItem
        controlMenu={controlMenu}
        name={isProfile ? name : t(`burger_menu.${name}.${name}`)}
        arrow={
          <Icon
            name="ArrowSign"
            className={cn(s.arrow_icon, {
              [s.closed]: currentItem[0].active,
            })}
          />
        }
        svg={renderIcon(name)}
        subList={subList}
        isProfile={isProfile}
        email={email}
        isListOpened={currentItem[0].active}
      />
    </div>
  )
}

ListItems.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  isProfile: PropTypes.bool,
  subList: PropTypes.array,
}
