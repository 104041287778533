export default function Bmp(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.2498 8.28667V36C34.2498 37.7949 32.7947 39.25 30.9998 39.25H9C7.20507 39.25 5.75 37.7949 5.75 36V4C5.75 2.20508 7.20508 0.75 9 0.75H26.0445L34.2498 8.28667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <mask id="path-2-inside-1_3068_47213" fill="currentColor">
        <path d="M25 1H33.5V9.5H26C25.4477 9.5 25 9.05229 25 8.5V1Z" />
      </mask>
      <path
        d="M25 1H33.5H25ZM33.5 11H26C24.6193 11 23.5 9.88071 23.5 8.5L26.5 8H33.5V11ZM26 11C24.6193 11 23.5 9.88071 23.5 8.5V1H26.5V8L26 11ZM33.5 1V9.5V1Z"
        fill="currentColor"
        mask="url(#path-2-inside-1_3068_47213)"
      />
      <rect x="1" y="20" width="27" height="13" rx="2" fill="#FF4AF6" />
      <path
        d="M8.36414 26.4C8.77081 26.4867 9.09747 26.69 9.34414 27.01C9.59081 27.3233 9.71414 27.6833 9.71414 28.09C9.71414 28.6767 9.50747 29.1433 9.09414 29.49C8.68747 29.83 8.11747 30 7.38414 30H4.11414V22.98H7.27414C7.98747 22.98 8.54414 23.1433 8.94414 23.47C9.35081 23.7967 9.55414 24.24 9.55414 24.8C9.55414 25.2133 9.44414 25.5567 9.22414 25.83C9.01081 26.1033 8.72414 26.2933 8.36414 26.4ZM5.82414 25.82H6.94414C7.22414 25.82 7.43747 25.76 7.58414 25.64C7.73747 25.5133 7.81414 25.33 7.81414 25.09C7.81414 24.85 7.73747 24.6667 7.58414 24.54C7.43747 24.4133 7.22414 24.35 6.94414 24.35H5.82414V25.82ZM7.08414 28.62C7.37081 28.62 7.59081 28.5567 7.74414 28.43C7.90414 28.2967 7.98414 28.1067 7.98414 27.86C7.98414 27.6133 7.90081 27.42 7.73414 27.28C7.57414 27.14 7.35081 27.07 7.06414 27.07H5.82414V28.62H7.08414ZM18.6559 22.98V30H16.9459V25.79L15.3759 30H13.9959L12.4159 25.78V30H10.7059V22.98H12.7259L14.6959 27.84L16.6459 22.98H18.6559ZM25.2356 25.24C25.2356 25.6467 25.1423 26.02 24.9556 26.36C24.769 26.6933 24.4823 26.9633 24.0956 27.17C23.709 27.3767 23.229 27.48 22.6556 27.48H21.5956V30H19.8856V22.98H22.6556C23.2156 22.98 23.689 23.0767 24.0756 23.27C24.4623 23.4633 24.7523 23.73 24.9456 24.07C25.139 24.41 25.2356 24.8 25.2356 25.24ZM22.5256 26.12C22.8523 26.12 23.0956 26.0433 23.2556 25.89C23.4156 25.7367 23.4956 25.52 23.4956 25.24C23.4956 24.96 23.4156 24.7433 23.2556 24.59C23.0956 24.4367 22.8523 24.36 22.5256 24.36H21.5956V26.12H22.5256Z"
        fill="#fff"
      />
    </svg>
  )
}
