export default function getItemCostValue(cost, t, replaceEur) {
  const translated = cost
    .replace('Day', t('day', { ns: 'other' }))
    .replace('Month', t('Month', { ns: 'other' }))
    .replace('Year', t('Year', { ns: 'other' }))
  const splitted = translated.split('/')

  const text1 = replaceEur ? '€ ' + splitted?.[0]?.replace('EUR', '') : splitted?.[0]
  const text2 = splitted?.[1]
  return (
    <>
      {text1} / <span className="text-dark-50 body_m">{text2}</span>
    </>
  )
}
