const SvgComponent = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.2498 8.28667V36C34.2498 37.7949 32.7947 39.25 30.9998 39.25H9C7.20507 39.25 5.75 37.7949 5.75 36V4C5.75 2.20508 7.20508 0.75 9 0.75H26.0445L34.2498 8.28667Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <mask id="path-2-inside-1_3068_47120" fill="currentColor">
      <path d="M25 1H33.5V9.5H26C25.4477 9.5 25 9.05229 25 8.5V1Z" />
    </mask>
    <path
      d="M25 1H33.5H25ZM33.5 11H26C24.6193 11 23.5 9.88071 23.5 8.5L26.5 8H33.5V11ZM26 11C24.6193 11 23.5 9.88071 23.5 8.5V1H26.5V8L26 11ZM33.5 1V9.5V1Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_3068_47120)"
    />
    <rect x="1" y="20" width="23" height="13" rx="2" fill="#F52E52" />
    <path
      d="M8.9827 25.24C8.9827 25.6467 8.88936 26.02 8.7027 26.36C8.51603 26.6933 8.22936 26.9633 7.8427 27.17C7.45603 27.3767 6.97603 27.48 6.4027 27.48H5.3427V30H3.6327V22.98H6.4027C6.9627 22.98 7.43603 23.0767 7.8227 23.27C8.20936 23.4633 8.49936 23.73 8.6927 24.07C8.88603 24.41 8.9827 24.8 8.9827 25.24ZM6.2727 26.12C6.59936 26.12 6.8427 26.0433 7.0027 25.89C7.1627 25.7367 7.2427 25.52 7.2427 25.24C7.2427 24.96 7.1627 24.7433 7.0027 24.59C6.8427 24.4367 6.59936 24.36 6.2727 24.36H5.3427V26.12H6.2727ZM12.5029 22.98C13.2429 22.98 13.8896 23.1267 14.4429 23.42C14.9963 23.7133 15.4229 24.1267 15.7229 24.66C16.0296 25.1867 16.1829 25.7967 16.1829 26.49C16.1829 27.1767 16.0296 27.7867 15.7229 28.32C15.4229 28.8533 14.9929 29.2667 14.4329 29.56C13.8796 29.8533 13.2363 30 12.5029 30H9.87293V22.98H12.5029ZM12.3929 28.52C13.0396 28.52 13.5429 28.3433 13.9029 27.99C14.2629 27.6367 14.4429 27.1367 14.4429 26.49C14.4429 25.8433 14.2629 25.34 13.9029 24.98C13.5429 24.62 13.0396 24.44 12.3929 24.44H11.5829V28.52H12.3929ZM21.7086 22.98V24.35H18.8486V25.83H20.9886V27.16H18.8486V30H17.1386V22.98H21.7086Z"
      fill="#fff"
    />
  </svg>
)
export default SvgComponent
