import s from './DeskWrapper.module.scss'
import cn from 'classnames'
import { Loader } from '@components'

export const ScrollContainer = ({ children, isLoading, className, contentClassName }) => {
  return (
    <div className={cn(s.content_wrapper, className)}>
      <div className={cn(s.content, contentClassName)}>{children}</div>
      {isLoading && <Loader local shown={isLoading} />}
    </div>
  )
}
