export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 6.99988C6.95267 8.95255 10.0001 12 10.0001 12M10.0001 12L15.0002 6.99988M10.0001 12L10.0001 2.99979"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 14L3 15C3 16.1046 3.89545 17.0001 5.00005 17.0001L15.0003 17.0001C16.1049 17.0001 17.0003 16.1046 17.0003 15V14"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
