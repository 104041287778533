import cn from 'classnames'
import { CSSTransition } from 'react-transition-group'
import animations from './animations.module.scss'
import s from './FixedFooter.module.scss'

export default function FixedFooter({ children, isShown, className, onlyFadeEffect }) {
  return (
    <CSSTransition in={isShown} classNames={animations} timeout={150} unmountOnExit>
      <div
        className={cn(s.buying_panel, className, {
          [s.opened]: isShown,
          [s.fade]: onlyFadeEffect,
        })}
      >
        {children}
      </div>
    </CSSTransition>
  )
}
