export default function SvgComponent(props) {
  const { svgwidth, svgheight } = props

  return (
    <svg
      viewBox="0 0 20 20"
      width={svgwidth || 20}
      height={svgheight || 20}
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2001_12472)">
        <path
          d="M4.28627 9.99951V6.24236C4.29743 5.50384 4.4542 4.77477 4.74761 4.09694C5.04102 3.41911 5.4653 2.80583 5.99613 2.29225C6.52696 1.77867 7.15391 1.37488 7.84107 1.10402C8.52822 0.833162 9.26207 0.700553 10.0006 0.713793C10.7391 0.700553 11.4729 0.833162 12.1601 1.10402C12.8472 1.37488 13.4742 1.77867 14.005 2.29225C14.5358 2.80583 14.9601 3.41911 15.2535 4.09694C15.5469 4.77477 15.7037 5.50384 15.7148 6.24236V9.99951M12.8577 17.4995C13.6155 17.4995 14.3422 17.1985 14.878 16.6627C15.4138 16.1269 15.7148 15.4001 15.7148 14.6424V11.4281M12.8577 17.4995C12.8577 17.9731 12.6696 18.4273 12.3347 18.7622C11.9998 19.0971 11.5456 19.2852 11.072 19.2852H8.92913C8.45553 19.2852 8.00133 19.0971 7.66644 18.7622C7.33155 18.4273 7.14342 17.9731 7.14342 17.4995C7.14342 17.0259 7.33155 16.5717 7.66644 16.2368C8.00133 15.9019 8.45553 15.7138 8.92913 15.7138H11.072C11.5456 15.7138 11.9998 15.9019 12.3347 16.2368C12.6696 16.5717 12.8577 17.0259 12.8577 17.4995ZM2.14342 7.85665H3.57199C3.76143 7.85665 3.94311 7.9319 4.07706 8.06586C4.21102 8.19981 4.28627 8.3815 4.28627 8.57094V12.8566C4.28627 13.0461 4.21102 13.2278 4.07706 13.3617C3.94311 13.4957 3.76143 13.5709 3.57199 13.5709H2.14342C1.76453 13.5709 1.40117 13.4204 1.13326 13.1525C0.865354 12.8846 0.714844 12.5212 0.714844 12.1424V9.28522C0.714844 8.90634 0.865354 8.54298 1.13326 8.27507C1.40117 8.00716 1.76453 7.85665 2.14342 7.85665ZM17.8577 13.5709H16.4291C16.2397 13.5709 16.058 13.4957 15.9241 13.3617C15.7901 13.2278 15.7148 13.0461 15.7148 12.8566V8.57094C15.7148 8.3815 15.7901 8.19981 15.9241 8.06586C16.058 7.9319 16.2397 7.85665 16.4291 7.85665H17.8577C18.2366 7.85665 18.5999 8.00716 18.8679 8.27507C19.1358 8.54298 19.2863 8.90634 19.2863 9.28522V12.1424C19.2863 12.5212 19.1358 12.8846 18.8679 13.1525C18.5999 13.4204 18.2366 13.5709 17.8577 13.5709Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2001_12472">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
