export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.00781 12.2C4.00781 10.6915 4.00781 9.93726 4.47644 9.46863C4.94507 9 5.69932 9 7.20781 9H16.8078C18.3163 9 19.0706 9 19.5392 9.46863C20.0078 9.93726 20.0078 10.6915 20.0078 12.2V16.2C20.0078 18.4627 20.0078 19.5941 19.3049 20.2971C18.6019 21 17.4706 21 15.2078 21H8.80781C6.54507 21 5.4137 21 4.71076 20.2971C4.00781 19.5941 4.00781 18.4627 4.00781 16.2V12.2Z"
        stroke="currentColor"
      />
      <path
        d="M16.0039 8V7C16.0039 4.79086 14.213 3 12.0039 3V3C9.79477 3 8.00391 4.79086 8.00391 7V8"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <circle cx="12.0078" cy="15.002" r="2" fill="currentColor" />
    </svg>
  )
}
