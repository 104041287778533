import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import cn from 'classnames'
import s from './DedicList.module.scss'
import DedicItem from '../DedicItem/DedicItem'
import DedicMobileItem from '../DedicMobileItem/DedicMobileItem'
import { CheckBox, CardsContainer, Table } from '@components'
import { useDispatch } from 'react-redux'
import { billingOperations, dedicOperations } from '@redux'
import { useEffect, useState } from 'react'

export const HEAD_CELLS = [
  { label: 'server_name', value: 'server_name' },
  { label: 'pricing_plan', value: 'tariff' },
  { label: 'status', value: 'status' },
  { label: 'data_center', value: 'datacentername' },
  { label: 'created', value: 'created' },
  { label: 'valid_until', value: 'valid_until' },
  { label: 'ostempl', value: 'ostempl' },
  { label: 'ip_address', value: 'ip_address' },
]

export default function DedicList({
  isFiltered,
  servers,
  setElidForEditModal,
  setElForProlongModal,
  setElidForHistoryModal,
  setElidForInstructionModal,
  setElidForRebootModal,
  rights,
  setActiveServices,
  activeServices,
  signal,
  setIsLoading,
  setIdForDeleteModal,
  getDedics,
  toggleIsAllActiveHandler,
}) {
  const { t } = useTranslation(['vds', 'other', 'access_log', 'dedicated_servers'])
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })
  const dispatch = useDispatch()

  const [unpaidItems, setUnpaidItems] = useState([])

  useEffect(() => {
    dispatch(billingOperations.getUnpaidOrders(setUnpaidItems, signal))
  }, [])

  const handleEditSubmit = (elid, server_name) => {
    dispatch(
      dedicOperations.editDedicServerNoExtraPay({
        elid,
        server_name,
        signal,
        setIsLoading,
        successCallback: getDedics,
      }),
    )
  }

  if (servers) {
    if (servers.length === 0 && isFiltered) {
      return (
        <div className={s.no_results_wrapper}>
          <p className={s.no_results_text}>{t('nothing_found', { ns: 'access_log' })}</p>
        </div>
      )
    }

    if (servers.length === 0 && servers) {
      return (
        <div className={s.no_service_wrapper}>
          <img
            src={require('@images/services/no_dedic_server.png')}
            alt="dedic"
            className={s.dedic_img}
          />

          <p className={s.no_service_title}>
            {t('YOU DO NOT HAVE A DEDICATED SERVER YET', { ns: 'dedicated_servers' })}
          </p>
          <div className={s.discount_wrapper}>
            <p className={s.discount_percent}>
              {t('DISCOUNT -10% ON DEDICATED SERVERS', { ns: 'other' })}
            </p>
            <p className={s.discount_desc}>
              {t('You can get a discount using a promo code', { ns: 'other' })}:
              <span className={s.promocode}>S-ZM-DED20</span>
            </p>
          </div>

          <p className={s.no_service_description}>
            {t('dedic no services description', { ns: 'dedicated_servers' })}
          </p>
        </div>
      )
    }
  }

  const isAllActive = activeServices?.length === servers?.length
  // const toggleIsActiveHandler = () => {
  //   isAllActive ? setActiveServices([]) : setActiveServices(servers)
  // }

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })

  return (
    <>
      {isDesktop ? (
        <Table isItemsClickable stickyHeader vAlignTop>
          <thead>
            <tr>
              <th>
                <CheckBox value={isAllActive} onClick={toggleIsAllActiveHandler} />
              </th>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody className={s.tbody}>
            {servers?.map(el => {
              return (
                <DedicItem
                  key={el.id.$}
                  server={el}
                  setElidForEditModal={() => setElidForEditModal(el.id.$)}
                  setElForProlongModal={() => setElForProlongModal([el])}
                  setElidForHistoryModal={() => setElidForHistoryModal(el.id.$)}
                  setElidForInstructionModal={() => setElidForInstructionModal(el.id.$)}
                  setElidForRebootModal={() => setElidForRebootModal([el.id.$])}
                  activeServices={activeServices}
                  setActiveServices={setActiveServices}
                  rights={rights}
                  handleEditSubmit={handleEditSubmit}
                  setIdForDeleteModal={setIdForDeleteModal}
                  unpaidItems={unpaidItems}
                />
              )
            })}
          </tbody>
        </Table>
      ) : (
        <>
          <label className={cn('in_row', s.check_all)}>
            <CheckBox value={isAllActive} onClick={toggleIsAllActiveHandler} />
            {t('Choose all', { ns: 'other' })}
          </label>
          <CardsContainer>
            {servers?.map(el => {
              return (
                <DedicMobileItem
                  key={el.id.$}
                  server={el}
                  setElidForEditModal={() => setElidForEditModal(el.id.$)}
                  setElForProlongModal={() => setElForProlongModal([el])}
                  setElidForHistoryModal={() => setElidForHistoryModal(el.id.$)}
                  setElidForInstructionModal={() => setElidForInstructionModal(el.id.$)}
                  setElidForRebootModal={() => setElidForRebootModal([el.id.$])}
                  setActiveServices={setActiveServices}
                  activeServices={activeServices}
                  rights={rights}
                  handleEditSubmit={handleEditSubmit}
                  setIdForDeleteModal={setIdForDeleteModal}
                  unpaidItems={unpaidItems}
                />
              )
            })}
          </CardsContainer>
        </>
      )}
    </>
  )
}

DedicList.propTypes = {
  servers: PropTypes.arrayOf(PropTypes.object),
  setElidForEditModal: PropTypes.func,
  setElForProlongModal: PropTypes.func,
  setElidForHistoryModal: PropTypes.func,
  setElidForInstructionModal: PropTypes.func,
  setElidForRebootModal: PropTypes.func,
  setActiveServices: PropTypes.func,
  activeServices: PropTypes.arrayOf(PropTypes.object),
  rights: PropTypes.object,
}
