export default function Location(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.3361 6.94733C13.3361 10.5659 9.06943 14.3362 8.00277 14.3362C6.9361 14.3362 2.66943 10.5659 2.66943 6.94733C2.66943 4.0325 5.05725 1.66956 8.00277 1.66956C10.9483 1.66956 13.3361 4.0325 13.3361 6.94733Z" />
      <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 10.0039 4.66956)" />
    </svg>
  )
}
