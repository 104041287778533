export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4199_40613)">
        <path
          d="M5.29102 18.7119L18.7093 5.29361"
          stroke="currentColor"
          strokeLinejoin="round"
        />
        <path
          d="M14.8771 5.29118C16.8422 5.29118 16.7439 5.29118 18.709 5.29118L18.709 9.12305"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.12288 18.7088C7.15782 18.7088 7.25608 18.7088 5.29102 18.7088L5.29102 14.877"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.28516 5.29395L18.7035 18.7123"
          stroke="currentColor"
          strokeLinejoin="round"
        />
        <path
          d="M18.7088 14.8771C18.7088 16.8422 18.7088 16.7439 18.7088 18.709L14.877 18.709"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.28508 9.12581C5.28508 7.16075 5.28508 7.25901 5.28508 5.29395L9.11694 5.29395"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4199_40613">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
