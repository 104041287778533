import { Icon, TooltipWrapper } from '@components'
import { useTranslation } from 'react-i18next'
import s from './AutoprolongIcon.module.scss'

export default function AutoprolongIcon({ item }) {
  const { t } = useTranslation(['autoprolong'])

  return (
    <>
      {item?.autoprolong?.$ && item?.autoprolong?.$ !== 'null' && (
        <TooltipWrapper
          wrapperClassName={s.wrapper}
          className={s.tooltip}
          render={() => {
            return (
              <>
                {t('autoprolong_enabled')}
                <br />
                {t(`autoprolong_period_info.${item.autoprolong?.$.toLowerCase()}`)}
              </>
            )
          }}
        >
          <Icon name="NewIconTime_fill" />
        </TooltipWrapper>
      )}
    </>
  )
}
