const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M1 18.25h18.125M1.313 8.872 6.938 7.62l4.376-3.127 6.249-1.248"
    />
    <path strokeLinecap="round" d="m15.063 2 2.5 1.25-1.875 1.875" />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m11.313 9.5-2.5.01v8.721l2.5.019V9.5ZM6.313 12l-2.5.01v6.22l2.5.02V12ZM16.313 8.25l-2.5.013v9.96l2.5.027v-10Z"
    />
  </svg>
)
export default SvgComponent
