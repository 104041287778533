export default function Close_square_light(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path d="M17 4L4 17" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 4L17 17" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
