const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.626 14.805c.208 0 .38.173.38.38v2.556c0 .207-.172.38-.38.38H3.536a.384.384 0 0 1-.382-.38v-2.555c0-.208.173-.381.381-.381h13.091Zm0-1.155H3.513A1.53 1.53 0 0 0 2 15.186v2.555c0 .854.693 1.535 1.535 1.535h13.091c.843 0 1.535-.693 1.535-1.535v-2.555c0-.855-.692-1.536-1.535-1.536Z"
    />
    <path
      fill="currentColor"
      d="M4.769 15.802a.581.581 0 0 0-.578.588c0 .324.254.578.578.578a.581.581 0 0 0 .577-.59.571.571 0 0 0-.577-.576ZM7.081 15.802a.581.581 0 0 0-.577.588c0 .324.254.578.577.578a.581.581 0 0 0 .577-.59.571.571 0 0 0-.577-.576ZM9.39 15.802a.581.581 0 0 0-.578.588c0 .324.254.578.578.578a.581.581 0 0 0 .577-.59.571.571 0 0 0-.577-.576Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M12.273 16.387h3.463"
    />
    <path
      fill="currentColor"
      d="M16.626 8.48c.208 0 .38.173.38.38v2.555c0 .208-.172.381-.38.381H3.536a.384.384 0 0 1-.382-.38V8.86c0-.207.173-.38.381-.38h13.091Zm0-1.155H3.513A1.53 1.53 0 0 0 2 8.86v2.555c0 .855.693 1.536 1.535 1.536h13.091c.843 0 1.535-.693 1.535-1.536V8.86c0-.854-.692-1.535-1.535-1.535Z"
    />
    <path
      fill="currentColor"
      d="M4.769 9.477a.581.581 0 0 0-.578.588c0 .323.254.577.578.577a.581.581 0 0 0 .577-.588.572.572 0 0 0-.577-.577ZM7.081 9.477a.581.581 0 0 0-.577.588c0 .323.254.577.577.577a.581.581 0 0 0 .577-.588.572.572 0 0 0-.577-.577ZM9.39 9.477a.581.581 0 0 0-.578.588c0 .323.254.577.578.577a.581.581 0 0 0 .577-.588.572.572 0 0 0-.577-.577Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M12.273 10.062h3.463"
    />
    <path
      fill="currentColor"
      d="M16.626 2.154c.208 0 .38.173.38.381V5.09c0 .208-.172.381-.38.381H3.536a.384.384 0 0 1-.382-.38V2.534c0-.208.173-.38.381-.38h13.091Zm0-1.154H3.513A1.53 1.53 0 0 0 2 2.535V5.09c0 .854.693 1.535 1.535 1.535h13.091c.843 0 1.535-.692 1.535-1.535V2.535c0-.854-.692-1.535-1.535-1.535Z"
    />
    <path
      fill="currentColor"
      d="M4.769 3.151a.581.581 0 0 0-.578.59c0 .322.254.576.578.576a.581.581 0 0 0 .577-.588.572.572 0 0 0-.577-.578ZM7.081 3.151a.581.581 0 0 0-.577.59c0 .322.254.576.577.576a.581.581 0 0 0 .577-.588.572.572 0 0 0-.577-.578ZM9.39 3.151a.581.581 0 0 0-.578.59c0 .322.254.576.578.576a.581.581 0 0 0 .577-.588.572.572 0 0 0-.577-.578Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M12.273 3.736h3.463"
    />
  </svg>
)
export default SvgComponent
