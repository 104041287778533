import { useTranslation } from 'react-i18next'
import { Icon } from '@components'
import { translatePeriodToMonths } from '@utils'
import s from './DomainItem.module.scss'
import cn from 'classnames'

export default function Component(props) {
  const { t } = useTranslation(['cart', 'other'])

  const { desc, deleteItemHandler, period } = props

  const renderDesc = () => {
    const beforeWord = 'Domain registration'
    const afterWord = '</b>'

    const domainName = desc.slice(
      desc.indexOf(beforeWord) + beforeWord?.length,
      desc.indexOf(afterWord),
    )

    const beforeWordProtect = 'Data protection'

    const dataProtect = desc.slice(
      desc.indexOf(beforeWordProtect) + beforeWordProtect?.length,
    )

    return {
      domainName,
      dataProtect,
    }
  }

  return (
    <div className={s.server_item}>
      <p className={cn('body_l_regular', s.domainName)}>{renderDesc()?.domainName}</p>
      <div className={s.periodInfo}>
        {desc?.includes('Data protection') && (
          <span className={'body_xs'}>
            {t('Data protection')}: {t(renderDesc()?.dataProtect?.trim())}
          </span>
        )}
        <span className={'body_xs'}>
          {t('Period', { ns: 'other' })}: {period} {translatePeriodToMonths(period)}
        </span>
      </div>

      {deleteItemHandler && (
        <Icon name="Delete" onClick={deleteItemHandler} className={s.deleteIcon} />
      )}
    </div>
  )
}
