import s from './SshList.module.scss'
import cn from 'classnames'
import { Icon } from '@components'
import { useTranslation } from 'react-i18next'
import { cloudVpsActions } from '@redux'
import { useDispatch } from 'react-redux'
import getFormatedDate from '@utils/getFormatedDate'

export default function SshItem({ item }) {
  const { t } = useTranslation(['vds'])

  const dispatch = useDispatch()

  const [date, time] = item?.cdate?.$.split(' ') || []
  const { fullDateString } = getFormatedDate({ date, time })

  return (
    <tr className={s.item}>
      {/* <td ref={checkboxCell}>
        <CheckBox />
      </td> */}
      <td className={cn(s.servername_cell)}>
        {item?.comment?.$ || t('server_placeholder')}
      </td>
      <td>{fullDateString}</td>
      <td className={cn(s.ip_cell)} data-wrap>
        {item?.fingerprint?.$}
      </td>
      <td data-target="options">
        <div className={s.options}>
          <button
            type="button"
            className={cn(s.btn, s.table_el_button)}
            onClick={() => {
              dispatch(cloudVpsActions.setItemForModals({ ssh_rename: item }))
            }}
          >
            <Icon name="NewIconEdit" />
          </button>

          <button
            className={s.btn}
            type="button"
            onClick={() => {
              dispatch(cloudVpsActions.setItemForModals({ ssh_delete: item }))
            }}
          >
            <Icon name="Delete" />
          </button>
        </div>
      </td>
    </tr>
  )
}
