import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { InputField, Select, DoubleInputField, Icon, Modal, Cta } from '@components'
import cn from 'classnames'

import s from './FiltersModal.module.scss'

export default function FiltersModal({
  closeFn,
  filters,
  filtersList,
  resetFilterHandler,
  handleSubmit,
}) {
  const { t } = useTranslation(['domains', 'dedicated_servers', 'other', 'vds', 'filter'])

  return (
    <Modal isOpen closeModal={closeFn} className={s.modal}>
      <Modal.Header>
        <p>{t('filter', { ns: 'filter' })}</p>
      </Modal.Header>
      <Modal.Body className={s.modal__body}>
        <Formik
          enableReinitialize
          initialValues={{
            id: filters?.id?.$ || '',
            domain: filters?.domain?.$ || '',
            ip: filters?.ip?.$ || '',
            pricelist: filters?.pricelist?.$ || '',
            period: filters?.period?.$ || '',
            status: filters?.status?.$ || '',
            opendate: filters?.opendate?.$ || '',
            expiredate: filters?.expiredate?.$ || '',
            orderdatefrom: filters?.orderdatefrom?.$ || '',
            orderdateto: filters?.orderdateto?.$ || '',
            cost_from: filters?.cost_from?.$ || '',
            cost_to: filters?.cost_to?.$ || '',
            autoprolong: filters?.autoprolong?.$ || '',
            ostemplate: filters?.ostemplate?.$ || '',
            datacenter: filters?.datacenter?.$ || '',
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched }) => {
            let dates = null
            if (values.opendate && values.expiredate) {
              dates = [new Date(values.opendate), new Date(values.expiredate)]
            } else if (values.opendate) {
              dates = new Date(values.opendate)
            }

            let datesOrdered = null
            if (values.orderdatefrom && values.orderdateto) {
              datesOrdered = [
                new Date(values.orderdatefrom),
                new Date(values.orderdateto),
              ]
            } else if (values.orderdatefrom) {
              datesOrdered = new Date(values.orderdatefrom)
            }

            return (
              <Form className={cn(s.form)} id="filter">
                <InputField
                  name="id"
                  label={`${t('Id')}:`}
                  placeholder={t('Enter id', { ns: 'other' })}
                  className={s.input}
                  error={!!errors.id}
                  touched={!!touched.id}
                />

                <InputField
                  name="domain"
                  label={`${t('Domain name')}:`}
                  placeholder={t('Enter domain name')}
                  className={s.input}
                  error={!!errors.domain}
                  touched={!!touched.domain}
                />
                <Select
                  className={s.select}
                  label={`${t('ostempl', { ns: 'vds' })}:`}
                  placeholder={t('Not selected', { ns: 'other' })}
                  value={values.ostemplate}
                  getElement={item => setFieldValue('ostemplate', item)}
                  isColored
                  itemsList={filtersList?.ostemplate?.map(({ $key, $ }) => ({
                    label: t($.trim()),
                    value: $key,
                  }))}
                />
                <InputField
                  name="ip"
                  label={`${t('ip', { ns: 'filter' })}:`}
                  placeholder={t('ip_placeholder', { ns: 'dedicated_servers' })}
                  className={s.input}
                  error={!!errors.ip}
                  touched={!!touched.ip}
                />

                <Select
                  label={`${t('pricing_plan')}:`}
                  placeholder={t('Not selected', { ns: 'other' })}
                  value={values.pricelist}
                  getElement={item => setFieldValue('pricelist', item)}
                  isColored
                  itemsList={filtersList?.pricelist?.map(({ $key, $ }) => {
                    return {
                      label: t($.trim().split('/')[0], { ns: 'other' }),
                      value: $key,
                    }
                  })}
                  className={s.select}
                />

                <Select
                  label={`${t('Period', { ns: 'other' })}:`}
                  placeholder={t('Not selected', { ns: 'other' })}
                  value={values.period}
                  getElement={item => setFieldValue('period', item)}
                  isColored
                  itemsList={filtersList?.period?.map(({ $key, $ }) => ({
                    label: t($.trim(), { ns: 'other' }),
                    value: $key,
                  }))}
                  className={s.select}
                />

                <Select
                  label={`${t('status', { ns: 'other' })}:`}
                  placeholder={t('Not selected', { ns: 'other' })}
                  value={values.status}
                  getElement={item => setFieldValue('status', item)}
                  isColored
                  itemsList={filtersList?.status?.map(({ $key, $ }) => ({
                    label: t($.trim(), { ns: 'other' }),
                    value: $key,
                  }))}
                  className={s.select}
                />

                <Select
                  label={`${t('Auto renewal')}:`}
                  placeholder={t('Not selected', { ns: 'other' })}
                  value={values.autoprolong}
                  getElement={item => setFieldValue('autoprolong', item)}
                  isColored
                  itemsList={filtersList?.autoprolong?.map(({ $key, $ }) => ({
                    label: t($.trim()),
                    value: $key,
                  }))}
                  className={s.select}
                />

                <DoubleInputField
                  className={s.input}
                  nameLeft="cost_from"
                  nameRight="cost_to"
                  valueLeft={values?.cost_from}
                  onChangeLeft={e => setFieldValue('cost_from', e?.target.value)}
                  valueRight={values?.cost_to}
                  onChangeRight={e => setFieldValue('cost_to', e?.target.value)}
                  label={`${t('Cost (from/to)', { ns: 'other' })}:`}
                  placeholderLeft="0.00"
                  placeholderRight="0.00"
                  textLeft="EUR"
                  textRight="EUR"
                  maxLengthLeft={5}
                  maxLengthRight={5}
                />

                <DoubleInputField
                  className={s.input}
                  nameLeft="opendate"
                  nameRight="expiredate"
                  valueLeft={values?.opendate}
                  onChangeLeft={() => null}
                  valueRight={values?.expiredate}
                  onChangeRight={() => null}
                  label={`${t('Valid (from/to)', { ns: 'other' })}:`}
                  placeholderLeft="00/00/00"
                  placeholderRight="00/00/00"
                  isCalendar
                  dates={dates}
                  range={values.opendate?.length !== 0}
                  setFieldValue={setFieldValue}
                />

                <DoubleInputField
                  className={s.input}
                  nameLeft="orderdatefrom"
                  nameRight="orderdateto"
                  valueLeft={values?.orderdatefrom}
                  onChangeLeft={() => null}
                  valueRight={values?.orderdateto}
                  onChangeRight={() => null}
                  label={`${t('Order date (from/to)', { ns: 'other' })}:`}
                  placeholderLeft="00/00/00"
                  placeholderRight="00/00/00"
                  isCalendar
                  dates={datesOrdered}
                  range={values.orderdatefrom?.length !== 0}
                  setFieldValue={setFieldValue}
                />
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>

      <Modal.Footer>
        <Cta view="secondary" buttonType="submit" form={'filter'}>
          <Icon name="NewIconSearch_light" />
          {t('search', { ns: 'filter' })}
        </Cta>
        <Cta
          viewType="text"
          onClick={() => {
            closeFn()
            resetFilterHandler()
          }}
        >
          <Icon name="NewIconClose_square_light" />
          {t('clear_filter', { ns: 'filter' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}

FiltersModal.propTypes = {
  closeFn: PropTypes.func,
  isOpened: PropTypes.bool,
}

FiltersModal.defaultProps = {
  closeFn: () => null,
}
