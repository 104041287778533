import { QIWI_PHONE_COUNTRIES, SBER_PHONE_COUNTRIES } from '@utils/constants'
import { PaymentMethodButton } from '@components'
import { useDispatch } from 'react-redux'
import { cartOperations } from '@redux'
import cn from 'classnames'

import s from './PaymentMethodComponents.module.scss'

export default function PaymentMethodList({
  methods,
  selectedMethod,
  onMethodSelect,
  state,
  setState,
  setFieldValue,
  setAdditionalPayMethodts,
  wrapperClassName,
}) {
  const dispatch = useDispatch()

  const setCode = list => {
    const country = list.find(el => el === state.userCountryCode) || list[0]
    setState({ phone: '', countryCode: country })
  }

  return (
    <div className={cn(s.formFieldsBlock, { [wrapperClassName]: wrapperClassName })}>
      {methods?.map(method => {
        const isSelected = method.paymethod?.$ === selectedMethod?.paymethod?.$

        const handleSelect = () => {
          onMethodSelect(method)

          // Додаткові дії в залежності від методу оплати
          if (method.paymethod?.$ === '90') {
            setCode(QIWI_PHONE_COUNTRIES)
          } else if (method.paymethod?.$ === '86') {
            setCode(SBER_PHONE_COUNTRIES)
          } else if (method.paymethod?.$ === '87') {
            setState({
              phone: '',
              countryCode: state.userCountryCode,
            })
          }

          if (method?.name?.$?.includes('balance') && method?.paymethod_type?.$ === '0') {
            setFieldValue('isPersonalBalance', 'on')
          } else {
            dispatch(
              cartOperations.getPayMethodItem(
                { paymethod: method?.paymethod?.$ },
                setAdditionalPayMethodts,
              ),
            )
            setFieldValue('isPersonalBalance', 'off')
          }
        }

        return (
          <PaymentMethodButton
            key={method.name?.$}
            method={method}
            isSelected={isSelected}
            onSelect={handleSelect}
            withHint={method.paymethod?.$ === '71'}
          />
        )
      })}
    </div>
  )
}
