import { createReducer } from '@reduxjs/toolkit'

import { actions, authActions } from '@redux'

const initialState = {
  theme: 'light',
  isLoading: false,
  scrollForbidden: false,
  online: true,
  blockingModalShown: false,
  promotionsList: [],
  hasActiveDedic: false,
  billmgrIsLocked: false,
  lockTime: '',
}

export const theme = createReducer(initialState.theme, {
  [actions.changeTheme]: () => {
    // const theme = payload ? payload : state === 'light' ? 'dark' : 'light'
    // cookies.setCookie('theme', theme)

    return theme
  },
})

export const isLoading = createReducer(initialState.isLoading, {
  [actions.showLoader]: () => true,
  [actions.hideLoader]: () => false,
  [authActions.registrationSuccess]: () => false,
  [authActions.loginSuccess]: () => false,
  [authActions.logoutSuccess]: () => false,
})

export const scrollForbidden = createReducer(initialState.scrollForbidden, {
  [actions.disableScrolling]: () => true,
  [actions.enableScrolling]: () => false,
})

export const online = createReducer(initialState.online, {
  [actions.setOffline]: () => false,
  [actions.setOnline]: () => true,
})

export const blockingModalShown = createReducer(initialState.blockingModalShown, {
  [actions.hideBlockingModal]: () => false,
  [actions.showBlockingModal]: () => true,
})

export const promotionsList = createReducer(initialState.promotionsList, {
  [actions.setPromotionsList]: (_, { payload }) => payload,
})

export const hasActiveDedic = createReducer(initialState.hasActiveDedic, {
  [actions.setHasActiveDedic]: (_, { payload }) => payload,
})

export const billmgrIsLocked = createReducer(initialState.billmgrIsLocked, {
  [actions.setBillmgrIsLocked]: (_, { payload }) => payload,
})

export const lockTime = createReducer(initialState.lockTime, {
  [actions.setLockTime]: (_, { payload }) => payload,
})
