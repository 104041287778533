import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'

import s from './DedicIPList.module.scss'

import DedicIPItem from '../DedicIPItem/DedicIPItem'
import DedicIPMobileItem from '../DedicIPMobileItem/DedicIPMobileItem'
import { CardsContainer, Table } from '@components'

export default function DedicIPList({
  IPList,
  setElidForEditModal,
  setActiveIP,
  activeIP,
  rights,
  setElidForDeleteModal,
}) {
  const { t } = useTranslation(['vds', 'dedicated_servers', 'other'])
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  return (
    <>
      {isDesktop ? (
        <Table isItemsClickable>
          <thead>
            <tr>
              <th>{t('ip_address')}</th>
              <th>{t('mask', { ns: 'dedicated_servers' })}</th>
              <th>{t('gateway', { ns: 'dedicated_servers' })}</th>
              <th>{t('domain', { ns: 'dedicated_servers' })}</th>
              <th>{t('status', { ns: 'other' })}</th>
            </tr>
          </thead>
          <tbody className={s.tbody}>
            {IPList?.map(el => {
              return (
                <DedicIPItem
                  key={el.id.$}
                  ip={el}
                  activeIP={activeIP}
                  setActiveIP={setActiveIP}
                />
              )
            })}
          </tbody>
        </Table>
      ) : (
        <CardsContainer>
          {IPList?.map(el => {
            return (
              <DedicIPMobileItem
                key={el.id.$}
                ip={el}
                setElidForEditModal={setElidForEditModal}
                setElidForDeleteModal={setElidForDeleteModal}
                rights={rights}
              />
            )
          })}
        </CardsContainer>
      )}
    </>
  )
}

DedicIPList.propTypes = {
  servers: PropTypes.arrayOf(PropTypes.object),
  setElidForEditModal: PropTypes.func,
  setActiveServer: PropTypes.func,
  activeServerID: PropTypes.string,
  rights: PropTypes.object,
}
