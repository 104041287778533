import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { Icon } from '@components'

import { userSelectors } from '@redux'
import NotificationList from './NotificationList/NotificationList'
import s from './NotificationsBar.module.scss'

export default function NotificationsBar({ handler, isBarOpened, isScrolled }) {
  const userItems = useSelector(userSelectors.getUserItems)

  const { t } = useTranslation('container')

  const getNotifBarEl = useRef()

  return (
    <>
      <div
        className={cn(s.notification_wrapper, {
          [s.opened]: isBarOpened,
          [s.scrolled]: isScrolled,
        })}
        role="button"
        tabIndex={0}
        onKeyDown={() => null}
        onClick={handler}
      ></div>

      <div
        ref={getNotifBarEl}
        className={cn(s.container, {
          [s.opened]: isBarOpened,
        })}
      >
        <div className={s.notification_title_container}>
          <p className={'body_l_medium'}>{`${t('notification_bar.notifications')} (${
            userItems.messages_count ? userItems?.messages_count : 0
          })`}</p>

          <button onClick={handler}>
            <Icon name="Close" className={s.close_btn} />
          </button>
        </div>

        {isBarOpened && <NotificationList notifications={userItems} />}
      </div>
    </>
  )
}
