import {
  Button,
  Modal,
  NotificationMessage,
  TariffCard,
  ScrollToFieldError,
  TooltipWrapper,
  Icon,
  Cta,
} from '@components'
import { ErrorMessage, Form, Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import s from './Modals.module.scss'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions,
  billingActions,
  cloudVpsOperations,
  cloudVpsSelectors,
  userSelectors,
} from '@redux'
import { getInstanceMainInfo } from '@utils'
import * as Yup from 'yup'
import * as route from '@src/routes'

export const ResizeModal = ({ item, closeModal, onSubmit }) => {
  const { t } = useTranslation(['cloud_vps'])
  const dispatch = useDispatch()
  const [tariffsForResize, setTariffsForResize] = useState()
  const [notEnoughMoney, setNotEnoughMoney] = useState(false)
  const [fundsErrorData, setFundsErrorData] = useState(null)

  const [price, setPrice] = useState(0)

  const [limitIsReached, setLimitIsReached] = useState(false)

  const premiumTariffs = useSelector(cloudVpsSelectors.getPremiumTariffs)
  const basicTariffs = useSelector(cloudVpsSelectors.getBasicTariffs)

  let allTariffs
  if (premiumTariffs && basicTariffs) {
    let commonObj = { ...premiumTariffs }
    for (const basicKey in basicTariffs) {
      if (commonObj?.[basicKey]) {
        commonObj[basicKey] = commonObj[basicKey].concat(basicTariffs[basicKey])
      } else {
        commonObj[basicKey] = basicTariffs[basicKey]
      }
    }

    allTariffs = commonObj
  }

  const { displayName } = getInstanceMainInfo(item)

  const warningEl = useRef()
  const errorEl = useRef()

  const { credit, realbalance } = useSelector(userSelectors.getUserInfo)

  const totalBalance = credit ? +realbalance + +credit : +realbalance

  const datacenter = item.datacenter.$

  useEffect(() => {
    if (!allTariffs?.[datacenter]) {
      dispatch(actions.showLoader())

      Promise.all([
        dispatch(
          cloudVpsOperations.getAllTariffsInfo({
            datacenter,
            setLimitIsReached,
          }),
        ),
        dispatch(
          cloudVpsOperations.getTariffsListToChange({
            elid: item.id.$,
            setTariffsForResize,
            closeModal,
          }),
        ),
      ]).finally(() => {
        dispatch(actions.hideLoader())
      })
    } else {
      dispatch(
        cloudVpsOperations.getTariffsListToChange({
          elid: item.id.$,
          setTariffsForResize,
          closeModal,
          showLoader: true,
        }),
      )
    }
  }, [])

  const onFormSubmit = values => {
    const errorCallback = errorData => {
      let requiredAmount

      errorData.param.forEach(el => {
        if (el.$name === 'amount') {
          requiredAmount = el.$
        }
      })

      setFundsErrorData({ requiredAmount })
      errorEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }

    onSubmit(values, errorCallback)
  }

  const validateSchema = Yup.object().shape({
    pricelist: Yup.string().required(t('choose_tariff')),
  })

  const currentPeriod = item.costperiod.$

  if (limitIsReached) {
    return (
      <Modal isOpen closeModal={closeModal}>
        <Modal.Header>
          <p>{t('limit_is_reached_title', { ns: 'cloud_vps' })}</p>
        </Modal.Header>
        <Modal.Body>
          <p>
            <Trans
              t={t}
              i18nKey="limit_is_reached"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a className={s.link} href={route.SUPPORT} />,
              }}
            />
          </p>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal
      isOpen={!!item && !!tariffsForResize && !!allTariffs?.[datacenter]}
      closeModal={closeModal}
      className={s.resize_modal}
    >
      <Modal.Header>
        <p>
          {t('choose_flavor')} {displayName}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ pricelist: '' }}
          onSubmit={onFormSubmit}
          validationSchema={validateSchema}
        >
          {({ values, setFieldValue }) => {
            const filledTariffsList = allTariffs?.[datacenter]?.filter(el =>
              tariffsForResize.some(tariff => tariff.$key === el.id.$),
            )

            const checkIfEnoughMoney = price => {
              setNotEnoughMoney(price && +price > totalBalance)
            }

            useEffect(() => {
              if (price !== 0) checkIfEnoughMoney(price)
            }, [price])

            return (
              /** Attribute "name" here is just for scrolling errorMessage into view */
              <Form id={'resize'} name="pricelist">
                <ScrollToFieldError
                  scrollBehavior={{ behavior: 'smooth', block: 'end' }}
                />
                <div className={s.body}>
                  <NotificationMessage className={s.without_margin} type="warning">
                    {t('resize_warning')}
                  </NotificationMessage>

                  <NotificationMessage type="note">
                    {t('resize_notes')}
                  </NotificationMessage>

                  {notEnoughMoney && (
                    <NotificationMessage
                      className={s.without_margin}
                      ref={warningEl}
                      type="warning"
                    >
                      {t('not_enough_money', { ns: 'cloud_vps' })}{' '}
                      <Cta
                        viewType="text"
                        onClick={() =>
                          dispatch(billingActions.setIsModalCreatePaymentOpened(true))
                        }
                      >
                        {t('top_up', { ns: 'cloud_vps' })}
                      </Cta>
                    </NotificationMessage>
                  )}

                  {fundsErrorData && (
                    <NotificationMessage
                      className={s.without_margin}
                      type="error"
                      ref={errorEl}
                    >
                      <Trans
                        t={t}
                        i18nKey="insufficient_funds"
                        components={{
                          button: (
                            <button
                              className={s.link}
                              type="button"
                              onClick={() =>
                                dispatch(
                                  billingActions.setIsModalCreatePaymentOpened(true),
                                )
                              }
                            />
                          ),
                        }}
                        values={{ price: fundsErrorData.requiredAmount }}
                      />
                    </NotificationMessage>
                  )}

                  <p>{t('prices_excluding_tax')}</p>

                  <ul className={s.tariffs_list}>
                    {filledTariffsList.map(tariff => {
                      const tariffPrice = tariff.prices.price.find(
                        el => el.period.$ === currentPeriod,
                      ).cost.$

                      return (
                        <TariffCard
                          key={tariff.id.$}
                          tariff={tariff}
                          onClick={() => {
                            dispatch(
                              cloudVpsOperations.getTariffPriceForResize({
                                elid: item.id.$,
                                pricelist: tariff.id.$,
                                setPrice,
                              }),
                            )

                            setFieldValue('pricelist', tariff.id.$)
                            setFundsErrorData(null)
                          }}
                          active={values.pricelist === tariff.id.$}
                          price={tariffPrice}
                          isColored
                        />
                      )
                    })}
                  </ul>
                  <ErrorMessage
                    className={s.error_message}
                    name="pricelist"
                    component="span"
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer className={s.footer}>
        <div className={s.price_block}>
          <div className={s.label_wrapper}>
            <span className={s.amount_label}>{t('upgrade_cost')}</span>
            <TooltipWrapper
              className={s.hint_wrapper}
              content={t('resize_explanation')}
              hintDelay={100}
            >
              <Icon name="NewInfoIcon" />
            </TooltipWrapper>
          </div>
          <p className={s.price}>€{price}</p>
        </div>

        <div className={s.btns_wrapper}>
          <Button
            className={s.btn_confirm}
            label={t('Confirm')}
            type="submit"
            form={'resize'}
            onClick={e => {
              if (notEnoughMoney) {
                e.preventDefault()

                warningEl.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                })
              }
            }}
          />

          <Cta
            buttonType="button"
            view="secondary"
            onClick={closeModal}
            className={'body_m'}
          >
            {t('Cancel')}
          </Cta>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
