export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="3.42866"
        cy="3.42866"
        r="3.42866"
        transform="matrix(-1 0 0 1 13.4287 2.29102)"
        stroke="currentColor"
      />
      <path
        d="M3.99951 14.2359C3.99951 13.4985 4.46311 12.8406 5.15762 12.5926V12.5926C8.28881 11.4743 11.7105 11.4743 14.8417 12.5926V12.5926C15.5362 12.8406 15.9998 13.4985 15.9998 14.2359V15.3636C15.9998 16.3814 15.0984 17.1632 14.0908 17.0193L13.2727 16.9024C11.1017 16.5923 8.89766 16.5923 6.72669 16.9024L5.9086 17.0193C4.901 17.1632 3.99951 16.3814 3.99951 15.3636V14.2359Z"
        stroke="currentColor"
      />
    </svg>
  )
}
