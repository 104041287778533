const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19 5.519a8.5 8.5 0 1 0 0 12.962M3 14h10M3 10h10"
    />
  </svg>
)
export default SvgComponent
