const getTheme = state => state.theme
const getIsLoading = state => state.isLoading
const isScrollForbidden = state => state.scrollForbidden
const onlineStatus = state => state.online
const getBlockingModalStatus = state => state.blockingModalShown
const getPromotionsList = state => state.promotionsList
const getHasActiveDedic = state => state.hasActiveDedic
const getBillmgrIsLocked = state => state.billmgrIsLocked
const getLockTime = state => state.lockTime

export default {
  getTheme,
  getIsLoading,
  isScrollForbidden,
  onlineStatus,
  getBlockingModalStatus,
  getPromotionsList,
  getHasActiveDedic,
  getBillmgrIsLocked,
  getLockTime,
}
