import { useTranslation } from 'react-i18next'
import s from './TicketStatus.module.scss'
import cn from 'classnames'
export default function TicketStatus({ status }) {
  const { t } = useTranslation(['support'])
  const ticketStatus = status?.trim()
  const statusClass = ticketStatus?.toLowerCase().replace(/ /g, '_')

  return <span className={cn(s.status, s[statusClass])}>{t(ticketStatus)}</span>
}
