const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    stroke="currentColor"
    {...props}
  >
    <path
      strokeLinejoin="round"
      d="M1.004 5.165v11a1 1 0 0 0 .615.923l9.385 3.91m-10-15.833 9.23-3.846a2 2 0 0 1 1.54 0l4.23 1.762m-15 2.084 5 2.083m5 2.083v11.667m0-11.667 10-4.166m-10 4.166-5-2.083m5 13.75 9.385-3.91a1 1 0 0 0 .615-.923v-11m0 0-5-2.084m-10 4.167 10-4.167"
      stroke="currentColor"
    />
  </svg>
)
export default SvgComponent
