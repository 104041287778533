import { formatCountryName, getFlagFromCountryName } from '@src/utils'
import { useTranslation } from 'react-i18next'
import { BtnLikeRadio } from '@components/index'

export default function CountryButton({ currentItem, item, onChange, disabled }) {
  const { t } = useTranslation(['countries'])
  const itemCountry = formatCountryName(item.$, t)

  return (
    <BtnLikeRadio
      isActive={currentItem.$key === item.$key && !disabled}
      onClick={() => onChange(item)}
      disabled={disabled}
    >
      <img
        src={require(
          `@images/countryFlags/${getFlagFromCountryName(itemCountry?.countryName)}.png`,
        )}
        width={20}
        height={20}
        alt={itemCountry?.countryName}
      />
      {itemCountry?.DCName}
    </BtnLikeRadio>
  )
}
