const SvgComponent = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M16 15.998V17.998C16 20.2072 14.2091 21.998 12 21.998H7C4.79086 21.998 3 20.2072 3 17.998V5.99805C3 3.78891 4.79086 1.99805 7 1.99805H12C14.2091 1.99805 16 3.78891 16 5.99805V7.99805"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M19 14.998L21.2929 12.7052C21.6834 12.3146 21.6834 11.6815 21.2929 11.2909L19 8.99805"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path d="M21 11.998L9 11.998" strokeWidth="1.2" strokeLinecap="round" />
  </svg>
)

export default SvgComponent
