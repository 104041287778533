import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import { CheckBox } from '@components'

import s from './WeekdaySelector.module.scss'
import cn from 'classnames'

export default function WeekdaySelector({
  selectedDays,
  setSelectedDays,
  isRequired,
  name,
}) {
  const { t } = useTranslation(['cloud_vps'])

  const daysOfWeek = [
    { value: 1, label: t('monday') },
    { value: 2, label: t('tuesday') },
    { value: 3, label: t('wednesday') },
    { value: 4, label: t('thursday') },
    { value: 5, label: t('friday') },
    { value: 6, label: t('saturday') },
    { value: 0, label: t('sunday') },
  ]

  const handleDayClick = dayValue => {
    const newSelectedDays = selectedDays.includes(dayValue)
      ? selectedDays.filter(day => day !== dayValue)
      : [...selectedDays, dayValue]
    setSelectedDays(newSelectedDays)
  }

  return (
    <div className={s.weekday__component}>
      <p className={cn('other_uppercase_small', s.weekday__label)}>
        {t('day')}: {isRequired && <span className={s.required_star}>*</span>}
      </p>
      <div className={s.weekday__wrapper}>
        {daysOfWeek.map(day => (
          <CheckBox
            value={selectedDays.includes(day.value)}
            onClick={() => handleDayClick(day.value)}
            name={day.label}
            key={`day_${day.value}`}
            viewType="circleCheck"
          />
        ))}
      </div>
      <ErrorMessage className={s.error_message} name={name} component="span" />
    </div>
  )
}

WeekdaySelector.propTypes = {
  selectedDays: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedDays: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
}
