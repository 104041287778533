import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getFormatedDate, historyTranslateFn } from '@utils'

export default function HistoryItem({ history }) {
  const { t } = useTranslation(['vds', 'other', 'dedicated_servers'])

  const { date, time } = getFormatedDate({ fullDate: history?.changedate?.$ })
  return (
    <tr>
      <td>
        <div>{date}</div>
        <span className={cn('text-dark-50', 'body_s')}>{time}</span>
      </td>
      <td data-wrap>{historyTranslateFn(history?.desc?.$, t)}</td>
      <td>
        {history?.user?.$.replace(String.fromCharCode(39), '') ===
        'Providers employee or system'
          ? t('Provider employee or system', { ns: 'dedicated_servers' })
          : history?.user?.$}
      </td>
      <td className={cn({ 'text-dark-50': history?.ip?.$?.trim() === '-' })}>
        {history?.ip?.$?.trim() === '-'
          ? t('Not provided', { ns: 'dedicated_servers' })
          : history?.ip?.$}
      </td>
    </tr>
  )
}

HistoryItem.propTypes = {
  server: PropTypes.object,
  setActiveServer: PropTypes.func,
  activeServerID: PropTypes.string,
}
