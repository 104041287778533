import { ContainerContext } from '@src/context/ContainerContext'
import { useContext, useEffect } from 'react'

export default function useContainerContext({ title } = {}) {
  const { contextData, setContextData } = useContext(ContainerContext)

  useEffect(() => {
    if (title) {
      setContextData({ pageTitle: title })
    }

    return () => {
      if (title) {
        setContextData({ pageTitle: '' })
      }
    }
  }, [])
  return { contextData, setContextData }
}
