import { Icon } from '@components/index'
import s from './FilterCloud.module.scss'
import cn from 'classnames'
export default function FilterCloud({
  label,
  value,
  onClick = () => {},
  crossIcon = true,
  className,
  disabled,
}) {
  return (
    <li>
      <button
        onClick={onClick}
        className={cn(s.filter__cloud, className)}
        type="button"
        disabled={disabled}
      >
        {label && <span className={s.filter__cloud_name}>{label}:</span>}
        <span className={s.filter__cloud_value}>{value}</span>
        {crossIcon && <Icon name="Cross" className={s.close_icon} />}
      </button>
    </li>
  )
}
