import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Cta, InputField } from '@components'
import { authSelectors, authActions, authOperations } from '@redux'
import * as route from '@src/routes'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SUPPORT_MAIL } from '@utils/constants'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'

import ss from '../AuthStyles.module.scss'

export default function VerificationModal() {
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  const formVisibility = useSelector(authSelectors.getTotpFormVisibility)
  const previousRoute = useSelector(authSelectors.getPreviousRoute)

  const { t } = useTranslation('auth')
  const dispatch = useDispatch()

  const handleSubmit = ({ totp }, { resetForm }) => {
    resetForm()
    /* If previous route was route.CHANGE_PASSWORD - we pass func navigate  
    to redirect to the route.USER_SETTINGS_ACCESS */
    if (previousRoute === route.CHANGE_PASSWORD) {
      dispatch(authOperations.sendTotp({ totp, setError, navigate }))
    } else {
      dispatch(authOperations.sendTotp({ totp, setError }))
    }
  }

  const handleBtnCloseClick = resetForm => {
    dispatch(authActions.closeTotpForm())
    resetForm()
    navigate(route.LOGIN, { replace: true })
  }

  const validationSchema = Yup.object().shape({
    totp: Yup.string()
      .length(6, t('verification.totp_length_error'))
      .matches(/^\d+$/, t('verification.totp_length_error'))
      .required(t('verification.error')),
  })

  return (
    <div
      className={cn(ss.backdrop, {
        [ss.shown]: formVisibility === 'shown',
      })}
    >
      <div className={ss.modalWindow}>
        <h3 className={cn('headings_h2', ss.title)}>{t('verification.form_title')}</h3>

        <Formik
          initialValues={{ totp: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, errors, touched, resetForm }) => {
            return (
              <>
                <Form className={ss.form}>
                  {error && (
                    <div className={ss.credentials_error}>{t('verification.error')}</div>
                  )}

                  <div className={ss.text_wrapper}>
                    <p className="body_m_light">{t('verification.text')}</p>
                    <p className="body_s_light">
                      {t('verification.hint')}{' '}
                      <a href={`mailto:${SUPPORT_MAIL}`} className={ss.mail_link}>
                        {SUPPORT_MAIL}
                      </a>
                    </p>
                  </div>

                  <InputField
                    label={t('verification.label')}
                    placeholder={t('verification.placeholder')}
                    name="totp"
                    error={!!errors.totp}
                    touched={!!touched.totp}
                    onBlur={e => {
                      const trimmedValue = e.target.value.trim()
                      setFieldValue('totp', trimmedValue)
                    }}
                  />

                  <Cta buttonType="submit">{t('verification.btn')}</Cta>
                </Form>
                <Cta viewType="text" onClick={() => handleBtnCloseClick(resetForm)}>
                  {t('cancel')}
                </Cta>
              </>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
