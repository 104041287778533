const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={88} height={88} fill="none" {...props}>
    <circle cx={44} cy={44} r={44} fill="#fff" fillOpacity={0.35} />
    <path
      stroke="#913EF5"
      d="M32 43.314c0-1.635 0-2.453.304-3.188.305-.735.883-1.313 2.04-2.47l1.313-1.313c1.156-1.156 1.734-1.734 2.47-2.039C38.86 34 39.678 34 41.313 34h5.372c1.635 0 2.453 0 3.188.304.735.305 1.313.883 2.47 2.04l1.313 1.313c1.156 1.156 1.734 1.734 2.039 2.47.304.734.304 1.552.304 3.187V52c0 3.771 0 5.657-1.172 6.828C53.657 60 51.771 60 48 60h-8c-3.771 0-5.657 0-6.828-1.172C32 57.657 32 55.771 32 52v-8.686Z"
    />
    <path
      stroke="#913EF5"
      strokeLinecap="round"
      d="m38 34-5.723-3.815a.5.5 0 0 0-.554 0l-5.215 3.477a.5.5 0 0 0-.077.77l5.155 5.154c.265.265.414.625.414 1v0c0 .78.633 1.414 1.414 1.414h21.172c.78 0 1.414-.633 1.414-1.414v0c0-.375.149-.735.414-1l5.155-5.155a.5.5 0 0 0-.077-.77l-5.215-3.476a.5.5 0 0 0-.554 0L50 34"
    />
  </svg>
)
export default SvgComponent
