import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { dedicOperations } from '@redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button, InputField, Select, Modal, Cta } from '@components'
import { useLocation } from 'react-router-dom'
import s from './DedicIPOrder.module.scss'

export default function DedicIPOrder({ closeFn, plid }) {
  const { t } = useTranslation(['dedicated_servers', 'vds', 'other'])
  const dispatch = useDispatch()
  const location = useLocation()
  const ipPlid = location.state.plid || plid

  const [initialValues, setInitialValues] = useState()

  const handleEditionModal = () => {
    closeFn()
  }

  const handleSubmit = values => {
    const { domain, ipAmout } = values

    dispatch(
      dedicOperations.orderNewIP(
        ipPlid,
        initialValues?.typeList[0]?.$name,
        initialValues?.maxcount?.$,
        ipAmout,
        domain,
        handleEditionModal,
      ),
    )
  }

  const maxIPAmountList = []
  for (let i = 1; i <= initialValues?.maxcount?.$; i++) {
    maxIPAmountList.push(i)
  }

  const validationSchema = Yup.object().shape({
    domain: Yup.string().matches(
      /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/,
      t('licence_error'),
    ),
  })

  useEffect(() => {
    dispatch(dedicOperations.orderIPInfo(ipPlid, setInitialValues))
  }, [])

  return (
    <Modal isOpen closeModal={closeFn} className={s.modal}>
      <Modal.Header>
        <p>{t('Add IP-address', { ns: 'dedicated_servers' })}</p>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            domain: initialValues?.domain?.$ || '',
            ipType: initialValues?.typeList[0]?.$ || '',
            ipAmount: initialValues?.count?.$ || '',
          }}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <Form id="ip-order" className={s.main_block}>
                <InputField
                  label={`${t('type')}:`}
                  name="ipType"
                  className={s.input_field_wrapper}
                  inputClassName={s.input}
                  autoComplete="off"
                  type="text"
                  value={t(values?.ipType)}
                  disabled
                />

                <InputField
                  label={`${t('domain')}:`}
                  name="domain"
                  error={!!errors.domain}
                  touched={!!touched.domain}
                  className={s.input_field_wrapper}
                  inputClassName={s.input}
                  autoComplete
                  type="text"
                  value={values?.domain}
                />

                <Select
                  value={values?.ipAmount}
                  getElement={item => {
                    setFieldValue('ipAmount', item)
                  }}
                  label={t('count_ip')}
                  itemsList={maxIPAmountList.map(el => {
                    return { label: el, value: el.toString() }
                  })}
                  className={s.select}
                />
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer column>
        <Button label={t('Save', { ns: 'other' })} type="submit" form="ip-order" />

        <Cta buttonType="button" view="secondary" onClick={closeFn} className={'body_m'}>
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
