import SupportArchiveTableItem from './SupportArchiveTableItem'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import s from './SupportArchiveTable.module.scss'
import { useMediaQuery } from 'react-responsive'
import { CardsContainer, Table } from '@components'

const HEAD_CELLS = [
  { label: 'request_id', value: 'request_id' },
  { label: 'subject', value: 'theme' },
  { label: 'created_at', value: 'date' },
]

export default function SupportArchiveTable(props) {
  const { t } = useTranslation(['support', 'other'])
  const { list, setSelctedTicket, selctedTicket } = props
  const mobile = useMediaQuery({ query: '(max-width: 767px)' })

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label, { ns: 'support' })}</th>
    })

  const renderItems = () => {
    return list?.map(el => {
      const { tstatus, last_message, name, id, unread } = el
      let onItemClick = () => setSelctedTicket(el)

      return (
        <SupportArchiveTableItem
          key={id?.$}
          theme={name?.$}
          date={last_message?.$}
          status={tstatus?.$}
          id={id?.$}
          unread={unread?.$ === 'on'}
          setSelctedTicket={onItemClick}
          selected={selctedTicket?.id?.$ === id?.$}
        />
      )
    })
  }

  return (
    <>
      {!mobile ? (
        <Table stickyHeader className={s.table} isItemsClickable>
          <thead>
            <tr>{renderHeadCells()}</tr>
          </thead>
          <tbody className={s.tbody}>{renderItems()}</tbody>
        </Table>
      ) : (
        <CardsContainer>{renderItems()}</CardsContainer>
      )}
    </>
  )
}

SupportArchiveTable.propTypes = {
  list: PropTypes.array,
  setSelctedTicket: PropTypes.func,
  selctedTicket: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object]),
}

SupportArchiveTable.defaultProps = {
  list: [],
}
