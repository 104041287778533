import PropTypes from 'prop-types'
import { getImageInfo } from '@utils'

export default function ImageItem({
  item,
  cells,
  itemOnClickHandler,
  idKey,
  isItemClickable,
}) {
  const { isDeleting } = getImageInfo(item)

  return (
    <tr
      data-disabled={[!isItemClickable || isDeleting]}
      onClick={e => !isDeleting && itemOnClickHandler(e, item)}
      tabIndex={0}
      data-stop-propagation
    >
      {cells.map(cell => (
        <td key={`item_${item?.[idKey].$}${cell.label}`} data-target={cell.label}>
          {cell.renderData?.(item[cell.value]?.$, item) ?? item[cell.value]?.$}
        </td>
      ))}
    </tr>
  )
}

ImageItem.propTypes = {
  item: PropTypes.object,
  cells: PropTypes.array,
  itemOnClickHandler: PropTypes.func,
  idKey: PropTypes.string,
}
