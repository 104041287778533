// import { useSelector } from 'react-redux'
// import { selectors } from '@redux'

const SvgComponent = props => {
  // const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.875 3.33398H8.125C5.47335 3.33398 4.14752 3.33398 3.32376 4.17082C2.5 5.00766 2.5 6.35453 2.5 9.04827V10.953C2.5 13.6468 2.5 14.9936 3.32376 15.8305C4.14752 16.6673 5.47335 16.6673 8.125 16.6673H11.875C14.5267 16.6673 15.8525 16.6673 16.6762 15.8305C17.5 14.9936 17.5 13.6468 17.5 10.953V9.04827C17.5 6.35453 17.5 5.00766 16.6762 4.17082C15.8525 3.33398 14.5267 3.33398 11.875 3.33398Z"
        stroke="currentColor"
      />
      <path d="M5.83594 6.66602H8.33594" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M15.8333 13.334H14.1667C13.381 13.334 12.9882 13.334 12.7441 13.0899C12.5 12.8458 12.5 12.453 12.5 11.6673C12.5 10.8816 12.5 10.4888 12.7441 10.2447C12.9882 10.0007 13.381 10.0007 14.1667 10.0007H15.8333C16.619 10.0007 17.0118 10.0007 17.2559 10.2447C17.5 10.4888 17.5 10.8816 17.5 11.6673C17.5 12.453 17.5 12.8458 17.2559 13.0899C17.0118 13.334 16.619 13.334 15.8333 13.334Z"
        stroke="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
