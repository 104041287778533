const SvgComponent = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.6286 21.7002L3.31625 21.3098H3.31625L3.6286 21.7002ZM6.45608 19.4383L6.14373 19.0478L6.45608 19.4383ZM2.50391 8C2.50391 5.51472 4.51863 3.5 7.00391 3.5V2.5C3.96634 2.5 1.50391 4.96243 1.50391 8H2.50391ZM2.50391 13.5V8H1.50391V13.5H2.50391ZM2.50391 14V13.5H1.50391V14H2.50391ZM2.50391 20.9194V14H1.50391V20.9194H2.50391ZM3.31625 21.3098C2.98887 21.5717 2.50391 21.3386 2.50391 20.9194H1.50391C1.50391 22.1771 2.9588 22.8764 3.94095 22.0907L3.31625 21.3098ZM6.14373 19.0478L3.31625 21.3098L3.94095 22.0907L6.76843 19.8287L6.14373 19.0478ZM17.0039 18.5H7.70547V19.5H17.0039V18.5ZM21.5039 14C21.5039 16.4853 19.4892 18.5 17.0039 18.5V19.5C20.0415 19.5 22.5039 17.0376 22.5039 14H21.5039ZM21.5039 8V14H22.5039V8H21.5039ZM17.0039 3.5C19.4892 3.5 21.5039 5.51472 21.5039 8H22.5039C22.5039 4.96243 20.0415 2.5 17.0039 2.5V3.5ZM7.00391 3.5H17.0039V2.5H7.00391V3.5ZM6.76843 19.8287C7.0344 19.6159 7.36486 19.5 7.70547 19.5V18.5C7.13779 18.5 6.58701 18.6932 6.14373 19.0478L6.76843 19.8287Z" />
    <circle cx="7.05469" cy="11.0488" r="1.25" />
    <circle cx="12.0508" cy="11.0488" r="1.25" />
    <circle cx="17.0586" cy="11.0488" r="1.25" />
  </svg>
)
export default SvgComponent
