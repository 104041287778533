import { useMediaQuery } from 'react-responsive'
import s from './SshList.module.scss'
import SshItem from './SshItem'
import SshItemMobile from './SshItemMobile'
import no_vds from '@images/services/no_vds.png'
import { useTranslation } from 'react-i18next'
import { CardsContainer, Table } from '@components'

export default function SshList({ ssh }) {
  const { t } = useTranslation(['cloud_vps'])
  const headCells = [
    { name: t('Name'), isSort: false, key: 'comment' },
    { name: t('Created at'), isSort: false, key: 'cdate' },
    { name: t('Fingerprint'), isSort: false, key: 'fingerprint' },
  ]

  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })

  const renderHeadCells = () =>
    headCells.map(cell => {
      return <th key={cell.name}>{cell.name}</th>
    })

  if (!ssh?.length) {
    return (
      <div className={s.no_vds_wrapper}>
        <img className={s.no_vds} src={no_vds} alt={t('no_ssh_yet')} />
        <p className={s.no_vds_title}>{t('no_ssh_yet')}</p>
      </div>
    )
  }

  return (
    <>
      {widerThan1024 ? (
        <Table className={s.table} stickyHeader>
          <thead>
            <tr>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ssh.map(item => (
              <SshItem key={item?.elid?.$} item={item} />
            ))}
          </tbody>
        </Table>
      ) : (
        <CardsContainer className={s.mobile__list}>
          {ssh.map(item => (
            <SshItemMobile key={item.elid.$} item={item} />
          ))}
        </CardsContainer>
      )}
    </>
  )
}
