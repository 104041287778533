export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3" y="6" width="18" height="13" rx="1.33333" stroke="currentColor" />
      <path d="M7 15H7.01062" stroke="currentColor" strokeLinecap="round" />
      <path d="M3 11L21 11" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
}
