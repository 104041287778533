const Hash = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M2.66699 5.33333H13.3337M2.66699 10.6667H13.3337M5.33366 2V14M10.667 2V14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Hash
