import s from './IconButton.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Icon } from '@components'
import { forwardRef } from 'react'

const IconButton = forwardRef(function IconButton(
  {
    type,
    onClick,
    disabled,
    icon,
    btnText,
    className,
    datatestid,
    size = 'large',
    color = 'primary',
    ...props
  },
  ref,
) {
  return (
    <button
      data-testid={datatestid}
      disabled={disabled}
      className={cn(s.icon_btn, s[color], s[size], className)}
      type={type}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      <Icon name={icon} />
      {btnText}
    </button>
  )
})

export default IconButton

IconButton.propTypes = {
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'third', 'fourth']),
  size: PropTypes.oneOf(['large', 'medium', 'small', 'xsmall', 'none']),
  className: PropTypes.string,
  datatestid: PropTypes.string,
}

IconButton.defaultProps = {
  type: 'button',
  onClick: () => null,
  disabled: false,
  icon: '',
}
