import { Button, Modal, NotificationMessage, Cta } from '@components'
import s from './Modals.module.scss'
import { useTranslation } from 'react-i18next'
import { getInstanceMainInfo } from '@utils'
import { CLOUDS_ACTIONS } from '@src/utils/constants'

export const ConfirmModal = ({ item, closeModal, onSubmit }) => {
  const { t } = useTranslation(['cloud_vps'])
  const { displayName } = getInstanceMainInfo(item)

  return (
    <Modal isOpen={!!item} closeModal={closeModal} isClickOutside>
      <Modal.Header>
        <p> {t(`confirm.title.${item.confirm_action}`)}</p>
      </Modal.Header>
      <Modal.Body>
        {item?.confirm_action === CLOUDS_ACTIONS.stop && (
          <NotificationMessage type="info">
            {t(`rebuild_modal.warning.${item.confirm_action}`)}
          </NotificationMessage>
        )}
        <p className={s.body__text}>
          {t(`confirm.text.${item.confirm_action}`, {
            name: displayName,
          })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          label={t('Confirm')}
          size={'small'}
          onClick={() => onSubmit(item.confirm_action, item.id.$)}
        />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
