import { useTranslation } from 'react-i18next'
import {
  InputField,
  Button,
  Select,
  CustomPhoneInput,
  CheckBox,
  Modal,
  Cta,
} from '@components'
import { Formik, Form } from 'formik'
import s from './DomainsContactsEditModal.module.scss'
import * as Yup from 'yup'
import {
  CYRILLIC_ALPHABET_PROHIBITED,
  EMAIL_SPECIAL_CHARACTERS_REGEX,
  INDEX_REGEX,
  LATIN_REGEX,
} from '@utils/constants'

export default function Component(props) {
  const { t } = useTranslation([
    'domains',
    'other',
    'vds',
    'payers',
    'autoprolong',
    'auth',
    'domain_contacts',
  ])

  const { closeModal, editData, handleSubmit, isOpen, isCreate } = props

  const initialValues = {
    service_profile_owner: editData?.service_profile_owner || '',

    email: editData?.email || '',
    lastname: editData?.lastname || '',
    lastname_locale: editData?.lastname_locale || '',
    firstname: editData?.firstname || '',
    firstname_locale: editData?.firstname_locale || '',

    location_address: editData?.location_address || '',
    location_city: editData?.location_city || '',
    location_country: editData?.location_country || '',
    location_postcode: editData?.location_postcode || '',
    location_state: editData?.location_state || '',
    middlename: editData?.middlename || '',
    middlename_locale: editData?.middlename_locale || '',
    name: editData?.name || '',
    phone: editData?.phone || '',

    private: editData?.private || '',
    profiletype: editData?.profiletype || '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        EMAIL_SPECIAL_CHARACTERS_REGEX,
        t('warnings.special_characters', { ns: 'auth' }),
      )
      .matches(
        CYRILLIC_ALPHABET_PROHIBITED,
        t('warnings.cyrillic_prohibited', { ns: 'auth' }),
      )
      .required(t('warnings.email_required', { ns: 'auth' })),
    firstname: Yup.string()
      .matches(LATIN_REGEX, t('Name can only contain Latin letters'))
      .required(t('Is a required field', { ns: 'other' })),
    firstname_locale: Yup.string().required(t('Is a required field', { ns: 'other' })),
    lastname: Yup.string()
      .matches(LATIN_REGEX, t('Lastname can only contain Latin letters'))
      .required(t('Is a required field', { ns: 'other' })),
    middlename: Yup.string().matches(
      LATIN_REGEX,
      t('Midlename can only contain Latin letters'),
    ),
    lastname_locale: Yup.string().required(t('Is a required field', { ns: 'other' })),
    phone: Yup.string().required(t('Is a required field', { ns: 'other' })),

    location_country: Yup.string().required(t('Is a required field', { ns: 'other' })),
    location_city: Yup.string().required(t('Is a required field', { ns: 'other' })),
    location_state: Yup.string().required(t('Is a required field', { ns: 'other' })),
    location_address: Yup.string().required(t('Is a required field', { ns: 'other' })),
    location_postcode: Yup.string()
      .matches(INDEX_REGEX, t('Index can contain only'))
      .required(t('Is a required field', { ns: 'other' })),
  })

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} className={s.modal}>
      <Modal.Header>
        {t('domain_contacts.edit.title', { ns: 'domain_contacts' })}
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, setFieldValue, handleBlur }) => {
            return (
              <Form id="edit-domain">
                <div className={s.ownerForm}>
                  <div className={s.formBlock}>
                    <div className={s.formBlockTitle}>1. {t('Main parameters')}</div>
                    {editData?.spusage > 0 && (
                      <div className={s.profileWarn}>
                        {t('This profile is used by one or several services')}
                      </div>
                    )}
                    <div className={s.formFieldsBlock}>
                      <InputField
                        name="name"
                        label={`${t('Profile name')}:`}
                        placeholder={t('Enter data', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.name}
                        touched={!!touched.name}
                        isRequired
                      />
                      <Select
                        placeholder={t('Not chosen', { ns: 'other' })}
                        label={`${t('Contact type')}:`}
                        value={values.profiletype}
                        getElement={item => setFieldValue('profiletype', item)}
                        className={s.select}
                        disabled={!isCreate}
                        itemsList={editData?.profiletype_list?.map(({ $key, $ }) => {
                          return {
                            label: t(`${$.trim()}`, { ns: 'payers' }),
                            value: $key,
                          }
                        })}
                        isColored
                      />
                      <div className={s.useFirstCheck}>
                        <CheckBox
                          value={values.private === 'on'}
                          onClick={() => {
                            setFieldValue(
                              'private',
                              values.private === 'on' ? 'off' : 'on',
                            )
                          }}
                          className={s.checkbox}
                        />
                        <span>{t('Hide data in WHOIS')}</span>
                      </div>
                    </div>
                  </div>

                  <div className={s.formBlock}>
                    <div className={s.formBlockTitle}>
                      2. {t('Contact person details')}
                    </div>
                    <div className={s.formFieldsBlock}>
                      <InputField
                        name="firstname_locale"
                        label={`${t('Name', { ns: 'other' })}:`}
                        placeholder={t('Enter name', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.firstname_locale}
                        touched={!!touched.firstname_locale}
                        isRequired
                      />
                      <InputField
                        name="firstname"
                        label={`${t('Name', { ns: 'other' })} (EN):`}
                        placeholder={t('Enter name', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.firstname}
                        touched={!!touched.firstname}
                        isRequired
                      />
                      <InputField
                        name="lastname_locale"
                        label={`${t('Surname', { ns: 'other' })}:`}
                        placeholder={t('Enter surname', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.lastname_locale}
                        touched={!!touched.lastname_locale}
                        isRequired
                      />
                      <InputField
                        name="lastname"
                        label={`${t('Surname', { ns: 'other' })} (EN):`}
                        placeholder={t('Enter surname', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.lastname}
                        touched={!!touched.lastname}
                        isRequired
                      />
                      <InputField
                        name="middlename_locale"
                        label={`${t('Middle name', { ns: 'other' })}:`}
                        placeholder={t('Enter middle name', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.middlename_locale}
                        touched={!!touched.middlename_locale}
                      />
                      <InputField
                        name="middlename"
                        label={`${t('Middle name', { ns: 'other' })} (EN):`}
                        placeholder={t('Enter middle name', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.middlename}
                        touched={!!touched.middlename}
                      />
                      <InputField
                        type="email"
                        name="email"
                        label={`${t('Email')}:`}
                        placeholder={t('Enter email', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.email}
                        touched={!!touched.email}
                        isRequired
                      />
                      <CustomPhoneInput
                        wrapperClass={s.input}
                        value={values.phone}
                        label={`${t('Phone', { ns: 'other' })}:`}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        name="phone"
                        isRequired
                      />
                    </div>
                  </div>

                  <div className={s.formBlock}>
                    <div className={s.formBlockTitle}>3. {t('Contact address')}</div>
                    <div className={s.formFieldsBlock}>
                      <Select
                        placeholder={t('Not chosen', { ns: 'other' })}
                        label={`${t('The country', { ns: 'other' })}:`}
                        value={values.location_country}
                        getElement={item => setFieldValue('location_country', item)}
                        className={s.select}
                        itemsList={editData?.location_country_list?.map(
                          ({ $key, $, $image }) => ({
                            label: (
                              <div className={s.countrySelectItem}>
                                {$key !== 'null' && (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${$image}`}
                                    alt="flag"
                                  />
                                )}
                                {t(`${$.trim()}`)}
                              </div>
                            ),
                            value: $key,
                          }),
                        )}
                        error={errors?.location_country}
                        isRequired
                        isColored
                      />
                      <InputField
                        name="location_postcode"
                        label={`${t('Index', { ns: 'other' })}:`}
                        placeholder={t('Enter index', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.location_postcode}
                        touched={!!touched.location_postcode}
                        isRequired
                      />
                      <InputField
                        name="location_state"
                        label={`${t('Region', { ns: 'other' })}:`}
                        placeholder={t('Enter region', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.location_state}
                        touched={!!touched.location_state}
                        isRequired
                      />
                      <InputField
                        name="location_city"
                        label={`${t('City', { ns: 'other' })}:`}
                        placeholder={t('Enter city', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.location_city}
                        touched={!!touched.location_city}
                        isRequired
                      />
                      <InputField
                        name="location_address"
                        label={`${t('The address', { ns: 'other' })}:`}
                        placeholder={t('Enter address', { ns: 'other' })}
                        className={s.input}
                        error={!!errors.location_address}
                        touched={!!touched.location_address}
                        isRequired
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={s.saveBtn}
          size="medium"
          label={t('Save', { ns: 'other' })}
          type="submit"
          form="edit-domain"
        />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
