import DepartmentSelect from './DepartmentSelect/DepartmentSelect'
import MessageInput from '../MessageInput/MessageInput'
import {
  Button,
  InputField,
  Modal,
  RSelect,
  ErrorMessageStyled,
  Select,
} from '@components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { supportSelectors, supportOperations } from '@redux'
import * as Yup from 'yup'
import s from './CreateTicketModal.module.scss'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import debounce from 'debounce'
import {
  SUPPORT_DEPARTMENT_CATGORIES,
  CUSTOMER_DEPARTMENT_CATGORIES,
} from '@utils/constants'

const MAX_FILE_SIZE_MB = 10
const FILE_SIZE_LIMIT = MAX_FILE_SIZE_MB * 1024 * 1024

export default function CreateTicketModal(props) {
  const { t } = useTranslation(['support', 'other'])
  const { setCreateTicketModal, getItems } = props
  const dispatch = useDispatch()
  const location = useLocation()

  const departmentsList = useSelector(supportSelectors.getDepartments)
  const supportDepartment = departmentsList?.find(el =>
    el.$plainval.includes('Technical support department'),
  )

  const servicesList = useSelector(supportSelectors.getServices)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  const [serviceInputValue, setServiceInputValue] = useState('')
  const [serviceInputSave, setServiceInputSave] = useState('')
  const [isServiceMenuOpened, setServiceIsMenuOpened] = useState(false)

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(t('Is a required field')),
    subject: Yup.string().required(t('Is a required field')),
    client_department: Yup.string().required(t('Select a department')),
    files: Yup.mixed().test(
      'fileSize',
      t('The size of the collected file should not exceed 10.0 MB'),
      files => {
        if (!files || files.length === 0) return true // If files isn't selected
        return files.every(file => file.size <= FILE_SIZE_LIMIT) // Checking the size of each file
      },
    ),
  })

  const sendMessageHandle = (values, { resetForm }) => {
    if (isSubmitDisabled) return
    const { request_category, subject, ...other } = values
    const requestSubject = `[${request_category.toUpperCase()}] ${subject}`
    const data = {
      subject: requestSubject,
      ...other,
    }

    dispatch(
      supportOperations.createTicket({
        data,
        setCreateTicketModal,
        resetForm,
        successCallback: () => getItems?.({ p_num: 1 }),
      }),
    )
  }
  const getServiceList = ticket_item =>
    dispatch(supportOperations.getServiceList({ ticket_item }))

  useEffect(() => {
    getServiceList(location.state?.id)
  }, [])

  const translatedServicesList = servicesList.map(el => ({
    ...el,
    label: t(el.label),
  }))

  const onInputDebounce = debounce(getServiceList, 300)

  const onChangeInputHandler = useCallback((value, { action }) => {
    setServiceInputValue(value)
    if (action === 'input-change') {
      onInputDebounce(value)
    }
  }, [])

  const filterOption = (option, inputValue) => {
    if (option.value === 'null') {
      return true
    }
    return option.label.toLowerCase().includes(inputValue.toLowerCase())
  }

  const closeModal = () => {
    setCreateTicketModal(false)
    location.state = ''
  }

  return (
    <Modal closeModal={closeModal} isOpen className={s.modal}>
      <Modal.Header>
        <p>{t('New support request')}</p>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            message: '',
            files: [],
            client_department: supportDepartment?.$key,
            ticket_item: location.state?.id ?? 'null',
            subject: '',
            request_category: SUPPORT_DEPARTMENT_CATGORIES[0].value,
          }}
          onSubmit={sendMessageHandle}
        >
          {({ values, setFieldValue, errors, touched }) => {
            let checkItemSize = values?.files.filter(el => el?.size >= 10000000)

            useEffect(() => {
              setIsSubmitDisabled(!!checkItemSize.length)
            }, [checkItemSize.length])

            const requestCategoriesItems =
              values.client_department === supportDepartment?.$key
                ? SUPPORT_DEPARTMENT_CATGORIES
                : CUSTOMER_DEPARTMENT_CATGORIES

            return (
              <Form id="create-ticket" className={s.modal_body}>
                <div className={s.departmentSelect_wrapper}>
                  <div className={s.departmentSelect}>
                    {departmentsList?.map(el => {
                      return (
                        <DepartmentSelect
                          key={el.$key}
                          selected={values?.client_department === el.$key}
                          value={el.$key}
                          title={el.$plainval}
                          description={el.$}
                          setValue={value => {
                            const category =
                              supportDepartment === el
                                ? SUPPORT_DEPARTMENT_CATGORIES[0].value
                                : CUSTOMER_DEPARTMENT_CATGORIES[0].value
                            setFieldValue('request_category', category)
                            setFieldValue('client_department', value)
                          }}
                        />
                      )
                    })}
                  </div>
                  <ErrorMessageStyled
                    className={s.error_department}
                    name={'client_department'}
                    component="span"
                  />
                </div>
                <Select
                  label={t('request_category_label')}
                  value={values.request_category}
                  itemsList={requestCategoriesItems.map(el => ({
                    ...el,
                    label: t(`request_category.${el.label}`),
                  }))}
                  getElement={value => setFieldValue('request_category', value)}
                  isColored
                />
                <RSelect
                  label={`${t('related_service')}:`}
                  placeholder={t('choose_service')}
                  options={translatedServicesList}
                  maxMenuHeight={200}
                  filterOption={filterOption}
                  onChange={({ value }) => {
                    setFieldValue('ticket_item', value)
                  }}
                  onInputChange={onChangeInputHandler}
                  value={translatedServicesList.find(
                    el => el.value === values.ticket_item,
                  )}
                  inputValue={serviceInputValue}
                  onMenuClose={() => {
                    if (isServiceMenuOpened) {
                      setServiceIsMenuOpened(false)
                      setServiceInputSave(serviceInputValue)
                    }
                  }}
                  onMenuOpen={() => {
                    setServiceIsMenuOpened(true)
                    setServiceInputValue(serviceInputSave)
                  }}
                />

                <InputField
                  label={t('Request subject:')}
                  placeholder={t('Enter the subject of your request')}
                  name="subject"
                  error={!!errors.subject}
                  touched={!!touched.subject}
                  autoComplete="off"
                />

                <MessageInput
                  message={values.message}
                  label={`${t('Message')}:`}
                  files={values.files}
                  onChangeFiles={value => setFieldValue('files', value)}
                  error={!!errors.message || !!errors.files}
                  touched={!!touched.message || !!touched.files}
                  filesError={isSubmitDisabled}
                />
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isSubmitDisabled}
          size="large"
          className={s.submit_btn}
          label={t('Send', { ns: 'other' })}
          type="submit"
          form="create-ticket"
        />
      </Modal.Footer>
    </Modal>
  )
}
