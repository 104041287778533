export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.09439 11.9997V8.11564C6.10593 7.35217 6.268 6.59848 6.57132 5.89776C6.87463 5.19703 7.31324 4.56304 7.862 4.03212C8.41076 3.50119 9.05889 3.08377 9.76925 2.80376C10.4796 2.52375 11.2382 2.38666 12.0017 2.40035C12.7651 2.38666 13.5237 2.52375 14.2341 2.80376C14.9445 3.08377 15.5926 3.50119 16.1414 4.03212C16.6901 4.56304 17.1287 5.19703 17.432 5.89776C17.7354 6.59848 17.8974 7.35217 17.909 8.11564V11.9997M14.9553 19.753C15.7387 19.753 16.4899 19.4418 17.0439 18.8879C17.5978 18.334 17.909 17.5827 17.909 16.7993V13.4765M14.9553 19.753C14.9553 20.2426 14.7608 20.7121 14.4146 21.0583C14.0684 21.4045 13.5989 21.599 13.1093 21.599H10.8941C10.4045 21.599 9.93492 21.4045 9.58872 21.0583C9.24253 20.7121 9.04803 20.2426 9.04803 19.753C9.04803 19.2634 9.24253 18.7938 9.58872 18.4476C9.93492 18.1015 10.4045 17.907 10.8941 17.907H13.1093C13.5989 17.907 14.0684 18.1015 14.4146 18.4476C14.7608 18.7938 14.9553 19.2634 14.9553 19.753ZM3.87916 9.78445H5.35598C5.55182 9.78445 5.73964 9.86224 5.87812 10.0007C6.0166 10.1392 6.09439 10.327 6.09439 10.5229V14.9533C6.09439 15.1492 6.0166 15.337 5.87812 15.4755C5.73964 15.6139 5.55182 15.6917 5.35598 15.6917H3.87916C3.48749 15.6917 3.11185 15.5361 2.83489 15.2592C2.55794 14.9822 2.40234 14.6066 2.40234 14.2149V11.2613C2.40234 10.8696 2.55794 10.494 2.83489 10.217C3.11185 9.94004 3.48749 9.78445 3.87916 9.78445ZM20.1242 15.6917H18.6474C18.4515 15.6917 18.2637 15.6139 18.1252 15.4755C17.9868 15.337 17.909 15.1492 17.909 14.9533V10.5229C17.909 10.327 17.9868 10.1392 18.1252 10.0007C18.2637 9.86224 18.4515 9.78445 18.6474 9.78445H20.1242C20.5159 9.78445 20.8915 9.94004 21.1685 10.217C21.4454 10.494 21.601 10.8696 21.601 11.2613V14.2149C21.601 14.6066 21.4454 14.9822 21.1685 15.2592C20.8915 15.5361 20.5159 15.6917 20.1242 15.6917Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.0045 14.0684H12ZM12 11.3548L12.0045 6.83203Z" fill="currentColor" />
      <path
        d="M12.0045 14.0684H12M12 11.3548L12.0045 6.83203"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
