export default function Chart(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33447 6.66718L5.33447 10.6672"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 8V10.6667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.6655 5.33282V10.6662" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="2" y="2.66718" width="12" height="10.6667" rx="1.33333" />
    </svg>
  )
}
