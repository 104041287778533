const MessageChat = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M5.07872 9.35741C5.02692 9.0236 5.00005 8.68163 5.00005 8.33341C5.00005 4.65152 8.00445 1.66675 11.7106 1.66675C15.4167 1.66675 18.4211 4.65152 18.4211 8.33341C18.4211 9.16514 18.2678 9.96129 17.9877 10.6955C17.9295 10.848 17.9005 10.9242 17.8872 10.9838C17.8742 11.0427 17.8691 11.0842 17.8677 11.1446C17.8663 11.2056 17.8745 11.2727 17.8911 11.407L18.2265 14.1322C18.2629 14.4272 18.281 14.5747 18.2319 14.6819C18.1889 14.7759 18.1126 14.8505 18.0177 14.8913C17.9093 14.9379 17.7623 14.9164 17.4682 14.8733L14.8138 14.4842C14.6752 14.4639 14.6059 14.4537 14.5428 14.4541C14.4803 14.4544 14.4371 14.459 14.376 14.4719C14.3143 14.4849 14.2354 14.5144 14.0776 14.5735C13.3415 14.8492 12.5438 15.0001 11.7106 15.0001C11.3621 15.0001 11.0198 14.9737 10.6856 14.9228M6.35973 18.3334C8.83048 18.3334 10.8334 16.2814 10.8334 13.7501C10.8334 11.2188 8.83048 9.16675 6.35973 9.16675C3.88898 9.16675 1.88605 11.2188 1.88605 13.7501C1.88605 14.2589 1.96698 14.7484 2.11637 15.2057C2.17952 15.399 2.2111 15.4957 2.22146 15.5617C2.23228 15.6306 2.23418 15.6693 2.23015 15.739C2.22629 15.8057 2.20959 15.8812 2.1762 16.032L1.66675 18.3334L4.16242 17.9926C4.29864 17.974 4.36675 17.9647 4.42622 17.9651C4.48885 17.9655 4.52209 17.9689 4.5835 17.9811C4.64183 17.9928 4.72855 18.0234 4.90197 18.0846C5.35891 18.2458 5.84934 18.3334 6.35973 18.3334Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MessageChat
