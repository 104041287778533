export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9985 21.002L18.4984 18.502M18.4984 18.502L20.9984 16.002M18.4984 18.502L20.9983 21.002M18.4984 18.502L15.9984 16.002M21 13.002V10.002C21 7.17354 21 5.75931 20.1213 4.88063C19.2426 4.00195 17.8284 4.00195 15 4.00195H9C6.17157 4.00195 4.75736 4.00195 3.87868 4.88063C3 5.75931 3 7.17353 3 10.002V14.002C3 16.8304 3 18.2446 3.87868 19.1233C4.75736 20.002 6.17157 20.002 9 20.002H13M8.00156 4.00195C8.74119 4.00195 9.111 4.00195 9.43648 4.11787C9.70306 4.21281 9.94646 4.36324 10.1506 4.55922C10.3998 4.7985 10.5652 5.12927 10.896 5.79081L11.896 7.79081C12.4339 8.86664 12.7029 9.40456 13.1862 9.70325C13.6695 10.002 14.2709 10.002 15.4737 10.002H18.0016C18.9334 10.002 19.3994 10.002 19.7669 10.1542C20.257 10.3572 20.6463 10.7465 20.8493 11.2366C21.0016 11.6041 21.0016 12.0701 21.0016 13.002"
        stroke="currentColor"
      />
    </svg>
  )
}
