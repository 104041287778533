import PropTypes from 'prop-types'
import ExpensesTableItem from './ExpensesTableItem'
import { useTranslation } from 'react-i18next'
import s from './ExpensesTable.module.scss'
import { useMediaQuery } from 'react-responsive'
import { CardsContainer, Table } from '@components'

const HEAD_CELLS = [
  { label: 'Id', value: 'Id' },
  { label: 'Name', value: 'Name' },
  { label: 'date', value: 'date' },
  { label: 'Sum', value: 'Sum' },
  { label: 'Paid in payments', value: 'Paid in payments' },
  { label: 'Tax', value: 'Tax' },
]

export default function Component(props) {
  const { t } = useTranslation(['billing', 'other'])
  const { list } = props
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label, { ns: 'other' })}</th>
    })

  const renderItems = () => {
    return list?.map(el => {
      const { id, payments, realdate, paymethod_name, amount, locale_name, taxamount } =
        el

      return (
        <ExpensesTableItem
          key={id?.$}
          id={id?.$}
          name={locale_name?.$}
          number={payments?.$}
          date={realdate?.$}
          sum={amount?.$}
          paymethod={paymethod_name?.$}
          tax={taxamount?.$}
          el={el}
        />
      )
    })
  }

  return (
    <>
      {widerThan1024 ? (
        <Table stickyHeader className={s.table}>
          <thead>
            <tr>{renderHeadCells()}</tr>
          </thead>
          <tbody className={s.tbody}>{renderItems()}</tbody>
        </Table>
      ) : (
        <CardsContainer>{renderItems()}</CardsContainer>
      )}
    </>
  )
}

Component.propTypes = {
  list: PropTypes.array,
}

Component.defaultProps = {
  list: [],
}
