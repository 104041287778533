import { useEffect, useRef, useState } from 'react'
import s from './InstancesList.module.scss'
import cn from 'classnames'
import {
  CopyText,
  EditCell,
  TooltipWrapper,
  InstancesOptions,
  AutoprolongIcon,
  Status,
} from '@components'
import * as route from '@src/routes'
import {
  getFlagFromCountryName,
  getInstanceMainInfo,
  formatCountryName,
  cutDcSuffix,
  getImageIconName,
  getItemCostValue,
  useOpenInNewWindow,
  getFormatedDate,
} from '@utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function InstanceItem({ item, editInstance }) {
  const { t } = useTranslation([
    'cloud_vps',
    'vds',
    'countries',
    'filter',
    'dedicated_servers',
    'other',
  ])

  const handleNewWindowNavigate = useOpenInNewWindow()
  const optionsCell = useRef()
  const servernameCell = useRef()
  const ipCell = useRef()
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  const [serverName, setServerName] = useState(item.servername?.$ || '')

  const { isNotActive } = getInstanceMainInfo(item)

  const editServerName = value => {
    const slicedValue = value.slice(0, 100)
    editInstance({
      value: slicedValue,
      elid: item.id.$,
      errorCallback: () => setServerName(serverName),
    })
    setServerName(value)
  }

  useEffect(() => {
    setServerName(item.servername?.$ || '')
  }, [item.servername?.$])

  const itemCountry = formatCountryName(item, t)

  const ipv4 = item?.ip?.$
  const ipv6 = item?.ip_v6?.$

  const osIcon = getImageIconName(item?.os_distro?.$, darkTheme)

  const tooltipContent = () => {
    if (!item?.os_distro?.$ && !item?.os_version?.$) {
      return t('select.detecting', { ns: 'filter' })
    }

    const osDistro = item?.os_distro?.$ ? item.os_distro.$ : ''
    const osVersion = item?.os_version?.$ ? item.os_version.$ : ''

    return `${osDistro} ${osVersion}`.trim()
  }

  const { date: expiredate, time: expiretime } = getFormatedDate({
    date: item.real_expiredate?.$,
    time: item.i_expiretime?.$,
  })
  const { date: createdate, time: createtime } = getFormatedDate({
    date: item.createdate?.$,
    time: item.i_opentime?.$,
  })

  return (
    <tr
      data-disabled={[isNotActive]}
      onClick={e => {
        if (
          optionsCell.current.contains(e.target) ||
          // checkboxCell.current.contains(e.target) ||
          servernameCell.current.contains(e.target) ||
          ipCell.current.contains(e.target) ||
          isNotActive
        )
          return

        handleNewWindowNavigate(e, `${route.CLOUD_VPS}/${item.id.$}`, { state: item })
      }}
      data-stop-propagation
      tabIndex={0}
    >
      <td ref={servernameCell} data-target="name">
        <EditCell
          originName={serverName}
          className={s.edit_name}
          onSubmit={editServerName}
          placeholder={t(serverName || t('server_placeholder', { ns: 'vds' }), {
            ns: 'vds',
          })}
        />

        <span className={cn('text-dark-50', 'body_s', s.item_id)}>ID: {item.id.$}</span>
      </td>
      <td data-wrap>
        <div data-td-value-top>
          <p className={s.tariff_name}>{cutDcSuffix(item.pricelist.$)}</p>
        </div>
        {getItemCostValue(item.cost.$, t)}
      </td>
      <td>
        <Status item={item} />
      </td>
      <td>
        {item?.datacentername && (
          <TooltipWrapper className={s.popup} content={itemCountry?.DCName}>
            <img
              src={require(`@images/countryFlags/${getFlagFromCountryName(
                itemCountry?.countryName,
              )}.png`)}
              width={20}
              height={20}
              alt={itemCountry?.countryName}
            />
          </TooltipWrapper>
        )}
      </td>
      <td>
        {item.createdate?.$ && item.i_opentime?.$ ? (
          <>
            <div data-td-value-top>{createdate}</div>
            <div className="text-dark-50">{createtime}</div>
          </>
        ) : (
          '-'
        )}
      </td>
      <td>
        <div data-td-value-top>{expiredate}</div>
        <div className="in_row align_start">
          <span className="text-dark-50">{expiretime}</span>
          <AutoprolongIcon item={item} />
        </div>
      </td>
      <td>
        {osIcon && (
          <TooltipWrapper
            className={s.popup}
            wrapperClassName={s.popup__wrapper}
            content={tooltipContent()}
          >
            <img
              src={require(`@images/soft_os_icons/${osIcon}.png`)}
              alt={item?.os_distro?.$}
            />
          </TooltipWrapper>
        )}
      </td>
      <td ref={ipCell} data-propagation>
        {ipv4 || ipv6 ? (
          <div>
            {ipv4 && (
              <div data-td-value-top>
                <CopyText
                  text={ipv4}
                  promptTextOnHover={ipv4}
                  promptText={t('ip_address_copied')}
                  label="IPv4"
                />
              </div>
            )}
            {ipv6 && (
              <CopyText
                text={ipv6}
                promptTextOnHover={ipv6}
                promptText={t('ip_address_copied')}
                label="IPv6"
              />
            )}
          </div>
        ) : (
          '-'
        )}
      </td>
      <td ref={optionsCell} data-target="options">
        <InstancesOptions item={item} buttonClassName={s.option_btn} />
      </td>
    </tr>
  )
}
