import { Icon, InputField, Select, NotificationMessage, RadioButton } from '@components'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { CSSTransition } from 'react-transition-group'
import animations from './animations.module.scss'
import s from './ConnectMethod.module.scss'

export default function ConnectMethod({
  connectionType,
  onChangeType,
  setSSHkey,
  setPassword,
  sshList,
  sshKey,
  password,
  errors,
  touched,
  isWindows,
  name,
  hiddenMode,
  isOpened,
  setIsOpened,
}) {
  const { t } = useTranslation(['cloud_vps'])

  const isSSH = connectionType === 'ssh'
  const isPassword = connectionType === 'password'

  return (
    <div>
      {hiddenMode && (
        <button
          className={s.dropdown_btn}
          type="button"
          onClick={() => setIsOpened(prev => !prev)}
        >
          {t('change_access_method')}{' '}
          <Icon className={cn(s.shevron, { [s.active]: isOpened })} name="Shevron" />
        </button>
      )}

      <CSSTransition in={isOpened} classNames={animations} timeout={150} unmountOnExit>
        <div className={s.method_body}>
          {hiddenMode && (
            <NotificationMessage type="warning">
              {t('new_access_method_warning')}
            </NotificationMessage>
          )}
          <div className={s.list} name={name}>
            {!isWindows && (
              <label className={cn(s.item, { [s.selected]: isSSH })} htmlFor="typeSSH">
                <div className={s.item__description}>
                  <RadioButton
                    className={s.radio}
                    labelClassName={s.checkbox}
                    setFieldValue={() => onChangeType('ssh')}
                    selected={isSSH}
                    value={sshKey}
                    id="typeSSH"
                  />
                  <div className={s.item__text_wrapper}>
                    <p className={cn('body_m_medium', s.item__name)}>{t('ssh_key')}</p>
                    <p className={cn('body_s', s.item__text)}>{t('pass_method_ssh')}</p>
                  </div>
                  <div className={s.icon_bg_circle}>
                    <Icon name="NewIconKey" />
                  </div>
                </div>

                {isSSH && (
                  <div className={s.item__field}>
                    <Select
                      name="ssh_keys"
                      itemsList={sshList}
                      placeholder={t('ssh_key')}
                      getElement={setSSHkey}
                      value={sshKey}
                      error={errors.ssh_keys}
                      isColored
                    />

                    {/* <IconButton
                      icon="NewIconAdd"
                      onClick={() => {
                        dispatch(cloudVpsActions.setItemForModals({ ssh_rename: true }))
                      }}
                    /> */}
                  </div>
                )}
              </label>
            )}
            <label
              className={cn(s.item, { [s.selected]: isPassword })}
              htmlFor="typePassword"
            >
              <div className={s.item__description}>
                <RadioButton
                  className={s.radio}
                  setFieldValue={() => onChangeType('password')}
                  selected={isPassword}
                  value={password}
                  id="typePassword"
                />
                <div className={s.item__text_wrapper}>
                  <p className={cn('body_m_medium', s.item__name)}>
                    {t('password', { ns: 'vds' })}
                  </p>
                  <p className={cn('body_s', s.item__text)}>
                    {t('pass_method_password', {
                      user: isWindows ? 'Administrator' : 'root',
                    })}
                  </p>
                </div>
                <div className={s.icon_bg_circle}>
                  <Icon name="NewIconPassword" />
                </div>
              </div>

              {isPassword && (
                <div className={s.item__field}>
                  <InputField
                    name="password"
                    type="password"
                    placeholder={t('new_password_placeholder', { ns: 'vds' })}
                    error={!!errors.password}
                    touched={!!touched.password}
                    isRequired
                    autoComplete="off"
                    onChange={e => setPassword(e.target.value)}
                    generatePasswordValue={value => setPassword(value)}
                  />
                </div>
              )}
            </label>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

ConnectMethod.propTypes = {
  connectionType: PropTypes.string,
  sshKey: PropTypes.string,
  password: PropTypes.string,
  onChangeType: PropTypes.func,
  setSSHkey: PropTypes.func,
  setPassword: PropTypes.func,
  sshList: PropTypes.array,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isWindows: PropTypes.bool,
  name: PropTypes.string,
  hiddenMode: PropTypes.bool,
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
}
