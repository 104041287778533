export default function Filter(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path d="M1 3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V3.41751C19 4.17739 18.7116 4.90895 18.1932 5.46447L13.2379 10.7737C12.8922 11.144 12.7 11.6317 12.7 12.1383V15.682C12.7 16.0607 12.486 16.407 12.1472 16.5764L8.74721 18.2764C8.08231 18.6088 7.3 18.1253 7.3 17.382V12.1383C7.3 11.6317 7.10776 11.144 6.76211 10.7737L1.80684 5.46447C1.28836 4.90895 1 4.17739 1 3.41751V3Z" />
    </svg>
  )
}
