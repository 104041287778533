import { useNavigate } from 'react-router-dom'

export default function useOpenInNewWindow() {
  const navigate = useNavigate()

  const handleNewWindowNavigate = (e, href, params) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(href)
    } else {
      navigate(href, params)
    }
  }
  return handleNewWindowNavigate
}
