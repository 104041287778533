import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Cta } from '@components'
import PropTypes from 'prop-types'

import s from './DeleteModal.module.scss'
import { useDispatch } from 'react-redux'
import { billingOperations } from '@redux/index'

export default function DeleteModal({
  closeModal,
  names,
  itemsId,
  isDeleteLater = false,
  deleteFn,
  isOpen,
  isDedic,
  service,
  closeFn,
}) {
  const { t } = useTranslation(['vds', 'other'])
  const [namesOpened, setNamesOpened] = useState(false)

  const elid = itemsId.join(', ')
  const displayedNames = namesOpened ? names : names.slice(0, 3)
  const remainingCount = names.length - displayedNames.length
  const dispatch = useDispatch()

  const deleteItemsHandler = () => {
    dispatch(
      billingOperations.deleteGroupAutoprolong({
        elid,
        service,
        closeFn,
        successCallback: () => {
          deleteFn()
        },
      }),
    )
  }

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} isClickOutside>
      <Modal.Header>
        {t('delete', { ns: 'other' })} {names.length === 1 && names}
      </Modal.Header>
      <Modal.Body>
        {isDeleteLater && (
          <p className={s.warn}>
            {isDedic ? t('warn_message_dedic') : t('warn_message')}
          </p>
        )}
        {names.length > 1 && (
          <div>
            <p className={s.names_block}>
              {displayedNames.map((name, idx) => (
                <span key={name}>
                  {name}
                  {idx < displayedNames.length - 1 ? ', ' : ''}
                </span>
              ))}
              {!namesOpened && remainingCount > 0 && (
                <span className={s.remaining_count}>
                  {` +${remainingCount} ${t('other_services', { ns: 'other' })}`}
                </span>
              )}
            </p>
            {names.length > 3 && (
              <Cta
                viewType="text"
                view="primary"
                onClick={() => setNamesOpened(!namesOpened)}
                className={'body_m'}
              >
                {namesOpened
                  ? t('collapse', { ns: 'other' })
                  : t('and_more', { ns: 'other', value: remainingCount })}
              </Cta>
            )}
          </div>
        )}
        <p className={s.text}>{t('delete_message')}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Cta
          buttonType="button"
          view="primary"
          onClick={deleteItemsHandler}
          className={'body_m'}
        >
          {t('delete', { ns: 'other' })}
        </Cta>

        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}

DeleteModal.propTypes = {
  names: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteFn: PropTypes.func.isRequired,
}
