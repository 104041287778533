const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={20}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="M20 0H0v20h20V0Z" />
      </mask>
      <g mask="url(#b)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.55 6.992h16.887c.787 1.85.775 4.813-.25 6.55H1.749a8.735 8.735 0 0 1-.2-6.55ZM9.373 1.61a9.076 9.076 0 0 1 8.825 5.3l-16.35.075c-.2-.138.937-1.813 1.1-2.025 1.487-1.875 4.025-3.2 6.425-3.35ZM2.059 13.828l15.937.025c-3.575 6.425-12.512 6.488-15.937-.025ZM9.996 1.621v5.063M9.996 13.535v5.075"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.998 1.797a8.221 8.221 0 0 0-3.1 2.475 7.861 7.861 0 0 0-1.25 2.425M10.133 1.836a8.222 8.222 0 0 1 3.1 2.475c.687.9 1.05 1.787 1.25 2.425M10.133 18.523a8.222 8.222 0 0 0 3.1-2.475 7.861 7.861 0 0 0 1.25-2.425M9.998 18.523a8.222 8.222 0 0 1-3.1-2.475 7.861 7.861 0 0 1-1.25-2.425"
        />
        <path
          fill="currentColor"
          d="m3.145 8.733.288 1.413c.062.35.137.737.187 1.125h.025c.063-.4.175-.788.25-1.125l.363-1.425h.85l.35 1.375c.1.4.187.775.25 1.175h.012c.05-.388.125-.788.2-1.175l.288-1.363h1.05l-1.05 3.438h-1l-.325-1.2c-.088-.338-.15-.65-.225-1.075h-.013c-.062.425-.137.75-.212 1.075l-.35 1.212H3.07l-1-3.437h1.088l-.013-.013ZM8.622 8.733l.287 1.413c.063.35.138.737.188 1.125h.025c.062-.4.175-.788.25-1.125l.362-1.425h.85l.35 1.375c.1.4.188.775.25 1.175h.013c.05-.388.125-.788.2-1.175l.287-1.363h1.05l-1.05 3.438h-1l-.325-1.2c-.087-.338-.15-.65-.225-1.075h-.012c-.063.425-.138.75-.213 1.075l-.35 1.212h-1L7.547 8.746h1.087l-.012-.013ZM14.096 8.733l.288 1.413c.062.35.137.737.187 1.125h.025c.063-.4.175-.788.25-1.125l.363-1.425h.85l.35 1.375c.1.4.187.775.25 1.175h.012c.05-.388.125-.788.2-1.175l.288-1.363h1.05l-1.063 3.438h-1l-.325-1.2c-.087-.338-.15-.65-.225-1.075h-.012c-.063.425-.138.75-.213 1.075l-.35 1.212H14.01l-1.013-3.437h1.088l.012-.013Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
