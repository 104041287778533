import cn from 'classnames'
import s from './BtnLikeRadio.module.scss'

export default function BtnLikeRadio({
  children,
  isActive,
  onClick,
  disabled,
  className,
}) {
  return (
    <button
      className={cn(s.category_btn, 'body_s', {
        [s.selected]: isActive,
        [className]: className,
      })}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
