export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2.5" y="5" width="15" height="12.5" rx="1.66667" stroke="currentColor" />
      <path d="M3.33203 9.16602H16.6654" stroke="currentColor" strokeLinecap="round" />
      <path d="M7.5 13.334H12.5" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M6.66797 2.5L6.66797 5.83333"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path d="M13.332 2.5L13.332 5.83333" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
}
