import cn from 'classnames'
import { getFormatedDate, historyTranslateFn } from '@utils'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import s from '../HistoryTableComponent.module.scss'
import { CopyText } from '@components'

export default function HistoryItem({ history }) {
  const { t } = useTranslation(['vds', 'dedicated_servers', 'cloud_vps'])
  const { date, time } = getFormatedDate({ fullDate: history?.changedate?.$ })
  return (
    <tr>
      <td>
        <div className={s.date}>{date}</div>
        <span className={cn('text-dark-50', 'body_s')}>{time}</span>
      </td>
      <td data-wrap>{historyTranslateFn(history?.desc?.$, t)}</td>
      <td>
        {history?.user?.$.replace(String.fromCharCode(39), '') ===
        'Providers employee or system'
          ? t('Provider employee or system', { ns: 'dedicated_servers' })
          : history?.user?.$}
      </td>
      <td className={cn({ 'text-dark-50': history?.ip?.$?.trim() === '-' })}>
        {history?.ip?.$?.trim() === '-' ? (
          t('Not provided', { ns: 'dedicated_servers' })
        ) : (
          <CopyText
            text={history?.ip?.$}
            promptText={t('ip_address_copied', { ns: 'cloud_vps' })}
            label={history?.ip?.$}
          />
        )}
      </td>
    </tr>
  )
}

HistoryItem.propTypes = {
  server: PropTypes.object,
  setActiveServer: PropTypes.func,
  activeServerID: PropTypes.string,
}
