export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="7.5" stroke="currentColor" />
      <path
        d="M12.6773 9.25399L8.94852 7.38962C8.28362 7.05717 7.5013 7.54067 7.5013 8.28405V11.7146C7.5013 12.458 8.28362 12.9415 8.94852 12.6091L12.6773 10.7447C13.2915 10.4376 13.2915 9.5611 12.6773 9.25399Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
