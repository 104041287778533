import { useTranslation } from 'react-i18next'
import { Icon, Cta } from '@components'
import { useAnalyticsSender } from '@utils'

import * as routes from '@src/routes'
import s from './SuccessPayment.module.scss'
import cn from 'classnames'

export default function Component() {
  const { t } = useTranslation(['billing', 'trusted_users'])

  useAnalyticsSender()

  return (
    <>
      <div className={s.modalBg} />
      <div className={s.modalBlock}>
        <div className={s.modalTopBlock}>
          <Icon name="SuccessPay" className={s.successIcon} />
          <p className={cn('headings_h1', s.approved)}>{t('Payment approved')}</p>
          <div className={cn('body_m', s.completed)}>
            {t('Payment was completed successfully')}
          </div>
        </div>

        <div className={s.linksBlock}>
          <Cta element="navlink" viewType="button" to={routes.BILLING}>
            {t('trusted_users.rights_alert.dashboard', { ns: 'trusted_users' })}
          </Cta>
        </div>
      </div>
    </>
  )
}
