import { useState } from 'react'
import { Field } from 'formik'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { ErrorMessageStyled, Icon, TooltipWrapper } from '@components'
import { generatePassword } from '@utils'
import s from './InputField.module.scss'

/**
 * @param {Object} props
 * @param {'text'|'email'|'number'|'password'|'time'} props.type
 * @param {'on'|'off'|'new-password'} props.autocomplete
 */
export default function InputField(props) {
  const {
    label,
    labelIconTooltip,
    labelTooltipPlace,
    iconRight,
    error = false,
    touched = false,
    type,
    placeholder,
    name,
    className,
    autoComplete,
    disabled,
    inputWrapperClass,
    inputClassName,
    onKeyDown,
    isRequired,
    onIconClick,
    infoText,
    generatePasswordValue,
    inputIconTooltip,
    useFormik,
    ...anotherProps
  } = props

  const [passShown, setPassShown] = useState(false)

  const WrapWithButton = ({ children, onIconClick }) => {
    return onIconClick ? (
      <button
        type="button"
        onClick={onIconClick}
        className={cn({
          [s.field_icon_right]: !inputIconTooltip,
          [s.interactive_icon]: !inputIconTooltip,
        })}
      >
        {children}
      </button>
    ) : (
      children
    )
  }

  const WrapWithTooltip = ({ children }) => {
    return inputIconTooltip ? (
      <TooltipWrapper
        content={inputIconTooltip}
        events={{ click: true }}
        wrapperClassName={cn(s.field_icon_right, s.interactive_icon)}
        className={s.hint}
        delayShow={0}
      >
        {children}
      </TooltipWrapper>
    ) : (
      children
    )
  }

  const renderIcon = () => {
    if (type === 'password') {
      return (
        <>
          {generatePasswordValue && (
            <button
              className={s.generate_pass_btn}
              type="button"
              onClick={generatePasswordHandler}
            >
              <Icon name="Cube" />
            </button>
          )}
          <button
            className={s.pass_show_btn}
            type="button"
            onClick={() => setPassShown(!passShown)}
          >
            {passShown ? <Icon name="NewIconEye" /> : <Icon name="NewIconEyeHide" />}
          </button>
        </>
      )
    } else if (type !== 'password' && iconRight) {
      return (
        <WrapWithTooltip>
          <WrapWithButton onIconClick={onIconClick}>
            <Icon
              name={iconRight}
              className={cn({ [s.field_icon_right]: !onIconClick && !inputIconTooltip })}
            />
          </WrapWithButton>
        </WrapWithTooltip>
      )
    }
  }

  const generatePasswordHandler = () =>
    generatePasswordValue(
      generatePassword({
        length: 15,
        includeLowerCase: true,
        includeNumber: true,
        includeUpperCase: true,
        includeSymbols: true,
      }),
    )

  const requiredLabel = labelName => {
    return (
      <>
        {labelName} <span className={s.required_star}>*</span>
      </>
    )
  }

  return (
    <div className={cn(s.field_wrapper, { [className]: className })}>
      {label && (
        <label htmlFor={name} className="input_label">
          {isRequired ? requiredLabel(label) : label}
          {labelIconTooltip && (
            <TooltipWrapper
              content={labelIconTooltip}
              wrapperClassName={s.label__tooltip}
              className={s.hint}
              place={labelTooltipPlace}
            >
              <Icon name="NewInfoIcon" />
            </TooltipWrapper>
          )}
        </label>
      )}
      <div className={cn(s.input_wrapper, inputWrapperClass)}>
        {useFormik ? (
          <Field
            disabled={disabled}
            className={cn('input_base_styles', s.input, {
              [s.icon_right]: iconRight || type === 'password' || infoText,
              [s.error]: error && touched,
              [s.disabled]: disabled,
              [s.number]: type === 'number',
              [inputClassName]: inputClassName,
              [s.icon_generate]: !!generatePasswordValue,
              [s.tooltip_btn_right]: inputIconTooltip,
            })}
            id={name}
            name={name}
            type={passShown ? 'text' : type}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            {...anotherProps}
          />
        ) : (
          <input
            disabled={disabled}
            className={cn('input_base_styles', s.input, {
              [s.icon_right]: iconRight || type === 'password' || infoText,
              [s.error]: error && touched,
              [s.disabled]: disabled,
              [s.number]: type === 'number',
              [inputClassName]: inputClassName,
              [s.icon_generate]: !!generatePasswordValue,
              [s.tooltip_btn_right]: inputIconTooltip,
            })}
            id={name}
            name={name}
            type={passShown ? 'text' : type}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            {...anotherProps}
          />
        )}

        {renderIcon()}

        {infoText && (
          <TooltipWrapper
            content={infoText}
            className={s.hint}
            wrapperClassName={s.info_btn}
            place="top-end"
          >
            <button type="button">
              <Icon name="NewInfoIcon" />
            </button>
          </TooltipWrapper>
        )}
      </div>
      {useFormik && <ErrorMessageStyled name={name} />}
    </div>
  )
}

InputField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconRight: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'number', 'password', 'time']),
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  autoComplete: PropTypes.oneOf(['on', 'off', 'new-password']),
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  inputWrapperClass: PropTypes.string,
  inputClassName: PropTypes.string,
  onKeyDown: PropTypes.func,
  onIconClick: PropTypes.func,
  labelIconTooltip: PropTypes.string,
  labelTooltipPlace: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
}

InputField.defaultProps = {
  type: 'text',
  autoComplete: 'off',
  disabled: false,
  isRequired: false,
  onKeyDown: () => null,
  onIconClick: () => null,
  useFormik: true,
}
