import PropTypes from 'prop-types'
import PaymentsMethodsTableItem from './PaymentsMethodsTableItem'
import { useTranslation } from 'react-i18next'
import s from './PaymentsMethodsTable.module.scss'
import { useMediaQuery } from 'react-responsive'
import { CardsContainer, Table } from '@components/index'

const HEAD_CELLS = [
  { label: 'Name', value: 'Name' },
  { label: 'Payment method', value: 'Payment method' },
  {
    label: 'Number of auto-renewable services',
    value: 'Number of auto-renewable services',
  },
  { label: 'status', value: 'status' },
]

export default function PaymentsMethodsTable(props) {
  const { list, reconfigHandler, deleteItemHandler, editItemNameHandler } = props
  const { t } = useTranslation(['billing', 'other'])
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label, { ns: 'other' })}</th>
    })

  const renderItems = () => {
    return list?.map(el => {
      const {
        name,
        fullname,
        num_prolong_items,
        status_msg,
        paymethod_name,
        recurring,
        customname,
      } = el

      return (
        <PaymentsMethodsTableItem
          key={recurring?.$}
          id={recurring?.$}
          customname={customname?.$}
          name={name?.$}
          fullname={fullname?.$}
          num_prolong_items={num_prolong_items?.$}
          status={status_msg?.$}
          paymethod_name={paymethod_name?.$}
          reconfigHandler={reconfigHandler}
          deleteItemHandler={deleteItemHandler}
          editItemNameHandler={editItemNameHandler}
        />
      )
    })
  }

  return (
    <>
      <>
        {widerThan1024 ? (
          <Table stickyHeader className={s.table}>
            <thead>
              <tr>
                {renderHeadCells()}
                <th></th>
              </tr>
            </thead>
            <tbody className={s.tbody}>{renderItems()}</tbody>
          </Table>
        ) : (
          <CardsContainer>{renderItems()}</CardsContainer>
        )}
      </>
    </>
  )
}

PaymentsMethodsTable.propTypes = {
  list: PropTypes.array,
  setSelctedPayment: PropTypes.func,
  selctedPayment: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object]),
}

PaymentsMethodsTable.defaultProps = {
  list: [],
}
