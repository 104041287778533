import SupportTableItem from './SupportTableItem'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import s from './SupportTable.module.scss'
import { CardsContainer, CheckBox, Table } from '@components'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'

const HEAD_CELLS = [
  { label: 'initiator', value: 'create_user' },
  { label: 'subject', value: 'theme' },
  { label: 'created_at', value: 'date_start' },
  { label: 'last_message', value: 'last_message' },
  { label: 'dialogue_status', value: 'status' },
]
export default function Component(props) {
  const { t } = useTranslation(['support', 'other'])
  const { list, setSelectedTickets, selectedTickets } = props
  const mobile = useMediaQuery({ query: '(max-width: 1023px)' })

  const isAllSelected = list.length === selectedTickets.length

  const toggleAllSelectedHandler = () => {
    isAllSelected ? setSelectedTickets([]) : setSelectedTickets(list)
  }

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label, { ns: 'support' })}</th>
    })

  const renderItems = () => {
    return list?.map(el => {
      const { tstatus, last_message, name, id, unread } = el
      const isSelected = selectedTickets.find(ticket => ticket?.id?.$ === id?.$)

      const onItemClick = () => {
        isSelected
          ? setSelectedTickets(list => list.filter(item => item !== el))
          : setSelectedTickets([...selectedTickets, el])
      }

      return (
        <SupportTableItem
          key={id?.$}
          el={el}
          theme={name?.$}
          date={last_message?.$}
          status={tstatus?.$}
          id={id?.$}
          unread={unread?.$ === 'on'}
          setSelectedTickets={onItemClick}
          selected={!!isSelected}
        />
      )
    })
  }

  return (
    <>
      <>
        {!mobile ? (
          <Table stickyHeader className={s.table} isItemsClickable vAlignTop>
            <thead>
              <tr>
                <th>
                  <CheckBox value={isAllSelected} onClick={toggleAllSelectedHandler} />
                </th>
                {renderHeadCells()}
              </tr>
            </thead>
            <tbody className={s.tbody}>{renderItems()}</tbody>
          </Table>
        ) : (
          <>
            <label className={cn('in_row', s.check_all)}>
              <CheckBox value={isAllSelected} onClick={toggleAllSelectedHandler} />
              {t('Choose all', { ns: 'other' })}
            </label>
            <CardsContainer className={s.mobile_list}>{renderItems()}</CardsContainer>
          </>
        )}
      </>
    </>
  )
}

Component.propTypes = {
  list: PropTypes.array,
  setSelectedTickets: PropTypes.func,
  selectedTickets: PropTypes.array,
}

Component.defaultProps = {
  list: [],
}
