const SvgComponent = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M17.7189 9.52923L19.8442 10.6789L20.2891 10.9289C21.237 11.4614 21.237 12.8344 20.2891 13.367L14.2525 16.7586C12.8524 17.5451 11.1476 17.5451 9.74754 16.7586L3.71092 13.367C2.76302 12.8344 2.76303 11.4614 3.71093 10.9289L4.15585 10.6789L6.16334 9.52923M18.1811 14.648L20.1129 15.6205C21.1092 16.122 21.1412 17.5417 20.1685 18.0882L14.2525 21.412C12.8524 22.1986 11.1476 22.1986 9.74754 21.412L3.9178 18.1367C2.9284 17.5808 2.98291 16.1297 4.0112 15.6507L6.16334 14.648M14.2525 11.8131L20.2891 8.42156C21.237 7.889 21.237 6.516 20.2891 5.98344L14.2525 2.59188C12.8524 1.80531 11.1476 1.80531 9.74754 2.59188L3.71093 5.98344C2.76303 6.516 2.76302 7.889 3.71092 8.42156L9.74754 11.8131C11.1476 12.5997 12.8524 12.5997 14.2525 11.8131Z"
      stroke="currentColor"
    />
  </svg>
)
export default SvgComponent
