export default function Edit(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9653 6.32496L4.97027 12.32C4.96044 12.3299 4.95061 12.3396 4.94082 12.3494C4.8078 12.4821 4.67993 12.6096 4.5896 12.7691C4.49927 12.9286 4.45572 13.1039 4.41041 13.2862C4.40708 13.2996 4.40373 13.3131 4.40036 13.3266L3.84742 15.5384C3.84541 15.5464 3.84338 15.5545 3.84133 15.5627C3.80915 15.6911 3.77276 15.8363 3.76067 15.9598C3.74721 16.0974 3.74845 16.3468 3.94952 16.5479L4.30175 16.1956L3.94952 16.5479C4.15059 16.7489 4.39996 16.7502 4.53756 16.7367C4.66111 16.7246 4.80633 16.6882 4.93473 16.6561C4.9429 16.654 4.95101 16.652 4.95904 16.65L7.17081 16.097C7.1843 16.0937 7.19776 16.0903 7.21118 16.087C7.39349 16.0417 7.56874 15.9981 7.72829 15.9078C7.88783 15.8175 8.01534 15.6896 8.14799 15.5566C8.15775 15.5468 8.16754 15.537 8.17738 15.5271L14.1724 9.53206L14.1948 9.50969C14.4534 9.25113 14.682 9.02262 14.8414 8.81365C15.0142 8.58714 15.1522 8.32587 15.1522 8C15.1522 7.67413 15.0142 7.41286 14.8414 7.18634C14.682 6.97737 14.4534 6.74886 14.1948 6.49029L14.1724 6.46793L14.0295 6.32496L14.0071 6.30259C13.7485 6.04398 13.52 5.81544 13.3111 5.656C13.0845 5.48318 12.8233 5.34518 12.4974 5.34518C12.1715 5.34518 11.9103 5.48318 11.6837 5.656C11.4748 5.81544 11.2463 6.04399 10.9877 6.3026L10.9653 6.32496Z"
        stroke="currentColor"
      />
      <path
        d="M10.4141 6.75065L12.9141 5.08398L15.4141 7.58398L13.7474 10.084L10.4141 6.75065Z"
        fill="currentColor"
      />
    </svg>
  )
}
