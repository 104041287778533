export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.9961 15L9.99609 19L13.9961 23" stroke="currentColor" />
      <path
        d="M5.93391 15.498C5.14085 14.1244 4.8378 12.5222 5.07442 10.9538C5.31105 9.38539 6.07331 7.94386 7.23633 6.86534C8.39936 5.78682 9.89419 5.13526 11.4759 5.0174C13.0577 4.89954 14.6326 5.32236 15.9426 6.21661C17.2526 7.11085 18.2201 8.42349 18.6866 9.93949C19.153 11.4555 19.0908 13.085 18.51 14.561C17.9293 16.037 16.8645 17.272 15.4901 18.0637C14.1157 18.8554 12.5131 19.1569 10.945 18.9187"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
