export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.8797 16.8773H11.5997M6.32031 16.8773L9.48444 14.6625C10.085 14.2421 10.085 13.3526 9.48444 12.9322L6.32031 10.7173M20.4035 7.19766H2.80352M7.20078 20.3979H16.0008C18.4308 20.3979 20.4008 18.4279 20.4008 15.9979V7.19785C20.4008 4.7678 18.4308 2.79785 16.0008 2.79785H7.20078C4.77073 2.79785 2.80078 4.7678 2.80078 7.19785V15.9979C2.80078 18.4279 4.77073 20.3979 7.20078 20.3979Z"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
