export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0013 18.3327C14.6037 18.3327 18.3346 14.6017 18.3346 9.99935C18.3346 5.39698 14.6037 1.66602 10.0013 1.66602C5.39893 1.66602 1.66797 5.39698 1.66797 9.99935C1.66797 14.6017 5.39893 18.3327 10.0013 18.3327ZM9.16797 6.66602C9.16797 6.20578 9.54106 5.83268 10.0013 5.83268C10.4615 5.83268 10.8346 6.20578 10.8346 6.66602C10.8346 7.12625 10.4615 7.49935 10.0013 7.49935C9.54106 7.49935 9.16797 7.12625 9.16797 6.66602ZM9.16797 9.99935C9.16797 9.53911 9.54106 9.16602 10.0013 9.16602C10.4615 9.16602 10.8346 9.53911 10.8346 9.99935V13.3327C10.8346 13.7929 10.4615 14.166 10.0013 14.166C9.54106 14.166 9.16797 13.7929 9.16797 13.3327V9.99935Z"
        fill="currentColor"
      />
    </svg>
  )
}
