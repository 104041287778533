import { Icon, TooltipWrapper } from '@components'
import PropTypes from 'prop-types'
import { getInstanceMainInfo } from '@utils'
import { useTranslation } from 'react-i18next'
import s from './Status.module.scss'
import cn from 'classnames'

export default function Status({ item, size }) {
  const { t } = useTranslation(['cloud_vps', 'vds', 'countries', 'filter'])

  const {
    isResized,
    displayStatus,
    isDeleting,
    isSuspended,
    isExpired,
    isActivationAfterExpired,
  } = getInstanceMainInfo(item)

  const isHintStatus =
    (isSuspended && !isExpired && !isActivationAfterExpired) || isResized
  const hintMessage = isResized ? t('resize_popup_text') : t('by_admin')

  const isBigStatus = size === 'big'

  const statusClassName = cn(s.status, s[displayStatus], {
    [s.status_big]: isBigStatus,
  })

  return (
    <>
      {isHintStatus && !isDeleting ? (
        <TooltipWrapper
          className={s.popup}
          wrapperClassName={s.popup__wrapper}
          content={hintMessage}
        >
          <span className={statusClassName}>
            {displayStatus}
            <Icon name="Attention" />
          </span>
        </TooltipWrapper>
      ) : (
        <span className={statusClassName}>{displayStatus}</span>
      )}
    </>
  )
}

Status.propTypes = {
  item: PropTypes.shape({
    instance_status: PropTypes.shape({
      $: PropTypes.string,
    }),
    item_status: PropTypes.shape({
      $: PropTypes.string,
    }),
    status: PropTypes.shape({
      $: PropTypes.string,
    }),
    autosuspend: PropTypes.shape({
      $: PropTypes.string,
    }),
    os_distro: PropTypes.shape({
      $: PropTypes.string,
    }),
    servername: PropTypes.shape({
      $: PropTypes.string,
    }),
    name: PropTypes.shape({
      $: PropTypes.string,
    }),
  }),
  // size: PropTypes.oneOfType(['default', 'big']),
}

Status.defaultProps = {
  size: 'default',
}
