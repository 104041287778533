import { createAction } from '@reduxjs/toolkit'

const changeTheme = createAction('CHANGE_THEME')

const showLoader = createAction('SHOW_LOADER')
const hideLoader = createAction('HIDE_LOADER')

const disableScrolling = createAction('DISABLE_SCROLLING')
const enableScrolling = createAction('ENABLE_SCROLLING')

const setOnline = createAction('SET_ONLINE')
const setOffline = createAction('SET_OFFLINE')

const showBlockingModal = createAction('SHOW_BLOCKING_MODAL')
const hideBlockingModal = createAction('HIDE_BLOCKING_MODAL')

const setPromotionsList = createAction('SET_PROMOTIONS_LIST')

const setHasActiveDedic = createAction('SET_HAS_ACTIVE_DEDIC')
const setBillmgrIsLocked = createAction('SET_BILLMGR_IS_LOCKED')
const setLockTime = createAction('SET_LOCK_TIME')

export default {
  changeTheme,
  showLoader,
  hideLoader,
  disableScrolling,
  enableScrolling,
  setOnline,
  setOffline,
  showBlockingModal,
  hideBlockingModal,
  setPromotionsList,
  setHasActiveDedic,
  setBillmgrIsLocked,
  setLockTime,
}
