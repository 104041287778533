export default function SvgComponent(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="14" height="14" rx="7" fill="#FF9314" />
      <path d="M7.00438 10H7H7.00438ZM7 7.375L7.00438 3L7 7.375Z" fill="#fff" />
      <path
        d="M7.00438 10H7M7 7.375L7.00438 3"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
