import * as routes from '@src/routes'

export default function getServicePath(billingPath) {
  switch (billingPath) {
    case 'instances':
      return routes?.CLOUD_VPS
    case 'dedic':
      return routes?.DEDICATED_SERVERS
    case 'domain':
      return routes?.DOMAINS
    case 'storage':
      return routes?.FTP
    case 'forexbox':
      return routes?.FOREX
    case 'image':
      return routes?.CLOUD_VPS_IMAGES
    case 'vhost':
      return routes?.SHARED_HOSTING
    case 'dnshost':
      return routes?.DNS
    case 'vds':
      return routes?.VPS

    default:
      return routes?.SERVICES
  }
}
