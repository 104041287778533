import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import s from './DNSMobileItem.module.scss'
import {
  CheckBox,
  ServerState,
  Options,
  CardWrapper,
  AutoprolongIcon,
  TooltipWrapper,
} from '@components'

import { dedicOperations } from '@redux'
import { useDispatch } from 'react-redux'
import {
  useCreateTicketOption,
  isUnpaidOrder,
  getFormatedDate,
  formatCountryName,
  getFlagFromCountryName,
} from '@utils'
import cn from 'classnames'

export default function DNSMobileItem({
  storage,
  setElidForEditModal,
  setElForProlongModal,
  setElidForHistoryModal,
  setElidForInstructionModal,
  pageRights,
  activeServices,
  setActiveServices,
  unpaidItems,
}) {
  const { t } = useTranslation(['vds', 'other', 'dns', 'crumbs', 'countries'])

  const dispatch = useDispatch()

  const handleToolBtnClick = fn => {
    fn()
  }

  const unpaidOption = isUnpaidOrder(storage, unpaidItems)
  const createTicketOption = useCreateTicketOption(storage.id.$)

  const isToolsBtnVisible =
    Object.keys(pageRights)?.filter(
      key => key !== 'ask' && key !== 'filter' && key !== 'new',
    ).length > 0

  const isActive = activeServices?.some(service => service?.id?.$ === storage?.id?.$)

  const toggleIsActiveHandler = () => {
    isActive
      ? setActiveServices(activeServices?.filter(item => item?.id?.$ !== storage?.id?.$))
      : setActiveServices([...activeServices, storage])
  }

  const options = [
    unpaidOption,
    {
      label: t('instruction'),
      icon: 'Instruction',
      disabled: storage?.status?.$ === '1' || !pageRights?.instruction,
      onClick: () => handleToolBtnClick(setElidForInstructionModal),
    },
    {
      label: t('go_to_panel'),
      icon: 'ToPanel',
      disabled:
        storage.transition?.$ !== 'on' ||
        !pageRights?.gotoserver ||
        storage?.status?.$ !== '2',
      onClick: () => dispatch(dedicOperations.goToPanel(storage.id.$)),
    },
    {
      label: t('prolong'),
      icon: 'Renew',
      disabled:
        storage?.status?.$ === '1' || storage?.status?.$ === '5' || !pageRights?.prolong,
      onClick: () => handleToolBtnClick(setElForProlongModal),
    },
    {
      label: t('edit', { ns: 'other' }),
      icon: 'NewIconEdit',
      disabled: !pageRights?.edit || storage?.status?.$ === '1',
      onClick: () => handleToolBtnClick(setElidForEditModal),
    },
    {
      label: t('history'),
      icon: 'HistoryTimer',
      disabled: !pageRights?.history || storage?.status?.$ === '1',
      onClick: () => handleToolBtnClick(setElidForHistoryModal),
    },
    createTicketOption,
  ]

  const { date: expiredate } = getFormatedDate({
    date: storage?.expiredate?.$,
    time: storage?.i_expiretime?.$,
  })
  const { date: createdate } = getFormatedDate({
    date: storage?.createdate?.$,
    time: storage?.i_opentime?.$,
  })
  const itemCountry = formatCountryName(storage, t)

  return (
    <CardWrapper className={s.item}>
      {isToolsBtnVisible && (
        <div className={s.tools_wrapper}>
          <CheckBox value={isActive} onClick={toggleIsActiveHandler} />
          <Options options={options} useModalOnMobile />
        </div>
      )}

      <span className={s.label}>Id:</span>
      <span className={s.value}>{storage?.id?.$}</span>
      <span className={s.label}>{t('pricing_plan')}:</span>
      <span className={s.value}>
        {storage?.pricelist?.$.replace('for', t('for', { ns: 'dns' }))
          .replace('domains', t('domains', { ns: 'dns' }))
          .replace('DNS-hosting', t('dns', { ns: 'crumbs' }))}
      </span>
      <span className={s.label}>{t('status', { ns: 'other' })}:</span>
      <ServerState className={s.value} server={storage} />

      <span className={s.label}>{t('datacenter')}:</span>
      <span className={s.value}>
        {' '}
        {storage?.datacentername?.$ ? (
          <TooltipWrapper className={s.popup} content={itemCountry?.DCName}>
            <img
              src={require(`@images/countryFlags/${getFlagFromCountryName(
                itemCountry?.countryName,
              )}.png`)}
              width={20}
              height={20}
              alt={itemCountry?.countryName}
            />
          </TooltipWrapper>
        ) : (
          '-'
        )}
      </span>
      <span className={s.label}>{t('created')}:</span>
      <span className={s.value}>{createdate}</span>
      <span className={s.label}>{t('valid_until')}:</span>
      <span className={cn(s.value)}>
        <span className="in_row">
          {expiredate} <AutoprolongIcon item={storage} />
        </span>
        <ServerState className={s.value} server={storage} onlyAutoDelete />
      </span>

      <span className={s.label}>{t('Price', { ns: 'domains' })}:</span>
      <span className={s.value}>
        {storage?.cost?.$.replace('Month', t('short_month', { ns: 'other' }))}
      </span>
    </CardWrapper>
  )
}

DNSMobileItem.propTypes = {
  storage: PropTypes.object,
  setElidForEditModal: PropTypes.func,
  setElForProlongModal: PropTypes.func,
  setElidForHistoryModal: PropTypes.func,
  setElidForInstructionModal: PropTypes.func,
  setActiveServices: PropTypes.func,
  activeServices: PropTypes.arrayOf(PropTypes.object),
  pageRights: PropTypes.object,
}
