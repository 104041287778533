export default function SvgComponent(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.2498 8.28667V36C34.2498 37.7949 32.7947 39.25 30.9998 39.25H9C7.20507 39.25 5.75 37.7949 5.75 36V4C5.75 2.20508 7.20508 0.75 9 0.75H26.0445L34.2498 8.28667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <mask id="path-2-inside-1_3068_47147" fill="currentColor">
        <path d="M25 1H33.5V9.5H26C25.4477 9.5 25 9.05229 25 8.5V1Z" />
      </mask>
      <path
        d="M25 1H33.5H25ZM33.5 11H26C24.6193 11 23.5 9.88071 23.5 8.5L26.5 8H33.5V11ZM26 11C24.6193 11 23.5 9.88071 23.5 8.5V1H26.5V8L26 11ZM33.5 1V9.5V1Z"
        fill="currentColor"
        mask="url(#path-2-inside-1_3068_47147)"
      />
      <rect x="1" y="20" width="25" height="13" rx="2" fill="#2A1C3C" />
      <path
        d="M5.70793 28.68H7.94793V30H3.99793V22.98H5.70793V28.68ZM12.0836 30.07C11.4236 30.07 10.8169 29.9167 10.2636 29.61C9.71689 29.3033 9.28022 28.8767 8.95355 28.33C8.63355 27.7767 8.47355 27.1567 8.47355 26.47C8.47355 25.7833 8.63355 25.1667 8.95355 24.62C9.28022 24.0733 9.71689 23.6467 10.2636 23.34C10.8169 23.0333 11.4236 22.88 12.0836 22.88C12.7436 22.88 13.3469 23.0333 13.8936 23.34C14.4469 23.6467 14.8802 24.0733 15.1936 24.62C15.5136 25.1667 15.6736 25.7833 15.6736 26.47C15.6736 27.1567 15.5136 27.7767 15.1936 28.33C14.8736 28.8767 14.4402 29.3033 13.8936 29.61C13.3469 29.9167 12.7436 30.07 12.0836 30.07ZM12.0836 28.51C12.6436 28.51 13.0902 28.3233 13.4236 27.95C13.7636 27.5767 13.9336 27.0833 13.9336 26.47C13.9336 25.85 13.7636 25.3567 13.4236 24.99C13.0902 24.6167 12.6436 24.43 12.0836 24.43C11.5169 24.43 11.0636 24.6133 10.7236 24.98C10.3902 25.3467 10.2236 25.8433 10.2236 26.47C10.2236 27.09 10.3902 27.5867 10.7236 27.96C11.0636 28.3267 11.5169 28.51 12.0836 28.51ZM21.2849 25.2C21.1582 24.9667 20.9749 24.79 20.7349 24.67C20.5015 24.5433 20.2249 24.48 19.9049 24.48C19.3515 24.48 18.9082 24.6633 18.5749 25.03C18.2415 25.39 18.0749 25.8733 18.0749 26.48C18.0749 27.1267 18.2482 27.6333 18.5949 28C18.9482 28.36 19.4315 28.54 20.0449 28.54C20.4649 28.54 20.8182 28.4333 21.1049 28.22C21.3982 28.0067 21.6115 27.7 21.7449 27.3H19.5749V26.04H23.2949V27.63C23.1682 28.0567 22.9515 28.4533 22.6449 28.82C22.3449 29.1867 21.9615 29.4833 21.4949 29.71C21.0282 29.9367 20.5015 30.05 19.9149 30.05C19.2215 30.05 18.6015 29.9 18.0549 29.6C17.5149 29.2933 17.0915 28.87 16.7849 28.33C16.4849 27.79 16.3349 27.1733 16.3349 26.48C16.3349 25.7867 16.4849 25.17 16.7849 24.63C17.0915 24.0833 17.5149 23.66 18.0549 23.36C18.5949 23.0533 19.2115 22.9 19.9049 22.9C20.7449 22.9 21.4515 23.1033 22.0249 23.51C22.6049 23.9167 22.9882 24.48 23.1749 25.2H21.2849Z"
        fill="#fff"
      />
    </svg>
  )
}
