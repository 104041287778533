export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.34184 6.30082C5.03274 7.60992 4.21804 9.33244 4.03658 11.1749C3.85512 13.0173 4.3181 14.8657 5.34666 16.405C6.37521 17.9444 7.9057 19.0794 9.67733 19.6169C11.449 20.1543 13.3521 20.0608 15.0626 19.3523C16.773 18.6438 18.1848 17.3642 19.0576 15.7314C19.9303 14.0987 20.2099 12.2139 19.8487 10.3981C19.4875 8.5823 18.5079 6.94793 17.0768 5.77345C15.6457 4.59896 13.8516 3.95703 12.0002 3.95703"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M11.9999 11.9589L7.83203 7.79102"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path d="M12 4.03906V6.12301" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M19.502 11.959L17.8348 11.959"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path d="M12 17.793V19.4601" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M6.16504 11.959L4.49788 11.959"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
