import { useState } from 'react'
import cn from 'classnames'
import s from './PaymentMethodComponents.module.scss'

import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { Cta } from '@components'

export default function PaymentInfo({
  selectedPayMethod,
  parsedText,
  readMoreThresholds,
}) {
  const { t } = useTranslation(['billing', 'cart'])

  const [showMore, setShowMore] = useState(false)

  const showLessThan170 = useMediaQuery({ query: '(min-width: 892px)' })
  const showLessThan106 = useMediaQuery({ query: '(min-width: 505px)' })

  const readMore = parsedText?.infoText
    ? showLessThan170
      ? parsedText?.minAmount?.length + parsedText?.infoText?.length >
        readMoreThresholds.minWidth170
      : parsedText?.minAmount?.length + parsedText?.infoText?.length >
        readMoreThresholds.default
    : showLessThan106
    ? parsedText?.minAmount?.length > readMoreThresholds.minWidth106
    : parsedText?.minAmount?.length > readMoreThresholds.default

  return parsedText ? (
    <div className={s.infoText_wrapper}>
      <div className={cn('body_s', s.infotext, { [s.showMore]: showMore })}>
        {selectedPayMethod && (
          <>
            <span>
              {t(`${parsedText?.minAmount?.trim()}`, { ns: 'cart' })}
              {parsedText?.commission && (
                <strong>
                  {t(`Commission ${parsedText?.commission}`, { ns: 'cart' })}
                </strong>
              )}
            </span>
            {parsedText?.infoText && (
              <p>{t(`${parsedText?.infoText?.trim()}`, { ns: 'cart' })}</p>
            )}
          </>
        )}
      </div>

      {selectedPayMethod && readMore && (
        <Cta
          buttonType="button"
          viewType="text"
          view="secondary"
          onClick={() => setShowMore(!showMore)}
          className={'body_s_m'}
        >
          {t(showMore ? 'Collapse' : 'Read more')}
        </Cta>
      )}
    </div>
  ) : null
}
