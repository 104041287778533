import { useEffect, useState } from 'react'
import cn from 'classnames'
import { CheckBox, Icon, Cta } from '@components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as route from '@src/routes'
import s from './DomainsPickUpZones.module.scss'
import { roundToDecimal } from '@utils'

export default function ServicesPage(props) {
  const { t } = useTranslation(['domains', 'other', 'vds'])

  const {
    domains,
    selectedDomains,
    setSelectedDomains,
    selected,
    registerDomainHandler,
    transfer,
    siteDomainCheckData,
    billUnavailableDomains,
    setSelectedPricelist,
    domainNameInput,
  } = props

  const [domainsList, setDomainsList] = useState(null)

  const [domain, setDomain] = useState('')

  useEffect(() => {
    if (domainNameInput) {
      setDomain(domainNameInput)
      setSelectedDomains([])
    }
  }, [domains])

  const navigate = useNavigate()

  useEffect(() => {
    if (!transfer) {
      const suggested = []
      let allResults = []
      domains &&
        Object.entries(domains).forEach(d => {
          allResults.push(d)
          if (
            selected?.includes(d[1]?.info?.billing_id) &&
            d[1]?.info?.is_available &&
            !billUnavailableDomains.includes(d[0])
          ) {
            setIsSelectedHandler(d)
            suggested.push(d)
          }
        })
      setDomainsList({ suggested, allResults })

      /* If we have domain zones from the site - set them to selected */
      if (siteDomainCheckData.length > 0) {
        allResults = [...siteDomainCheckData]

        /* Adding transferredFromSite to Domains List to monitor if data setted from site */
        setDomainsList({ suggested, allResults, transferredFromSite: true })

        setSelectedDomains(siteDomainCheckData)
      }
    } else {
      setDomainsList(domains)
    }
  }, [domains])

  const itemIsSelected = item =>
    selectedDomains.some(
      dom => dom[0] === item[0] && !billUnavailableDomains.includes(item[0]),
    )

  const setIsSelectedHandler = item => {
    const isDomainNameSelected = selectedDomains.some(dom => dom[0] === item[0])

    let newSelectedDomains
    if (!isDomainNameSelected) {
      // Adding element to selectedDomains
      setSelectedDomains(prevState => [...prevState, item])
      newSelectedDomains = [...selectedDomains, item]
    } else {
      // Removing element from selectedDomains
      const newArray = selectedDomains.filter(domain => domain[0] !== item[0])
      setSelectedDomains(newArray)
      newSelectedDomains = newArray
    }

    // Updating the state for selectedPricelist based on the new selectedDomains
    const newPricelist = newSelectedDomains.map(domain => domain[1].info.billing_id)
    setSelectedPricelist(newPricelist)
  }

  /* Calculation sums of selected domain zones */
  const calculateSumOfSelected = () => {
    return selectedDomains?.reduce((sum, domain) => {
      const price = domain?.transferredFromSite ? domain : domain[1]?.info?.price
      const calculatedPrice = sum + (price?.reg ? price.reg : price?.main_price_reg)

      return +calculatedPrice.toFixed(2)
    }, 0)
  }

  const renderDomains = d => {
    /* Destructuring domainName and domainInfo depending on the object structure */
    const domainName = domainsList?.transferredFromSite ? Object.keys(d)[0] : d?.[0]
    const domainInfo = domainsList?.transferredFromSite ? Object.values(d)[0] : d?.[1]

    const { info, tld } = domainInfo
    const { billing_id, is_available, price } = info
    const { reg, main_price_reg, renew, main_price_renew } = price

    const notAvailable =
      is_available === false ||
      tld === 'invalid' ||
      billUnavailableDomains.includes(domainName)

    const selected = itemIsSelected(d)

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyDown={null}
        key={`${domainName}-${billing_id}`}
        onClick={() => !notAvailable && setIsSelectedHandler(d)}
        className={cn(s.domainItem, 'box_styles', {
          [s.selected]: selected,
          [s.notAvailable]: notAvailable,
        })}
      >
        {!notAvailable && <CheckBox className={s.checkbox} value={selected} />}

        <div
          className={cn(s.domainName, {
            [s.selected]: selected,
            [s.notAvailable]: notAvailable,
          })}
        >
          {domainName?.replace(domain, '')}
        </div>

        <div className={s.pricesBlock}>
          {notAvailable ? (
            <div
              className={cn(s.selectBtn, {
                [s.notAvailable]: notAvailable,
              })}
            >
              {t('not_available')}
            </div>
          ) : (
            <>
              <div className={s.domainPrice}>€ {reg > 0 ? reg : main_price_reg}</div>
              <div className={cn(s.saleEur, s.ordering)}>€ {reg && main_price_reg}</div>
            </>
          )}
        </div>

        {!notAvailable && main_price_renew && (
          <div className={s.prolongBlock}>
            <span>{t('prolong', { ns: 'vds' })}:</span>
            <span>
              € {renew ? roundToDecimal(renew) : roundToDecimal(main_price_renew)}
            </span>
          </div>
        )}
      </div>
    )
  }

  const renderDomainTransferAll = domainItem => {
    const [
      domain,
      {
        info: {
          is_available,
          billing_id,
          price: { reg, main_price_reg, renew, main_price_renew },
        },
      },
    ] = domainItem

    const salePercent = Math.floor(reg === 0 ? 0 : 100 - (100 * reg) / main_price_reg)

    const selected = itemIsSelected(domainItem)

    return (
      <div
        key={`${domain}-${billing_id}`}
        role="button"
        tabIndex={0}
        onKeyUp={() => {}}
        className={cn(s.domainItem, 'box_styles', {
          [s.selected]: selected,
          [s.notAvailable]: is_available,
        })}
        onClick={() => !is_available && setIsSelectedHandler(domainItem)}
      >
        {!is_available && <CheckBox value={selected} className={s.checkbox} />}
        <div
          className={cn(s.domainName, {
            [s.selected]: selected,
            [s.notAvailable]: is_available,
          })}
        >
          {domain}
        </div>

        <div className={s.pricesBlock}>
          {is_available ? (
            <div
              className={cn(s.selectBtn, {
                [s.notAvailable]: is_available,
              })}
            >
              {!is_available ? t('Registered') : t('Not registered')}
            </div>
          ) : (
            <>
              <div className={s.domainPrice}>€ {reg ? reg : main_price_reg}</div>
              {salePercent > 0 && (
                <div className={s.saleEur}>€ {reg && main_price_reg}</div>
              )}
            </>
          )}
        </div>
        {!is_available && main_price_renew && (
          <div className={s.prolongBlock}>
            <span>{t('prolong', { ns: 'vds' })}:</span>
            <span>
              € {renew ? roundToDecimal(renew) : roundToDecimal(main_price_renew)}
            </span>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={s.domainsZone}>
      {transfer ? (
        <>
          {Array.isArray(domainsList) && domainsList?.length > 0 && (
            <h2 className={s.domainsZoneTitle}>
              {t('Domain zones')}{' '}
              <span className={s.priceDescr}>
                ({t(transfer ? 'for the transfer' : 'price_per_year', { ns: 'other' })})
              </span>
            </h2>
          )}
          {domainsList ? (
            <div className={s.domainsBlock}>
              {Object?.entries(domainsList)?.map(domainItem =>
                renderDomainTransferAll(domainItem),
              )}
            </div>
          ) : null}
        </>
      ) : (
        domainsList?.allResults.length > 0 && (
          <>
            <h2 className={s.domainsZoneTitle}>
              {t('All results')} {domain}{' '}
              <span className={s.priceDescr}>
                ({t(transfer ? 'for the transfer' : 'price_per_year', { ns: 'other' })})
              </span>
            </h2>
            <div className={s.domainsBlock}>
              {domainsList?.allResults?.map(d => renderDomains(d))}
            </div>
          </>
        )
      )}

      <div className={s.selectedDomainsBlock}>
        <div className={s.sum}>
          {t('Total price')}: <span>€ {calculateSumOfSelected()}</span>
        </div>
        {!!selectedDomains?.length && (
          <div className={s.selectedDomains}>
            {selectedDomains?.map(e => {
              const domainName = domainsList?.transferredFromSite
                ? Object.keys(e)[0]
                : e?.[0]
              const domainInfo = domainsList?.transferredFromSite
                ? Object.values(e)[0]
                : e?.[1]

              const {
                info: { billing_id, price },
              } = domainInfo
              const { reg, main_price_reg } = price

              return (
                <div className={s.selectedItem} key={`${domainName}_${billing_id}`}>
                  <div className={s.selectedDomainName}>{domainName}</div>
                  <div className={s.pricesBlock}>
                    <div className={s.domainPrice}>€ {reg ? reg : main_price_reg}</div>
                  </div>
                  <Icon
                    name="Delete"
                    onClick={() => setIsSelectedHandler(e)}
                    className={s.cross}
                  />
                </div>
              )
            })}
          </div>
        )}
        <div className={s.btnBlock}>
          <Cta
            buttonType="button"
            view="primary"
            onClick={registerDomainHandler}
            className={'body_l_regular'}
            disabled={selectedDomains.length === 0}
          >
            {t(transfer ? 'Transfer' : 'Register')}
          </Cta>

          <Cta
            buttonType="button"
            view="secondary"
            onClick={() =>
              navigate(route.DOMAINS, {
                replace: true,
              })
            }
            className={'body_l_regular'}
          >
            {t('Cancel', { ns: 'other' })}
          </Cta>
        </div>
      </div>
    </div>
  )
}
