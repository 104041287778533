// hooks/useIsTouchDevice.js
import { useState, useEffect } from 'react'

export default function useIsTouchDevice() {
  const [isTouchDevice, setIsTouchDevice] = useState(false)

  const checkIsTouchDevice = () => {
    const userAgent = navigator.userAgent
    const isTouch =
      'ontouchstart' in window || // сенсорні події підтримуються
      navigator.maxTouchPoints > 0 || // пристрій з багатоточковим сенсором
      navigator.msMaxTouchPoints > 0 || // сенсорний пристрій Microsoft
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile Safari|CriOS/i.test(
        userAgent,
      ) // перевірка на мобільний userAgent

    setIsTouchDevice(isTouch)
  }

  useEffect(() => {
    checkIsTouchDevice()
    window.addEventListener('resize', checkIsTouchDevice)
    window.addEventListener('orientationchange', checkIsTouchDevice)
    return () => {
      window.removeEventListener('resize', checkIsTouchDevice)
      window.removeEventListener('orientationchange', checkIsTouchDevice)
    }
  }, [])

  return isTouchDevice
}
