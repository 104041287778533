export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3329 9.34632L17.7715 9.10641L17.3329 9.34632ZM17.3329 10.6538L17.7715 10.8937L17.3329 10.6538ZM2.66715 9.3462L3.10583 9.58611L2.66715 9.3462ZM2.66714 10.6537L2.22845 10.8936H2.22845L2.66714 10.6537ZM16.1824 7.01372C15.9847 6.82094 15.6681 6.82494 15.4753 7.02265C15.2826 7.22037 15.2866 7.53692 15.4843 7.7297L16.1824 7.01372ZM8.04085 14.2541C7.77336 14.1855 7.50092 14.3468 7.43233 14.6143C7.36375 14.8817 7.52499 15.1542 7.79248 15.2228L8.04085 14.2541ZM12.17 5.28428L12.0406 5.76725L12.17 5.28428ZM5.89387 13.9241L5.64794 14.3594L6.18194 13.5154L5.89387 13.9241ZM3.10583 9.58611C4.43769 7.1508 7.02582 5.5 9.99997 5.5V4.5C6.64644 4.5 3.72898 6.36258 2.22847 9.10629L3.10583 9.58611ZM16.8942 10.4139C15.5623 12.8492 12.9742 14.5 10 14.5V15.5C13.3536 15.5 16.271 13.6374 17.7715 10.8937L16.8942 10.4139ZM16.8942 9.58622C17.0353 9.84424 17.0353 10.1559 16.8942 10.4139L17.7715 10.8937C18.0762 10.3367 18.0762 9.66342 17.7715 9.10641L16.8942 9.58622ZM2.22847 9.10629C1.92385 9.66329 1.92384 10.3366 2.22845 10.8936L3.10583 10.4138C2.96472 10.1558 2.96472 9.84413 3.10583 9.58611L2.22847 9.10629ZM15.4843 7.7297C16.0416 8.27316 16.5181 8.89859 16.8942 9.58622L17.7715 9.10641C17.3474 8.3308 16.8103 7.62593 16.1824 7.01372L15.4843 7.7297ZM10 14.5C9.32293 14.5 8.66657 14.4145 8.04085 14.2541L7.79248 15.2228C8.49867 15.4038 9.23847 15.5 10 15.5V14.5ZM9.99997 5.5C10.7065 5.5 11.3905 5.59305 12.0406 5.76725L12.2994 4.80132C11.5656 4.6047 10.7947 4.5 9.99997 4.5V5.5ZM4.85981 12.5865C4.15308 11.9746 3.55713 11.239 3.10583 10.4138L2.22845 10.8936C2.73748 11.8244 3.40918 12.6533 4.20529 13.3425L4.85981 12.5865ZM6.1398 13.4887C5.68274 13.2305 5.2541 12.9278 4.85981 12.5865L4.20529 13.3425C4.64951 13.7271 5.13259 14.0683 5.64794 14.3594L6.1398 13.4887ZM4.24448 13.3732L5.6058 14.3328L6.18194 13.5154L4.82062 12.5558L4.24448 13.3732ZM12.0406 5.76725C12.6478 5.92996 13.2261 6.16361 13.7659 6.45882L14.2457 5.58146C13.637 5.24855 12.9846 4.98493 12.2994 4.80132L12.0406 5.76725Z"
        fill="#2A1C3C"
      />
      <path
        d="M16.2188 3.7207L3.71875 16.2207"
        stroke="#2A1C3C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 10C7.5 8.61929 8.61929 7.5 10 7.5"
        stroke="#2A1C3C"
        strokeLinecap="round"
      />
    </svg>
  )
}
