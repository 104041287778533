export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="11.9961" cy="12.002" r="8.5" stroke="currentColor" />
      <path
        d="M16.5 12.002H12.2C12.0895 12.002 12 11.9124 12 11.802V8.50195"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
