export default function Time_fill(props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5ZM8.66667 4.83203C8.66667 4.46384 8.36819 4.16536 8 4.16536C7.63181 4.16536 7.33333 4.46384 7.33333 4.83203V8.33203C7.33333 8.79227 7.70643 9.16536 8.16667 9.16536H10.3333C10.7015 9.16536 11 8.86689 11 8.4987C11 8.13051 10.7015 7.83203 10.3333 7.83203H8.66667V4.83203Z"
        fill="currentColor"
      />
    </svg>
  )
}
