import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import s from './ForexMobileItem.module.scss'
import cn from 'classnames'
import {
  CheckBox,
  ServerState,
  Options,
  CardWrapper,
  AutoprolongIcon,
  TooltipWrapper,
} from '@components'
import {
  useCreateTicketOption,
  isUnpaidOrder,
  getFormatedDate,
  getFlagFromCountryName,
  formatCountryName,
} from '@src/utils'

export default function ForexMobileItem({
  server,
  setElidForEditModal,
  setElForProlongModal,
  setElidForHistoryModal,
  setElidForDeletionModal,
  setElidForInstructionModal,
  activeServices,
  setActiveServices,
  pageRights,
  unpaidItems,
}) {
  const { t } = useTranslation(['vds', 'other', 'dns', 'crumbs', 'countries'])

  const handleToolBtnClick = fn => {
    fn()
  }

  const unpaidOption = isUnpaidOrder(server, unpaidItems)
  const createTicketOption = useCreateTicketOption(server.id.$)

  const isToolsBtnVisible =
    Object.keys(pageRights)?.filter(
      key => key !== 'ask' && key !== 'filter' && key !== 'new',
    ).length > 0

  const isActive = activeServices?.some(service => service?.id?.$ === server?.id?.$)
  const toggleIsActiveHandler = () => {
    isActive
      ? setActiveServices(activeServices?.filter(item => item?.id?.$ !== server?.id?.$))
      : setActiveServices([...activeServices, server])
  }

  const options = [
    unpaidOption,
    {
      label: t('instruction'),
      icon: 'Instruction',
      disabled: server?.status?.$ === '1' || !pageRights?.instruction,
      onClick: () => handleToolBtnClick(setElidForInstructionModal),
    },
    {
      label: t('prolong'),
      icon: 'Renew',
      disabled:
        server?.status?.$ === '1' || server?.status?.$ === '5' || !pageRights?.prolong,
      onClick: () => handleToolBtnClick(setElForProlongModal),
    },
    {
      label: t('edit', { ns: 'other' }),
      icon: 'NewIconEdit',
      disabled:
        !pageRights?.edit || server?.status?.$ === '1' || server?.status?.$ === '5',
      onClick: () => handleToolBtnClick(setElidForEditModal),
    },
    {
      label: t('history'),
      icon: 'HistoryTimer',
      disabled: !pageRights?.history || server?.status?.$ === '1',
      onClick: () => handleToolBtnClick(setElidForHistoryModal),
    },
    createTicketOption,
    {
      label: t('delete', { ns: 'other' }),
      icon: 'Delete',
      disabled: !server.id.$ || !pageRights?.delete || server?.status?.$ === '5',
      onClick: () => handleToolBtnClick(setElidForDeletionModal),
      isDelete: true,
    },
  ]

  const { date: expiredate } = getFormatedDate({
    date: server?.expiredate?.$,
    time: server?.i_expiretime?.$,
  })
  const { date: createdate } = getFormatedDate({
    date: server?.createdate?.$,
    time: server?.i_opentime?.$,
  })

  const itemCountry = formatCountryName(server, t)

  return (
    <CardWrapper className={s.item}>
      {isToolsBtnVisible && (
        <div className={s.tools_wrapper}>
          <div className={s.headerName_wrapper}>
            <CheckBox value={isActive} onClick={toggleIsActiveHandler} />
            <span className={s.headerName}>{server?.server_name?.$}</span>
          </div>
          <Options options={options} useModalOnMobile />
        </div>
      )}

      <span className={s.label}>ID:</span>
      <span className={s.value}>{server?.id?.$}</span>
      <span className={s.label}>{t('pricing_plan')}:</span>
      <span className={s.value}>
        {server?.pricelist?.$.replace('for', t('for', { ns: 'dns' }))
          .replace('domains', t('domains', { ns: 'dns' }))
          .replace('DNS-hosting', t('dns', { ns: 'crumbs' }))}
      </span>
      <span className={s.label}>{t('status', { ns: 'other' })}:</span>
      <ServerState className={s.value} server={server} />
      <span className={s.label}>{t('data_center', { ns: 'vds' })}:</span>
      <span className={s.value}>
        {' '}
        {server?.datacentername && (
          <TooltipWrapper className={s.popup} content={itemCountry?.DCName}>
            <img
              src={require(`@images/countryFlags/${getFlagFromCountryName(
                itemCountry?.countryName,
              )}.png`)}
              width={20}
              height={20}
              alt={itemCountry?.countryName}
            />
          </TooltipWrapper>
        )}
      </span>
      <span className={s.label}>{t('created')}:</span>
      <span className={s.value}>{createdate}</span>
      <span className={s.label}>{t('valid_until')}:</span>
      <span className={cn(s.value)}>
        <span className="in_row">
          {expiredate} <AutoprolongIcon item={server} />
        </span>
        <ServerState className={s.value} server={server} onlyAutoDelete />
      </span>

      <span className={s.label}>{t('Price', { ns: 'domains' })}:</span>
      <span className={s.value}>
        {server?.cost?.$.replace('Month', t('short_month', { ns: 'other' }))}
      </span>
    </CardWrapper>
  )
}

ForexMobileItem.propTypes = {
  server: PropTypes.object,
  setElidForEditModal: PropTypes.func,
  setElForProlongModal: PropTypes.func,
  setElidForHistoryModal: PropTypes.func,
  setElidForInstructionModal: PropTypes.func,
  setElidForDeletionModal: PropTypes.func,
  setActiveServices: PropTypes.func,
  activeServices: PropTypes.arrayOf(PropTypes.object),
  pageRights: PropTypes.object,
}
