export default function Copy(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path d="M11.6693 6.33398V6.33398C11.6693 5.55742 11.6693 5.16913 11.5424 4.86285C11.3732 4.45447 11.0488 4.13001 10.6404 3.96085C10.3341 3.83398 9.94584 3.83398 9.16927 3.83398H6.66927C5.09792 3.83398 4.31225 3.83398 3.82409 4.32214C3.33594 4.8103 3.33594 5.59597 3.33594 7.16732V9.66732C3.33594 10.4439 3.33594 10.8322 3.4628 11.1385C3.63196 11.5468 3.95642 11.8713 4.3648 12.0405C4.67108 12.1673 5.05937 12.1673 5.83594 12.1673V12.1673" />
      <rect x="8.33594" y="8.83398" width="8.33333" height="8.33333" rx="1.66667" />
    </svg>
  )
}
