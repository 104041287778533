import s from './ImagesList.module.scss'
import PropTypes from 'prop-types'
import { getImageInfo } from '@utils'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { CardWrapper, IconButton } from '@components'
import { Fragment } from 'react'

export default function ImageMobileItem({
  item,
  cells,
  itemOnClickHandler,
  idKey,
  isItemClickable,
}) {
  const nameField = cells.find(cell => cell.label === 'name')
  const optionsField = cells.find(cell => cell.label === 'options')

  const { isDeleting } = getImageInfo(item)
  const { t } = useTranslation(['cloud_vps'])

  return (
    <CardWrapper className={cn(s.mobile_item, { [s.disabled]: isDeleting })}>
      <div className={s.mobile_item__header}>
        <div className={s.mobile_item__header_name}>
          {nameField.renderData?.(item[nameField.value]?.$, item) ??
            item[nameField.value]?.$}
        </div>
        <div className="in_row">
          {isItemClickable && !isDeleting && (
            <IconButton
              color="third"
              size="small"
              icon="NewIconArrowUpRight"
              onClick={e => itemOnClickHandler(e, item)}
              data-stop-propagation
            />
          )}

          {optionsField?.renderData(undefined, item)}
        </div>
      </div>

      <CardWrapper.Grid className={s.mobile_item__body}>
        {cells.map(cell => {
          if (cell.label === 'name' || cell.label === 'options') return null
          return (
            <Fragment key={`item_m_${item?.[idKey].$}${cell.label}`}>
              <div>{t(cell?.label)}</div>

              <div>
                {cell.renderData?.(item[cell.value]?.$, item) ?? item[cell.value]?.$}
              </div>
            </Fragment>
          )
        })}
      </CardWrapper.Grid>
    </CardWrapper>
  )
}

ImageMobileItem.propTypes = {
  item: PropTypes.object,
  cells: PropTypes.array,
  itemOnClickHandler: PropTypes.func,
  idKey: PropTypes.string,
}
