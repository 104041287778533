import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getFormatedDate, historyTranslateFn } from '@utils'

import { CardWrapper } from '@components/index'
import s from './HistoryModal.module.scss'

export default function HistoryMobileItem({ history }) {
  const { t } = useTranslation(['vds', 'other', 'dedicated_servers'])

  const { fullDateString } = getFormatedDate({ fullDate: history?.changedate?.$ })

  return (
    <CardWrapper className={s.item}>
      <CardWrapper.Grid className={s.grid}>
        <span>{t('date', { ns: 'other' })}:</span>
        <span>{fullDateString}</span>
        <span>{t('Changing', { ns: 'other' })}:</span>
        <span>{historyTranslateFn(history?.desc?.$, t)}</span>

        <span>{t('user_name')}:</span>
        <span>
          {history?.user?.$?.replace(String.fromCharCode(39), '') ===
          'Providers employee or system'
            ? t('Provider employee or system', { ns: 'dedicated_servers' })
            : history?.user?.$}
          :
        </span>
        <span>{t('ip_address')}:</span>
        <span>
          {history?.ip?.$?.trim() === '-'
            ? t('Not provided', { ns: 'dedicated_servers' })
            : history?.ip?.$}
        </span>
      </CardWrapper.Grid>
    </CardWrapper>
  )
}

HistoryMobileItem.propTypes = {
  server: PropTypes.object,
  setElidForEditModal: PropTypes.func,
}
