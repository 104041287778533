import HistoryList from './HistoryList/HistoryList'

import s from './HistoryTableComponent.module.scss'

export default function HistoryTableComponent({ historyList }) {
  return (
    <div className={s.container}>
      <HistoryList historyList={historyList} />
    </div>
  )
}
