const SvgComponent = props => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 16.6693C9.03553 16.6693 8.125 16.6693 8.125 16.6693C5.47335 16.6693 4.14752 16.6693 3.32376 15.8325C2.5 14.9956 2.5 13.6488 2.5 10.955V9.05023C2.5 6.35649 2.5 5.00962 3.32376 4.17278C4.14752 3.33594 5.47335 3.33594 8.125 3.33594H11.875C14.5267 3.33594 15.8525 3.33594 16.6762 4.17278C17.5 5.00962 17.5 6.35649 17.5 9.05023V13"
      strokeLinecap="round"
    />
    <path d="M5.83594 6.66406H8.33594" strokeLinecap="round" />
    <path d="M15.8333 12.3333H14.1667C13.381 12.3333 12.9882 12.3333 12.7441 12.0892C12.5 11.8451 12.5 11.4523 12.5 10.6666C12.5 9.8809 12.5 9.4881 12.7441 9.244C12.9882 9 13.381 9 14.1667 9H15.8333C16.619 9 17.0118 9 17.2559 9.244C17.5 9.4881 17.5 9.8809 17.5 10.6666C17.5 11.4523 17.5 11.8451 17.2559 12.0892C17.0118 12.3333 16.619 12.3333 15.8333 12.3333Z" />
    <path
      d="M18.3841 17L17.5373 16L16.5659 17M17.5373 17C17.5373 18.1046 16.6075 19 15.4607 19C14.8911 19 14.3752 18.7792 14 18.4216"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9988 16L12.8455 17L13.8169 16M12.8455 16C12.8455 14.8954 13.7753 14 14.9221 14C15.4917 14 16.0076 14.2208 16.3828 14.5784"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
