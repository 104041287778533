import { Link, matchPath, NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { Icon, Portal } from '@components'
import * as routes from '@src/routes'
import { useRightsContext } from '@src/context/RightsContext'
import { useRef, useState } from 'react'

import s from './AsideServicesMenu.module.scss'
import { useOutsideAlerter } from '@utils/index'

const AsideServicesMenu = () => {
  const tabletOrHigher = useMediaQuery({ query: '(min-width: 1024px)' })
  const location = useLocation()

  const { t } = useTranslation('container')

  const { categories_rights, menuLists } = useRightsContext()

  const NAV_ITEMS = [
    {
      allowedToRender: categories_rights.areServicesAllowed,
      icon: 'NewIconServices',
      text: 'aside_menu.services',
      categoryName: 'services',
    },
    {
      allowedToRender: categories_rights.isFinanceAllowed,
      icon: 'NewIconFinance',
      text: 'aside_menu.finance_and_docs',
      categoryName: 'billing',
    },
    {
      allowedToRender: categories_rights.isAffiliateProgramAllowed,
      icon: 'NewIconReferralProgram',
      text: 'aside_menu.referral_program',
      categoryName: 'affiliate',
    },
    {
      allowedToRender: categories_rights.isSupportAllowed,
      icon: 'NewIconSupport',
      text: 'aside_menu.support',
      categoryName: 'support',
    },
  ]

  const [currentRightBlock, setAciveRightBlock] = useState(NAV_ITEMS[0])
  const [isRightBlockShown, setIsRightBlockShown] = useState(false)
  const [asideMenuHovered, setAsideMenuHovered] = useState(false)

  const menu = useRef(null)
  const collapseMenu = e => {
    e?.stopPropagation()
    menu.current.style.pointerEvents = 'none'

    setAsideMenuHovered(false)
    setIsRightBlockShown(false)

    setTimeout(() => {
      menu.current.style.pointerEvents = 'initial'
    }, 200)
  }

  useOutsideAlerter(menu, asideMenuHovered, collapseMenu)

  const renderMenuList = list =>
    list.map(elem => (
      <NavLink
        className={({ isActive }) => cn(s.link, { [s.active_link]: isActive })}
        key={elem.name}
        to={elem.routeName}
        onClick={collapseMenu}
      >
        <div className={s.active_page_circle}>
          <Icon className={s.icon_block} name={elem.iconName} />
        </div>
        <span className="body_s">{t(elem.name)}</span>
      </NavLink>
    ))

  const leaveHoverArea = () => {
    setIsRightBlockShown(false)
    setAsideMenuHovered(false)
    setAciveRightBlock(null)
  }

  const openSideMenu = item => {
    setAciveRightBlock(item)
    setAsideMenuHovered(true)
    setIsRightBlockShown(true)
  }

  return (
    tabletOrHigher && (
      <div
        className={cn(s.aside_menu_area, { [s.hover]: asideMenuHovered })}
        onMouseEnter={() => {
          setAsideMenuHovered(true)
        }}
        onMouseLeave={leaveHoverArea}
        ref={menu}
      >
        <nav
          className={cn('body_s', s.aside_menu_container, {
            [s.right_block_shown]: isRightBlockShown,
          })}
        >
          <div className={cn(s.left_block)}>
            <Link className={s.logo_wrapper} to={routes.HOME}>
              <img
                className={s.short_logo}
                width="48px"
                height="64px"
                src={require('@images/short_logo.png')}
                alt="logo"
              />

              <img
                className={s.logo}
                width="106px"
                height="31px"
                src={require('@images/new_logo.png')}
                alt="logo"
              />
            </Link>

            <div className={s.list_wrapper}>
              <NavLink
                to={routes.HOME}
                className={({ isActive }) =>
                  cn(s.item, s.item_btn, {
                    [s.active_category]: isActive,
                    [s.no_hover]: !asideMenuHovered,
                  })
                }
                onClick={collapseMenu}
              >
                <div className={s.item_inner}>
                  <div className={s.circle}>
                    <Icon name="NewIconHome" className={s.img} />
                  </div>
                  <p className={s.text}>{t('Dashboard')}</p>
                  <span style={{ width: '6px' }}></span>
                </div>
              </NavLink>

              <ul
                className={s.list}
                onMouseLeave={() => {
                  setIsRightBlockShown(false)
                }}
              >
                {NAV_ITEMS.map(item => {
                  return (
                    item.allowedToRender && (
                      <li
                        className={cn(s.item, {
                          [s.hovered]:
                            item.categoryName === currentRightBlock?.categoryName &&
                            isRightBlockShown,
                          [s.active_category]: matchPath(
                            { end: false, path: item.categoryName },
                            location.pathname,
                          ),
                        })}
                        key={item.categoryName}
                      >
                        <div
                          tabIndex={0}
                          role="button"
                          onMouseEnter={() => {
                            openSideMenu(item)
                          }}
                          onClick={e => {
                            if (e.currentTarget === e.target) {
                              openSideMenu(item)
                            }
                          }}
                          onKeyUp={e => {
                            if (e.key === 'Enter') {
                              openSideMenu(item)
                            }
                          }}
                        >
                          <div className={s.item_inner}>
                            <div className={s.circle}>
                              <Icon
                                name={item.icon}
                                className={cn(s.img, {
                                  [s.support]: item.icon === 'NewIconSupport',
                                })}
                              />
                            </div>
                            <p className={s.text}>{t(item.text)}</p>
                            <Icon className={cn(s.shevron)} name="Shevron" />
                          </div>

                          {currentRightBlock?.categoryName === 'services' &&
                            item.categoryName === 'services' && (
                              <div className={s.right_block_content}>
                                {Boolean(menuLists.myServicesMenuList?.length) && (
                                  <div className={s.box}>
                                    <p className={cn('body_m_medium', s.block_top_line)}>
                                      {t('burger_menu.my_services.my_services')}
                                    </p>

                                    <ul className={s.block_list}>
                                      {renderMenuList(menuLists.myServicesMenuList)}
                                    </ul>
                                  </div>
                                )}

                                {Boolean(menuLists.otherServicesMenuList?.length) && (
                                  <div className={s.box}>
                                    <p className={cn('body_m_medium', s.block_top_line)}>
                                      {t('other_services')}
                                    </p>

                                    <ul className={s.block_list}>
                                      {renderMenuList(menuLists.otherServicesMenuList)}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            )}

                          {currentRightBlock?.categoryName === 'billing' &&
                            item.categoryName === 'billing' && (
                              <div className={s.right_block_content}>
                                <ul className={s.block_list}>
                                  {renderMenuList(menuLists.financeMenuList)}
                                </ul>
                              </div>
                            )}
                          {currentRightBlock?.categoryName === 'affiliate' &&
                            item.categoryName === 'affiliate' && (
                              <div className={s.right_block_content}>
                                <ul className={s.block_list}>
                                  {renderMenuList(menuLists.refProgramMenuList)}
                                </ul>
                              </div>
                            )}
                          {currentRightBlock?.categoryName === 'support' &&
                            item.categoryName === 'support' && (
                              <div className={s.right_block_content}>
                                <ul className={s.block_list}>
                                  {renderMenuList(menuLists.supportMenuList)}
                                </ul>
                              </div>
                            )}
                        </div>
                      </li>
                    )
                  )
                })}
              </ul>
            </div>
          </div>

          <div
            className={s.right_block}
            onMouseEnter={() => setIsRightBlockShown(true)}
            onMouseLeave={() => {
              setIsRightBlockShown(false)
            }}
          ></div>
        </nav>

        <Portal>
          <div className={cn(s.background, { [s.visible]: asideMenuHovered })}></div>
        </Portal>
      </div>
    )
  )
}

export default AsideServicesMenu
