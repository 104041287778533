import PropTypes from 'prop-types'
import { LangBtn, Icon } from '@components'
import s from './AuthPageHeader.module.scss'
import cn from 'classnames'
import { useState, useEffect } from 'react'

export default function AuthPageHeader(props) {
  const { onLogoClick, hideLangBtn } = props

  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const container = document?.getElementById('authContainer')

    const handleScroll = () => {
      setScrollY(container?.scrollTop)
    }

    container?.addEventListener('scroll', handleScroll)

    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isScrolled = scrollY > 50

  return (
    <header
      className={cn(s.header, {
        [s.scrolled]: isScrolled,
      })}
    >
      <div
        className={`container ${s.flex}`}
      >
        <Icon
          name="Logo"
          onClick={() => onLogoClick && onLogoClick()}
          svgwidth="105"
          svgheight="48"
        />

        <div className={s.btns_wrapper}>
          {/* <ThemeBtn authType /> */}
          {!hideLangBtn && <LangBtn authType />}
        </div>
      </div>
    </header>
  )
}

AuthPageHeader.defaultProps = {
  onLogoClick: () => window.open(process.env.REACT_APP_SITE_URL),
}

AuthPageHeader.propTypes = {
  onLogoClick: PropTypes.func,
  hideLangBtn: PropTypes.bool,
}
