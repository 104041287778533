import { useState, useMemo, useCallback } from 'react'
import { FilterCloud, FilterCloudsContainer, IconButton } from '@components'
import s from './Filter.module.scss'
import FilterModal from './FilterModal'
import { useTranslation } from 'react-i18next'
import { exists as isTranslationExists } from 'i18next'
import actions from '@redux/actions'
import { useDispatch } from 'react-redux'
import getFormatedDate from '@utils/getFormatedDate'

export default function useFilter({ filters, fields, setFiltersHandler, itemsCount }) {
  const { t } = useTranslation(['filter'])
  const dispatch = useDispatch()

  const [isFiltersOpened, setIsFiltersOpened] = useState(false)

  const closeFilterModal = () => {
    dispatch(actions.enableScrolling())
    setIsFiltersOpened(false)
  }

  const clearFilterValues = useMemo(() => {
    return fields.reduce((res, { value }) => {
      res[value] = ''
      return res
    }, {})
  }, [fields])

  const filterState = useMemo(() => {
    return fields.reduce((res, field) => {
      res[field.value] = filters[field.value]?.$ || ''
      return res
    }, {})
  }, [filters])

  const activeFilters = Object.keys(filterState).filter(
    key => filterState[key] && filterState[key] !== 'null',
  )

  const isFiltered = !!activeFilters.length

  const FilterClouds = ({ className }) => {
    return (
      <>
        {isFiltered ? (
          <FilterCloudsContainer className={className}>
            {activeFilters?.map(key => {
              const value = filterState[key]?.trim()
              const field = fields.find(field => field.value === key)
              const { label: fieldLabel, value: fieldValue, type, options } = field

              const optionLabel =
                type === 'select'
                  ? (
                      options?.find(el => el.value === value)?.label ??
                      filters.slist
                        .find(el => el.$name === fieldValue)
                        ?.val.find(el => el.$key === value)?.$
                    )?.trim()
                  : type === 'date_range'
                  ? getFormatedDate({ date: value }).date
                  : false

              const translatedOptionLabel = isTranslationExists(`select.${optionLabel}`, {
                ns: 'filter',
              })
                ? t(`select.${optionLabel}`)
                : optionLabel

              return (
                <FilterCloud
                  key={key}
                  label={t(fieldLabel)}
                  value={
                    type === 'select'
                      ? isTranslationExists(`select.${value}`, {
                          ns: 'filter',
                        })
                        ? t(`select.${value}`)
                        : field.transformLable?.(optionLabel) ||
                          translatedOptionLabel ||
                          value
                      : type === 'date_range'
                      ? optionLabel
                      : value
                  }
                  onClick={() => {
                    setFiltersHandler({ ...filterState, [key]: '' })
                  }}
                />
              )
            })}
          </FilterCloudsContainer>
        ) : null}
      </>
    )
  }

  const FilterButton = useCallback(
    ({ disabled }) => (
      <div className={s.filter}>
        <IconButton
          onClick={() => {
            dispatch(actions.disableScrolling())
            setIsFiltersOpened(true)
          }}
          icon="NewIconFilter"
          color="secondary"
          disabled={(!itemsCount && !isFiltered) || disabled}
        />

        {isFiltersOpened && (
          <FilterModal
            filters={filters}
            filterState={filterState}
            closeFilterModal={closeFilterModal}
            setFiltersHandler={setFiltersHandler}
            clearFilterValues={clearFilterValues}
            isFiltered={isFiltered}
            fields={fields}
          />
        )}
      </div>
    ),
    [itemsCount, isFiltersOpened, isFiltered],
  )

  return { FilterButton, FilterClouds, isFiltered, clearFilterValues }
}
