import { Icon } from '@components'
import { AuthPageHeader } from '@pages'
import { useSelector } from 'react-redux'
import { selectors } from '@redux'
import s from './MaintenancePage.module.scss'

export default function MaintenancePage() {
  const billmgrIsLocked = useSelector(selectors.getBillmgrIsLocked)
  const lockTime = useSelector(selectors.getLockTime)

  const reloadPage = () => {
    window.location.reload()
  }

  return (
    billmgrIsLocked && (
      <div className={s.modalBg} id="authContainer">
        <AuthPageHeader onLogoClick={reloadPage} hideLangBtn />

        <div className={s.modalBlock}>
          <div className={s.modalTopBlock}>
            <p className={s.title}>Maintenance</p>
            <Icon className={s.icon} name="Maintenance" />
            {lockTime && <p className={s.text}>{lockTime}</p>}
            <p className={s.text}>Sorry for the inconvenience</p>

            <button className={s.link} type="button" onClick={reloadPage}>
              Reload page
            </button>
          </div>
        </div>
      </div>
    )
  )
}
