import cn from 'classnames'
import PropTypes from 'prop-types'
import { Icon } from '@components'
import s from './CheckBox.module.scss'

export default function Component({
  value,
  onClick,
  disabled,
  className,
  error,
  touched,
  name,
  viewType = 'checkbox',
  id,
  tabIndex,
}) {
  const viewTypes = {
    checkbox: () => (
      <Icon name="NewIconTick" className={cn(s.check, { [s.active]: value })} />
    ),
    radio: () => <p className={cn(s.circle, { [s.active]: value })}></p>,
    switcher: () => <p className={cn(s.circle, { [s.active]: value })}></p>,
    circleCheck: () => <p className={cn('body_s', { [s.active]: value })}>{name}</p>,
    // can be created another types
  }

  return (
    <button
      tabIndex={tabIndex}
      disabled={disabled}
      className={cn(s[viewType], s.btn, {
        [s.error]: touched && error,
        [s.active]: value,
        [s.disabled]: disabled,
        [className]: className,
      })}
      type="button"
      name={name || ''}
      onClick={onClick}
      id={id}
    >
      {viewTypes[viewType]()}
    </button>
  )
}

Component.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  error: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
  viewType: PropTypes.string,
  id: PropTypes.string,
  tabIndex: PropTypes.number,
}
