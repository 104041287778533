export default function SvgComponent(props) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.6673 42.6668C42.6673 42.6668 38.6673 37.3335 32.0007 37.3335C25.334 37.3335 21.334 42.6668 21.334 42.6668M40.0007 24.0002H40.0273M24.0007 24.0002H24.0273M58.6673 32.0002C58.6673 46.7278 46.7282 58.6668 32.0007 58.6668C17.2731 58.6668 5.33398 46.7278 5.33398 32.0002C5.33398 17.2726 17.2731 5.3335 32.0007 5.3335C46.7282 5.3335 58.6673 17.2726 58.6673 32.0002ZM41.334 24.0002C41.334 24.7365 40.737 25.3335 40.0007 25.3335C39.2643 25.3335 38.6673 24.7365 38.6673 24.0002C38.6673 23.2638 39.2643 22.6668 40.0007 22.6668C40.737 22.6668 41.334 23.2638 41.334 24.0002ZM25.334 24.0002C25.334 24.7365 24.737 25.3335 24.0007 25.3335C23.2643 25.3335 22.6673 24.7365 22.6673 24.0002C22.6673 23.2638 23.2643 22.6668 24.0007 22.6668C24.737 22.6668 25.334 23.2638 25.334 24.0002Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
