export default function Refresh(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path d="M11.6673 12.499L8.33398 15.8324L11.6673 19.1657" />
      <path
        d="M15.0525 7.08268C15.5955 8.0232 15.8651 9.09663 15.8311 10.1821C15.7971 11.2676 15.4607 12.322 14.8599 13.2267C14.259 14.1314 13.4176 14.8503 12.4302 15.3026C11.4429 15.755 10.3489 15.9227 9.27137 15.7869"
        strokeLinecap="round"
      />
      <path d="M8.33268 7.50098L11.666 4.16764L8.33268 0.83431" />
      <path
        d="M4.94753 12.9173C4.39664 11.9631 4.12724 10.8725 4.17052 9.77155C4.21379 8.67062 4.56796 7.60446 5.19205 6.69647C5.81613 5.78848 6.68459 5.07581 7.69692 4.64093C8.70924 4.20606 9.82403 4.06677 10.9122 4.23919"
        strokeLinecap="round"
      />
    </svg>
  )
}
