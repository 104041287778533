import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import s from './DashboardInfoBlock.module.scss'
import { useSelector } from 'react-redux'
import { userSelectors } from '@redux'
import dayjs from 'dayjs'

const NAME_CLASS = cn('body_m', s.item_name)
const INFO_CLASS = cn('body_m', s.item_info)

export default function DashboardQuickAccessBlock() {
  const { t } = useTranslation(['other', 'user_settings'])

  const { annual_turnover, prolong_amount_min, prolong_amount, enoughmoneyto } =
    useSelector(userSelectors.getDashboardInfo)

  return (
    <section className={cn('box_styles', s.section)}>
      <h4 className="other_btn_uppercase">{t('payment_information')}</h4>

      <div className={s.info_block_wrapper}>
        <div className={s.info_block_item}>
          <p className={NAME_CLASS}>{t('min_amoun_to_activation')}:</p>
          <p className={INFO_CLASS}>
            {prolong_amount_min?.$ && prolong_amount_min?.$?.includes('EUR')
              ? ('€ ' + prolong_amount_min?.$)?.replace('EUR', '')
              : '-'}
          </p>
        </div>

        <div className={s.info_block_item}>
          <p className={NAME_CLASS}>{t('recomended_amoun_to_activation')}:</p>
          <p className={INFO_CLASS}>
            {prolong_amount?.$ && prolong_amount?.$?.includes('EUR')
              ? ('€ ' + prolong_amount?.$)?.replace('EUR', '')
              : '-'}
          </p>
        </div>

        <div className={s.info_block_item}>
          <p className={NAME_CLASS}>{t('sufficient_until')}:</p>
          <p className={INFO_CLASS}>
            {dayjs(enoughmoneyto?.$).format('DD MMM YYYY') || '-'}
          </p>
        </div>

        <div className={s.info_block_item}>
          <p className={NAME_CLASS}>{t('annual_turnover')}:</p>
          <p className={INFO_CLASS}>
            {annual_turnover?.$ && annual_turnover?.$?.includes('EUR')
              ? ('€ ' + annual_turnover?.$)?.replace('EUR', '')
              : '-'}
          </p>
        </div>
      </div>
    </section>
  )
}
