const SvgComponent = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="3.40426"
      cy="3.40426"
      r="3.40426"
      transform="matrix(-1 0 0 1 15.6211 5.00195)"
    />
    <path d="M6.26172 16.8604C6.26172 16.1282 6.72202 15.475 7.41158 15.2287V15.2287C10.5205 14.1184 13.9179 14.1184 17.0267 15.2287V15.2287C17.7163 15.475 18.1766 16.1282 18.1766 16.8604V17.98C18.1766 18.9905 17.2815 19.7668 16.2811 19.6239L15.9476 19.5763C13.4745 19.223 10.9638 19.223 8.49077 19.5763L8.15722 19.6239C7.15679 19.7668 6.26172 18.9905 6.26172 17.98V16.8604Z" />
    <path
      d="M17.3213 11.9062C18.7986 11.9062 19.9961 10.7086 19.9961 9.23141C19.9961 7.75418 18.7986 6.55664 17.3213 6.55664"
      strokeLinecap="round"
    />
    <path
      d="M20.2549 18.0075L20.517 18.0449C21.303 18.1572 22.0063 17.5473 22.0063 16.7533V15.8736C22.0063 15.2983 21.6447 14.7851 21.1029 14.5916C20.5624 14.3985 20.0109 14.2482 19.4531 14.1406"
      strokeLinecap="round"
    />
    <path
      d="M6.66696 11.9062C5.18972 11.9062 3.99219 10.7086 3.99219 9.23141C3.99219 7.75418 5.18972 6.55664 6.66696 6.55664"
      strokeLinecap="round"
    />
    <path
      d="M3.73336 18.0075L3.47128 18.0449C2.68523 18.1572 1.98196 17.5473 1.98196 16.7533V15.8736C1.98196 15.2983 2.34363 14.7851 2.88543 14.5916C3.42588 14.3985 3.97743 14.2482 4.53516 14.1406"
      strokeLinecap="round"
    />
  </svg>
)
export default SvgComponent
