import { Icon, TooltipWrapper } from '@components'
import s from './CopyText.module.scss'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { useState } from 'react'

export default function CopyText({
  text,
  className,
  promptText,
  promptTextOnHover,
  isBtnDisabled = false,
  label,
  labelClassName,
}) {
  const { t } = useTranslation(['other'])
  const [tooltipText, setTooltipText] = useState(promptTextOnHover || promptText)

  const handleCopyText = () => {
    promptTextOnHover && setTooltipText(promptText)
    navigator.clipboard.writeText(text)
  }

  const params = promptTextOnHover
    ? { afterHide: () => promptTextOnHover && setTooltipText(promptTextOnHover) }
    : { events: { click: true } }

  return (
    <TooltipWrapper
      content={tooltipText || t('copied')}
      wrapperClassName={s.wrapper}
      delayShow={0}
      {...params}
    >
      <button
        className={cn(s.copy_btn, className)}
        onClick={handleCopyText}
        type="button"
        disabled={isBtnDisabled}
      >
        <span className={labelClassName}>{label}</span>
        <Icon
          name="NewIconCopy"
          className={cn(s.copy_icon, { [s.disabled]: isBtnDisabled })}
        />
      </button>
    </TooltipWrapper>
  )
}
