export default function SvgComponent(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.2498 8.28667V36C34.2498 37.7949 32.7947 39.25 30.9998 39.25H9C7.20507 39.25 5.75 37.7949 5.75 36V4C5.75 2.20508 7.20508 0.75 9 0.75H26.0445L34.2498 8.28667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <mask id="path-2-inside-1_3068_47180" fill="currentColor">
        <path d="M25 1H33.5V9.5H26C25.4477 9.5 25 9.05229 25 8.5V1Z" />
      </mask>
      <path
        d="M25 1H33.5H25ZM33.5 11H26C24.6193 11 23.5 9.88071 23.5 8.5L26.5 8H33.5V11ZM26 11C24.6193 11 23.5 9.88071 23.5 8.5V1H26.5V8L26 11ZM33.5 1V9.5V1Z"
        fill="currentColor"
        mask="url(#path-2-inside-1_3068_47180)"
      />
      <rect x="1" y="20" width="23" height="13" rx="2" fill="#17B26A" />
      <path
        d="M8.3868 30L6.9568 27.85L5.6968 30H3.7568L6.0068 26.43L3.7068 22.98H5.6968L7.1068 25.1L8.3468 22.98H10.2868L8.0568 26.52L10.3768 30H8.3868ZM12.9452 28.68H15.1852V30H11.2352V22.98H12.9452V28.68ZM18.5409 30.07C18.0275 30.07 17.5675 29.9867 17.1609 29.82C16.7542 29.6533 16.4275 29.4067 16.1809 29.08C15.9409 28.7533 15.8142 28.36 15.8009 27.9H17.6209C17.6475 28.16 17.7375 28.36 17.8909 28.5C18.0442 28.6333 18.2442 28.7 18.4909 28.7C18.7442 28.7 18.9442 28.6433 19.0909 28.53C19.2375 28.41 19.3109 28.2467 19.3109 28.04C19.3109 27.8667 19.2509 27.7233 19.1309 27.61C19.0175 27.4967 18.8742 27.4033 18.7009 27.33C18.5342 27.2567 18.2942 27.1733 17.9809 27.08C17.5275 26.94 17.1575 26.8 16.8709 26.66C16.5842 26.52 16.3375 26.3133 16.1309 26.04C15.9242 25.7667 15.8209 25.41 15.8209 24.97C15.8209 24.3167 16.0575 23.8067 16.5309 23.44C17.0042 23.0667 17.6209 22.88 18.3809 22.88C19.1542 22.88 19.7775 23.0667 20.2509 23.44C20.7242 23.8067 20.9775 24.32 21.0109 24.98H19.1609C19.1475 24.7533 19.0642 24.5767 18.9109 24.45C18.7575 24.3167 18.5609 24.25 18.3209 24.25C18.1142 24.25 17.9475 24.3067 17.8209 24.42C17.6942 24.5267 17.6309 24.6833 17.6309 24.89C17.6309 25.1167 17.7375 25.2933 17.9509 25.42C18.1642 25.5467 18.4975 25.6833 18.9509 25.83C19.4042 25.9833 19.7709 26.13 20.0509 26.27C20.3375 26.41 20.5842 26.6133 20.7909 26.88C20.9975 27.1467 21.1009 27.49 21.1009 27.91C21.1009 28.31 20.9975 28.6733 20.7909 29C20.5909 29.3267 20.2975 29.5867 19.9109 29.78C19.5242 29.9733 19.0675 30.07 18.5409 30.07Z"
        fill="#fff"
      />
    </svg>
  )
}
