import cn from 'classnames'
import s from './AccessRightsModal.module.scss'
import { CheckBox, Icon, TooltipWrapper } from '@components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { usersOperations } from '@src/Redux'
import { useEffect, useState } from 'react'
import List from './List'

export default function ListItem({
  item,
  userId,
  openedCategory,
  setOpenedCategory,
  nested,
  isRightsChanged,
  setIsRightsChanges,
  plid,
}) {
  const { t } = useTranslation('trusted_users')
  const dispatch = useDispatch()

  const [subItems, setSubItems] = useState([])
  const [childPlid, setChildPlid] = useState('')
  const [isFirstRender, setIsFirstRender] = useState(true)

  const hasSubitems = item.hassubitems?.$ === 'on'
  const itemName = item.name?.$
  const isActive = item.active?.$ === 'on'
  const isPart = item.active?.$ === 'part'

  const isOpened = openedCategory === itemName

  useEffect(() => {
    setIsFirstRender(false)
  }, [])

  useEffect(() => {
    if (!isFirstRender && subItems?.length && isOpened) {
      getSublistRights()
    }
  }, [isRightsChanged])

  const changeRightsHandler = () => setIsRightsChanges(prev => !prev)

  const getSublistRights = () =>
    dispatch(
      usersOperations.getSublistRights(
        plid ?? userId,
        itemName,
        setSubItems,
        setChildPlid,
      ),
    )

  const openSublistHandler = () => {
    if (openedCategory !== itemName) {
      getSublistRights()
      setOpenedCategory(itemName)
    } else {
      setOpenedCategory('')
    }
  }

  return (
    <li className={cn(s.item_wrapper, { [s.opened]: isOpened })}>
      <button
        className={cn(s.item, { [s.item_clickable]: hasSubitems })}
        onClick={() => {
          hasSubitems && openSublistHandler()
        }}
        tabIndex={hasSubitems ? 0 : -1}
      >
        <span className={cn(s.item_name)}>
          {t(`trusted_users.rights_alert.${itemName.replaceAll('.', '_')}`)}
        </span>

        {hasSubitems && <Icon name="Shevron" className={cn(s.shevron)} />}
      </button>
      <div className={cn(s.checkbox_wrapper)}>
        {item.active?.$ && (
          <>
            {isPart && (
              <TooltipWrapper
                wrapperClassName={s.info_tooltip}
                content={t('part_access')}
              >
                <Icon name="NewInfoIcon" />
              </TooltipWrapper>
            )}

            <CheckBox
              viewType="switcher"
              value={isActive}
              className={cn({ [s.part]: isPart })}
              onClick={e => {
                e.stopPropagation()
                const act = isActive ? 'suspend' : 'resume'

                dispatch(
                  usersOperations.changeRightsItem({
                    userId,
                    plid: plid || userId,
                    itemName,
                    act,
                    changeRightsHandler,
                  }),
                )
              }}
            />
          </>
        )}
      </div>
      {!!subItems?.length && isOpened && (
        <List
          items={subItems}
          userId={userId}
          nested={nested + 1}
          isRightsChanged={isRightsChanged}
          setIsRightsChanges={setIsRightsChanges}
          plid={childPlid}
        />
      )}
    </li>
  )
}
