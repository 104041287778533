const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.546 3.513c.406-2.503 3.547-3.32 5.509-1.607 1.527-.51 2.658.131 2.977 1.58 2.677.247 2.484 3.26.26 3.514H5.716c-2.445-.281-2.368-3.75.831-3.487Z"
    />
    <path
      fill="currentColor"
      d="M16.626 14.154c.208 0 .38.173.38.381v2.555c0 .208-.172.381-.38.381H3.536a.384.384 0 0 1-.382-.38v-2.556c0-.208.173-.38.381-.38h13.091Zm0-1.154H3.513A1.53 1.53 0 0 0 2 14.535v2.555c0 .854.693 1.535 1.535 1.535h13.091c.843 0 1.535-.692 1.535-1.535v-2.555c0-.854-.692-1.535-1.535-1.535Z"
    />
    <path
      fill="currentColor"
      d="M4.769 15.151a.581.581 0 0 0-.578.589c0 .323.254.577.578.577a.581.581 0 0 0 .577-.588.571.571 0 0 0-.577-.578ZM7.081 15.151a.581.581 0 0 0-.577.589c0 .323.254.577.577.577a.581.581 0 0 0 .577-.588.571.571 0 0 0-.577-.578ZM9.39 15.151a.581.581 0 0 0-.578.589c0 .323.254.577.578.577a.581.581 0 0 0 .577-.588.571.571 0 0 0-.577-.578Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M12.273 15.736h3.463"
    />
    <path
      fill="currentColor"
      d="M16.626 8.154c.208 0 .38.173.38.381v2.555c0 .208-.172.381-.38.381H3.536a.384.384 0 0 1-.382-.38V8.534c0-.208.173-.38.381-.38h13.091Zm0-1.154H3.513A1.53 1.53 0 0 0 2 8.535v2.555c0 .854.693 1.535 1.535 1.535h13.091c.843 0 1.535-.692 1.535-1.535V8.535c0-.854-.692-1.535-1.535-1.535Z"
    />
    <path
      fill="currentColor"
      d="M4.769 9.151a.581.581 0 0 0-.578.59c0 .322.254.576.578.576a.581.581 0 0 0 .577-.588.572.572 0 0 0-.577-.578ZM7.081 9.151a.581.581 0 0 0-.577.59c0 .322.254.576.577.576a.581.581 0 0 0 .577-.588.572.572 0 0 0-.577-.578ZM9.39 9.151a.581.581 0 0 0-.578.59c0 .322.254.576.578.576a.581.581 0 0 0 .577-.588.572.572 0 0 0-.577-.578Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M12.273 9.736h3.463"
    />
  </svg>
)
export default SvgComponent
