import PropTypes from 'prop-types'
import DomainsTableItem from './DomainsTableItem'
import { useTranslation } from 'react-i18next'
import { CheckBox, CardsContainer, Table } from '@components'
import s from './DomainsTable.module.scss'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'

const HEAD_CELLS = [
  { label: 'Domain name', value: 'Domain name' },
  { label: 'domain_zone', value: 'Tariff' },
  { label: 'status', value: 'status' },
  { label: 'created_at', value: 'createdate' },
  { label: 'valid_until', value: 'real_expiredate' },
]

export default function DomainsTable(props) {
  const { t } = useTranslation(['domains', 'other'])
  const {
    list,
    selctedItem,
    setSelctedItem,
    editDomainHandler,
    deleteDomainHandler,
    renewDomainHandler,
    historyDomainHandler,
    whoisDomainHandler,
    NSDomainHandler,
    rights,
    unpaidItems,
  } = props

  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  const setSelectedAll = val => {
    if (val) {
      setSelctedItem(list)
      return
    }
    setSelctedItem([])
  }

  const isAllActive = list?.length === selctedItem?.length

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })

  const renderItems = () => {
    return list.map(el => {
      const { id, domain, pricelist, real_expiredate, item_status, cost } = el

      const addSelectedItem = (val, ids) => {
        if (val) {
          setSelctedItem(s => [...s, ids])
          return
        }
        setSelctedItem(s => s.filter(item => item?.id?.$ !== ids.id.$))
      }

      return (
        <DomainsTableItem
          key={id?.$}
          id={id?.$}
          domain={domain?.$}
          tariff={pricelist?.$}
          expiredate={real_expiredate?.$}
          status={item_status?.$}
          cost={cost?.$}
          setSelctedItem={addSelectedItem}
          selected={selctedItem}
          el={el}
          historyDomainHandler={historyDomainHandler}
          deleteDomainHandler={deleteDomainHandler}
          editDomainHandler={editDomainHandler}
          renewDomainHandler={renewDomainHandler}
          NSDomainHandler={NSDomainHandler}
          whoisDomainHandler={whoisDomainHandler}
          rights={rights}
          unpaidItems={unpaidItems}
        />
      )
    })
  }

  return (
    <>
      {isDesktop ? (
        <Table stickyHeader className={s.table} vAlignTop>
          <thead>
            <tr>
              <th>
                <CheckBox
                  value={isAllActive}
                  onClick={() => setSelectedAll(!isAllActive)}
                />
              </th>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody className={s.tbody}>{renderItems()}</tbody>
        </Table>
      ) : (
        <>
          <label className={cn('in_row', s.check_all)}>
            <CheckBox value={isAllActive} onClick={() => setSelectedAll(!isAllActive)} />
            {t('Choose all', { ns: 'other' })}
          </label>
          <CardsContainer>{renderItems()}</CardsContainer>
        </>
      )}
    </>
  )
}

DomainsTable.propTypes = {
  list: PropTypes.array,
  setSelctedPayment: PropTypes.func,
  selctedPayment: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object]),
  rights: PropTypes.object,
}

DomainsTable.defaultProps = {
  list: [],
}
