import s from './ExpensesTable.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { CardWrapper, TooltipWrapper } from '@components'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { expensesTranslateFn, getFormatedDate } from '@utils'

export default function Component(props) {
  const { id, number, date, sum, name, tax, el } = props
  const { t } = useTranslation(['billing', 'other', 'dedicated_servers'])
  const mobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })

  const { date: paymentDate } = getFormatedDate({
    date,
    time: el.realdate_locale_time?.$,
  })

  return (
    <>
      {widerThan1024 ? (
        <tr>
          <td>{id}</td>
          <td data-wrap>
            <TooltipWrapper
              content={expensesTranslateFn(name, t)}
              wrapperClassName={s.tooltip_wrapper}
            >
              <div className={cn(s.item_text)}>{expensesTranslateFn(name, t)}</div>
            </TooltipWrapper>
          </td>
          <td>{paymentDate}</td>
          <td>{t(sum)}</td>
          <td data-wrap>{t(number || '-')}</td>
          <td>{tax}</td>
        </tr>
      ) : (
        <CardWrapper className={s.item}>
          <div className={s.tableBlockFirst}>
            {mobile && <div className={s.item_title}>{t('Id')}:</div>}
            <div className={cn(s.item_text, s.first_item)}>{id}</div>
          </div>

          <div className={s.tableBlockSecond}>
            {mobile && <div className={s.item_title}>{t('Name', { ns: 'other' })}:</div>}
            <TooltipWrapper content={expensesTranslateFn(name, t)}>
              <div className={cn(s.item_text, s.second_item)}>
                {expensesTranslateFn(name, t)}
              </div>
            </TooltipWrapper>
          </div>

          <div className={s.tableBlockThird}>
            {mobile && <div className={s.item_title}>{t('date', { ns: 'other' })}:</div>}
            <div className={cn(s.item_text, s.third_item)}>{paymentDate}</div>
          </div>
          <div className={s.tableBlockFourth}>
            {mobile && <div className={s.item_title}>{t('Sum', { ns: 'other' })}:</div>}
            <div className={cn(s.item_text, s.fourth_item)}>{t(sum)}</div>
          </div>
          <div className={s.tableBlockFifth}>
            {mobile && (
              <div className={s.item_title}>
                {t('Paid in payments', { ns: 'other' })}:
              </div>
            )}
            <div className={cn(s.item_text, s.fifth_item)}>{t(number || '-')}</div>
          </div>
          <div className={s.tableBlockSixth}>
            {mobile && <div className={s.item_title}>{t('Tax', { ns: 'other' })}:</div>}
            <div className={cn(s.item_text, s.sixth_item)}>{t(tax)}</div>
          </div>
        </CardWrapper>
      )}
    </>
  )
}

Component.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  unread: PropTypes.bool,
  setSelctedTicket: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
}

Component.defaultProps = {
  id: '',
  theme: '',
  date: '',
  status: '',
  unread: false,
  setSelctedTicket: () => null,
  selected: null,
}
