import Div100vh from 'react-div-100vh'
import AuthPageHeader from './AuthPageHeader/AuthPageHeader'
import s from './AuthPage.module.scss'
import cn from 'classnames'

export default function AuthPage({ children }) {
  return (
    <Div100vh className={cn(s.wrapper)} id="authContainer">
      <div className={cn('container', s.auth_container)}>
        <AuthPageHeader />
        {children}
      </div>
    </Div100vh>
  )
}
