import { useRef, useState } from 'react'
import s from './HeaderSelect.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useOutsideAlerter, useIsTouchDevice } from '@utils'
import { useRightsContext } from '@src/context/RightsContext'
import { NavLink } from 'react-router-dom'
import * as route from '@src/routes'

import { Icon } from '@components'

export default function HeaderSelect({ isScrolled }) {
  const { t } = useTranslation('container', 'other')
  const { menuLists } = useRightsContext()

  const [isOpened, setIsOpened] = useState(false)
  const [isHoveredButton, setIsHoveredButton] = useState(false)
  const [isHoveredDropdown, setIsHoveredDropdown] = useState(false)

  const dropdown = useRef(null)

  const closingTimeoutRef = useRef(null)
  const isTouchDevice = useIsTouchDevice()

  const handleClick = () => {
    if (isTouchDevice) {
      setIsOpened(prev => !prev)
    } else {
      setIsOpened(true)
    }
  }

  useOutsideAlerter(dropdown, isOpened, handleClick)

  const orderRoutes = {
    '/services/cloud_vps': route.CLOUD_VPS_CREATE_PREMIUM_INSTANCE,
    '/services/dedicated_servers': route.DEDICATED_SERVERS_ORDER,
    '/services/domains': route.DOMAINS_ORDERS,
    '/services/vhost': route.SHARED_HOSTING_ORDER,
    '/services/forex': route.FOREX_ORDER,
    '/services/dns': route.DNS_ORDER,
    // '/services/ftp': route.FTP_ORDER,
    // '/services/vps': route.VPS_ORDER,
  }

  const serviceDescriptions = {
    '/services/cloud_vps': 'order_description.cloud_vps',
    '/services/dedicated_servers': 'order_description.dedicated_servers',
    '/services/domains': 'order_description.domains',
    '/services/vhost': 'order_description.shared_hosting',
    '/services/forex': 'order_description.forex',
    '/services/dns': 'order_description.dns',
  }

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      setIsHoveredButton(true)
      setIsOpened(true)
      /* Clear the closing timer if the cursor returns to the button */
      clearTimeout(closingTimeoutRef.current)
    }
  }

  const handleButtonMouseLeave = () => {
    if (!isTouchDevice) {
      setIsHoveredButton(false)

      /* Set a closing timer if the cursor is not over the dropdown */
      closingTimeoutRef.current = setTimeout(() => {
        if (!isHoveredDropdown) {
          setIsOpened(false)
        }
      }, 100)
    }
  }

  const handleDropdownMouseEnter = () => {
    setIsHoveredDropdown(true)

    /* If hovering over the dropdown, cancel the closing timer */
    clearTimeout(closingTimeoutRef.current)
  }

  const handleDropdownMouseLeave = () => {
    setIsHoveredDropdown(false)

    closingTimeoutRef.current = setTimeout(() => {
      if (!isHoveredButton) {
        setIsOpened(false)
      }
    }, 100)
  }

  return (
    <>
      <div
        className={cn(s.wrapper, {
          [s.opened]: isOpened,
          [s.scrolled]: isScrolled,
        })}
      />

      <div className={s.select_btn} ref={dropdown}>
        <button
          type="button"
          className={cn(s.input_wrapper, {
            [s.opened]: isOpened,
          })}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleButtonMouseLeave}
        >
          <p className={cn('body_s_m', s.btn_text)}>{t('to_order', { ns: 'other' })}</p>
          <Icon
            name="Shevron"
            className={cn(s.arrow_icon, {
              [s.active]: isOpened,
            })}
          />
        </button>

        {menuLists.allServicesList.length !== 0 && isOpened && (
          <div
            className={cn(s.dropdown, {
              [s.opened]: isOpened,
            })}
            onMouseEnter={handleDropdownMouseEnter}
            onMouseLeave={handleDropdownMouseLeave}
          >
            {menuLists.allServicesList
              .filter(service => orderRoutes[service.routeName])
              .map(service => {
                const descriptionKey = serviceDescriptions[service.routeName]
                return (
                  <NavLink
                    className={cn('body_s_m', s.list_item_link)}
                    to={orderRoutes[service.routeName]}
                    key={service?.service_id}
                    onClick={() => setIsOpened(false)}
                  >
                    <div className={s.active_page_circle}>
                      <Icon className={s.icon_block} name={service.iconName} />
                    </div>

                    <div className={s.description}>
                      <span>{t(service.name)}</span>
                      <span className={cn('body_xs', s.service_info)}>
                        {t(descriptionKey)}
                      </span>
                    </div>
                  </NavLink>
                )
              })}
          </div>
        )}
      </div>
    </>
  )
}
