export default function Star(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.31293 1.8042C7.62385 1.28754 8.37289 1.28754 8.68382 1.8042L10.2375 4.3858C10.3492 4.57141 10.5314 4.70379 10.7424 4.75266L13.6777 5.4325C14.2652 5.56855 14.4967 6.28093 14.1014 6.73629L12.1262 9.01164C11.9842 9.17523 11.9146 9.38942 11.9333 9.60524L12.1939 12.607C12.246 13.2077 11.64 13.648 11.0848 13.4128L8.31044 12.2374C8.11098 12.1529 7.88577 12.1529 7.6863 12.2374L4.91196 13.4128C4.35673 13.648 3.75075 13.2077 3.80288 12.607L4.0634 9.60524C4.08213 9.38942 4.01253 9.17523 3.87053 9.01164L1.89538 6.73629C1.50009 6.28093 1.73155 5.56855 2.319 5.4325L5.25435 4.75266C5.46539 4.70379 5.64759 4.57141 5.75929 4.3858L7.31293 1.8042Z" />
    </svg>
  )
}
