import { useRef, useState } from 'react'
import s from './PaymentsTable.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { getFormatedDate, useOutsideAlerter } from '@utils'
import { TooltipWrapper, CardWrapper, InlineOptions, Options } from '@components'
import { useDispatch } from 'react-redux'
import { billingActions, billingOperations } from '@src/Redux'

export default function PaymentsTableItem(props) {
  const {
    id,
    number,
    date,
    status,
    payer,
    sum,
    paymethod,
    downloadPdfHandler,
    deletePayment,
    payHandler,
    allowrefund,
    el,
  } = props
  const { t } = useTranslation(['billing', 'other'])
  const mobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const dispatch = useDispatch()
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })
  const widerThan1440 = useMediaQuery({ query: '(min-width: 1440px)' })
  const dropDownEl = useRef()
  const [isOpened, setIsOpened] = useState(false)

  const { date: paymentDate } = getFormatedDate({
    date,
    time: el.create_date_locale_time?.$,
  })

  const closeMenuHandler = () => {
    setIsOpened(!isOpened)
  }

  useOutsideAlerter(dropDownEl, isOpened, closeMenuHandler)

  const downloadHandler = () => {
    downloadPdfHandler(id, number)
    setIsOpened(false)
  }

  const deleteHandler = () => {
    deletePayment(id)
    setIsOpened(false)
  }

  const payRedirectHandler = () => {
    payHandler(id, number, paymethod)
    setIsOpened(false)
  }

  const payBtnHandler = () => {
    setIsOpened(false)
    if (
      (status.trim() === 'New' && paymethod?.trim().toLowerCase() !== 'select') ||
      (status.trim() === 'Payment in progress' &&
        (paymethod?.trim().toLowerCase() === 'yookassa' ||
          paymethod?.trim().toLowerCase().includes('nuvei')))
    ) {
      return payRedirectHandler()
    }
    if (allowrefund === 'off') {
      return dispatch(
        billingOperations.payPayment(id, () =>
          dispatch(billingActions.setIsModalCreatePaymentOpened(true)),
        ),
      )
    } else {
      return payRedirectHandler()
    }
  }

  const options = [
    {
      label: t('Pay'),
      icon: 'Pay',
      disabled: !(status.trim() !== 'Paid' && status.trim() !== 'Canceled'),
      onClick: payBtnHandler,
    },
    {
      label: t('Download'),
      icon: 'Download',
      onClick: downloadHandler,
    },
    {
      label: t('Delete'),
      icon: 'Delete',
      disabled: !(status.trim() === 'New'),
      onClick: deleteHandler,
    },
  ]

  const renderLastColumn = () => {
    return widerThan1440 ? (
      <InlineOptions options={options} />
    ) : (
      <Options options={options} />
    )
  }

  return (
    <>
      {widerThan1024 ? (
        <tr>
          <td>{id}</td>
          <td>{paymentDate}</td>
          <td data-wrap>{t(payer)}</td>
          <td>{t(sum)}</td>
          <td data-wrap>
            <TooltipWrapper
              disabled={t(paymethod).length < 10}
              content={t(paymethod)}
              wrapperClassName={s.tooltip_wrapper}
            >
              {t(paymethod)}
            </TooltipWrapper>
          </td>
          <td>
            <div className={cn(s.status, s[el?.status?.$color])}>{t(status.trim())}</div>
          </td>
          <td align="right">{renderLastColumn()}</td>
        </tr>
      ) : (
        <CardWrapper className={s.item}>
          <div className={s.mobileSettings}>{renderLastColumn()}</div>
          <div className={s.tableBlockFirst}>
            {mobile && <div className={s.item_title}>{t('Id')}:</div>}
            <div className={cn(s.item_text, s.first_item)}>{id}</div>
          </div>
          {/* <div className={s.tableBlockSecond}>
            {mobile && (
              <div className={s.item_title}>{t('Number', { ns: 'other' })}:</div>
            )}
            <div className={cn(s.item_text, s.second_item)}>{number}</div>
          </div> */}
          <div className={s.tableBlockThird}>
            {mobile && <div className={s.item_title}>{t('date', { ns: 'other' })}:</div>}
            <div className={cn(s.item_text, s.third_item)}>{paymentDate}</div>
          </div>
          <div className={s.tableBlockFourth}>
            {mobile && <div className={s.item_title}>{t('Payer', { ns: 'other' })}:</div>}
            <div className={cn(s.item_text, s.fourth_item)}>{t(payer)}</div>
          </div>
          <div className={s.tableBlockFifth}>
            {mobile && <div className={s.item_title}>{t('Sum', { ns: 'other' })}:</div>}
            <div className={cn(s.item_text, s.fifth_item)}>{t(sum)}</div>
          </div>
          <div className={s.tableBlockSixth}>
            {mobile && (
              <div className={s.item_title}>{t('Payment method', { ns: 'other' })}:</div>
            )}
            <TooltipWrapper disabled={t(paymethod).length < 10} content={t(paymethod)}>
              <div className={cn(s.item_text, s.sixth_item)}>{t(paymethod)}</div>
            </TooltipWrapper>
          </div>
          <div className={s.tableBlockSeventh}>
            {mobile && (
              <div className={s.item_title}>{t('status', { ns: 'other' })}:</div>
            )}
            <div
              className={cn(s.item_text, s.seventh_item, s.status, s[el?.status?.$color])}
            >
              {t(status.trim())}
            </div>
          </div>
        </CardWrapper>
      )}
    </>
  )
}
PaymentsTableItem.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  unread: PropTypes.bool,
  setSelctedTicket: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
}

PaymentsTableItem.defaultProps = {
  id: '',
  theme: '',
  date: '',
  status: '',
  unread: false,
  setSelctedTicket: () => null,
  selected: null,
}
