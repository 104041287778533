const HeartHand = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M5.00008 16.7393H7.17532C7.45894 16.7393 7.74082 16.7731 8.01576 16.8406L10.3142 17.3991C10.8129 17.5206 11.3324 17.5324 11.8363 17.4345L14.3776 16.9401C15.0489 16.8093 15.6664 16.4879 16.1504 16.0171L17.9483 14.2681C18.4618 13.7695 18.4618 12.9604 17.9483 12.4609C17.486 12.0112 16.754 11.9606 16.231 12.342L14.1356 13.8707C13.8355 14.0901 13.4703 14.2082 13.0948 14.2082H11.0713L12.3593 14.2082C13.0853 14.2082 13.6733 13.6361 13.6733 12.93V12.6743C13.6733 12.0879 13.2631 11.5767 12.6785 11.4349L10.6906 10.9515C10.3671 10.873 10.0357 10.8334 9.7027 10.8334C8.89869 10.8334 7.44333 11.499 7.44333 11.499L5.00008 12.5208M1.66675 12.1667L1.66675 17C1.66675 17.4667 1.66675 17.7001 1.75758 17.8783C1.83747 18.0352 1.96495 18.1626 2.12176 18.2425C2.30002 18.3334 2.53337 18.3334 3.00008 18.3334H3.66675C4.13346 18.3334 4.36681 18.3334 4.54507 18.2425C4.70188 18.1626 4.82936 18.0352 4.90925 17.8783C5.00008 17.7001 5.00008 17.4667 5.00008 17V12.1667C5.00008 11.7 5.00008 11.4666 4.90925 11.2884C4.82936 11.1316 4.70188 11.0041 4.54507 10.9242C4.36681 10.8334 4.13346 10.8334 3.66675 10.8334H3.00008C2.53337 10.8334 2.30002 10.8334 2.12176 10.9242C1.96495 11.0041 1.83747 11.1316 1.75758 11.2884C1.66675 11.4666 1.66675 11.7 1.66675 12.1667ZM14.3262 2.99357C13.8289 1.95286 12.6822 1.40151 11.5671 1.93367C10.452 2.46583 9.97691 3.72784 10.4438 4.83571C10.7323 5.52041 11.559 6.85003 12.1485 7.76588C12.3663 8.10428 12.4752 8.27348 12.6342 8.37247C12.7707 8.45737 12.9415 8.50313 13.1021 8.49782C13.2893 8.49163 13.4682 8.39955 13.826 8.21538C14.7944 7.71695 16.1752 6.97882 16.7674 6.53013C17.7257 5.80412 17.9631 4.46967 17.2456 3.45522C16.5282 2.44078 15.2773 2.34096 14.3262 2.99357Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HeartHand
