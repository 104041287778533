export default function SvgComponent(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.2498 8.28667V36C34.2498 37.7949 32.7947 39.25 30.9998 39.25H9C7.20507 39.25 5.75 37.7949 5.75 36V4C5.75 2.20508 7.20508 0.75 9 0.75H26.0445L34.2498 8.28667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <mask id="path-2-inside-1_3068_47167" fill="currentColor">
        <path d="M25 1H33.5V9.5H26C25.4477 9.5 25 9.05229 25 8.5V1Z" />
      </mask>
      <path
        d="M25 1H33.5H25ZM33.5 10.5H26C24.8954 10.5 24 9.60457 24 8.5H26H33.5V10.5ZM26 10.5C24.8954 10.5 24 9.60457 24 8.5V1H26V8.5V10.5ZM33.5 1V9.5V1Z"
        fill="currentColor"
        mask="url(#path-2-inside-1_3068_47167)"
      />
      <rect x="1" y="20" width="30" height="13" rx="2" fill="#17B26A" />
      <path
        d="M8.31258 30L6.88258 27.85L5.62258 30H3.68258L5.93258 26.43L3.63258 22.98H5.62258L7.03258 25.1L8.27258 22.98H10.2126L7.98258 26.52L10.3026 30H8.31258ZM12.871 28.68H15.111V30H11.161V22.98H12.871V28.68ZM18.4666 30.07C17.9533 30.07 17.4933 29.9867 17.0866 29.82C16.68 29.6533 16.3533 29.4067 16.1066 29.08C15.8666 28.7533 15.74 28.36 15.7266 27.9H17.5466C17.5733 28.16 17.6633 28.36 17.8166 28.5C17.97 28.6333 18.17 28.7 18.4166 28.7C18.67 28.7 18.87 28.6433 19.0166 28.53C19.1633 28.41 19.2366 28.2467 19.2366 28.04C19.2366 27.8667 19.1766 27.7233 19.0566 27.61C18.9433 27.4967 18.8 27.4033 18.6266 27.33C18.46 27.2567 18.22 27.1733 17.9066 27.08C17.4533 26.94 17.0833 26.8 16.7966 26.66C16.51 26.52 16.2633 26.3133 16.0566 26.04C15.85 25.7667 15.7466 25.41 15.7466 24.97C15.7466 24.3167 15.9833 23.8067 16.4566 23.44C16.93 23.0667 17.5466 22.88 18.3066 22.88C19.08 22.88 19.7033 23.0667 20.1766 23.44C20.65 23.8067 20.9033 24.32 20.9366 24.98H19.0866C19.0733 24.7533 18.99 24.5767 18.8366 24.45C18.6833 24.3167 18.4866 24.25 18.2466 24.25C18.04 24.25 17.8733 24.3067 17.7466 24.42C17.62 24.5267 17.5566 24.6833 17.5566 24.89C17.5566 25.1167 17.6633 25.2933 17.8766 25.42C18.09 25.5467 18.4233 25.6833 18.8766 25.83C19.33 25.9833 19.6966 26.13 19.9766 26.27C20.2633 26.41 20.51 26.6133 20.7166 26.88C20.9233 27.1467 21.0266 27.49 21.0266 27.91C21.0266 28.31 20.9233 28.6733 20.7166 29C20.5166 29.3267 20.2233 29.5867 19.8366 29.78C19.45 29.9733 18.9933 30.07 18.4666 30.07ZM26.379 30L24.949 27.85L23.689 30H21.749L23.999 26.43L21.699 22.98H23.689L25.099 25.1L26.339 22.98H28.279L26.049 26.52L28.369 30H26.379Z"
        fill="#fff"
      />
    </svg>
  )
}
