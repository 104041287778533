/* eslint-disable no-unused-vars */
import PayersTableItem from './PayersTableItem'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { CardsContainer, Table } from '@components/index'

const HEAD_CELLS = [
  { label: 'Id', value: 'Id' },
  { label: 'Name', value: 'Name' },
  { label: 'Payer status', value: 'Payer status' },
]
export default function PayersTable(props) {
  const { t } = useTranslation(['payers', 'other'])
  const { list, openEditModalHandler } = props
  const widerThan768 = useMediaQuery({ query: '(min-width: 768px)' })

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })

  const renderItems = () => {
    return list?.map(el => {
      const { profiletype, name, id } = el
      const editHanler = () => openEditModalHandler(id?.$)
      return (
        <PayersTableItem
          key={id?.$}
          name={name?.$}
          status={profiletype?.$}
          id={id?.$}
          editHanler={editHanler}
        />
      )
    })
  }

  return (
    <>
      {widerThan768 ? (
        <Table stickyHeader>
          <thead>
            <tr>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody>{renderItems()}</tbody>
        </Table>
      ) : (
        <CardsContainer>{renderItems()}</CardsContainer>
      )}
    </>
  )
}

PayersTable.propTypes = {
  list: PropTypes.array,
}

PayersTable.defaultProps = {
  list: [],
}
