export default function SvgComponent(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.2498 8.28667V36C34.2498 37.7949 32.7947 39.25 30.9998 39.25H9C7.20507 39.25 5.75 37.7949 5.75 36V4C5.75 2.20508 7.20508 0.75 9 0.75H26.0445L34.2498 8.28667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <mask id="path-2-inside-1_3068_47140" fill="currentColor">
        <path d="M25 1H33.5V9.5H26C25.4477 9.5 25 9.05229 25 8.5V1Z" />
      </mask>
      <path
        d="M25 1H33.5H25ZM33.5 11H26C24.6193 11 23.5 9.88071 23.5 8.5L26.5 8H33.5V11ZM26 11C24.6193 11 23.5 9.88071 23.5 8.5V1H26.5V8L26 11ZM33.5 1V9.5V1Z"
        fill="currentColor"
        mask="url(#path-2-inside-1_3068_47140)"
      />
      <rect x="1" y="20" width="23" height="13" rx="2" fill="#2A1C3C" />
      <path
        d="M8.68758 22.98V24.35H6.82758V30H5.11758V24.35H3.25758V22.98H8.68758ZM13.8458 30L12.4158 27.85L11.1558 30H9.21578L11.4658 26.43L9.16578 22.98H11.1558L12.5658 25.1L13.8058 22.98H15.7458L13.5158 26.52L15.8358 30H13.8458ZM21.7442 22.98V24.35H19.8842V30H18.1742V24.35H16.3142V22.98H21.7442Z"
        fill="#fff"
      />
    </svg>
  )
}
