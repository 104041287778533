import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import s from './FTPItem.module.scss'
import {
  CheckBox,
  ServerState,
  Options,
  AutoprolongIcon,
  TooltipWrapper,
} from '@components'
import { useDispatch } from 'react-redux'
import { dedicOperations } from '@redux'
import {
  useCreateTicketOption,
  isUnpaidOrder,
  getFormatedDate,
  getItemCostValue,
  formatCountryName,
  getFlagFromCountryName,
} from '@utils'

export default function FTPItem({
  storage,
  rights,
  activeServices,
  setActiveServices,
  setElForProlongModal,
  setElidForEditModal,
  setElidForHistoryModal,
  setElidForInstructionModal,
  setIdForDeleteModal,
  unpaidItems,
}) {
  const { t } = useTranslation(['vds', 'other', 'countries'])

  const dispatch = useDispatch()

  const isToolsBtnVisible =
    Object.keys(rights)?.filter(key => key !== 'ask' && key !== 'filter' && key !== 'new')
      .length > 0

  const isActive = activeServices?.some(service => service?.id?.$ === storage?.id?.$)
  const toggleIsActiveHandler = () => {
    isActive
      ? setActiveServices(activeServices?.filter(item => item?.id?.$ !== storage?.id?.$))
      : setActiveServices([...activeServices, storage])
  }

  const handleToolBtnClick = fn => {
    fn()
  }

  const unpaidOption = isUnpaidOrder(storage, unpaidItems)
  const createTicketOption = useCreateTicketOption(storage.id.$)

  const options = [
    unpaidOption,
    {
      label: t('instruction'),
      icon: 'Instruction',
      disabled: storage?.status?.$ === '1' || !rights?.instruction,
      onClick: () => handleToolBtnClick(setElidForInstructionModal),
    },
    {
      label: t('go_to_panel'),
      icon: 'ToPanel',
      disabled:
        storage.transition?.$ !== 'on' ||
        !rights?.gotoserver ||
        storage?.status?.$ !== '2',
      onClick: () => dispatch(dedicOperations.goToPanel(storage.id.$)),
    },
    {
      label: t('prolong'),
      icon: 'Renew',
      disabled:
        storage?.status?.$ === '1' || storage?.status?.$ === '5' || !rights?.prolong,
      onClick: () => handleToolBtnClick(setElForProlongModal),
    },
    {
      label: t('edit', { ns: 'other' }),
      icon: 'NewIconEdit',
      disabled: !rights?.edit || storage?.status?.$ === '1',
      onClick: () => handleToolBtnClick(setElidForEditModal),
    },
    {
      label: t('history'),
      icon: 'HistoryTimer',
      disabled: !rights?.history || storage?.status?.$ === '1',
      onClick: () => handleToolBtnClick(setElidForHistoryModal),
    },
    createTicketOption,
    {
      label: t('delete', { ns: 'other' }),
      icon: 'Delete',
      disabled:
        storage?.status?.$ === '5' ||
        storage?.scheduledclose?.$ === 'on' ||
        !rights?.delete,
      onClick: () => setIdForDeleteModal([storage.id.$]),
      isDelete: true,
    },
  ]

  const { date: expiredate } = getFormatedDate({
    date: storage?.expiredate?.$,
    time: storage?.i_expiretime?.$,
  })
  const { date: createdate } = getFormatedDate({
    date: storage?.createdate?.$,
    time: storage?.i_opentime?.$,
  })

  const itemCountry = formatCountryName(storage, t)

  return (
    <>
      <tr>
        <td data-target="checkbox">
          <CheckBox value={isActive} onClick={toggleIsActiveHandler} />
        </td>
        <td>{storage?.id?.$}</td>

        <td>
          <div data-td-value-top data-wrap>
            {storage?.pricelist?.$.replace('for', t('for', { ns: 'dns' }))
              .replace('domains', t('domains', { ns: 'dns' }))
              .replace('DNS-hosting', t('dns', { ns: 'crumbs' }))}
          </div>
          <span>{getItemCostValue(storage?.cost?.$, t)}</span>
        </td>
        <td>
          <ServerState className={s.value} server={storage} />
        </td>
        <td>
          {storage?.datacentername?.$ ? (
            <TooltipWrapper className={s.popup} content={itemCountry?.DCName}>
              <img
                src={require(`@images/countryFlags/${getFlagFromCountryName(
                  itemCountry?.countryName,
                )}.png`)}
                width={20}
                height={20}
                alt={itemCountry?.countryName}
              />
            </TooltipWrapper>
          ) : (
            '-'
          )}
        </td>
        <td>{createdate}</td>
        <td>
          <div className="in_row align_start" data-td-value-top>
            {expiredate} <AutoprolongIcon item={storage} />
          </div>
          <ServerState server={storage} onlyAutoDelete />
        </td>

        <td data-target="options">
          {isToolsBtnVisible && (
            <Options options={options} dropdownClassName={s.options_dopdown} />
          )}
        </td>
      </tr>
    </>
  )
}

FTPItem.propTypes = {
  storage: PropTypes.object,
  setElidForEditModal: PropTypes.func,
  setElForProlongModal: PropTypes.func,
  setElidForHistoryModal: PropTypes.func,
  setElidForInstructionModal: PropTypes.func,
  setActiveServices: PropTypes.func,
  activeServices: PropTypes.arrayOf(PropTypes.object),
  rights: PropTypes.object,
}
