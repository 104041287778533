import { useTranslation } from 'react-i18next'
import { notificationsTranslateFn } from '@utils'
import { Icon } from '@components'

import s from './NotificationListItem.module.scss'

export default function NotificationListItem({ arr, removeItem }) {
  const { t } = useTranslation(['container', 'other'])

  return (
    <>
      {Array.isArray(arr) ? (
        arr.map(notif => {
          return (
            <div key={notif?.$id} className={s.message}>
              <p className={'body_s_m'}>{notificationsTranslateFn(notif?.msg?.$, t)}</p>

              <button
                onClick={() => {
                  removeItem(notif?.$id)
                }}
              >
                <Icon name="Close" className={s.close_btn} />
              </button>
            </div>
          )
        })
      ) : (
        <div key={arr?.$id} className={s.notification_message_container}>
          <p className={'body_s_m'}>{notificationsTranslateFn(arr?.msg?.$, t)}</p>

          <button
            onClick={() => {
              removeItem(arr?.$id)
            }}
          >
            <Icon name="Close" className={s.close_btn} />
          </button>
        </div>
      )}
    </>
  )
}
