import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import ManageUserForm from '../ManageUserForm/ManageUserForm'

import {
  Button,
  Icon,
  Alert,
  CheckBox,
  Options,
  CardWrapper,
  TooltipWrapper,
  InlineOptions,
} from '@components'
import { usersActions, usersOperations, usersSelectors } from '@redux'

import s from './TrustedUserCard.module.scss'
import AccessRightsModal from '../AccessRightsModal/AccessRightsModal'

export default function TrustedUserCard({
  name,
  hasAccess,
  status,
  email,
  userId,
  handleUserRolesData,
  isOwner,
  hasPageOwnerFullAccess,
  signal,
  setIsLoading,
  availableRights,
}) {
  const { t } = useTranslation('trusted_users')

  const [isSuccessAlertOpened, setIsSuccessAlertOpened] = useState(false)
  const [isStatusAlertOpened, setIsStatusAlertOpened] = useState(false)
  const [showRightsAlert, setShowRightsAlert] = useState(false)

  const checkIfHasArr = availableRights?.toolbar?.toolgrp

  const isTurnOnUserAllowed = Array.isArray(checkIfHasArr)
    ? availableRights?.toolbar?.toolgrp[0]?.toolbtn?.some(el => el?.$name === 'resume')
    : false
  const isTurnOffUserAllowed = Array.isArray(checkIfHasArr)
    ? availableRights?.toolbar?.toolgrp[0]?.toolbtn?.some(el => el?.$name === 'suspend')
    : false
  const isDeleteUserAllowedToRender = Array.isArray(checkIfHasArr)
    ? availableRights?.toolbar?.toolgrp[0]?.toolbtn?.some(el => el?.$name === 'delete')
    : false

  const isEditUserAllowed = Array.isArray(checkIfHasArr)
    ? availableRights?.toolbar?.toolgrp[0]?.toolbtn?.some(el => el?.$name === 'edit')
    : false

  const isRightsComponentAllowedToRender = Array.isArray(checkIfHasArr)
    ? availableRights?.toolbar?.toolgrp?.some(el => el?.$name === 'rights')
    : false

  const rightsList = useSelector(usersSelectors.getRights)
  const listWithoutProfile = rightsList.filter(item => item.name.$ !== 'clientoption')

  const dispatch = useDispatch()

  const laptopOrHigher = useMediaQuery({ query: '(min-width: 1024px)' })

  const handleAccessAlert = () => {
    setIsSuccessAlertOpened(!isSuccessAlertOpened)
  }
  const handleStatusAlert = () => {
    setIsStatusAlertOpened(!isStatusAlertOpened)
  }

  const handleAccessClick = () => {
    const switchAccess = hasAccess ? 'off' : 'on'

    dispatch(usersOperations.changeUserRights(userId, switchAccess, handleUserRolesData))

    if (switchAccess === 'off') {
      dispatch(
        usersOperations.getRights(userId, isOwner, undefined, signal, setIsLoading),
      )
    }
    setIsSuccessAlertOpened(!isSuccessAlertOpened)
  }

  const handleStatusClick = () => {
    const changeStatus = status === 'on' ? 'suspend' : 'resume'
    setIsStatusAlertOpened(!isStatusAlertOpened)
    dispatch(usersOperations.changeUserStatus(userId, changeStatus, handleUserRolesData))
  }

  useEffect(() => {
    dispatch(usersActions.setRights([]))
    if (!isOwner && !hasAccess && showRightsAlert) {
      dispatch(
        usersOperations.getRights(userId, isOwner, undefined, signal, setIsLoading),
      )
    }
  }, [showRightsAlert])

  let alertAccessText = ''

  if (!hasAccess) {
    alertAccessText = `${t('trusted_users.alerts.access.text')} ${email} ${t(
      'trusted_users.alerts.access.text2',
    )}`
  } else {
    alertAccessText = `${t('trusted_users.alerts.access.text3')} ${email} ${t(
      'trusted_users.alerts.access.text4',
    )}`
  }

  const alertStatusText =
    status === 'on'
      ? `${t('trusted_users.alerts.status.text2')} ${email}?`
      : `${t('trusted_users.alerts.status.text')} ${email}?`

  const RenderAccessSwitcher = () => (
    <CheckBox
      value={hasAccess === 'on'}
      onClick={handleAccessAlert}
      disabled={!hasPageOwnerFullAccess || isOwner}
      viewType="switcher"
    />
  )

  const RenderStatusSwitcher = () => (
    <CheckBox
      value={status === 'on'}
      onClick={handleStatusAlert}
      disabled={
        (!isTurnOnUserAllowed && status === 'off') ||
        (!isTurnOffUserAllowed && status === 'on') ||
        isOwner
      }
      viewType="switcher"
    />
  )
  const [settingsForm, setSettingForm] = useState(false)

  const handleSettingsForm = () => {
    setSettingForm(!settingsForm)
  }

  const handleSubmit = values => {
    // const { email, name, phone, password } = values

    dispatch(
      usersOperations.editUserInfo(
        values['password' + userId],
        values['email' + userId],
        values['phone' + userId],
        values['name' + userId],
        userId,
        handleSettingsForm,
      ),
    )
  }
  const [showRemoveAlert, setShowRemoveAlert] = useState(false)

  const handleRemoveAlert = () => {
    setShowRemoveAlert(!showRemoveAlert)
  }

  const removeUser = () => {
    dispatch(usersOperations.removeUser(userId, handleUserRolesData))
    handleRemoveAlert()
  }

  const options = [
    {
      label: t('trusted_users.user_cards.drop_list.settings'),
      icon: 'NewIconSettings',
      disabled: !isEditUserAllowed,
      onClick: handleSettingsForm,
    },
    {
      label: t('trusted_users.user_cards.drop_list.access_rights'),
      icon: 'NewIconKey',
      disabled: isOwner || hasAccess || !isRightsComponentAllowedToRender,
      onClick: () => setShowRightsAlert(true),
    },
    {
      label: t('trusted_users.user_cards.drop_list.delete'),
      icon: 'Delete',
      disabled: isOwner || !isDeleteUserAllowedToRender,
      onClick: handleRemoveAlert,
    },
  ]

  return (
    <>
      {laptopOrHigher ? (
        <tr>
          <td>{email}</td>
          <td data-wrap>
            <div className="line_clamp clamp_2">{name}</div>
          </td>
          <td>
            <div className={s.row}>
              <p className={s.user_access_lg}>
                {hasAccess
                  ? t('trusted_users.user_cards.turn_off_field')
                  : t('trusted_users.user_cards.turn_on_field')}
              </p>
              <RenderAccessSwitcher />
              {hasAccess && !isOwner && (
                <TooltipWrapper
                  className={s.tooltip}
                  content={t('trusted_users.user_cards.warning_message')}
                >
                  <Icon name="NewInfoIcon" />
                </TooltipWrapper>
              )}
            </div>
          </td>
          <td>
            <div className={s.row}>
              <p className={s.user_status_lg}>
                {status === 'on'
                  ? t('trusted_users.user_cards.active')
                  : t('trusted_users.user_cards.inactive')}
              </p>
              <RenderStatusSwitcher />
            </div>
          </td>
          <td data-target="options">
            <InlineOptions options={options} />
          </td>
        </tr>
      ) : (
        <CardWrapper className={s.min_card_wrapper}>
          <div className={s.email_wrapper}>
            <p className={s.label}>{t('trusted_users.table_header.email')}:</p>
            <p className={s.user_email}>{email}</p>
          </div>
          <div className={s.name_wrapper}>
            <p className={s.label}>{t('trusted_users.table_header.name')}:</p>
            <p className={s.user_name}>{name}</p>
          </div>
          <div className={s.full_access_wrapper}>
            <p className={s.label}> {t('trusted_users.table_header.full_access')}</p>
            <div className={s.toggle_wrapper}>
              <p className={s.user_access}>
                {hasAccess
                  ? t('trusted_users.user_cards.yes')
                  : t('trusted_users.user_cards.no')}
              </p>
              <RenderAccessSwitcher />

              {hasAccess && !isOwner && (
                <div className={s.warning_sign_wrapper}>
                  <TooltipWrapper
                    className={s.tooltip}
                    content={t('trusted_users.user_cards.warning_message')}
                  >
                    <Icon name="NewInfoIcon" />
                  </TooltipWrapper>
                </div>
              )}
            </div>
          </div>
          <div className={s.status_wrapper}>
            <p className={s.label}>{t('trusted_users.table_header.status')}</p>
            <div className={s.toggle_wrapper}>
              <p
                className={cn({
                  [s.user_status]: true,
                  [s.user_status_off]: status !== 'on',
                })}
              >
                {status === 'on'
                  ? t('trusted_users.user_cards.active')
                  : t('trusted_users.user_cards.inactive')}
              </p>
              <RenderStatusSwitcher />
            </div>
          </div>
          <div className={s.options_mobile}>
            <Options options={options} />
          </div>
        </CardWrapper>
      )}

      <Alert
        hasControlBtns={true}
        datatestid="trusted_users_alert_access"
        isOpened={isSuccessAlertOpened}
        controlAlert={handleAccessAlert}
        title={
          hasAccess
            ? t('trusted_users.alerts.access.title2')
            : t('trusted_users.alerts.access.title')
        }
        text={alertAccessText}
        mainBtn={
          <Button
            datatestid="alert_controlBtn_test_access"
            size="small"
            label={
              hasAccess
                ? t('trusted_users.alerts.access.btn_text_ok2').toUpperCase()
                : t('trusted_users.alerts.access.btn_text_ok').toUpperCase()
            }
            type="button"
            className={cn({ [s.add_btn]: true, [s.access]: true })}
            onClick={handleAccessClick}
          />
        }
      />

      <Alert
        hasControlBtns={true}
        datatestid="trusted_users_alert_status"
        isOpened={isStatusAlertOpened}
        controlAlert={handleStatusAlert}
        title={
          status === 'on'
            ? t('trusted_users.alerts.status.title2')
            : t('trusted_users.alerts.status.title')
        }
        text={alertStatusText}
        mainBtn={
          <Button
            datatestid="alert_controlBtn_test_status"
            size="small"
            label={
              status === 'on'
                ? t('trusted_users.alerts.status.btn_text_ok2').toUpperCase()
                : t('trusted_users.alerts.status.btn_text_ok').toUpperCase()
            }
            type="button"
            className={cn({ [s.add_btn]: true, [s.access]: true })}
            onClick={handleStatusClick}
          />
        }
      />

      {showRightsAlert && (
        <AccessRightsModal
          isOpen
          closeModal={() => setShowRightsAlert(false)}
          items={listWithoutProfile}
          userId={userId}
        />
      )}

      {settingsForm && (
        <ManageUserForm
          isOpen={settingsForm}
          formName="settings"
          title={t('trusted_users.rights_alert.usrparam')}
          subtitle={email}
          handleSubmit={handleSubmit}
          closeModal={handleSettingsForm}
          datatestid="settings_form"
          email={email}
          userName={name}
          userId={userId}
        />
      )}
      <Alert
        hasControlBtns={true}
        datatestid="trusted_users_alert_remove"
        isOpened={showRemoveAlert}
        controlAlert={handleRemoveAlert}
        title={t('trusted_users.alerts.remove.title')}
        text={`${t('trusted_users.alerts.remove.text')} ${name}?`}
        mainBtn={
          <Button
            datatestid="alert_removeuser_test_status"
            size="small"
            label={t('trusted_users.alerts.remove.btn_text_ok').toUpperCase()}
            type="button"
            className={cn({ [s.remove_btn]: true, [s.btn]: true })}
            onClick={removeUser}
          />
        }
      />
    </>
  )
}
