import cn from 'classnames'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { BtnLikeRadio, Icon } from '@components'
import { selectors } from '@redux'
import { useOutsideAlerter, getImageIconName } from '@utils'

import s from './SoftwareOSSelect.module.scss'
import ss from '../../../ui/Select/Select.module.scss'

export default function SoftwareOSSelect({
  iconName,
  itemsList,
  state,
  getElement,
  disabled,
  isColored,
  ...other
}) {
  const dropdown = useRef(null)
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  const [isOpened, setIsOpened] = useState(false)

  const initialState = itemsList?.find(item => item.value === state) || itemsList[0]

  const [selectedItem, setSelectedItem] = useState(initialState)

  useOutsideAlerter(dropdown, isOpened, () => setIsOpened(false))

  const itemSelectHandler = item => {
    setSelectedItem(item)
    getElement(item?.value)
    setIsOpened(false)
  }

  const osIcon = getImageIconName(iconName, darkTheme)

  const renderImg = () => {
    return require(`@images/soft_os_icons/${osIcon}.png`)
  }

  return (
    <div className={s.bg} {...other}>
      <BtnLikeRadio
        className={cn(s.select, { [s.opened]: isOpened })}
        isActive={selectedItem.value === state}
        disabled={disabled}
        onClick={() => setIsOpened(true)}
      >
        {iconName === 'iso' ? (
          <Icon name={'NewIconISO'} />
        ) : (
          <img className={cn(s.img)} src={renderImg()} alt="icon" />
        )}

        <div className={cn(s.name_wrapper, 'body_s_light-body_m_light')}>
          {selectedItem?.label} {selectedItem?.os_version?.$}{' '}
          {selectedItem?.architecture?.$ && (
            <span className={s.architecture}>{selectedItem?.architecture?.$}</span>
          )}
          {selectedItem?.$name && (
            <p className={cn('body_xs', s.image_name)}>{selectedItem?.$name}</p>
          )}
        </div>

        <Icon name="Shevron" className={cn(s.shevron_icon, { [ss.opened]: isOpened })} />
      </BtnLikeRadio>

      {itemsList.length !== 0 && (
        <div
          ref={dropdown}
          className={cn(ss.dropdown, {
            [ss.opened]: isOpened,
            [ss.purple_background]: isColored,
          })}
        >
          <div className={s.list}>
            {itemsList?.map((el, index) => {
              return (
                <div
                  className={s.list_item}
                  onClick={() => itemSelectHandler(el)}
                  key={index}
                  tabIndex={0}
                  onKeyDown={null}
                  role="button"
                >
                  <img
                    className={cn(s.img, s.left, s.grey)}
                    src={renderImg()}
                    alt="icon"
                  />

                  <div className="body_s_light-body_m_light">
                    {el.label} {el?.os_version?.$}{' '}
                    {el?.$name && (
                      <p className={cn('body_xs', s.image_name)}>{el?.$name}</p>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
