import { useMediaQuery } from 'react-responsive'
import s from './InstancesList.module.scss'
import InstanceItem from './InstanceItem'
import InstanceItemMobile from './InstanceItemMobile'
import no_vds from '@images/services/no_vds.png'
import { useTranslation } from 'react-i18next'
import { CardsContainer, Table } from '@components'

export const HEAD_CELLS = [
  { label: 'Name', value: 'servername' },
  { label: 'pricing_plan', value: 'pricelist' },
  { label: 'Status', value: 'status' },
  { label: 'data_center', value: 'datacentername' },
  { label: 'Created at', value: 'createdate' },
  { label: 'paid_until', value: 'real_expiredate' },
  { label: 'OS', value: 'instances_os' },
  { label: 'Access IP', value: 'ip' },
]

export default function InstancesList({ instances, editInstance, isFiltered }) {
  const { t } = useTranslation(['cloud_vps', 'vds', 'other'])
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })

  if (!instances?.length) {
    return (
      <div className={s.no_vds_wrapper}>
        <img className={s.no_vds} src={no_vds} alt="no_vds" />
        <p className={s.no_vds_title}>
          {t(isFiltered ? 'no_servers_yet_filtered' : 'no_servers_yet', { ns: 'vds' })}
        </p>
      </div>
    )
  }

  return (
    <>
      {widerThan1024 ? (
        <Table className={s.table} stickyHeader isItemsClickable vAlignTop>
          <thead>
            <tr>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody className={s.tbody}>
            {instances.map(item => {
              return (
                <InstanceItem key={item.id.$} item={item} editInstance={editInstance} />
              )
            })}
          </tbody>
        </Table>
      ) : (
        <CardsContainer className={s.mobile__list}>
          {instances.map(item => {
            return (
              <InstanceItemMobile
                key={item.id.$}
                item={item}
                editInstance={editInstance}
              />
            )
          })}
        </CardsContainer>
      )}
    </>
  )
}
