import { useRef, useState, useEffect } from 'react'
import { Icon, IconButton, Modal } from '@components'

import s from './Options.module.scss'
import cn from 'classnames'
import { useOutsideAlerter } from '@utils'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

export default function Options({
  options,
  columns = 1,
  buttonClassName,
  listItemClassName,
  isTileLayout,
  useModalOnMobile = false,
  mobileModalTitle,
  buttonIcon = 'NewIconSettings',
  dropdownClassName,
  isBackdrop = false,
}) {
  const dropdownEl = useRef()
  const openBtnEl = useRef()
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [optionsCount, setOptionsCount] = useState(0)
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  useEffect(() => {
    const filtredArr = options.filter(option => !option.hidden)
    setOptionsCount(filtredArr.length)
  }, [options])

  const { t } = useTranslation(['other'])

  useOutsideAlerter(dropdownEl, isOptionsOpen, () => setIsOptionsOpen(false), openBtnEl)

  const renderOptionsList = () =>
    options
      .filter(option => !option.hidden)
      .map(option => (
        <li
          key={option.label}
          className={cn(s.tool_item, {
            [s.tile_item]: isTileLayout,
            [listItemClassName]: !isTileLayout,
            [s.modal_item]: isMobile && useModalOnMobile,
          })}
        >
          <button
            className={cn(s.tool_btn, {
              [s.tile_btn]: isTileLayout,
              ['body_xs']: isTileLayout,
              [s.whiteBackground]: isBackdrop || isMobile,
              [s.tool_btn_delete]: option.isDelete,
              [s.delete_white]: option.isDelete && isBackdrop,
            })}
            type="button"
            onClick={() => {
              option.onClick()
              setIsOptionsOpen(false)
            }}
            disabled={option.disabled}
          >
            <span
              className={cn(s.tool_icon__wrapper, {
                [s.services_icons]: isTileLayout,
                [s.mobile]: isMobile || (isBackdrop && !isDesktop),
              })}
            >
              <Icon
                name={option.icon}
                className={cn(s.tool_icon, {
                  [s.tool_icon_delete]: option.isDelete,
                  [s.tile_icon]: isTileLayout,
                })}
                {...(option.icon === 'Clock' && { width: '24px', height: '20px' })}
              />
            </span>
            {option.label}
          </button>
        </li>
      ))

  return isTileLayout ? (
    <ul
      className={cn(s.tools__list, s.tile_wrapper)}
      style={
        isDesktop && optionsCount < 6
          ? { gridTemplateColumns: `repeat(${optionsCount}, 110px)` }
          : undefined
      }
    >
      {renderOptionsList()}
    </ul>
  ) : (
    <div className={s.wrapper} data-propagation>
      <IconButton
        icon={buttonIcon}
        type="button"
        color="third"
        onClick={() => setIsOptionsOpen(prev => !prev)}
        className={cn(s.btn, buttonClassName, { [s.opened]: isOptionsOpen })}
        size={isBackdrop ? 'none' : 'small'}
        btnText={isBackdrop && t('service', { ns: 'other' })}
        data-option-btn
        ref={openBtnEl}
      />

      {isOptionsOpen &&
        (isMobile && useModalOnMobile ? (
          <Modal
            isOpen={isOptionsOpen}
            closeModal={e => {
              e?.stopPropagation()
              setIsOptionsOpen(false)
            }}
            isClickOutside
          >
            <Modal.Header>
              <span className={s.headerText}>
                {mobileModalTitle
                  ? t(mobileModalTitle, { ns: 'other' })
                  : t('service', { ns: 'other' })}
              </span>
            </Modal.Header>
            <Modal.Body>
              <ul className={cn(s.modal__list)}>{renderOptionsList()}</ul>
            </Modal.Body>
          </Modal>
        ) : (
          <>
            {isBackdrop && (
              <div className={cn(s.backdrop, { [s.opened]: isOptionsOpen })} />
            )}
            <ul
              className={cn(s.tools__list, s.dropdown, dropdownClassName)}
              style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
              ref={dropdownEl}
            >
              {renderOptionsList()}
            </ul>
          </>
        ))}
    </div>
  )
}
