import s from './Modal.module.scss'
import cn from 'classnames'

export const Footer = ({ children, className, column, hasPriceFooter }) => (
  <div
    className={cn(s.modal__footer, className, {
      [s.actionFooterWithPrice]: hasPriceFooter,
      [s.footer_column]: column,
    })}
  >
    {children}
  </div>
)
