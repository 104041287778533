import s from '../AccessLogsComponents.module.scss'
import { useTranslation } from 'react-i18next'
import AccessLogsTableItem from './AccessLogsTableItem'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { CardsContainer, Table } from '@components/index'

const HEAD_CELLS = [
  { label: 'time', value: 'time' },
  { label: 'user', value: 'user' },
  { label: 'remote_ip_address', value: 'remote_ip_address' },
]
export default function Component(props) {
  const { t } = useTranslation(['access_log', 'other'])
  const { list } = props
  const widerThanMobile = useMediaQuery({ query: '(min-width: 1024px)' })
  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label, { ns: 'other' })}</th>
    })

  const renderItems = () => {
    return list?.map(({ time, user, ip, id }) => (
      <AccessLogsTableItem key={id?.$} ip={ip?.$} time={time?.$} user={user?.$} />
    ))
  }
  return (
    <>
      {widerThanMobile ? (
        <Table stickyHeader className={s.table}>
          <thead>
            <tr>{renderHeadCells()}</tr>
          </thead>
          <tbody className={s.tbody}>{renderItems()}</tbody>
        </Table>
      ) : (
        <CardsContainer className={s.list}>{renderItems()}</CardsContainer>
      )}
    </>
  )
}

Component.propTypes = {
  list: PropTypes.array,
}

Component.defaultProps = {
  list: [],
}
