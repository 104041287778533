import { useEffect } from 'react'

export default function useOutsideAlerter(ref, condition = true, func, ignoreRef) {
  useEffect(() => {
    condition && document.addEventListener('click', handleClickOutside, true)

    function handleClickOutside(event) {
      // temporary solution
      if (ignoreRef?.current.contains(event.target)) return

      if (ref.current && !ref.current.contains(event.target) && condition) {
        if (func) {
          if (
            event.target.closest('[data-stop-propagation]') &&
            !event.target.closest('[data-propagation]')
          ) {
            event.stopPropagation()
          }

          func()
        }
      }
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref, condition])
}
