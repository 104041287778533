import { useTranslation } from 'react-i18next'
import { Icon, Cta } from '@components'
import * as routes from '@src/routes'
import s from './ErrorPayment.module.scss'
import cn from 'classnames'

export default function ErrorPayment({ title, text, isSupport = true }) {
  const { t } = useTranslation(['billing', 'other', 'payers'])
  const errorTitle = title || t('Payment error')
  const errorText = text || t('payment_error_text')

  return (
    <>
      <div className={s.modalBg} />
      <div className={s.modalBlock}>
        <div className={s.modalTopBlock}>
          <Icon name="ErrorPay" className={s.errorIcon} />

          <p className={cn('headings_h1', s.error)}>{errorTitle}</p>
          <div className={cn('body_m', s.errorText)}>{errorText}</div>
        </div>

        <div className={s.linksBlock}>
          <Cta element="navlink" viewType="button" to={routes.BILLING}>
            {t('trusted_users.rights_alert.dashboard', { ns: 'trusted_users' })}
          </Cta>

          {isSupport && (
            <Cta element="navlink" viewType="button" to={routes.SUPPORT}>
              {t('Support service')}
            </Cta>
          )}
        </div>
      </div>
    </>
  )
}
