const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={20} height={20} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M16.124 11.237c.192 0 .351.16.351.352v2.358c0 .192-.16.351-.351.351H4.042a.355.355 0 0 1-.352-.351v-2.358c0-.192.16-.352.352-.352h12.082Zm0-1.065H4.02c-.778.01-1.396.64-1.396 1.417v2.358c0 .788.64 1.417 1.417 1.417h12.082c.777 0 1.417-.64 1.417-1.417v-2.358c0-.789-.64-1.417-1.417-1.417Z"
    />
    <path
      fill="currentColor"
      d="M5.181 12.157a.537.537 0 0 0-.533.543c0 .299.235.533.533.533a.537.537 0 0 0 .533-.543.528.528 0 0 0-.533-.533ZM7.314 12.157a.537.537 0 0 0-.533.543c0 .299.235.533.533.533a.537.537 0 0 0 .533-.543.528.528 0 0 0-.533-.533ZM9.447 12.157a.537.537 0 0 0-.533.543c0 .299.234.533.533.533a.537.537 0 0 0 .532-.543.528.528 0 0 0-.532-.533Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M12.105 12.697h3.196"
    />
    <path
      fill="currentColor"
      d="M14.726 16.23c-.565 0-1.027.456-1.027 1.015 0 .559.45 1.015 1.004 1.015a1.03 1.03 0 0 0 1.027-1.015c0-.56-.45-1.015-1.004-1.015ZM10.109 17.152c-.566 0-1.027.457-1.027 1.016 0 .559.45 1.015 1.004 1.015a1.03 1.03 0 0 0 1.027-1.015c0-.56-.45-1.016-1.004-1.016ZM5.496 16.23c-.566 0-1.027.456-1.027 1.015 0 .559.45 1.015 1.003 1.015A1.03 1.03 0 0 0 6.5 17.245c0-.56-.45-1.015-1.003-1.015Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      d="m14.71 17.254.012-2.503M10.098 17.254l.011-2.503M5.48 17.254l.012-2.503"
    />
    <path
      fill="currentColor"
      d="M16.124 8.706c.192 0 .351-.16.351-.351V5.996a.355.355 0 0 0-.351-.351H4.042a.355.355 0 0 0-.352.351v2.359c0 .191.16.351.352.351h12.082Zm0 1.065H4.02a1.412 1.412 0 0 1-1.396-1.416V5.996c0-.788.64-1.416 1.417-1.416h12.082c.777 0 1.417.639 1.417 1.416v2.359c0 .788-.64 1.416-1.417 1.416Z"
    />
    <path
      fill="currentColor"
      d="M5.181 7.786a.537.537 0 0 1-.533-.543c0-.298.235-.533.533-.533.298 0 .533.245.533.543a.527.527 0 0 1-.533.533ZM7.314 7.786a.537.537 0 0 1-.533-.543c0-.298.235-.533.533-.533.298 0 .533.245.533.543a.527.527 0 0 1-.533.533ZM9.447 7.786a.537.537 0 0 1-.533-.543c0-.298.234-.533.533-.533.298 0 .532.245.532.543a.527.527 0 0 1-.532.533Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M12.105 7.246h3.196"
    />
    <path
      fill="currentColor"
      d="M14.726 3.714a1.023 1.023 0 0 1-1.027-1.015c0-.56.45-1.016 1.004-1.016A1.03 1.03 0 0 1 15.73 2.7c0 .559-.45 1.015-1.004 1.015ZM10.109 2.791a1.023 1.023 0 0 1-1.027-1.015c0-.56.45-1.015 1.004-1.015a1.03 1.03 0 0 1 1.027 1.015c0 .559-.45 1.015-1.004 1.015ZM5.496 3.714a1.023 1.023 0 0 1-1.027-1.015c0-.56.45-1.016 1.003-1.016A1.03 1.03 0 0 1 6.5 2.7c0 .559-.45 1.015-1.003 1.015Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      d="m14.71 2.689.012 2.503M10.098 2.689l.011 2.503M5.48 2.689l.012 2.503"
    />
  </svg>
)
export default SvgComponent
