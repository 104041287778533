import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import { CardsContainer, Table } from '@components'
import { useTranslation } from 'react-i18next'
import HistoryItem from './HistoryItem'
import HistoryMobileItem from './HistoryMobileItem'
import s from './HistoryModal.module.scss'

const HEAD_CELLS = [
  { label: 'date', value: 'date' },
  { label: 'Changing', value: 'changing' },
  { label: 'user_name', value: 'user_name' },
  { label: 'ip_address', value: 'ip_address' },
]

export default function HistoryList({ historyList }) {
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })

  const { t } = useTranslation(['other'])

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label, { ns: 'other' })}</th>
    })
  return (
    <>
      {widerThan1024 ? (
        <Table stickyHeader className={s.table} color="secondary">
          <thead>
            <tr>{renderHeadCells()}</tr>
          </thead>
          <tbody>
            {historyList?.map((el, index) => {
              return <HistoryItem key={index} history={el} />
            })}
          </tbody>
        </Table>
      ) : (
        <CardsContainer className={s.list}>
          {historyList?.map((el, index) => {
            return <HistoryMobileItem key={index} history={el} />
          })}
        </CardsContainer>
      )}
    </>
  )
}

HistoryList.propTypes = {
  servers: PropTypes.arrayOf(PropTypes.object),
  setElidForEditModal: PropTypes.func,
  setActiveServer: PropTypes.func,
  activeServerID: PropTypes.string,
}
