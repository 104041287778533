import { useTranslation } from 'react-i18next'
import { Table } from '@components'
import { userSelectors } from '@redux'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import s from './DashboardServicesBlock.module.scss'
import { capitalizeFirstLetter, getServicePath, useOpenInNewWindow } from '@utils'

export const HEAD_CELLS = [
  { label: 'product_name', value: 'name' },
  { label: 'amount', value: 'items_total' },
]

export default function DashboardServicesBlock() {
  const { t } = useTranslation(['other', 'cloud_vps'])

  const navigate = useOpenInNewWindow()

  const services = useSelector(userSelectors.getDashboardServices)

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })

  if (!services?.length) {
    return null
  }

  return (
    <section className={cn('box_styles', s.section)}>
      <h4 className="other_btn_uppercase">{t('product_services')}</h4>

      <Table isItemsClickable vAlignTop>
        <thead>
          <tr>{renderHeadCells()}</tr>
        </thead>
        <tbody className={s.tbody}>
          {services?.map(service => {
            const { name, intname, items_used, items_total } = service

            const match = items_used && items_used?.$?.match(/^(\d+)([a-zA-Z]+)$/)

            return (
              <tr
                onClick={e => navigate(e, getServicePath(intname?.$))}
                data-stop-propagation
                tabIndex={0}
                key={intname?.$}
              >
                <td>
                  {t(`dashboard_services.${name?.$.trim()}`, {
                    ns: 'other',
                  })}
                </td>
                <td>
                  <div className={s.amountCount}>
                    {items_total?.$}
                    <p className={s.stopped}>
                      {!!items_used &&
                        match &&
                        !!match?.length &&
                        `(${match?.[1]} ${t(capitalizeFirstLetter(match?.[2]), {
                          ns: 'cloud_vps',
                        })})`}
                    </p>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </section>
  )
}
