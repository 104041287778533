export const CHANGE_PASSWORD = '/change_pass'
export const RESET_PASSWORD = '/reset_pass'
export const LOGIN = '/login'
export const REGISTRATION = '/signup'

export const SOC_NET_AUTH = '/socialnetwork'
export const GEO_CONFIRM = '/geoconfirm'
export const SITE_CART = '/site_cart'

export const HOME = '/'
export const ACCESS_LOG = '/access_log'
export const TRUSTED_USERS = '/trusted_users'
export const SUPPORT = '/support'
export const REQUEST = '/support/requests'
export const REQUEST_ARCHIVE = '/support/requests_archive'

export const USER_SETTINGS = '/usrparam'
export const USER_SETTINGS_PERSONAL = '/usrparam/personal'
export const USER_SETTINGS_ACCESS = '/usrparam/access'
export const PHONE_VERIFICATION = '/phone_verification'

export const DOMAIN_CONTACTS = '/domain_contacts'

export const AFFILIATE_PROGRAM = '/affiliate'
export const AFFILIATE_PROGRAM_ABOUT = 'about'
export const AFFILIATE_PROGRAM_INCOME = 'income'
export const AFFILIATE_PROGRAM_STATISTICS = 'statistics'
export const BILLING = '/billing'
export const SUCCESS_PAYMENT = '/billing/payments/success'
export const FAILED_PAYMENT = '/billing/payments/error'
export const PAYERS = '/payers'

export const CONFIRM_EMAIL = '/confirm_email'
export const CONFIRM_MAIN_EMAIL = '/email_confirmation'

export const ERROR_PAGE = '/error_page'

export const SERVICES = '/services'

export const DOMAINS = '/services/domains'
export const DOMAINS_ORDERS = '/services/domains/domain_orders'
export const DOMAINS_CONTACT_INFO = '/services/domains/domain_orders/contact_info'
export const DOMAINS_NS = '/services/domains/domain_orders/contact_info/domain_params'

export const DOMAINS_TRANSFER_ORDERS = '/services/domains/domain_transfer_orders'
export const DOMAINS_TRANSFER_CONTACT_INFO =
  '/services/domains/domain_transfer_orders/contact_info'
export const DOMAINS_TRANSFER_NS =
  '/services/domains/domain_transfer_orders/contact_info/domain_params'

export const VDS = '/services/vds'
export const VDS_ORDER = '/services/vds/vds_order'
export const VDS_IP = '/services/vds/ip'

export const VPS = '/services/vps'
export const VPS_ORDER = '/services/vps/vps_order'
export const VPS_IP = '/services/vps/ip'

export const CONTRACTS = '/contracts'
export const DEDICATED_SERVERS = '/services/dedicated_servers'
export const DEDICATED_SERVERS_ORDER = '/services/dedicated_servers/order'
export const DEDICATED_SERVERS_IP = '/services/dedicated_servers/ips'

export const SHARED_HOSTING = '/services/vhost'
export const SHARED_HOSTING_ORDER = '/services/vhost/vhost_order'

export const FTP = '/services/ftp'
export const FTP_ORDER = '/services/ftp/ftp_order'

export const DNS = '/services/dns'
export const DNS_ORDER = '/services/dns/dns_order'

export const FOREX = '/services/forex'
export const FOREX_ORDER = '/services/forex/forex_order'

export const SITE_CARE = '/services/site_care'
export const SITE_CARE_ORDER = '/services/site_care/site_care_order'

export const VPN = '/services/vpn'
export const VPN_ORDER = '/services/vpn/vpn_order'

export const PAYMENT_SAVED = '/payment_method_saved'
export const PAYMENT_PROCESSING = '/payment_processing'

export const WORDPRESS = '/services/wordpress'
export const WORDPRESS_ORDER = '/services/wordpress/wordpress_order'

export const CART = '/cart'
export const ORDER = '/order'

export const CLOUD_VPS = '/services/cloud_vps'
export const CLOUD_VPS_IMAGES = '/services/cloud_vps/images'
export const CLOUD_VPS_SSH_KEYS = '/services/cloud_vps/ssh_keys'
export const CLOUD_VPS_CREATE_INSTANCE = '/services/cloud_vps/create_instance'
export const CLOUD_VPS_CREATE_PREMIUM_INSTANCE =
  '/services/cloud_vps/create_instance?type=premium'
export const CLOUD_VPS_CREATE_BASIC_INSTANCE =
  '/services/cloud_vps/create_instance?type=basic'

export const routesWithPagination = [
  CLOUD_VPS,
  CLOUD_VPS_IMAGES,
  CLOUD_VPS_SSH_KEYS,
  DOMAINS,
  VDS,
  VPS,
  DEDICATED_SERVERS,
  SHARED_HOSTING,
  FTP,
  DNS,
  FOREX,
  SITE_CARE,
  VPN,
  REQUEST_ARCHIVE,
  REQUEST,
  ACCESS_LOG,
  `${AFFILIATE_PROGRAM}/${AFFILIATE_PROGRAM_STATISTICS}`,
  `${BILLING}/expenses`,
  `${BILLING}/payments`,
  `${BILLING}/payment_method`,
  `${BILLING}/payers`,
  CONTRACTS,
  PAYERS,
  `${BILLING}/auto_renewal`,
  `${USER_SETTINGS}${DOMAIN_CONTACTS}`,
  `${USER_SETTINGS}${ACCESS_LOG}`,
  `${USER_SETTINGS}${CONTRACTS}`,
  `${USER_SETTINGS}${TRUSTED_USERS}`,
  /^\/services\/cloud_vps\/\d+\/(snapshots|backups|backup_schedules)$/,
]
