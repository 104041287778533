export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.0009 10.9992V16.9992M9.99776 10.9992V16.9992M16.0009 6L15.4567 4.36754C15.1845 3.55086 14.4202 3 13.5594 3H10.4424C9.58154 3 8.81726 3.55086 8.54503 4.36754L8.00088 6M5.04995 8.73418C5.6662 9.55584 5.99932 10.5552 5.99932 11.5823V18C5.99932 20.2091 7.79018 22 9.99932 22H13.9993C16.2085 22 17.9993 20.2091 17.9993 18V11.5823C17.9993 10.5552 18.3324 9.55584 18.9487 8.73418C19.7936 7.60763 18.9898 6 17.5816 6H6.41704C5.00886 6 4.20505 7.60763 5.04995 8.73418Z"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
