import cn from 'classnames'
import { useSelector } from 'react-redux'
import { selectors } from '@redux'
import { getImageIconName } from '@utils'
import { BtnLikeRadio, Icon } from '@components'

import s from './SoftwareOSBtn.module.scss'

export default function SoftwareOSBtn({
  iconName,
  label,
  imageData,
  value,
  state,
  onClick,
  disabled,
}) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  const icon =
    iconName === 'alma'
      ? 'almalinux'
      : iconName === 'astra'
      ? 'astralinux'
      : iconName === 'noos'
      ? 'null'
      : iconName

  const osIcon = getImageIconName(icon, darkTheme)

  const renderImg = () => {
    return require(`@images/soft_os_icons/${osIcon}.png`)
  }

  return (
    <BtnLikeRadio
      onClick={() => onClick(value)}
      isActive={value === state && !disabled}
      disabled={disabled}
    >
      {iconName === 'iso' ? (
        <Icon name={'Iso'} />
      ) : (
        <img
          className={cn(s.img, { [s.without]: icon === 'null' })}
          src={renderImg()}
          alt="icon"
        />
      )}

      <div className={s.name_wrapper}>
        {label} {imageData?.os_version?.$}
        {imageData?.$name && <p className={s.image_name}>{imageData?.$name}</p>}
      </div>
    </BtnLikeRadio>
  )
}
