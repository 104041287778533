import { useTranslation } from 'react-i18next'
import s from './CertificateModal.module.scss'
import { Attention } from '@images'
import { Button, InputField, Modal, Cta } from '@components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import { billingOperations } from '@redux'
import { useDispatch } from 'react-redux'
import { useRightsContext } from '@src/context/RightsContext'

export default function CertificateModal() {
  const { t } = useTranslation(['container', 'other'])

  const dispatch = useDispatch()

  const { toggleCertificateModal } = useRightsContext()

  const [isNotExist, setIsNotExist] = useState(false)

  const validationSchema = Yup.object().shape({
    certificate_code: Yup.string()
      .matches(/^[A-Za-z0-9-]+$/, t('certificate_matches_error', { ns: 'other' }))
      .required(t('enter_certificate_code', { ns: 'other' })),
  })

  return (
    <Modal isOpen closeModal={toggleCertificateModal}>
      <Modal.Header>
        <p>{t('profile.use_certificate')}</p>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validateOnMount={false}
          validationSchema={validationSchema}
          initialValues={{
            certificate_code: '',
          }}
          onSubmit={values => {
            dispatch(
              billingOperations.useCertificate({
                coupon: values.certificate_code,
                errorFunc: () => setIsNotExist(true),
                successFunc: () => toggleCertificateModal(),
              }),
            )
          }}
        >
          {({ errors, touched }) => {
            return (
              <Form id="use-certificate">
                <div className={s.nsInputBlock}>
                  <InputField
                    inputWrapperClass={s.inputHeight}
                    name="certificate_code"
                    label={`${t('certificate_code', { ns: 'other' })}:`}
                    placeholder={t('enter_certificate_code', { ns: 'other' })}
                    error={!!errors.certificate_code}
                    touched={!!touched.certificate_code}
                    infoText={t('enter_certificate_code', { ns: 'other' })}
                  />
                </div>
                {isNotExist && (
                  <p className={s.not_exist__content}>
                    <Attention />
                    {t('certificate_not_exist', { ns: 'other' })}
                  </p>
                )}
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="large"
          label={t('Save', { ns: 'other' })}
          type="submit"
          form="use-certificate"
        />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={toggleCertificateModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
